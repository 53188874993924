import React, { useEffect, useState } from 'react';
import { Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, getUnixTime, fromUnixTime } from 'date-fns';
import * as _ from 'lodash';
import './RatesEvolutionChart.scss';
import { Checkbox } from '@material-ui/core';
import { ChartData } from '../../types';
import { DATE_FORMAT } from '@common/types';
import { NoDataAlert } from '..';
import { ChartUtils } from '../../utils';

interface RatesEvolutionProps {
    rates: ChartData;
    options?: {
        models: {
            models: { [id: string]: string };
            disabled?: string[];
            selected: string[];
            synced: string[];
            syncData?: (modelId: number) => void;
        };
        preferences?: {
            onApply: (selected: string[]) => void;
        };
    };
}

interface modelLineProps {
    [model: string]: {
        color: string;
        hidden: boolean;
        strokeWidth: number;
        opacity: number;
    };
}

export const RatesEvolutionChart: React.FC<RatesEvolutionProps> = (props) => {
    const { rates, options } = props;
    const firstElement = _.head(rates);
    const models = firstElement?.points ?? [];
    const colors = firstElement?.colors ?? {};
    const strokeWidth = firstElement?.strokeWidth ?? {};
    const opacity = firstElement?.opacity ?? {};
    const [modelNames, setModelNames] = useState(_.keys(models));

    const [modelLineState, setModelLineState] = useState<modelLineProps>({});

    useEffect(() => {
        if (!_.isEmpty(options?.models.models)) {
            setInitialOptions();
        }
        const modelLineState = _.reduce(
            modelNames,
            (acc, curr) => {
                return {
                    ...acc,
                    [curr]: {
                        color: colors[curr] || ChartUtils.generateRandomHexColor(),
                        strokeWidth: strokeWidth[curr] || 2,
                        hidden: isHidden(curr),
                        opacity: opacity[curr] ?? 0.7,
                    },
                };
            },
            {} as { modelLineProps }
        );
        setModelLineState(modelLineState);
    }, [JSON.stringify(modelNames)]);

    const setInitialOptions = () => {
        const newModels: string[] = [];
        const modelIds = _.keys(options?.models.models);
        modelIds?.forEach((modelId) => {
            newModels.push(options?.models.models[modelId]);
        });
        options?.models.selected.map((model) => (!newModels.includes(model) ? newModels.unshift(model) : null));
        setModelNames(_.filter(newModels, (item) => item !== null));
    };

    const isHidden = (modelName: string) => {
        if (!_.isEmpty(options)) {
            return !options.models.selected.includes(modelName);
        }
        return false;
    };

    const isDisabled = (modelName: string) => {
        if (!_.isEmpty(options)) {
            return options.models.disabled?.includes(modelName);
        }
        return false;
    };

    const findIdByModelName = (modelName: string): number => {
        if (!_.isEmpty(options)) {
            const keys = _.keys(options.models.models);
            for (const key of keys) {
                if (options.models.models[key] === modelName) return parseInt(key);
            }
        }
        return null;
    };

    if (_.isEmpty(rates)) {
        return <NoDataAlert />;
    }

    const formatDate = (unixTime: number): string => format(fromUnixTime(unixTime), DATE_FORMAT);

    const syncToggle = (model: string) => {
        if (options.preferences) {
            if (!modelLineState[model].hidden) {
                options.preferences.onApply(_.filter(options.models.selected, (m) => m !== model));
            } else {
                options.preferences.onApply([...options.models.selected, model]);
            }
        }
    };

    const toggleHideModelLine = (model: string) => {
        if (isDisabled(model)) return;
        if (!_.isEmpty(options)) {
            syncToggle(model);
            if (!options.models.synced.includes(model)) {
                options.models.syncData(findIdByModelName(model));
                return;
            }
        }
        const newState = {
            ...modelLineState,
            [model]: { ...modelLineState[model], hidden: !modelLineState[model].hidden },
        };
        setModelLineState(newState);
    };

    if (_.isEmpty(modelLineState)) {
        return null;
    }

    const data = _.reduce(
        rates,
        (acc, curr) => {
            const point = { date: getUnixTime(curr.date), ...curr.points };
            acc.push(point);
            return acc;
        },
        [] as { [key: string]: number }[]
    );

    return (
        <div className="rates-evolution-chart">
            <div className="rates-evolution-chart-header">
                {modelNames.map((model, i) => {
                    return (
                        <div
                            key={`chart-label-${model}-${i}`}
                            className={'model-checkbox ' + (isDisabled(model) ? 'disabled' : 'pointer')}
                            onClick={() => toggleHideModelLine(model)}>
                            <Checkbox
                                size="small"
                                className={'model-checkbox-box'}
                                checked={!modelLineState[model]?.hidden}
                                value={model}
                            />
                            <span style={{ color: modelLineState[model]?.color, textDecoration: 'underline' }}> {model}</span>
                        </div>
                    );
                })}
            </div>

            <div className="rates-evolution-line-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data}>
                        <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                        <YAxis type="number" />
                        <XAxis dataKey="date" type="number" tickFormatter={formatDate} domain={['dataMin', 'dataMax']} />

                        <Tooltip labelFormatter={formatDate} />

                        {modelNames.map(
                            (model, i) =>
                                modelLineState[model] && (
                                    <Line
                                        key={`chart-line-${model}-${i}`}
                                        className="rates-evolution-line"
                                        type="monotone"
                                        dataKey={model}
                                        hide={modelLineState[model].hidden}
                                        stroke={modelLineState[model].color}
                                        strokeWidth={modelLineState[model].strokeWidth}
                                        opacity={modelLineState[model].opacity}
                                        dot={false}
                                    />
                                )
                        )}

                        <Brush className="rates-evolution-chart-brush" tickFormatter={(i) => formatDate(data[i].date)} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};
