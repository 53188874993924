import { format } from 'date-fns';

export class Configuration {
    public static isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === 'production';

    public static DEFAULT_DAYS_RANGE = 540;

    static getApiURL = () => {
        return process.env.REACT_APP_API_URL;
    };
    static getApiURLV2 = () => {
        return process.env.REACT_APP_API_URL_V2;
    };
    static getApiECSURLV2 = () => {
        return process.env.REACT_APP_API_ECS_URL_V2;
    };
    static getApiECSURLV3 = () => {
        return process.env.REACT_APP_API_ECS_URL_V3;
    };

    static getMaxValueAnalystFactor = () => {
        return +(process.env.REACT_APP_MAX_ANALYST_FACTOR || 10);
    };

    static getIDPConfiguration = () => {
        const { REACT_APP_IDP_CLIENT_ID, REACT_APP_RATES_20_DOMAIN, REACT_APP_IDP_REDIRECT_URI, REACT_APP_CUSTOM_NODE_ENV } = process.env;

        const envs = {
            production: 'prod',
            staging: 'stage',
            development: 'stage',
            local: 'stage',
        };

        return {
            clientId: REACT_APP_IDP_CLIENT_ID,
            scopes:
                'unit-rates:read unit-rates:write oz-unit-details:read oz-unit-rates:read oz-unit-dashboard:read oz-unit-log:read oz-cohort-details:read oz-cohort-rates:read oz-cohort-dashboard:read oz-cohort-log:read oz-holiday:read ' +
                'oz-unit-details:write oz-unit-rates:write oz-unit-dashboard:write oz-unit-log:write oz-cohort-details:write oz-cohort-rates:write oz-cohort-dashboard:write oz-cohort-log:write oz-holiday:write',
            audience: REACT_APP_RATES_20_DOMAIN,
            redirectUrl: REACT_APP_IDP_REDIRECT_URI,
            directoryHint: Configuration.isProduction ? 'onelogin' : 'email',
            env: envs[REACT_APP_CUSTOM_NODE_ENV],
            useAuthorizationContext: false,
            tokenRefresh: true,
            followRedirect: true,
        };
    };

    static getAdminResEditUrl = (reservationId: string) => {
        const adminDomain = process.env.REACT_APP_ADMIN_DOMAIN;
        return `https://${adminDomain}/admin/dashboard/reservation/${reservationId}`;
    };
    static getAdminUnitPageUrl = (unitId: number) => {
        const adminDomain = process.env.REACT_APP_ADMIN_DOMAIN;
        return `https://${adminDomain}/admin/dashboard/units/rates?UnitID=${unitId}`;
    };
    static getUnitListingPage = (unitId: number) => {
        const vacasaDomain = process.env.REACT_APP_VACASA_DOMAIN;
        return `https://${vacasaDomain}/unit/${unitId}`;
    };
    static getUnitResGrid = (unitId: number, startDate: Date, numberOfDays: number) => {
        const gridStart = format(startDate, 'MM/dd/yyyy');
        const width = numberOfDays + 1;
        const adminDomain = process.env.REACT_APP_ADMIN_DOMAIN;
        return `https://${adminDomain}/admin/resgrid.php?gridstart=${gridStart}&width=${width}&UnitID=${unitId}`;
    };
    static getUnitDashboardPage = (unitId: number) => {
        const ozDomain = process.env.REACT_APP_IDP_REDIRECT_URI;
        return `${ozDomain}/units/${unitId}`;
    };
    static getMaxCSVLength = () => {
        return +process.env.REACT_APP_MAX_CSV_LENGTH;
    };
    static getMaxCSVLengthUnitDate = () => {
        return +process.env.REACT_APP_MAX_CSV_LENGTH_UNIT_DATE;
    };

    static getMaxCSVLengthInitialRateSettingUpload = () => {
        return +process.env.REACT_APP_MAX_CSV_LENGTH_INITIAL_RATE_SETTING_UPLOAD;
    };

    static getABTestingCohorts = () => {
        return (process.env.REACT_APP_AB_TESTING_COHORTS ?? '').split('|');
    };
    static getMaxSizeOfPayload = () => {
        // it's not exactly MB (1024 * 1024), to ensure that the whole payload is less than the limit
        return +process.env.REACT_APP_MAX_SIZE_PAYLOAD_MB * 1000 * 1000;
    };
}
