import React from 'react';
import { Icon } from '@vacasa/react-components-lib';
import './NoDataAlert.scss';

export const NoDataAlert: React.FC = () => {
    return (
        <div className="no-data-alert">
            <Icon.AlertTriangle width={24} height={24} className="no-data-alert-icon" />
            <span>No Data to display</span>
        </div>
    );
};
