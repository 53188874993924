import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { Tabs } from '@vacasa/react-components-lib';
import { UnitsTable, UnitRatesEvolution, Navbar } from '../../components';

import './Units.scss';
import { useParams } from 'react-router-dom';
import { history } from '../../App';
import { AppRoutes } from '../../Routes';

enum UnitTabs {
    SEARCH,
    EVOLUTION,
}

export const Units: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(null);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            setSelectedTab(UnitTabs.EVOLUTION);
        } else {
            setSelectedTab(UnitTabs.SEARCH);
        }
    }, []);

    const handleTabChange = (tab) => {
        if (selectedTab === tab) {
            return;
        }

        if (tab === UnitTabs.SEARCH) {
            return history.push(AppRoutes.UNITS);
        }
    };

    return (
        <React.Fragment>
            <Navbar title="Rates Units" domain="units" />
            <Paper elevation={3} className="units-view">
                <Tabs
                    selected={selectedTab}
                    onChange={handleTabChange}
                    tabs={[
                        {
                            id: 'tab-units-search',
                            label: 'Search',
                            component: <UnitsTable />,
                        },
                        {
                            id: 'tab-units-rates-evolution',
                            label: 'Rates Evolution',
                            disabled: selectedTab !== UnitTabs.EVOLUTION,
                            component: <UnitRatesEvolution />,
                        },
                    ]}
                />
            </Paper>
        </React.Fragment>
    );
};
