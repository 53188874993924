import React from 'react';
import * as _ from 'lodash';
import { Tooltip } from '@material-ui/core';
import './IconToolBar.scss';

interface IconToolBarProps {
    icons: {
        id: string;
        tip: string;
        onClick: () => void;
        icon: JSX.Element;
    }[];
}

export const IconToolBar: React.FC<IconToolBarProps> = (props) => {
    const { icons } = props;
    return (
        <div className="icon-tool-bar">
            {_.map(icons, (icon) => (
                <Tooltip key={icon.id} arrow={true} title={icon.tip}>
                    <div id={icon.id} onClick={icon.onClick}>
                        {icon.icon}
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};
