import React from 'react';
import './EditableBox.scss';
import { AutoCompleteSelect, Icon, Select, Tooltip } from '@vacasa/react-components-lib';
import { GenericInput, ListGroup, MenuSelector } from '..';
import { SelectOption } from '../../types';
import { TableInfo } from '../Table/TableInfo';
import { AppRoutes } from '../../Routes';

type EditableInputsProps = {
    type: 'input';
    havePermission?: boolean;
    inputs: {
        decimals?: boolean;
        max?: number;
        min?: number;
        label?: string;
        value: number | string;
        disable?: boolean;
        onChange: (newValue: number | string) => void;
        tooltip?: string;
        inline?: boolean;
        invalid?: boolean;
        isString?: boolean;
        validationPattern?: RegExp;
        multipleLine?: boolean;
    }[];
};

type EditableMenuSelectProps = {
    type: 'menu';
    value: SelectOption;
    options: SelectOption[];
    onChange: (option: SelectOption) => void;
};
type EditableDropdownSelectProps = {
    type: 'dropdown';
    value: SelectOption;
    options: SelectOption[];
    onChange: (value: SelectOption) => void;
    label: string;
};
type EditableSearchSelectProps = {
    type: 'search';
    value: SelectOption;
    options: SelectOption[];
    onChange: (newValue: SelectOption) => void;
    label: string;
    havePermission?: boolean;
};
type EditableCustomCohortSelectProps = {
    type: 'customCohort';
    value: SelectOption;
    options: SelectOption[];
    onChange: (newValue: SelectOption) => void;
};

type NonEditableChannelMarketShareProps = {
    type: 'channelMarketShare';
    value: SelectOption;
    options: SelectOption[];
    label: string;
};

export type EditableBoxProps = {
    id: string;
    title: string;
    tip: string;
    textBox?: { title: string; description: string };
    textContent?: { content: string; url?: string | AppRoutes; disable?: boolean };
    footer?: { subtitle: string; url?: string | AppRoutes; onClick?: () => void };
    invalid?: boolean;
} & (
    | EditableInputsProps
    | EditableMenuSelectProps
    | EditableDropdownSelectProps
    | EditableSearchSelectProps
    | EditableCustomCohortSelectProps
    | NonEditableChannelMarketShareProps
);

const footerBuilder = (footer: EditableBoxProps['footer']) => {
    if (!footer) return null;

    if (footer.url) {
        return (
            <a href={footer.url} target="_blank" rel="noreferrer">
                {footer.subtitle}
            </a>
        );
    }

    return (
        <span className={`${footer.onClick ? 'as-link' : ''}`} onClick={footer.onClick ? footer.onClick : () => null}>
            {footer.subtitle}
        </span>
    );
};

const textContentBuilder = (content: EditableBoxProps['textContent']) => {
    if (!content) return null;

    if (content.url) {
        return (
            <a href={content.url} target="_blank" rel="noreferrer">
                {content.content}
            </a>
        );
    }

    return <span>{content.content}</span>;
};

export const EditableBox: React.FC<EditableBoxProps> = (props) => {
    const { title, tip, footer, invalid, textContent } = props;

    const TextContent = textContentBuilder(textContent);

    const renderContent = (props: EditableBoxProps) => {
        if (props.type === 'customCohort') {
            return (
                <div className={`${props.options.length === 0 ? 'for-text' : ''}`}>
                    {props.options.length !== 0 ? (
                        <ListGroup options={props.options} onChange={(selected) => props.onChange(selected)} />
                    ) : (
                        <span className="label-text"> No assigned cohorts </span>
                    )}
                </div>
            );
        }

        if (props.type === 'menu') {
            return (
                <MenuSelector
                    value={props.value}
                    getDisplayText={(option) => option.display}
                    multiple={false}
                    options={props.options}
                    onChange={(selected) => props.onChange(selected)}
                    classname="editable-box-menu"
                />
            );
        }

        if (props.type === 'dropdown') {
            return (
                <div className="editable-box-select">
                    <Select
                        value={props.value.value}
                        options={props.options}
                        label={props.label}
                        onChange={(event) => {
                            const selected = props.options.find((o) => o.value === event.target.value);
                            props.onChange(selected);
                        }}
                        disabled={invalid}
                    />
                </div>
            );
        }
        if (props.type === 'search') {
            return (
                <Tooltip message={`${props.havePermission ? '' : 'Editor access required'}`}>
                    <div className="editable-box-select">
                        <AutoCompleteSelect
                            value={props.value}
                            options={props.options}
                            label={props.label}
                            getOptionLabel={(item) => item.display}
                            onChange={(newValue) => {
                                props.onChange(newValue);
                            }}
                            disabled={invalid || !props.havePermission}
                            disabledAutoComplete={!props.havePermission}
                        />
                    </div>
                </Tooltip>
            );
        }
        if (props.type === 'channelMarketShare') {
            return (
                <div className={`${props.options.length == 0 ? 'for-text' : ''}`}>
                    {props.options.length != 0 ? (
                        <TableInfo options={props.options} />
                    ) : (
                        <span className="label-text"> No data available</span>
                    )}
                </div>
            );
        }

        return (
            <div className="editable-box-input-father">
                {props.inputs.map((input, i) => {
                    return (
                        <div key={i} className={input.inline ? 'editable-box-input-inline' : 'editable-box-input'}>
                            <label>{input.label}</label>
                            <GenericInput
                                value={input.value}
                                onChange={input.onChange}
                                decimals={input.decimals}
                                max={input.max}
                                min={input.min}
                                disable={input.disable || !props.havePermission}
                                tooltip={input.tooltip}
                                invalid={input.invalid}
                                isString={input.isString}
                                validationPattern={input.validationPattern}
                                multipleLine={input.multipleLine}
                            />
                        </div>
                    );
                })}
                {props.textBox && (
                    <div className="editable-box-input-subtext">
                        <div className="title">{props.textBox.title}</div>
                        <div className="description">{props.textBox.description}</div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={invalid ? 'error' : ''}>
            <div className="editable-box">
                <div className="editable-box-header">
                    <span className="editable-box-title">{title}</span>
                    <Tooltip message={tip} arrow={true}>
                        <span className="editable-box-icon">
                            <Icon.AlertCircle className="pointer" name="info" width={20} height={20} />
                        </span>
                    </Tooltip>
                </div>

                <div className="editable-box-content">{renderContent(props)} </div>
                <div className={TextContent ? 'editable-box-text-content' : ''}>{TextContent}</div>
                <div className="info-box-subtitle">{footerBuilder(footer)}</div>
            </div>
        </div>
    );
};
