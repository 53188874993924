import { StorageKeys } from '../types';
import decode from 'jwt-decode';
import { IdentityToken } from '@common/types';
import { flowId } from '../App';

export class LoggingService {
    private static getTracingData(): object {
        let user = undefined;
        const identityToken = localStorage.getItem(StorageKeys.ID_TOKEN);
        if (identityToken) {
            const decodedUser = decode(identityToken) as IdentityToken;
            user = {
                user_name: decodedUser.name,
                user_email: decodedUser.email,
            };
        }

        return {
            flow_id: flowId,
            user,
        };
    }

    public static info(message: string, data: object = {}) {
        const context = { ...data, ...LoggingService.getTracingData() };
        const logger = (window as any).DD_LOGS?.logger;
        if (logger) {
            logger.info(message, context);
            return;
        }
        console.log(message, context);
    }

    public static warn(message: string, data: object = {}) {
        const context = { ...data, ...LoggingService.getTracingData() };
        const logger = (window as any).DD_LOGS?.logger;
        if (logger) {
            logger.warn(message, context);
            return;
        }
        console.warn(message, context);
    }

    public static error(message: string, data: object = {}) {
        const context = { ...data, ...LoggingService.getTracingData() };
        const logger = (window as any).DD_LOGS?.logger;
        if (logger) {
            logger.error(message, context);
            return;
        }
        console.error(message, context);
    }
}
