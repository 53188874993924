import { RateService } from '../../services/RateService';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUnitTableFilters = createAsyncThunk('ui/fetchUnitTableFilters', async (request: undefined, { rejectWithValue }) => {
    try {
        return await RateService.getSelectFiltersForUnits();
    } catch (e) {
        return rejectWithValue(RateService.makeError('fetchUnitTableFilters', e));
    }
});
