import React from 'react';
import { Navbar } from '../../components';
import { Paper } from '@material-ui/core';
import Iframe from 'react-iframe';
import './Northstar.scss';

export const Northstar: React.FC = () => {
    const getHeight = () => {
        const { innerHeight: height } = window;
        return (height - 90).toString();
    };

    return (
        <React.Fragment>
            <Navbar title={'Northstar'} domain={'northstar'} />
            <Paper elevation={3}>
                <Iframe url={process.env.REACT_APP_NORTHSTAR_URL} id="northstar-id" className="northstar-iframe" height={getHeight()} />
            </Paper>
        </React.Fragment>
    );
};
