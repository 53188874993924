import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { AppState } from '../store';
import { ModelStatusEnum, RateModelDTO } from '@common/types';
import { fetchModels } from './model.actions';

type ModelState = EntityState<RateModelDTO> & {
    isFetchingModels: boolean;
    error: Error | null;
};

const modelAdapter = createEntityAdapter<RateModelDTO>({
    selectId: (model) => model.id,
});

const initialState: ModelState = modelAdapter.getInitialState({
    isFetchingModels: false,
    error: null,
});

export const modelSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        setIsFetchingModels(state, action: PayloadAction<boolean>) {
            state.isFetchingModels = action.payload;
        },
        updateModel(state, action: PayloadAction<RateModelDTO>) {
            const model = action.payload;
            modelAdapter.upsertOne(state, model);
        },
        upsertModels(state, action: PayloadAction<RateModelDTO[]>) {
            modelAdapter.upsertMany(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchModels.pending, (state) => {
            state.error = null;
        });
        builder.addCase(fetchModels.fulfilled, (state, action) => {
            modelAdapter.upsertMany(state, action.payload);
        });
        builder.addCase(fetchModels.rejected, (state, action) => {
            state.error = action.payload as Error;
        });
    },
});
export const { selectAll: selectAllModels } = modelAdapter.getSelectors((state: AppState) => state.model);

export const { setIsFetchingModels, updateModel, upsertModels } = modelSlice.actions;

export const selectActiveModels = createSelector([selectAllModels], (models) => {
    return _.filter(models, (model) => model.status === ModelStatusEnum.ACTIVE);
});
export const selectExperimentalModels = createSelector([selectAllModels], (models) => {
    return _.filter(models, (model) => model.status === ModelStatusEnum.EXPERIMENTAL);
});
