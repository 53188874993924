import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { FilterOption } from '../../types/VirtualizedTable';
import { fetchUnitTableFilters } from './ui.actions';
import { AppState } from '../store';

export enum UiElements {
    UnitTableFilters = 'UnitTableFilters',
}
type UiElementsType = { id: UiElements.UnitTableFilters; options: FilterOption[] };

type UiState = EntityState<UiElementsType>;
const uiAdapter = createEntityAdapter<UiElementsType>({
    selectId: (UiElementsType) => UiElementsType.id,
});

const initialState: UiState = uiAdapter.getInitialState({});

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUnitTableFilters.fulfilled, (state, action) => {
            uiAdapter.upsertOne(state, { id: UiElements.UnitTableFilters, options: action.payload });
        });
    },
});

export const { selectById } = uiAdapter.getSelectors((state: AppState) => state.ui);
