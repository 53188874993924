import React from 'react';
import { Navbar } from '../../components';
import { Paper } from '@material-ui/core';
import Iframe from 'react-iframe';
import './HolidayManager.scss';

export const HolidayManager: React.FC = () => {
    const getHeight = () => {
        const { innerHeight: height } = window;
        return (height - 90).toString();
    };

    return (
        <React.Fragment>
            <Navbar title={'Holiday Manager'} domain={'holidayManager'} />
            <Paper elevation={3}>
                <Iframe
                    url={process.env.REACT_APP_HOLIDAY_MANAGER}
                    id="holiday-manager-id"
                    className="holiday-manager-iframe"
                    height={getHeight()}
                />
            </Paper>
        </React.Fragment>
    );
};
