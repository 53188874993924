import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Tooltip } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import './GenericInput.scss';
import { OnlyNumbersRegex, TwoDecimalNumberRegex } from '@common/types';

interface GenericInputProps {
    value: number | string;
    onChange: (value: number | string) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    decimals?: boolean;
    min?: number;
    max?: number;
    className?: string;
    placeholder?: string;
    disable?: boolean;
    tooltip?: string;
    originalValue?: string;
    invalid?: boolean;
    validationPattern?: RegExp;
    isString?: boolean;
    multipleLine?: boolean;
    dataTestId?: string;
    maxLength?: number;
}

export const GenericInput = React.forwardRef<HTMLInputElement, GenericInputProps>((props, ref) => {
    const {
        value,
        onChange,
        decimals,
        min,
        max,
        className,
        onKeyDown,
        placeholder,
        disable,
        tooltip,
        originalValue,
        invalid,
        validationPattern,
        isString,
        multipleLine,
        dataTestId,
        maxLength,
    } = props;
    const [stringValue, setStringValue] = useState<string>('');

    useEffect(() => {
        const asString = _.isNaN(value) ? '' : _.toString(value);
        setStringValue(asString);
    }, [value]);

    const handleOnChangeMultipleLine = (e) => {
        let newValue = e.target.value;
        if (_.isEmpty(newValue)) {
            setStringValue('');
            onChange(null);
            return;
        }
        if (validationPattern && !validationPattern.test(newValue.trim())) {
            return;
        }
        setStringValue(newValue);
        onChange(newValue.trim());
    };
    const handleOnChangeSingleLine = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;
        if (_.isEmpty(newValue)) {
            setStringValue('');
            onChange(null);
            return;
        }
        if (!isString) {
            if (decimals) {
                if (newValue === '0.' || newValue === '0.0' || newValue === '0.00') {
                    setStringValue(newValue);
                    return;
                }
                if (newValue.startsWith('.')) {
                    newValue = '0' + newValue;
                    setStringValue(newValue);
                    return;
                }
            }
            const valueAsNumber = Number(newValue);
            if ((!_.isUndefined(min) && valueAsNumber < min) || (!_.isUndefined(max) && valueAsNumber > max)) {
                return;
            }

            const isNumeric = _.isNumber(valueAsNumber) && !_.isNaN(valueAsNumber);
            const isValidNumber = isNumeric && OnlyNumbersRegex.test(newValue);
            const isValidDecimalNumber = isNumeric && TwoDecimalNumberRegex.test(newValue);

            if (newValue === '-') {
                setStringValue('-');
            }

            if (isValidNumber || (decimals && isValidDecimalNumber)) {
                setStringValue(newValue.trim());
                onChange(valueAsNumber);
            }
        } else {
            if (validationPattern && !validationPattern.test(newValue.trim())) {
                return;
            }
            setStringValue(newValue);
            onChange(newValue.trim());
        }
    };
    return (
        <Tooltip message={tooltip ? tooltip : disable ? 'Editor Access Required' : ''}>
            {!multipleLine ? (
                <input
                    data-testid={dataTestId}
                    className={`generic-input ${stringValue != originalValue ? 'changed' : ''} ${className ?? ''} ${
                        invalid ? 'error' : ''
                    }`}
                    type="string"
                    value={stringValue}
                    onChange={handleOnChangeSingleLine}
                    onKeyDown={onKeyDown}
                    ref={ref}
                    placeholder={placeholder}
                    disabled={disable}
                    maxLength={maxLength}
                />
            ) : (
                <textarea
                    className={`generic-input multiple ${stringValue != originalValue ? 'changed' : ''} ${className ?? ''} ${
                        invalid ? 'error' : ''
                    }`}
                    value={stringValue}
                    onChange={handleOnChangeMultipleLine}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    disabled={disable}
                    maxLength={127}
                />
            )}
        </Tooltip>
    );
});
