import React, { useEffect, useState } from 'react';
import { DatePicker } from '@vacasa/react-components-lib';
import { addDays, differenceInDays, format, subDays } from 'date-fns';
import { DATE_FORMAT } from '@common/types';
import { isValidDateRange, parseDate } from '@common/utils';
import './DateRangePickers.scss';

interface DateRangePickersProps {
    start: Date;
    end: Date;
    onDateRangeChange: (startDate: Date, endDate: Date, numberOfDays: number, holiday?: string) => void;
    decorators?: { start: string; end: string };
}

export const DateRangePickers: React.FC<DateRangePickersProps> = (props) => {
    const { start, end, onDateRangeChange, decorators } = props;

    const [displayStartDate, setDisplayStartDate] = useState<string>(format(start, DATE_FORMAT));
    const [displayEndDate, setDisplayEndDate] = useState<string>(format(end, DATE_FORMAT));

    useEffect(() => {
        setDisplayStartDate(format(start, DATE_FORMAT));
        setDisplayEndDate(format(end, DATE_FORMAT));
    }, [start, end]);

    const handleStartDateChange = (date: Date | null, value?: string | null) => {
        if (date && isValidDateRange(date, end)) {
            const numberOfDays = differenceInDays(end, date);
            onDateRangeChange(date, end, numberOfDays);
        }
        const displayValue = value ?? '';
        setDisplayStartDate(displayValue);
    };

    const handleEndDateChange = (date: Date | null, value?: string | null) => {
        if (date && isValidDateRange(start, date)) {
            const numberOfDays = differenceInDays(date, start);
            onDateRangeChange(start, date, numberOfDays);
        }
        const displayValue = value ?? '';
        setDisplayEndDate(displayValue);
    };

    const handleStartDateLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const date = parseDate(e.target.value);
        if (!isValidDateRange(date, end)) {
            // TODO: use the alert component as styled modal
            alert(`Invalid date range - start: ${date}, end: ${end}`);
            setDisplayStartDate(format(start, DATE_FORMAT));
        }
    };

    const handleEndDateLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const date = parseDate(e.target.value);
        if (!isValidDateRange(start, date)) {
            // TODO: use the alert component as styled modal
            alert(`Invalid date range - start: ${date}, end: ${end}`);
            setDisplayEndDate(format(end, DATE_FORMAT));
        }
    };

    return (
        <div className="date-range-pickers">
            <DatePicker
                variant="inline"
                value={start}
                onChange={handleStartDateChange}
                maxDate={subDays(end, 1)}
                decoration={decorators?.start}
                label="Date Range"
                disableToolbar={true}
                inputValue={displayStartDate}
                onBlur={handleStartDateLostFocus}
                customClass="date-picker"
                autoOk={true}
            />
            <DatePicker
                variant="inline"
                value={end}
                onChange={handleEndDateChange}
                minDate={addDays(start, 1)}
                decoration={decorators?.end}
                disableToolbar={true}
                inputValue={displayEndDate}
                onBlur={handleEndDateLostFocus}
                customClass="date-picker"
                autoOk={true}
            />
        </div>
    );
};
