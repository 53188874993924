import React from "react";
import {Link} from "react-router-dom";
import {DataSourceBuilder} from "../VirtualizedTable/DataSourceBuilder";
import {CohortRateTableValues} from "../../types";
import {UiUtils} from "../../utils";
import {HolidaysIcon} from "../HolidaysIcon/HolidayIcons";
import {Column, ActionColumn} from "../../types/VirtualizedTable";
import {AppRoutes} from "../../Routes";
import {Icon} from "@vacasa/react-components-lib";
import {Tooltip} from "@material-ui/core";

import "./RatesTable.scss";
import {Configuration} from "../../Configuration";
import {format} from "date-fns";
import {DATE_FORMAT} from "@common/types";

export enum CohortRateTableColumnsKeys {
    DATE = "date",
    DOW = "dow",
    ANALYST_FACTOR = "analystFactor",
    PREDICTED_RATE = "predictedRate",
    ADJUSTED_RATE = "adjustedRate",
    ADVERTISED_RATE = "advertisedRate",
    FACTOR_DISTRIBUTION = "factor",
    OCCUPANCY = "occupancy",
}

export const DefaultCohortRateTableVisibleColumns = [
    CohortRateTableColumnsKeys.DOW,
    CohortRateTableColumnsKeys.ANALYST_FACTOR,
    CohortRateTableColumnsKeys.PREDICTED_RATE,
    CohortRateTableColumnsKeys.ADJUSTED_RATE,
    CohortRateTableColumnsKeys.ADVERTISED_RATE,
    CohortRateTableColumnsKeys.FACTOR_DISTRIBUTION,
];

const FactorDistribution: React.FC<{min: number; max: number; avg: number; ratio: number}> = (props) => {
    return (
        <div className="factor-distribution-container">
            <Tooltip arrow={true} title={"Min. Factor"}>
                <div className="item">{props.min}</div>
            </Tooltip>
            <span>|</span>
            <Tooltip arrow={true} title={"Max. Factor"}>
                <div className="item">{props.max}</div>
            </Tooltip>
            <span>|</span>
            <Tooltip arrow={true} title={"Avg. Factor"}>
                <div className="item">{props.avg}</div>
            </Tooltip>
            <span>|</span>
            <Tooltip arrow={true} title={"Factor Applied %"}>
                <div className="item">{Math.trunc(props.ratio * 100)}%</div>
            </Tooltip>
            <div>{props.children}</div>
        </div>
    );
};
const today = format(new Date(), DATE_FORMAT);
export const CohortRateTableColumns: {[key in CohortRateTableColumnsKeys]: Column | ActionColumn} = {
    [CohortRateTableColumnsKeys.DATE]: {
        field: "date",
        label: "Date",
        displayConfiguration: {flexGrow: 0.7, justifyContent: "flex-start"},
        fieldConfiguration: {
            customRightComponent: (obj) => {
                if (obj["holidays"] && obj["holidays"].length > 0) {
                    return <HolidaysIcon holidays={obj["holidays"]} />;
                }
            },
        },
    },

    [CohortRateTableColumnsKeys.DOW]: {field: "dow", label: "DOW", displayConfiguration: {flexGrow: 0.5}},

    [CohortRateTableColumnsKeys.ANALYST_FACTOR]: {
        field: "analystFactor",
        label: "Analyst Factor",
        editable: {
            fieldType: "input",
            copyable: true,
            inputConfiguration: {
                minValue: 0,
                maxValue: 10,
                placeholder: true,
                validation: (obj) => {
                    if (
                        (obj["analystFactor"] === 0 || obj["analystFactor"] > Configuration.getMaxValueAnalystFactor()) &&
                        new Date(obj["date"]) >= new Date(today)
                    ) {
                        const isValid = false;
                        return {isValid, validationSummary: "Analyst Factor must be a value between 0.01 and 10"};
                    }
                    return {isValid: true};
                },
            },
        },
    },

    [CohortRateTableColumnsKeys.PREDICTED_RATE]: {
        field: "predictedRate",
        label: "Predicted Rate",
        fieldConfiguration: {format: UiUtils.formatToCurrency},
    },

    [CohortRateTableColumnsKeys.ADJUSTED_RATE]: {
        field: "previewAdjusted",
        label: "Adjusted Rate",
        fieldConfiguration: {format: UiUtils.formatToCurrency},
    },
    [CohortRateTableColumnsKeys.ADVERTISED_RATE]: {
        field: "advertisedRate",
        label: "Advertised Rate",
        fieldConfiguration: {format: UiUtils.formatToCurrency},
    },

    [CohortRateTableColumnsKeys.FACTOR_DISTRIBUTION]: {
        label: "Factor Distribution",
        field: "factor",
        displayConfiguration: {flexGrow: 1.5},
        func: (obj) => (
            <>
                <FactorDistribution min={obj.factor_min} max={obj.factor_max} avg={obj.factor_avg} ratio={obj.factor_ratio}>
                    {obj.cohortName && (
                        <Tooltip arrow={true} title={"Go to cohort factor distribution page"}>
                            <Link
                                to={`${AppRoutes.COHORT_FACTOR.replace(":name", encodeURIComponent(obj.cohortName))}?date=${obj.date}`}
                                target="_blank"
                            >
                                <Icon.ExternalLink height={18} width={18} />
                            </Link>
                        </Tooltip>
                    )}
                </FactorDistribution>
            </>
        ),
    },

    [CohortRateTableColumnsKeys.OCCUPANCY]: {
        field: "occupancy",
        label: "Occupancy",
        fieldConfiguration: {format: UiUtils.transformInPercentage},
    },
};

export const getCohortRateTableBuilder = (columns: string[]): DataSourceBuilder<CohortRateTableValues> => {
    const builder = new DataSourceBuilder<CohortRateTableValues>();

    builder.addColumn(CohortRateTableColumns[CohortRateTableColumnsKeys.DATE] as Column);

    for (const key of columns) {
        const col = CohortRateTableColumns[key];
        if ("func" in col && col.field !== "factor") {
            builder.addActionColumn(col);
            continue;
        }

        builder.addColumn(col);
    }

    return builder;
};
