import React, { ReactElement, useEffect, useState } from 'react';
import * as _ from 'lodash';
import './MenuSelector.scss';
import { Input } from '@vacasa/react-components-lib';

type MultipleSelectionMenuProps<T> = {
    multiple: true;
    onChange: (selected: T[]) => void;
    value: T[];
};
type SingleSelectionMenuProps<T> = {
    multiple: false;
    onChange: (selected: T) => void;
    value: T | null;
};

type MenuSelectorProps<T> = {
    label?: string;
    getDisplayText: (option: T) => string;
    searchable?: boolean;
    options: T[];
    classname?: string;
} & (SingleSelectionMenuProps<T> | MultipleSelectionMenuProps<T>);

type MenuSelectorComponent = <T>(props: MenuSelectorProps<T>) => ReactElement<any, any> | null;

export const MenuSelector: MenuSelectorComponent = <T extends unknown>(props) => {
    const { label, multiple, value, options, onChange, getDisplayText, searchable, classname } = props;
    const [search, setSearch] = useState<string>(null);
    const [filteredOptions, setFilteredOptions] = useState<T[]>(options);
    const [selectedOptions, setSelectedOptions] = useState<Map<string, T>>(new Map());
    const [selectedValues, setSelectedValues] = useState<any[]>([]);

    useEffect(() => {
        const selectedMap = new Map();

        if (multiple && _.isArray(value)) {
            for (const v of value) {
                setSelectedValues(value);
                selectedMap.set(getOptionKey(v), v);
            }
        } else if (!_.isEmpty(value)) {
            setSelectedValues([value]);
            selectedMap.set(getOptionKey(value), value);
        }

        setSelectedOptions(selectedMap);
    }, [value]);

    useEffect(() => {
        let filtered = options;
        if (search) {
            filtered = _.filter(options, (option) => _.toLower(getDisplayText(option)).includes(_.toLower(search)));
        }
        setFilteredOptions(filtered);
    }, [search, options]);

    const getOptionKey = (option: T): string => getDisplayText(option);

    const handleOptionClicked = (option: T) => {
        const key = getOptionKey(option);
        const clone = new Map(selectedOptions);

        if (!multiple) {
            if (clone.has(key)) {
                return onChange(null);
            }
            return onChange(option);
        }

        if (clone.has(key)) {
            clone.delete(key);
        } else {
            clone.set(key, option);
        }

        onChange(Array.from(clone.values()));
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };
    const updatedOptions = _.differenceWith(filteredOptions, selectedValues, _.isEqual);

    return (
        <div className={`menu-selector ${classname} ?? ""`}>
            <span className="menu-selector-label">{label}</span>
            {searchable && (
                <div className="menu-selector-search">
                    <Input type="text" value={search} onChange={handleSearchChange} placeholder="Type your search" />
                </div>
            )}

            <div className="menu-selector-options">
                {selectedValues.map((option, i) => {
                    return (
                        <div key={i} className={`menu-selector-item selected`} onClick={() => handleOptionClicked(option)}>
                            <span> {getDisplayText(option)} </span>
                        </div>
                    );
                })}
                {updatedOptions.map((option, i) => {
                    return (
                        <div key={i} className={`menu-selector-item`} onClick={() => handleOptionClicked(option)}>
                            <span> {getDisplayText(option)}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
