import React, { useState } from 'react';
import { Icon, AlertMessage } from '@vacasa/react-components-lib';
import { CohortUnitAssociation } from '@common/types';
import { DataSourceBuilder } from '../VirtualizedTable/DataSourceBuilder';
import { CustomCohortUnitTableValues, Message } from '../../types';
import { IconToolBar, VirtualizedTable } from '..';
import './CustomCohortUnitTable.scss';
import { HouseImg } from '../../assets/images';

interface CustomCohortUnitTableProps {
    units: CustomCohortUnitTableValues[];
    onAdd?: (selectedUnits: string[] | CustomCohortUnitTableValues[]) => void;
    onDelete?: (selectedUnits: string[]) => void;
    message?: Message;
}

export const CustomCohortUnitTable: React.FC<CustomCohortUnitTableProps> = (props) => {
    const { units, onAdd, onDelete, message } = props;

    const [selectedUnits, setSelectedUnits] = useState([]);

    const handleSelectedIdsChange = (units: string[]) => {
        setSelectedUnits(units);
    };

    const filterUnits = async (pageNumber, pageSize, filters) => {
        let filteredUnits = [...units];
        filters.forEach((filter) => {
            filteredUnits = filteredUnits.filter((fu) => {
                return fu[filter.field]?.toString().toLowerCase().startsWith(filter.value?.toString().toLowerCase());
            });
        });
        return { count: filteredUnits.length, result: filteredUnits.slice((pageNumber - 1) * pageSize, pageNumber * pageSize) };
    };
    const builder = new DataSourceBuilder<CustomCohortUnitTableValues>();
    builder.addColumn({ label: 'Unit ID', field: 'id' });
    builder.addColumn({ label: 'Unit Code', field: 'code' });
    builder.addColumn({ label: 'State', field: 'state' });
    builder.addColumn({ label: 'City', field: 'city' });
    builder.addColumn({ label: 'Housing Type', field: 'housing_type' });
    builder.addColumn({ label: 'Nº of Bedrooms', field: 'bedroom_count' });
    builder.addActionColumn({
        label: 'Status',
        func: (cohortUnit: CustomCohortUnitTableValues) => {
            if (cohortUnit.association_type === CohortUnitAssociation.ADDED) {
                return <Icon.UserCheck className="pointer success" height={25} width={25} key={cohortUnit.id} />;
            }
            if (cohortUnit.association_type === CohortUnitAssociation.REMOVED) {
                return <Icon.UserX className="pointer danger" height={25} width={25} key={cohortUnit.id} />;
            }
        },
    });

    builder.addPagination({ remote: true, function: filterUnits });

    builder.setFilterHeader({ options: [] });

    const toolbarIcons = [];

    if (onDelete) {
        toolbarIcons.push({
            icon: <Icon.Trash className="pointer" height={24} width={24} />,
            id: 'units-dashboard-settings-remove',
            tip: 'Select the units to be removed in the table.',
            onClick: () => {
                onDelete(selectedUnits);
            },
        });
    }

    if (onAdd) {
        toolbarIcons.push({
            icon: <Icon.PlusCircle className="pointer" height={24} width={24} />,
            id: 'units-dashboard-settings',
            tip: 'Add Units',
            onClick: () => {
                onAdd(selectedUnits);
            },
        });
    }
    return (
        <React.Fragment>
            <div className="unit-selector">
                <div className="icons-tool-bar">
                    {message && <AlertMessage customClass="alert-message" text={message.content} type={message.type} height="small" />}
                    <div className="custom-table-icons">
                        <IconToolBar icons={toolbarIcons} />
                    </div>
                </div>

                {units.length === 0 && (
                    <div className="no-units-card">
                        <HouseImg />
                        <p>No units for criteria, You can add some manually</p>
                    </div>
                )}

                {units.length > 0 && (
                    <VirtualizedTable
                        dataSource={builder.build([])}
                        onRowChange={() => null}
                        onSelectedChange={handleSelectedIdsChange}
                        key={units.map((u) => u.id).join(',')}
                    />
                )}
            </div>
        </React.Fragment>
    );
};
