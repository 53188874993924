import { Button, Icon } from '@vacasa/react-components-lib';
import React, { useState } from 'react';
import { TooltipProps } from '../../types/VirtualizedTable';
import { Tooltip } from '@material-ui/core';
import './ColumnTooltip.scss';

interface ColumnTooltipProps extends TooltipProps {
    popperProps?: {};
    id?: string;
}

export const ColumnTooltip: React.FC<ColumnTooltipProps> = (props) => {
    const { contentType, content, hasCloseButton, hasBottomDetailsLink, popperProps, id } = props;
    const [isShown, setIsShown] = useState(false);
    let bodyContent = null;

    if (contentType === 'element') {
        bodyContent = content;
    }

    const close = () => {
        setIsShown(false);
    };

    const tooltipContent = (
        <div style={{ width: 'fit-content' }}>
            <div className="tooltip-top">
                {hasCloseButton && (
                    <span className="close-button" onClick={close}>
                        <Icon.XCircle height={18} />
                    </span>
                )}
            </div>
            <div className="tooltip-body">{bodyContent}</div>
            <div className="tooltip-bottom">
                <div className="tooltip-bottom-left">{hasBottomDetailsLink ?? <a href="#">Details</a>}</div>
                <div className="tooltip-bottom-right">
                    <Button variant="secondary" children={'Understood'} onClick={close} />
                </div>
            </div>
        </div>
    );

    return (
        <Tooltip
            disableFocusListener
            disableHoverListener
            open={isShown}
            placement="bottom"
            title={tooltipContent}
            style={{
                zIndex: 'inherit',
                pointerEvents: 'all',
            }}
            PopperProps={{
                placement: 'bottom',
                modifiers: { flip: { enabled: false } },
                ...popperProps,
            }}
            id={`${id}-column-tooltip`}>
            <span onClick={() => setIsShown(!isShown)} className="tooltip-icon">
                <Icon.AlertCircle height={12} />
            </span>
        </Tooltip>
    );
};
