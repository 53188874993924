import { createEntityAdapter, createSlice, EntityState, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { fetchUnits, fetchRatesForUnit, fetchEnhancedUnit } from './unit.actions';
import { UnitWithRates } from '../../types';
import * as _ from 'lodash';
import { AppState } from '../store';
import { RateUtils } from '../../utils';
import { EnhancedUnitDTO, RateDescription } from '@common/types';

type UnitState = EntityState<UnitWithRates> & {
    isFetchingUnits: boolean;
    isFetchingRates: boolean;
    isFetchingEnhancedUnit: boolean;
    error: Error | null;
};

const unitAdapter = createEntityAdapter<UnitWithRates>({
    selectId: (unit) => unit.id,
    sortComparer: (a, b) => a.id - b.id,
});

const initialState: UnitState = unitAdapter.getInitialState({
    isFetchingUnits: false,
    isFetchingRates: false,
    isFetchingEnhancedUnit: false,
    error: null,
});

export const unitSlice = createSlice({
    name: 'unit',
    initialState,
    reducers: {
        setIsFetchingUnits(state, action: PayloadAction<boolean>) {
            state.isFetchingUnits = action.payload;
        },

        setRateChangesForUnit(state, action: PayloadAction<{ id: number; changes: { date: string; value: RateDescription }[] }>) {
            const { id, changes } = action.payload;
            const unit = state.entities[id];
            if (unit) {
                const currentChanges = unit.changes || {};
                for (const change of changes) {
                    currentChanges[change.date] = change.value;
                }
                unit.changes = currentChanges;
            }
        },

        updateUnit(state, action: PayloadAction<EnhancedUnitDTO>) {
            const unit = action.payload;
            unitAdapter.upsertOne(state, unit);
        },

        applyChangesForUnit(state, action: PayloadAction<{ id: number }>) {
            const { id } = action.payload;
            const unit = state.entities[id];
            if (unit) {
                const changes = unit.changes;
                for (const date of _.keys(changes)) {
                    if (unit.rates && unit.rates[date]) {
                        const updatedRates = _.merge(unit.rates[date], changes[date]);
                        unit.rates[date] = updatedRates;
                        delete unit.changes[date];
                    }
                }
            }
        },

        upsertUnits(state, action: PayloadAction<EnhancedUnitDTO[]>) {
            unitAdapter.upsertMany(state, action.payload);
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchUnits.pending, (state) => {
            state.error = null;
        });

        builder.addCase(fetchUnits.fulfilled, (state, action) => {
            //unitAdapter.removeAll(state);
            unitAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(fetchUnits.rejected, (state, action) => {
            state.error = action.payload as Error;
        });

        builder.addCase(fetchRatesForUnit.pending, (state) => {
            state.isFetchingRates = true;
            state.error = null;
        });

        builder.addCase(fetchRatesForUnit.fulfilled, (state, action) => {
            state.isFetchingRates = false;
            const { id, rates } = action.payload;
            const unit = state.entities[id];
            if (unit) {
                unit.rates = _.merge(unit.rates, rates);
            }
        });

        builder.addCase(fetchRatesForUnit.rejected, (state, action) => {
            state.isFetchingRates = false;
            state.error = action.payload as Error;
        });

        /// enhanced unit fetching

        builder.addCase(fetchEnhancedUnit.pending, (state) => {
            state.isFetchingEnhancedUnit = true;
            state.error = null;
        });

        builder.addCase(fetchEnhancedUnit.fulfilled, (state, action) => {
            state.isFetchingEnhancedUnit = false;
            const unit = action.payload;
            unitAdapter.upsertOne(state, unit);
        });

        builder.addCase(fetchEnhancedUnit.rejected, (state, action) => {
            state.isFetchingEnhancedUnit = false;
            state.error = action.payload as Error;
        });
    },
});

export const { selectById: selectUnitById, selectAll: selectAllUnits } = unitAdapter.getSelectors((state: AppState) => state.unit);
export const { setRateChangesForUnit, applyChangesForUnit, setIsFetchingUnits, updateUnit, upsertUnits } = unitSlice.actions;

export const selectRatesForUnitBetweenDateRange = createSelector(
    [
        (state: AppState, id: number, range: { start: Date; end: Date }) => {
            return selectUnitById(state, id);
        },
        (state: AppState, id: number, range: { start: Date; end: Date }) => range,
    ],
    (unit, { start, end }) => {
        let rates = unit?.rates || {};
        return RateUtils.getRatesBetweenDates(rates, start, end);
    }
);

export const selectUnitRatesWithChanges = createSelector(
    [
        (state: AppState, id: number, range: { start: Date; end: Date }, avg_rate?: number, std_per?: number) => selectUnitById(state, id),
        selectRatesForUnitBetweenDateRange,
    ],
    (unit, rates) => {
        const changes = unit?.changes || {};
        return RateUtils.mergeChanges(rates, changes);
    }
);
