import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { ChangeHistoryDTO } from '@common/types';
import { fetchHistory } from './history.actions';
import * as _ from 'lodash';
import { AppState } from '../store';

const changeHistoryAdapter = createEntityAdapter<ChangeHistoryDTO>({
    selectId: (change) => change.id,
});

type ChangeHistoryState = EntityState<ChangeHistoryDTO> & {
    isFetchingHistory: boolean;
    error: Error | null;
};

const initialState: ChangeHistoryState = changeHistoryAdapter.getInitialState({
    isFetchingHistory: false,
    error: null,
});

export const changeHistorySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchHistory.pending, (state) => {
            state.isFetchingHistory = true;
            state.error = null;
        });

        builder.addCase(fetchHistory.fulfilled, (state, action) => {
            state.isFetchingHistory = false;
            changeHistoryAdapter.removeAll(state);
            changeHistoryAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(fetchHistory.rejected, (state, action) => {
            state.isFetchingHistory = false;
            state.error = action.payload as Error;
        });
    },
});

export const { selectById: selectHistoryChangeById, selectAll: selectAllHistoryChanges } = changeHistoryAdapter.getSelectors(
    (state: AppState) => {
        return state.history;
    }
);

export const selectChangesForCohort = createSelector(
    [selectAllHistoryChanges, (state, cohortName: string) => cohortName],
    (changes, cohortName) => _.filter(changes, (change) => change.cohort_name === cohortName)
);

export const selectChangesForUnit = createSelector([selectAllHistoryChanges, (state, unitId: number) => unitId], (changes, unitId) => {
    return _.filter(changes, (change) => change.unit_id === unitId);
});
