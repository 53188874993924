import React from "react";
import {Link} from "react-router-dom";
import {AppRoutes} from "../../Routes";
import "./Navbar.scss";

interface NavbarProps {
    title: string;
    domain: "units" | "cohorts" | "processExecutions" | "holidayManager" | "northstar";
}
export const Navbar: React.FC<NavbarProps> = (props) => {
    const {title, domain} = props;

    return (
        <div className="nav-bar">
            <div className="nav-bar-title">
                <h1>{title}</h1>
            </div>
            <div className="nav-bar-links">
                <div>
                    <Link className={`${domain === "units" ? "selected" : ""}`} to={AppRoutes.UNITS}>
                        Units
                    </Link>
                </div>
                <div>
                    <Link className={`${domain === "cohorts" ? "selected" : ""}`} to={AppRoutes.COHORTS}>
                        Cohorts
                    </Link>
                </div>
                <div>
                    <Link className={`${domain === "processExecutions" ? "selected" : ""}`} to={AppRoutes.PROCESSES}>
                        Process Executions
                    </Link>
                </div>
                {process.env.REACT_APP_NORTHSTAR_ENABLED === "true" ? (
                    <div>
                        <Link className={`${domain === "northstar" ? "selected" : ""}`} to={AppRoutes.NORTHSTAR}>
                            Northstar
                        </Link>
                    </div>
                ) : (
                    ""
                )}
                <div>
                    <Link className={`${domain === "holidayManager" ? "selected" : ""}`} to={AppRoutes.HOLIDAY_MANAGER}>
                        Holiday Manager
                    </Link>
                </div>
            </div>
        </div>
    );
};
