import React, { useState } from 'react';
import './SwitchPillow.scss';

interface SwitchPillowInterface {
    labelLeft: string;
    labelRight: string;
    callback: (toggle: boolean) => void;
    initial: boolean;
}

export const SwitchPillow: React.FC<SwitchPillowInterface> = (props) => {
    const { labelLeft, labelRight, callback, initial } = props;
    const [toggle, setToggle] = useState<string>(initial ? 'left' : 'right');

    const handle = (side: string) => {
        setToggle(side);
        side === 'left' ? callback(true) : callback(false);
    };

    return (
        <React.Fragment>
            <div className={'switch-pillow'}>
                <div className={'pill left ' + (toggle === 'left' ? 'active' : 'pasive')} onClick={() => handle('left')}>
                    {labelLeft}
                </div>
                <div className={'pill right ' + (toggle === 'right' ? 'active' : 'pasive')} onClick={() => handle('right')}>
                    {labelRight}
                </div>
            </div>
        </React.Fragment>
    );
};
