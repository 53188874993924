import React from "react";
import {Redirect, Route, RouteProps, Switch} from "react-router-dom";
import {CohortFactor, Cohorts, CustomCohort, HolidayManager, Northstar, Processes, Units} from "./views";

export enum AppRoutes {
    UNITS = "/units",
    UNIT_EVOLUTION = "/units/:id",
    COHORTS = "/cohorts",
    CUSTOM_COHORT = "/cohorts/custom/:name",
    CREATE_CUSTOM_COHORT = "/cohorts/custom/new",
    COHORT_EVOLUTION = "/cohorts/:name",
    COHORT_FACTOR = "/cohorts/:name/factors",
    PROCESSES = "/processes",
    NORTHSTAR = "/northstar",
    HOLIDAY_MANAGER = "/holiday-manager",
}

const routes: RouteProps[] = [
    {path: AppRoutes.UNITS, exact: true, component: Units},
    {path: AppRoutes.UNIT_EVOLUTION, exact: true, component: Units},
    {path: AppRoutes.COHORTS, exact: true, component: Cohorts},
    {path: AppRoutes.COHORT_EVOLUTION, exact: true, component: Cohorts},
    {path: AppRoutes.CREATE_CUSTOM_COHORT, exact: true, component: CustomCohort},
    {path: AppRoutes.CUSTOM_COHORT, exact: true, component: CustomCohort},
    {path: AppRoutes.COHORT_FACTOR, exact: true, component: CohortFactor},
    {path: AppRoutes.PROCESSES, exact: true, component: Processes},
    {path: AppRoutes.HOLIDAY_MANAGER, exact: true, component: HolidayManager},
];
if (process.env.REACT_APP_NORTHSTAR_ENABLED === "true") {
    routes.unshift({path: AppRoutes.NORTHSTAR, exact: true, component: Northstar});
}
export const Routes: React.FC = () => {
    return (
        <React.Fragment>
            <Switch>
                {routes.map((route, i) => (
                    <Route key={i} {...route} />
                ))}
                <Redirect from="*" to={AppRoutes.UNITS} />
            </Switch>
        </React.Fragment>
    );
};
