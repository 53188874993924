import React, { useEffect, useState } from 'react';
import { CohortsTable, CohortRatesEvolution, Navbar } from '../../components';
import './Cohorts.scss';
import { Tabs } from '@vacasa/react-components-lib';
import { Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { history } from '../../App';
import { AppRoutes } from '../../Routes';
enum CohortTabs {
    SEARCH,
    EVOLUTION,
}

export const Cohorts: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(null);
    const { name } = useParams<{ name: string }>();

    useEffect(() => {
        if (name) {
            setSelectedTab(CohortTabs.EVOLUTION);
        } else {
            setSelectedTab(CohortTabs.SEARCH);
        }
    }, []);

    const handleTabChange = (tab) => {
        if (selectedTab === tab) {
            return;
        }

        if (tab === CohortTabs.SEARCH) {
            return history.push(AppRoutes.COHORTS);
        }
    };

    return (
        <React.Fragment>
            <Navbar title="Rates Cohorts" domain="cohorts" />
            <Paper elevation={3} className="cohorts-view">
                <Tabs
                    selected={selectedTab}
                    onChange={handleTabChange}
                    tabs={[
                        {
                            id: 'tab-cohorts-search',
                            label: 'Search',
                            component: <CohortsTable />,
                        },
                        {
                            id: 'tab-cohorts-rates-evolution',
                            label: 'Rates Evolution',
                            disabled: selectedTab !== CohortTabs.EVOLUTION,
                            component: <CohortRatesEvolution />,
                        },
                    ]}
                />
            </Paper>
        </React.Fragment>
    );
};
