import React from 'react';
import { Navbar } from '../../components';
import { Paper } from '@material-ui/core';
import { ProcessesTable } from '../../components/ProcessesTable/ProcessesTable';

export const Processes: React.FC = () => {
    return (
        <React.Fragment>
            <Navbar title={'Process Executions'} domain={'processExecutions'} />
            <Paper elevation={3} className="units-view">
                <ProcessesTable />
            </Paper>
        </React.Fragment>
    );
};
