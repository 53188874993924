import { DoW, UnitDateCurrentStatus } from '@common/types';

export type Message = { type: 'success' | 'warning' | 'error' | 'info'; content: string };

export type DowOptions =
    | 'All'
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday'
    | 'Weekdays'
    | 'Weekends';

export type StatusOptions = 'All' | UnitDateCurrentStatus;

export type SelectOption = { value: number | string; display: string };

export type Filters = { start: Date; end: Date; dow: DoW[]; status: UnitDateCurrentStatus[] } | { selected: boolean };

export type Sidebar = { show: boolean; defaults?: { column: string; value: number } };

export type ColumnOptions = { field: string; label: string }[];

export enum StorageKeys {
    ACCESS_TOKEN = 'accessToken',
    ID_TOKEN = 'idToken',
}

export enum DashboardHash {
    DASHBOARD = '#dash',
    RATES = '#rates',
    CHANGELOG = '#logs',
}

export enum CopyTargetOptions {
    ALL = 'To All',
    SELECTED = 'To Selected',
    PERIOD = 'To Period',
    HOLIDAY_OR_MONTH = 'To Holiday or Month',
}

export enum ActionTargetOptions {
    ALL = 'To All',
    SELECTED = 'To Selected',
    PERIOD = 'To Period',
    HOLIDAY_OR_MONTH = 'To Holiday or Month',
}
