import React, { useEffect, useState } from 'react';
import './CriteriaSelector.scss';
import { MenuSelector, MinMaxSelector } from '..';
import { Icon } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import { CustomCohortCriteriaDTO, CustomCohort, CustomCohortOptionsDTO } from '@common/types';

interface CriteriaSelectorProps {
    initialCriteria?: CustomCohortCriteriaDTO;
    onCriteriaChange: (criteria: CustomCohortCriteriaDTO) => void;
    criteriaOptions: CustomCohortOptionsDTO;
    onRefreshCriteria: () => void;
}

export const CriteriaSelector: React.FC<CriteriaSelectorProps> = (props) => {
    const { initialCriteria, criteriaOptions, onCriteriaChange, onRefreshCriteria } = props;
    const [selectedCriteria, setSelectedCriteria] = useState<CustomCohortCriteriaDTO>(initialCriteria);
    const [locationOptions, setLocationOptions] = useState<CustomCohort.Location[]>([]);
    const [destinationOptions, setDestinationOptions] = useState<CustomCohort.Destination[]>([]);
    const [housingTypeOptions, setHousingTypeOptions] = useState<string[]>([]);
    const [amenitiesOptions, setAmenitiesOptions] = useState<CustomCohort.Amenity[]>([]);

    useEffect(() => {
        if (criteriaOptions) {
            const { locations, destinations, amenities, housing_types } = criteriaOptions;
            setLocationOptions(_.orderBy(locations, ['region_name'], ['asc']));
            setDestinationOptions(_.orderBy(destinations, ['name'], ['asc']));
            setAmenitiesOptions(_.orderBy(amenities, ['name'], ['asc']));
            setHousingTypeOptions(housing_types.sort());
        }
    }, [criteriaOptions]);

    useEffect(() => {
        setSelectedCriteria(initialCriteria);
    }, [initialCriteria]);

    const handleLocationChange = (selected: CustomCohort.Location[]) => {
        const updatedCriteria = { ...selectedCriteria, locations: selected };
        setSelectedCriteria(updatedCriteria);
        onCriteriaChange(updatedCriteria);
    };

    const handleDestinationChange = (selected: CustomCohort.Destination[]) => {
        const updatedCriteria = { ...selectedCriteria, destinations: selected };
        setSelectedCriteria(updatedCriteria);
        onCriteriaChange(updatedCriteria);
    };

    const handleAmenitiesChange = (selected: CustomCohort.Amenity[]) => {
        const updatedCriteria = { ...selectedCriteria, amenities: selected };
        setSelectedCriteria(updatedCriteria);
        onCriteriaChange(updatedCriteria);
    };
    const handleHousingTypesChange = (selected: string[]) => {
        const updatedCriteria = { ...selectedCriteria, housing_types: selected };
        setSelectedCriteria(updatedCriteria);
        onCriteriaChange(updatedCriteria);
    };

    const cleanMinMax = (value: { min: number; max: number }) => {
        if (value.min === null) {
            value.min = undefined;
        }
        if (value.max === null) {
            value.max = undefined;
        }
        return value;
    };

    const handleBedroomsChange = (minMax: { min: number; max: number }) => {
        cleanMinMax(minMax);
        const updatedCriteria = { ...selectedCriteria, bedrooms: minMax };
        setSelectedCriteria(updatedCriteria);
        onCriteriaChange(updatedCriteria);
    };

    const handleBathroomsChange = (minMax: { min: number; max: number }) => {
        cleanMinMax(minMax);
        const updatedCriteria = { ...selectedCriteria, bathrooms: minMax };
        setSelectedCriteria(updatedCriteria);
        onCriteriaChange(updatedCriteria);
    };

    const handleMinMinStayChange = (minMax: { min: number; max: number }) => {
        cleanMinMax(minMax);
        const updatedCriteria = { ...selectedCriteria, minMinStay: minMax };
        setSelectedCriteria(updatedCriteria);
        onCriteriaChange(updatedCriteria);
    };
    return (
        <div>
            <div className="criteria-selector">
                <div className="criteria-selector-top-bar">
                    <Icon.RefreshCW className="refresh-criteria-icon pointer" height={18} width={18} onClick={onRefreshCriteria} />
                </div>

                <React.Fragment>
                    <div className="menu-selectors">
                        <MenuSelector
                            label="Location:"
                            searchable={true}
                            multiple={true}
                            getDisplayText={(location) => `${location.region_name}, ${location.city}`}
                            value={selectedCriteria.locations}
                            options={locationOptions}
                            onChange={handleLocationChange}
                            key={'location:' + selectedCriteria.locations.length}
                        />

                        <MenuSelector
                            label="Destination:"
                            searchable={true}
                            multiple={true}
                            getDisplayText={(destination) => destination.name}
                            value={selectedCriteria.destinations}
                            options={destinationOptions}
                            onChange={handleDestinationChange}
                            key={'destination:' + selectedCriteria.destinations.length}
                        />

                        <MenuSelector
                            label="Housing Type:"
                            multiple={true}
                            getDisplayText={(housingType) => housingType}
                            value={selectedCriteria.housing_types}
                            options={housingTypeOptions}
                            onChange={handleHousingTypesChange}
                            key={'housing_type:' + selectedCriteria.housing_types.length}
                        />

                        <MenuSelector
                            label="Amenities:"
                            multiple={true}
                            value={selectedCriteria.amenities}
                            getDisplayText={(amenity) => amenity.name}
                            options={amenitiesOptions}
                            onChange={handleAmenitiesChange}
                            key={'amenities:' + selectedCriteria.amenities.length}
                        />
                    </div>

                    <div className="min-max-selectors">
                        <MinMaxSelector
                            label="Bedrooms"
                            min={selectedCriteria.bedrooms.min || undefined}
                            max={selectedCriteria.bedrooms.max || undefined}
                            onChange={handleBedroomsChange}
                        />

                        <MinMaxSelector
                            label="Bathrooms"
                            min={selectedCriteria.bathrooms.min || undefined}
                            max={selectedCriteria.bathrooms.max || undefined}
                            onChange={handleBathroomsChange}
                        />

                        <MinMaxSelector
                            label="Min minstay"
                            min={selectedCriteria.minMinStay.min || undefined}
                            max={selectedCriteria.minMinStay.max || undefined}
                            onChange={handleMinMinStayChange}
                        />
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};
