import React from 'react';
import { SelectOption } from '../../types';
import './TableInfo.scss';

export interface TableInfoProps {
    options: SelectOption[];
}

export const TableInfo: React.FC<TableInfoProps> = (props) => {
    const { options } = props;

    return (
        <div className="table-container">
            <table>
                {options.map((option) => {
                    const displayValues = JSON.parse(option.display);
                    return (
                        <tr className="option">
                            <td className="value">{option.value}:</td>
                            <td className="display">{displayValues.percent !== '-' ? `${displayValues.percent}%` : '-'}</td>
                            <td className="display">{displayValues.reservation}</td>
                            <td className="display">{displayValues.rent}</td>
                        </tr>
                    );
                })}
            </table>
        </div>
    );
};
