import React from 'react';
import { SeasonalChange } from '../../types';
import { Icon } from '@vacasa/react-components-lib';
import './ChangeSummary.scss';

interface SeasonalChangeSummaryProps {
    changes: SeasonalChange[];
    analystNote: string;
}

export const SeasonalChangeSummary: React.FC<SeasonalChangeSummaryProps> = (props) => {
    const { changes, analystNote } = props;

    return (
        <div className="change-summary">
            {changes.map((change) => {
                const { season_start_date, season_end_date } = change;
                return (
                    <div className="change-summary-item">
                        <div className="change-summary-item-icon">
                            <Icon.AlertCircle width={16} height={16} />
                        </div>
                        <div className="change-summary-item-text">
                            <strong>{`${analystNote} : `}</strong>
                            <span>
                                {analystNote !== 'Seasonal min rate deleted' ? `From ${season_start_date} to ${season_end_date}.` : ''}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
