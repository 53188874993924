import React from 'react';
import * as _ from 'lodash';
import { Icon } from '@vacasa/react-components-lib';
import { CustomCohortChange } from '../../types';

interface CustomCohortSummaryProps {
    changes: CustomCohortChange;
}

export const CustomCohortSummary: React.FC<CustomCohortSummaryProps> = (props) => {
    const {
        changes: { added_units, removed_units, grouping_criteria },
    } = props;

    const locationChanged = grouping_criteria?.locations.length > 0;
    const destinationsChanged = grouping_criteria?.destinations.length > 0;
    const amenitiesChanged = grouping_criteria?.amenities.length > 0;
    const housingTypesChanged = grouping_criteria?.housing_types.length > 0;

    const bedroomsChanged = !_.isUndefined(grouping_criteria?.bedrooms.min) || !_.isUndefined(grouping_criteria?.bedrooms.max);
    const bathroomsChanged = !_.isUndefined(grouping_criteria?.bathrooms.min) || !_.isUndefined(grouping_criteria?.bathrooms.max);
    const minMinStayChanged = !_.isUndefined(grouping_criteria?.minMinStay.min) || !_.isUndefined(grouping_criteria?.minMinStay.max);

    const criteriaChanged = [
        locationChanged,
        destinationsChanged,
        amenitiesChanged,
        housingTypesChanged,
        bedroomsChanged,
        bathroomsChanged,
        minMinStayChanged,
    ].some((v) => v);

    return (
        <div className="change-summary">
            <div className="change-summary-item">
                <div className="change-summary-item-icon">
                    <Icon.AlertCircle width={16} height={16} />
                </div>

                <div className="change-summary-item-text">
                    <ul>
                        <li>
                            <span>Units manually added: {added_units.length}</span>
                        </li>

                        <li>
                            <span>Units manually removed: {removed_units.length}</span>
                        </li>
                    </ul>
                    {criteriaChanged && (
                        <React.Fragment>
                            <br />
                            <strong className="filter-criteria">Filter Criteria</strong>
                            <ul>
                                {locationChanged && (
                                    <li>
                                        <strong>Locations:</strong>{' '}
                                        <span>{_.map(grouping_criteria?.locations, (l) => l.region_name).join(', ')}</span>
                                    </li>
                                )}

                                {destinationsChanged && (
                                    <li>
                                        <strong>Destinations:</strong>{' '}
                                        <span>{_.map(grouping_criteria?.destinations, (d) => d.name).join(', ')}</span>
                                    </li>
                                )}

                                {amenitiesChanged && (
                                    <li>
                                        <strong>Amenities:</strong>{' '}
                                        <span>{_.map(grouping_criteria?.amenities, (a) => a.name).join(', ')}</span>
                                    </li>
                                )}

                                {housingTypesChanged && (
                                    <li>
                                        <strong>Housing Types:</strong> <span>{grouping_criteria?.housing_types.join(',')}</span>
                                    </li>
                                )}

                                {bedroomsChanged && (
                                    <li>
                                        <strong>Bedrooms:</strong>{' '}
                                        {grouping_criteria.bedrooms.min && <span>{` Min: ${grouping_criteria?.bedrooms.min}`}</span>}
                                        {grouping_criteria.bedrooms.max && <span>{` Max: ${grouping_criteria?.bedrooms.max}`}</span>}
                                    </li>
                                )}

                                {bathroomsChanged && (
                                    <li>
                                        <strong>Bathrooms:</strong>{' '}
                                        {grouping_criteria.bathrooms.min && <span>{` Min: ${grouping_criteria?.bathrooms.min}`}</span>}
                                        {grouping_criteria.bathrooms.max && <span>{` Max: ${grouping_criteria?.bathrooms.max}`}</span>}
                                    </li>
                                )}

                                {minMinStayChanged && (
                                    <li>
                                        <strong>Min minstay:</strong>{' '}
                                        {grouping_criteria.minMinStay.min && <span>{` Min: ${grouping_criteria?.minMinStay.min}`}</span>}
                                        {grouping_criteria.minMinStay.max && <span>{` Max: ${grouping_criteria?.minMinStay.max}`}</span>}
                                    </li>
                                )}
                            </ul>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
