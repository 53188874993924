import React, { useState } from 'react';
import { DataSourceBuilder } from '../VirtualizedTable/DataSourceBuilder';
import { Message, ProcessTableValues } from '../../types';
import { VirtualizedTable } from '../VirtualizedTable/VirtualizedTable';
import { RateService } from '../../services/RateService';
import { ProcessExecutionUtils } from '../../utils/ProcessExecutionUtils';
import { Modal } from '../Modal/Modal';
import './ProcessesTable.scss';
import { BulkProcessType, DATETIME_FORMAT, ProcessExecution, ProcessExecutionDetailDTO, ProcessExecutionStatus } from '@common/types';
import { AlertMessage, Icon, Tooltip } from '@vacasa/react-components-lib';
import { FilterField, OrderField } from '../../types/VirtualizedTable';
import { addHours, format, intervalToDuration } from 'date-fns';
import * as _ from 'lodash';
import { ProcessDetailsTable } from './ProcessDetailsTable';
import { UiUtils } from '../../utils';
import { Loading } from '../Loading/Loading';
import { Badge } from '@vacasa/react-components-lib';

export const ProcessesTable: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [processDetails, setProcessDetails] = useState<ProcessExecutionDetailDTO[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);
    const [processType, setProcessType] = useState<string>();
    const [processStatus, setProcessStatus] = useState<string>();
    const [processId, setProcessId] = useState<string>();

    const colorStatus = (status) => {
        switch (status) {
            case ProcessExecutionStatus.Processing:
                return 'info';
            case ProcessExecutionStatus.Success:
                return 'success';
            case ProcessExecutionStatus.Warning:
                return 'warning';
            case ProcessExecutionStatus.Failed:
                return 'error';
            default:
                return 'default';
        }
    };

    const loadModal = async (id: string) => {
        setIsLoading(true);
        setShowModal(true);
        try {
            const details = await RateService.getProcessExecutionDetailByProcessId(id);
            setProcessDetails(details);
        } catch (e) {
            setUiAlert(UiUtils.getErrorMessage(e.message));
            setShowModal(false);
        } finally {
            setIsLoading(false);
        }
    };

    const builder = new DataSourceBuilder<ProcessTableValues>();
    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: { containerType: 'span', flexGrow: 0.4, justifyContent: 'flex-start' },
        disableSort: { tooltipText: 'Copy process ID' },
        fieldConfiguration: {
            format: (obj) => (
                <Tooltip message={obj}>
                    <div className={'container-id'}>
                        <Icon.Copy
                            className={'iconStatus pointer'}
                            width={24}
                            height={24}
                            onClick={() => {
                                navigator.clipboard.writeText(obj);
                            }}
                        />
                    </div>
                </Tooltip>
            ),
        },
    });
    builder.addColumn({
        label: 'Process Type',
        field: 'process_type',
        displayConfiguration: { containerType: 'div', flexGrow: 2.2, justifyContent: 'flex-start' },
    });
    builder.addColumn({
        label: 'Created by',
        field: 'user',
        displayConfiguration: { containerType: 'div', flexGrow: 2.2, justifyContent: 'flex-start' },
    });
    builder.addColumn({
        label: 'Description',
        field: 'description',
        displayConfiguration: { containerType: 'div', flexGrow: 3, justifyContent: 'center' },
    });
    builder.addColumn({
        label: 'Status',
        field: 'status',
        displayConfiguration: { containerType: 'span', flexGrow: 2.3, justifyContent: 'center' },
        fieldConfiguration: {
            format: (i) => {
                return (
                    <div className={'container-status'}>
                        <Badge
                            disabled={false}
                            closeButton={false}
                            isPill={true}
                            text={i.status}
                            outline={false}
                            variant={colorStatus(i.status)}
                        />
                        <Icon.Eye
                            className={'iconStatus pointer'}
                            width={24}
                            height={24}
                            onClick={() => {
                                loadModal(i.id);
                                setProcessType(i.process_type);
                                setProcessStatus(i.status);
                                setProcessId(i.id);
                            }}
                        />
                    </div>
                );
            },
        },
    });
    builder.addColumn({
        label: 'Changed units',
        field: 'changed_units',
        disableSort: { tooltipText: 'Changed units' },
        displayConfiguration: { containerType: 'span', flexGrow: 2, justifyContent: 'flex-start' },
        fieldConfiguration: {
            format: (i) => {
                const unitIds = _.sortBy(
                    i.reduce((units, change) => {
                        if (!_.isNil(change.unit_id)) {
                            units.push(change.unit_id);
                        }
                        return units;
                    }, [])
                ).join(', ');
                return (
                    <div className={'process-table-row-changed-units'}>
                        <p>{unitIds}</p>
                    </div>
                );
            },
        },
    });
    builder.addColumn({
        label: 'Created at',
        field: 'started_at',
        displayConfiguration: { containerType: 'div', flexGrow: 1.6, justifyContent: 'center' },
    });
    builder.addColumn({
        label: 'Time',
        field: 'execution_time',
        displayConfiguration: { containerType: 'div', flexGrow: 0.8, justifyContent: 'center' },
    });

    const castFilter = (key: string): string => {
        const cast = {
            id: 'filter[id][in]',
            process_type: 'filter[process_type[contains]',
            user: 'filter[user][contains]',
            status: 'filter[status][contains]',
            started_at: 'filter[started_at][between]',
            // filters by one unit
            changed_units: 'filter[changed_unit]',
            //execution_time: 'filter[execution_time][between]'
        };
        return cast[key] ? cast[key] : 'filter[name][contains]';
    };

    const getParamName = (key: string) => {
        const cast = {
            id: 'id',
            process_type: 'process_type',
            user: 'user',
            status: 'status',
            started_at: 'started_at',
        };
        return cast[key] ? cast[key] : 'id';
    };

    const infoSelectStatus = ['ALL', 'Processing', 'Success', 'Warning', 'Failed'];
    const infoSelectType = [
        'ALL',
        ProcessExecution.UPDATE_UNIT_RATES,
        ProcessExecution.UPDATE_UNITS_FACTORS,
        ProcessExecution.UPDATE_UNITS_PARAMS,
        ProcessExecution.UPDATE_COHORT_RATES,
        ProcessExecution.UPDATE_COHORT_BY_NAME,
        BulkProcessType.UNIT_DATE_BULK_UPLOAD,
        BulkProcessType.COHORT_DATE_BULK_UPLOAD,
        BulkProcessType.INITIAL_RATE_SETTING_UPLOAD,
    ];

    builder.setFilterHeader({
        options: [
            { field: 'id', type: 'text' },
            { field: 'description', type: 'text', disabled: true },
            { field: 'process_type', type: 'select', values: infoSelectType },
            { field: 'status', type: 'select', values: infoSelectStatus },
            { field: 'changed_units', type: 'number' },
            //{ field: 'started_at', type: 'date' },
            { field: 'execution_time', type: 'text', disabled: true },
        ],
    });

    builder.setSortable({ field: 'started_at', order: 'desc' });

    builder.addPagination({
        remote: true,
        function: async (pageNumber, pageSize, filters: FilterField[], order: OrderField) => {
            const params = {
                'page[number]': pageNumber.toString(),
                'page[size]': pageSize.toString(),
            };
            filters.forEach((filter) => {
                if (filter.field === 'started_at') {
                    const date = filter.value.trim();
                    const iso = new Date().toISOString().replace(/[T Z]/g, ' ');
                    const { hours } = intervalToDuration({ start: new Date(iso), end: new Date() });
                    const dateStart = format(addHours(new Date(`${date} 00:00:00`), hours), `${DATETIME_FORMAT}.000000 +00:00`);
                    const dateEnd = format(addHours(new Date(`${date} 23:59:59`), hours), `${DATETIME_FORMAT}.999999 +00:00`);
                    params[castFilter(filter.field)] = `${dateStart},${dateEnd}`;
                } else {
                    params[castFilter(filter.field)] = filter.value;
                }
            });

            if (!_.isNil(order)) {
                let symbol = '';
                if (order.order === 'desc') symbol = '-';
                params['sort'] = `${symbol}${getParamName(order.field)}`;
            }

            const { rows, count } = await RateService.getProcessExecution(params);
            return {
                result: ProcessExecutionUtils.transformToTableData(rows),
                count,
            };
        },
    });

    return (
        <React.Fragment>
            <Modal
                className={'modal-process-detail'}
                title={'Details Process Status'}
                show={showModal}
                onClose={() => setShowModal(!showModal)}>
                {isLoading ? (
                    <div className={'modal-loading'}>
                        <Loading />
                    </div>
                ) : (
                    <ProcessDetailsTable
                        processDetails={ProcessExecutionUtils.transformToProcessDetailsTableData(processDetails)}
                        processType={processType}
                        processStatus={processStatus}
                        processId={processId}
                    />
                )}
            </Modal>

            <div className={'processes-table-header-container'}>
                <div className={'processes-table-top-bar'}>
                    {uiAlert && <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small" />}
                    <Tooltip message="Reset Process Executions">
                        <span>
                            <Icon.RefreshCW className={'pointer'} height={25} width={25} onClick={() => window.location.reload()} />
                        </span>
                    </Tooltip>
                </div>
            </div>
            <VirtualizedTable className="process-table" dataSource={builder.build([])} onRowChange={() => null} />
        </React.Fragment>
    );
};
