import React, {useEffect, useState} from "react";
import {AlertMessage, Button, Tooltip} from "@vacasa/react-components-lib";
import * as _ from "lodash";
import {EditableBox, EditableBoxProps, InfoBox, InfoBoxProps, SaveFormValues, SaveSidebar} from "..";
import "./CohortDashboard.scss";
import {CohortDashboardValues, CohortWithRates, Message, RegularChange, SelectOption} from "../../types";
import {RateService} from "../../services/RateService";
import {useDispatch, useSelector} from "react-redux";
import {AppState, selectAllModels} from "../../store";
import {UiUtils} from "../../utils";
import {CohortBoxes} from "./CohortBoxes";
import {COHORT_NAME_PATTERN, CohortTypes, EnhancedCohortDTO, NORTHSTAR_1_0_ID} from "@common/types";
import {LoggingService} from "../../services/LoggingService";
import {fetchModels} from "../../store/model/model.actions";
import {AppRoutes} from "../../Routes";
import {history} from "../../App";
import {Link} from "react-router-dom";
import {Icon} from "@vacasa/react-components-lib";

interface CohortDashboardProps {
    cohort: CohortWithRates;
    values: CohortDashboardValues;
    onChange: (values: Partial<CohortDashboardValues>) => void;
    havePermission?: boolean;
}

export const CohortDashboard: React.FC<CohortDashboardProps> = (props) => {
    const dispatch = useDispatch();
    const {cohort, values, havePermission, onChange} = props;
    const {unit_count, details} = cohort;
    const [modelOptions, setModelOptions] = useState<SelectOption[]>([]);
    const models = useSelector((state: AppState) => selectAllModels(state));
    const cohortError = useSelector((state: AppState) => state.cohort.error);
    const modelError = useSelector((state: AppState) => state.model.error);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);
    const [isShowingSave, setIsShowingSave] = useState(false);
    const [changes, setChanges] = useState<RegularChange[]>([]);

    useEffect(() => {
        if (_.isEmpty(models)) {
            dispatch(fetchModels());
        }
        let modelOptions: SelectOption[];

        if (cohort.independent_of_location === 1) {
            modelOptions = models.reduce((acc, model) => {
                if (model.type === "unit-level") {
                    acc.push({
                        value: model.id,
                        display: `${model.name} ${model.version} ${model.status.substr(0, 3)}`,
                    });
                }
                return acc;
            }, []);
        } else {
            modelOptions = models.reduce((acc, model) => {
                //Northstar should be available only for strategic cohorts
                if (model.id !== NORTHSTAR_1_0_ID) {
                    acc.push({
                        value: model.id,
                        display: `${model.name} ${model.version} ${model.status.substr(0, 3)}`,
                    });
                }
                return acc;
            }, []);
        }
        setModelOptions(modelOptions);
    }, [models]);

    useEffect(() => {
        const updatedChanges: RegularChange[] = [];
        const regionIdChanged = values.region_id !== cohort.region_id;
        if (regionIdChanged) {
            updatedChanges.push({key: "region_id", value: values.region_id, label: "Region Id"});
        }
        const assignedModelChanged = (values.assigned_model?.value || null) !== cohort.model_id;
        if (assignedModelChanged) {
            updatedChanges.push({key: "model_id", value: values.assigned_model?.display, label: "Assigned Model"});
        }
        const cohortNameChanged = values.name !== cohort.name;
        if (cohortNameChanged) {
            updatedChanges.push({key: "name", value: values.name, label: "Cohort Name"});
        }
        setChanges(updatedChanges);
    }, [values]);

    useEffect(() => {
        if (changes.length > 0) {
            setUiAlert(UiUtils.getPendingChangesMessage());
        } else {
            setUiAlert(null);
        }
        validateFields();
        if (!!cohortError || !!modelError) {
            const message = modelError.message || cohortError?.message;
            setUiAlert(UiUtils.getErrorMessage(message));
        }
    }, [cohortError, modelError, changes]);

    const handleAssignedModelChange = (newValue: SelectOption) => {
        onChange({assigned_model: newValue});
    };
    const handleNameChange = (value: string) => {
        onChange({name: value});
    };
    const handleRegionIdChange = (value: number) => {
        onChange({region_id: value});
    };

    const validateFields = () => {
        if (values.assigned_model == null && !_.isEmpty(changes)) {
            setUiAlert(UiUtils.getEmptyModelMessage());
        }
        if (values.name == null && !_.isEmpty(changes)) {
            setUiAlert(UiUtils.getEmptyNameMessage());
        }
    };

    const handleSave = async (data: SaveFormValues) => {
        const cohortData: Partial<EnhancedCohortDTO> = {};
        try {
            cohortData.context = data.context;

            for (const change of data.changes as RegularChange[]) {
                const key = change.key;
                if (key === "model_id") {
                    cohortData[key] = +values.assigned_model.value;
                    continue;
                }
                cohortData[key] = change["value"];
            }
            if (cohort.type === CohortTypes.CUSTOM) {
                await RateService.updateCustomCohort(cohort.name, cohortData);
            } else {
                await RateService.updateCohort(cohort.name, cohortData);
            }
            setChanges(() => []);
            setUiAlert(UiUtils.getSaveSuccessMessage());
            if (values.name !== cohort.name) {
                history.push(AppRoutes.COHORT_EVOLUTION.replace(":name", encodeURIComponent(values.name)));
            }
        } catch (e) {
            LoggingService.error(`error saving cohort dashboard changes`, {
                cohort_name: cohort?.name,
                params: {update: cohortData},
            });
            if (e.response.data[0]?.detail) {
                setUiAlert(UiUtils.getErrorMessage(e.response.data[0]?.detail.error));
            } else {
                setUiAlert(UiUtils.getErrorMessage(e));
            }
        }
        setIsShowingSave(false);
    };

    const isSaveDisabled = _.isEmpty(changes) || _.isEmpty(values.assigned_model) || _.isEmpty(values.name);
    const isModelOrStrategic = ["model", "strategic"].includes(cohort.type);

    const boxes: InfoBoxProps[] = [];
    boxes.push({
        id: CohortBoxes.UNIT_COUNT,
        title: "Units",
        content: (
            <div className="info-box-unit-count">
                <h3>{unit_count}</h3>
                {isModelOrStrategic && (
                    <Link className="info-box-unit-count-link" to={`${AppRoutes.UNITS}?cohort=${encodeURIComponent(cohort.name)}`} target="_blank">
                        <Icon.Search className="pointer link-icon" height={18} width={18} /> List units
                    </Link>
                )}
            </div>
        ),
        footer: {
            subtitle: "Number of Units for Cohort",
        },
        tip: "Number of Units for Cohort",
        direction: "horizontal",
    });

    if (details) {
        boxes.push({
            id: CohortBoxes.OCCUPANCY,
            title: "Occupancy (%)",
            content: [
                {
                    label: `${details.occupancy_percent} %`,
                    headingLevel: "h3",
                },
            ],
            footer: {subtitle: "Average Occupancy on Units"},
            tip: "Average Occupancy on Units",
            direction: "horizontal",
        });

        boxes.push({
            id: CohortBoxes.HOLDS_PERCENTAGE,
            title: "Vacasa vs Owner Holds",
            content: [
                {
                    label: `${details.vacasa_holds_percent} %`,
                    headingLevel: "h4",
                },
                {
                    label: `${details.owner_holds_percent} %`,
                    headingLevel: "h4",
                },
            ],
            footer: {subtitle: "Units on Hold by Vacasa and Owners"},
            tip: "Units on  Hold by Vacasa and Owners",
            direction: "horizontal",
        });

        boxes.push({
            id: CohortBoxes.FEES_AND_RENT_AMOUNT,
            title: "Rent and Fees ($)",
            content: [
                {
                    label: details.rent_amount,
                    headingLevel: "h4",
                },
                {
                    label: details.fees_amount,
                    headingLevel: "h4",
                },
            ],
            footer: {subtitle: "Income by Rent and Fees"},
            tip: "Income by Rent and Fees",
            direction: "horizontal",
        });

        boxes.push({
            id: CohortBoxes.FEES_AND_RENT_RATIO,
            title: "Fee/Rent ratio (%)",
            content: [
                {
                    label: `${details.rent_fee_ratio} %`,
                    headingLevel: "h3",
                },
            ],
            footer: {subtitle: "Relation Between Fees and Rent"},
            tip: "Relation Between Fees and Rent",
            direction: "horizontal",
        });
    }

    const editableBoxes: EditableBoxProps[] = [];

    if (cohort.type === "model") {
        editableBoxes.push({
            id: CohortBoxes.REGION_ID,
            title: "Region ID",
            tip: "Update the Region ID",
            type: "input",
            footer: {subtitle: "Update the Region ID"},
            inputs: [
                {
                    decimals: false,
                    min: 0,
                    value: values.region_id,
                    disable: !havePermission,
                    onChange: handleRegionIdChange,
                    tooltip: havePermission ? "" : "Editor access required",
                },
            ],
            havePermission: havePermission,
        });
    }

    editableBoxes.push({
        id: CohortBoxes.COHORT_NAME,
        title: "Cohort Name",
        tip: "Update the cohort name",
        type: "input",
        footer: {subtitle: "Update the Cohort Name"},
        inputs: [
            {
                value: values.name,
                disable: (cohort?.independent_of_location === 0 && cohort?.type === CohortTypes.MODEL) || !havePermission,
                onChange: handleNameChange,
                tooltip:
                    cohort?.independent_of_location === 0 && cohort?.type === CohortTypes.MODEL
                        ? havePermission
                            ? "Only available for strategic and custom cohorts"
                            : "Editor access required"
                        : havePermission
                        ? ""
                        : "Editor access required",
                isString: true,
                validationPattern: COHORT_NAME_PATTERN,
                multipleLine: true,
            },
        ],
        havePermission: havePermission,
    });

    //add assigned model box only for model and strategic cohorts
    if (isModelOrStrategic) {
        editableBoxes.unshift({
            id: CohortBoxes.ASSIGNED_MODEL,
            title: "Assigned Model",
            tip: cohort.independent_of_location === 1 ? "Strategic Cohorts only allow unit level models" : "Assigned Model",
            type: "search",
            label: "Selected:",
            footer: {subtitle: "Update the Assigned Model"},
            options: modelOptions,
            value: values.assigned_model,
            invalid: _.isEmpty(modelOptions) && !!modelError,
            onChange: handleAssignedModelChange,
            havePermission: havePermission,
        });
    }

    const items = _.concat(
        _.map(editableBoxes, (box, i) => ({id: box.id, content: <EditableBox key={i} {...box} />})),
        _.map(boxes, (box) => ({id: box.id, content: <InfoBox {...box} />}))
    );

    return (
        <div className="cohort-dashboard">
            <div className="actions-header">
                {uiAlert && <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small" />}
                {/*<IconToolBar
                    icons={[
                        {
                            icon: <Icon.Settings className="tool-bar-icons pointer" height={24} width={24} />,
                            id: 'cohort-dashboard-settings',
                            tip: 'Dashboard Settings',
                            onClick: () => {
                                alert('Dashboard Settings');
                            },
                        },
                    ]}
                />*/}

                <Tooltip message={`${havePermission ? "" : "Editor access required"}`}>
                    <div>
                        <Button variant="secondary" onClick={() => setIsShowingSave(true)} disabled={isSaveDisabled || !havePermission}>
                            Save
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <div className={"dashboard-boxes"}>
                <div className="grid">
                    {items.map((item) => (
                        <div>{item.content}</div>
                    ))}
                </div>
            </div>

            {isShowingSave && !isSaveDisabled && (
                <SaveSidebar
                    type="regular"
                    hasChangeReason={true}
                    hasExpirationDays={false}
                    onSave={handleSave}
                    changes={changes}
                    onClose={() => setIsShowingSave(false)}
                />
            )}
        </div>
    );
};
