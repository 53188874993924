import React, { useEffect, useState } from 'react';
import { DataSourceBuilder } from '../VirtualizedTable/DataSourceBuilder';
import { ProcessDetailsTableValues } from '../../types';
import { VirtualizedTable } from '../VirtualizedTable/VirtualizedTable';
import { BulkProcessType, ProcessExecutionDetailStatus } from '@common/types';
import { Icon } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import { CSVLink } from 'react-csv';
import { RateService } from '../../services/RateService';
import { LoggingService } from '../../services/LoggingService';
import { Loading } from '..';

interface ProcessDetailsTableProps {
    processDetails: ProcessDetailsTableValues[];
    processType: string;
    processStatus: string;
    processId: string;
}

export const ProcessDetailsTable: React.FC<ProcessDetailsTableProps> = (props) => {
    const { processDetails, processType, processStatus, processId } = props;

    const [isBulk] = useState<boolean>(!_.isNil(BulkProcessType[processType]));
    const [isFetchingFile, setIsFetchingFile] = useState<boolean>(true);
    const [fileData, setFileData] = useState<{ filename: string; content: string }>({ filename: '', content: '' });

    const bulkProcessList: string[] = [
        BulkProcessType.UNIT_DATE_BULK_UPLOAD,
        BulkProcessType.COHORT_DATE_BULK_UPLOAD,
        BulkProcessType.INITIAL_RATE_SETTING_UPLOAD,
    ];
    const fetchFile = async (processExecutionId) => {
        try {
            if (bulkProcessList.includes(processType)) {
                setIsFetchingFile(true);
                const file = await RateService.getProcessExecutionUploadedFile(processExecutionId);
                if (file) {
                    const { filename, content } = file;
                    if (filename && content) {
                        setFileData({ filename: filename, content: content });
                    }
                }
            }
        } catch (e) {
            LoggingService.error(`ERROR fetching uploaded file ${processExecutionId} error:`, e);
        } finally {
            setIsFetchingFile(false);
        }
    };

    useEffect(() => {
        fetchFile(processId);
    }, [processId]);

    const iconStatus = (status) => {
        switch (status) {
            case ProcessExecutionDetailStatus.Processing:
                return <Icon.Loader className={'color-processing'} height={25} width={25} />;
            case ProcessExecutionDetailStatus.Success:
                return <Icon.CheckCircle className={'color-success'} height={25} width={25} />;
            case ProcessExecutionDetailStatus.Warning:
                return <Icon.AlertTriangle className={'color-warning'} height={25} width={25} />;
            case ProcessExecutionDetailStatus.Waiting:
                return <Icon.Clock className={'color-processing'} height={25} width={25} />;
            case ProcessExecutionDetailStatus.Failed:
                return <Icon.AlertOctagon className={'color-failed'} height={25} width={25} />;
            case ProcessExecutionDetailStatus.Canceled:
                return <Icon.Slash className={'color-processing'} height={25} width={25} />;
            default:
                console.error(`error loading icon for ${status} status`);
        }
    };

    const iconStatusModal = (status: string) => {
        switch (status) {
            case ProcessExecutionDetailStatus.Processing:
                return (
                    <>
                        <Icon.Loader className={'color-processing'} height={50} width={50} /> <span>Processing</span>
                    </>
                );
            case ProcessExecutionDetailStatus.Success:
                return (
                    <>
                        <Icon.CheckCircle className={'color-success'} height={50} width={50} /> <span>Successful process</span>
                    </>
                );
            case ProcessExecutionDetailStatus.Warning:
                return (
                    <>
                        <Icon.AlertTriangle className={'color-warning'} height={50} width={50} /> <span>Warning</span>
                    </>
                );
            case ProcessExecutionDetailStatus.Waiting:
                return (
                    <>
                        <Icon.Clock className={'color-processing'} height={50} width={50} /> <span>Waiting</span>
                    </>
                );
            case ProcessExecutionDetailStatus.Failed:
                return (
                    <>
                        <Icon.AlertOctagon className={'color-failed'} height={50} width={50} />
                        <span>{isBulk ? 'Bulk Failed' : 'Process Failed'}</span>{' '}
                    </>
                );
            case ProcessExecutionDetailStatus.Canceled:
                return (
                    <>
                        <Icon.Slash className={'color-processing'} height={50} width={50} /> <span>Process Canceled</span>
                    </>
                );
            default:
                console.error(`error loading icon for ${status} status`);
        }
    };

    const downloadErrors = (allWarnings: Array<any>) => {
        let headers = [];
        let data = [];
        _.map(allWarnings, (warnings) => {
            _.map(warnings, (warning) => {
                if (typeof warning === 'string') return data.push([warning]);
                if (_.isEmpty(headers) && !_.isNil(warning.row)) headers.push('line', ...Object.keys(warning.row), 'issues');
                const value = [warning.line, ...Object.values(warning.row)];
                let issue = '';
                _.map(warning.issues, (issues) => {
                    issue = !_.isEmpty(issue) ? `${issue} - ${issues.message}` : issues.message;
                });
                value.push(issue);
                data.push(value);
            });
        });
        if (_.isEmpty(headers)) headers.push('error');
        return (
            <CSVLink data={data} headers={headers} filename={'errors'} enclosingCharacter={``}>
                <button className={'pointer'}>Download Errors</button>
            </CSVLink>
        );
    };

    const downloadOriginalFile = (content, filename) => {
        return content !== '' && filename !== '' ? (
            <CSVLink data={content} filename={filename} enclosingCharacter={``}>
                <button className={'pointer'}>Download Original File</button>
            </CSVLink>
        ) : (
            <button className={'button-disabled'} disabled={true}>
                Download Original File
            </button>
        );
    };

    const getOriginalFileButton = () => {
        return isFetchingFile ? (
            <button className={'button-loading'} disabled={true}>
                <Loading />
            </button>
        ) : (
            downloadOriginalFile(fileData.content, fileData.filename)
        );
    };

    const footerAccordingToStatus = (processDetails: ProcessDetailsTableValues[], processType: string) => {
        const status = [];
        _.map(processDetails, (detail) => {
            status.push(detail.status);
        });
        const warnings: Array<any> = [];
        _.map(processDetails, (detail) => {
            if (_.isEmpty(detail.metadata?.warnings) || _.isNil(detail.metadata?.warnings)) return [];
            if (!_.isEmpty(detail.metadata?.warnings)) return warnings.push(detail.metadata?.warnings);
        });
        const errors: Array<any> = [];
        _.map(processDetails, (detail) => {
            if (_.isEmpty(detail.metadata?.errors)) return [];
            _.map(detail.metadata?.errors, (error) => {
                if (_.isEmpty(error.issues)) return errors.push(...detail.metadata?.errors);
                _.map(error.issues, (issue) => {
                    errors.push(`${issue.message} - `);
                });
            });
        });
        return status.includes(ProcessExecutionDetailStatus.Failed) ? (
            <>
                <br />
                <div className={'info-footer-failed'}>
                    <span>Error Message: </span>
                </div>
                <div className={'container-error-popup-process'}>
                    <textarea disabled={true}>{JSON.stringify(errors)}</textarea>
                </div>
                {Object.values(BulkProcessType).toString().includes(processType) && (
                    <div className={'container-button-popup-process'}>
                        {downloadErrors(warnings)}
                        {getOriginalFileButton()}
                    </div>
                )}
            </>
        ) : status.includes(ProcessExecutionDetailStatus.Failed) && !_.isEmpty(warnings) ? (
            Object.values(BulkProcessType).toString().includes(processType) && (
                <>
                    <div className={'info-footer'}>
                        <span>Files: </span>
                    </div>
                    <div className={'container-button-popup-process'}>
                        {downloadErrors(warnings)}
                        {getOriginalFileButton()}
                    </div>
                </>
            )
        ) : status.includes(ProcessExecutionDetailStatus.Canceled) && _.isEmpty(warnings) ? (
            <>
                <br />
                <div className={'info-footer-failed'}>
                    <span>Error Message: </span>
                </div>
                <div className={'container-error-popup-process'}>
                    <textarea disabled={true}>{JSON.stringify(errors)}</textarea>
                </div>
                {Object.values(BulkProcessType).toString().includes(processType) && (
                    <div className={'container-button-popup-process'}>
                        {downloadErrors(warnings)}
                        {getOriginalFileButton()}
                    </div>
                )}
            </>
        ) : status.includes(ProcessExecutionDetailStatus.Canceled) && !_.isEmpty(warnings) ? (
            Object.values(BulkProcessType).toString().includes(processType) && (
                <>
                    <div className={'info-footer'}>
                        <span>Files: </span>
                    </div>
                    <div className={'container-button-popup-process'}>
                        {downloadErrors(warnings)}
                        {getOriginalFileButton()}
                    </div>
                </>
            )
        ) : status.includes(ProcessExecutionDetailStatus.Warning) && _.isEmpty(warnings) ? (
            <>
                <br />
                <div className={'info-footer-failed'}>
                    <span>Error Message: </span>
                </div>
                <div className={'container-error-popup-process'}>
                    <textarea disabled={true}>{JSON.stringify(errors)}</textarea>
                </div>
                <div className={'container-button-popup-process'}>
                    {downloadErrors(warnings)}
                    {getOriginalFileButton()}
                </div>
            </>
        ) : status.includes(ProcessExecutionDetailStatus.Warning) && !_.isEmpty(warnings) ? (
            Object.values(BulkProcessType).toString().includes(processType) && (
                <>
                    <div className={'info-footer'}>
                        <span>Files: </span>
                    </div>
                    <div className={'container-button-popup-process'}>
                        {downloadErrors(warnings)}
                        {getOriginalFileButton()}
                    </div>
                </>
            )
        ) : status.includes(ProcessExecutionDetailStatus.Waiting) ? (
            Object.values(BulkProcessType).toString().includes(processType) && (
                <>
                    <div className={'info-footer'}>
                        <span>Files: </span>
                    </div>
                    <div className={'container-button-popup-process'}>{getOriginalFileButton()}</div>
                </>
            )
        ) : status.includes(ProcessExecutionDetailStatus.Processing) ? (
            Object.values(BulkProcessType).toString().includes(processType) && (
                <>
                    <div className={'info-footer'}>
                        <span>Files: </span>
                    </div>
                    <div className={'container-button-popup-process'}>{getOriginalFileButton()}</div>
                </>
            )
        ) : status.includes(ProcessExecutionDetailStatus.Success) ? (
            Object.values(BulkProcessType).toString().includes(processType) && (
                <>
                    <div className={'info-footer'}>
                        <span>Files: </span>
                    </div>
                    <div className={'container-button-popup-process'}>{getOriginalFileButton()}</div>
                </>
            )
        ) : (
            ''
        );
    };

    const builder = new DataSourceBuilder<ProcessDetailsTableValues>();
    builder.addColumn({
        label: 'Process',
        field: 'step',
        displayConfiguration: { containerType: 'div', flexGrow: 1.3, justifyContent: 'center' },
    });
    builder.addColumn({
        label: 'Started at',
        field: 'started_at',
        displayConfiguration: { containerType: 'div', flexGrow: 1, justifyContent: 'center' },
    });
    builder.addColumn({
        label: 'Time',
        field: 'execution_time',
        displayConfiguration: { containerType: 'div', flexGrow: 1, justifyContent: 'center' },
    });
    builder.addColumn({
        label: 'Status',
        field: 'status',
        displayConfiguration: { containerType: 'span', flexGrow: 0.6, justifyContent: 'center' },
        fieldConfiguration: {
            format: (i) => {
                return <span>{iconStatus(i)}</span>;
            },
        },
    });

    return (
        <React.Fragment>
            <div className={'container-icon'}>{iconStatusModal(processStatus)}</div>

            <div className={'process-detail-table'}>
                <VirtualizedTable dataSource={builder.build(processDetails)} onRowChange={() => null} />
            </div>
            <div className={'footer-process-detail'}>{footerAccordingToStatus(processDetails, processType)}</div>
        </React.Fragment>
    );
};
