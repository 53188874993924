import React, { useRef } from 'react';
import { DraggableItem } from './DraggableList';
import './DraggableList.scss';
import { useDragAndDrop } from './useDragAndDrop';

const ListItem = 'ListItem';

interface DraggableListItemProps {
    item: DraggableItem;
    onHover: (draggedItemId: string, hoverItemId: string) => void;
    onDrop: (draggedItemId: string, hoverItemId: string) => void;
}

export const DraggableListItem: React.FC<DraggableListItemProps> = (props) => {
    const { item, onHover, onDrop } = props;
    const ref = useRef<HTMLDivElement>(null);

    const { isDragging, handlerId } = useDragAndDrop(ListItem, { ref, payload: item }, onHover, onDrop);

    return (
        <div ref={ref} data-handler-id={handlerId} key={item.key} className={`draggable-list-item ${isDragging ? 'dragging' : ''}`}>
            {item.content}
        </div>
    );
};
