export enum CohortBoxes {
    // Info
    UNIT_COUNT = 'unit_count',
    OCCUPANCY = 'occupancy',
    HOLDS_PERCENTAGE = 'holds_percentage',
    FEES_AND_RENT_AMOUNT = 'fees_and_rent_amount',
    FEES_AND_RENT_RATIO = 'fees_and_rent_ratio',
    // Editable
    ASSIGNED_MODEL = 'assigned_model',
    REGION_ID = 'region_id',
    COHORT_NAME = 'cohort_name',
}
