import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from '@vacasa/react-components-lib';
import { VirtualizedTable, SaveSidebar } from '..';
import { DataSourceBuilder } from '../VirtualizedTable/DataSourceBuilder';
import './CohortFactorUnitTable.scss';
import { CohortFactorAndStatusUnitTableValues, Message } from '../../types';
import { UnitFactorDTO, UnitStatus, UpdateContextDTO } from '@common/types';
import { AlertMessage } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import { Row } from '../../types/VirtualizedTable';
import { UiUtils } from '../../utils';
import { Configuration } from '../../Configuration';

interface CohortFactorUnitTableProps<R extends CohortFactorAndStatusUnitTableValues> {
    units: CohortFactorAndStatusUnitTableValues[];
    onRowChange: (column: keyof R, updatedRows: Row<R>[]) => void;
    changes: UnitFactorDTO[];
    onSave: (formValues: { context: UpdateContextDTO; changes: UnitFactorDTO[] }) => void;
}
type CohortFactorUnitTableComponent = <T extends CohortFactorAndStatusUnitTableValues>(
    props: CohortFactorUnitTableProps<T>
) => ReactElement<any, any> | null;
export const CohortFactorUnitTable: CohortFactorUnitTableComponent = <T extends CohortFactorAndStatusUnitTableValues>(props) => {
    const { onRowChange, onSave, changes, units } = props;
    const [isShowingSaveSidebar, setIsShowingSetSidebar] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<Message>(null);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const validationFactor = (): boolean => {
        const valid = _.some(changes, (change) => {
            return change.factor === 0;
        });
        if (valid) {
            setAlertMessage({ type: 'error', content: 'There are factors that are 0' });
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (changes.length === 0) {
            setIsSaveDisabled(true);
            setIsShowingSetSidebar(false);
        } else {
            setIsSaveDisabled(false);
        }
        setShowAlert(validationFactor());
    }, [changes]);

    const builder = new DataSourceBuilder<CohortFactorAndStatusUnitTableValues>();
    builder.addColumn({
        label: 'Unit ID',
        field: 'id',
        func: (obj) => <a href={Configuration.getUnitDashboardPage(obj['id'])}>{obj['id']}</a>,
    });

    builder.addColumn({ label: 'Unit Code', field: 'code' });
    builder.addColumn({
        label: 'Analyst Base Rate Override',
        field: 'base_rate_override',
        editable: { fieldType: 'input', copyable: true, inputConfiguration: { minValue: 0 } },
    });
    builder.addColumn({
        label: 'Factor',
        field: 'factor',
        editable: {
            fieldType: 'input',
            copyable: true,
            inputConfiguration: {
                minValue: 0,
                maxValue: 10,
                validation: (obj) => {
                    if (obj['factor'] === 0 || obj['factor'] > Configuration.getMaxValueAnalystFactor()) {
                        const isValid = false;
                        return { isValid, validationSummary: 'Analyst Factor must be a value between 0.01 and 10' };
                    }
                    return { isValid: true };
                },
            },
        },
    });
    builder.addColumn({ label: 'Predicted Rate', field: 'predicted_rate' });
    builder.addColumn({ label: 'Adjusted Rate', field: 'adjusted_rate' });
    builder.addColumn({ label: 'Advertised Rate', field: 'advertised_rate' });
    builder.addColumn({
        label: 'Status',
        field: 'status',
        fieldConfiguration: {
            customLeftComponent: (obj) => {
                let customClass = 'circle ';
                switch (obj.status) {
                    case UnitStatus.Available:
                        customClass = customClass + 'green';
                        break;
                    case UnitStatus.Booked:
                        customClass = customClass + 'red';
                        break;
                    case UnitStatus.Owner_Hold:
                        customClass = customClass + 'yellow';
                        break;
                    case UnitStatus.Vacasa_Hold:
                        customClass = customClass + 'midnight';
                        break;
                    case UnitStatus.Stateless:
                        customClass = customClass + 'empty';
                        break;
                }
                return <span className={customClass} />;
            },
        },
    });

    const infoSelectStatus = ['ALL', 'Available', 'Booked', 'Owner Hold', 'Vacasa Hold', 'N/A'];

    builder.setFilterHeader({
        options: [
            { field: 'id', type: 'number' },
            { field: 'base_rate_override', type: 'range' },
            { field: 'factor', type: 'range' },
            { field: 'predicted_rate', type: 'range' },
            { field: 'adjusted_rate', type: 'range' },
            { field: 'advertised_rate', type: 'range' },
            { field: 'status', type: 'select', values: infoSelectStatus },
        ],
    });

    builder.setSortable({ field: 'id', order: 'asc' });

    const copyToAllUnits = (column: keyof T, value: number, rows: Row<T>[]) => {
        UiUtils.copyToAll(column, value, rows, onRowChange);
    };
    builder.addCopyHandler(copyToAllUnits);
    return (
        <div className="cohort-factor-table">
            <div className="cohort-factors-toolbar">
                {showAlert && (
                    <div className="cohort-factor-alert">
                        <AlertMessage height="small" type={alertMessage.type} text={alertMessage.content} />
                    </div>
                )}
                <Button
                    customClass="save-cohort-factor-button"
                    variant="secondary"
                    onClick={() => setIsShowingSetSidebar(true)}
                    disabled={isSaveDisabled || showAlert}>
                    Save
                </Button>
            </div>

            {isShowingSaveSidebar && (
                <SaveSidebar
                    type="unit_factors"
                    hasExpirationDays={true}
                    changes={changes}
                    onSave={onSave}
                    onClose={() => setIsShowingSetSidebar(false)}
                    hasChangeReason={true}
                />
            )}

            <VirtualizedTable
                dataSource={builder.build(units)}
                onRowChange={(column, row) => {
                    onRowChange(column, [row]);
                }}
                headerOptions={{ height: 80 }}
            />
        </div>
    );
};
