import React, { ReactNode, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableListItem } from './DraggableListItem';

import './DraggableList.scss';

export type DraggableItem = {
    key: string;
    content: ReactNode;
};

interface DraggableListProps {
    items: DraggableItem[];
    onOrderChange: (order: string[]) => void;
    customClass?: string;
}

export const DraggableList: React.FC<DraggableListProps> = (props) => {
    const { items, onOrderChange, customClass } = props;

    const getOrder = (items: DraggableItem[]) => {
        return items.reduce((acc, current, index) => {
            acc[current.key] = index;
            return acc;
        }, {} as { [id: string]: number });
    };

    const [itemsOrder, setItemsOrder] = useState<{ [key: string]: number }>(getOrder(items));

    useEffect(() => {
        setItemsOrder(getOrder(items));
    }, [items]);

    const sortItems = (a: DraggableItem, b: DraggableItem) => itemsOrder[a.key] - itemsOrder[b.key];

    const handleOver = (firstItemId: string, secondItemId: string) => {
        const currentOrder = { ...itemsOrder };
        const swappable = [currentOrder[firstItemId], currentOrder[secondItemId]].every((value) => value !== undefined);
        if (swappable) {
            [currentOrder[firstItemId], currentOrder[secondItemId]] = [currentOrder[secondItemId], currentOrder[firstItemId]];
        }
        setItemsOrder(currentOrder);
    };

    const handleDrop = () => {
        const ordered = [...items].sort(sortItems).map((item) => item.key);
        onOrderChange(ordered);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={`draggable-list ${customClass}`}>
                {[...items].sort(sortItems).map((item, i) => (
                    <DraggableListItem key={item.key} item={item} onHover={handleOver} onDrop={handleDrop} />
                ))}
            </div>
        </DndProvider>
    );
};
