import React, { useState } from 'react';
import * as _ from 'lodash';
import { Icon, Tooltip } from '@vacasa/react-components-lib';
import { format } from 'date-fns';
import { ChangeHistoryDTO, DATETIME_FORMAT } from '@common/types';
import './ChangeLog.scss';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { IntervalChangeSummary, Loading, Modal, NoDataAlert, RegularChangesSummary, SeasonalChangeSummary } from '..';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { RegularChange } from '../../types';
import { sortBy } from 'lodash';

interface ChangeLogProps {
    history: ChangeHistoryDTO[];
}

export const ChangeLog: React.FC<ChangeLogProps> = (props) => {
    const { history } = props;
    const sortedHistory = history.sort((a, b) => {
        return +new Date(b.created_at) - +new Date(a.created_at);
    });
    const { isFetchingHistory } = useSelector((state: AppState) => state.history);
    const [JSONDetail, setJSONDetail] = useState<object>(null);
    const [detailName, setDetailName] = useState<string>();
    const [detailType, setDetailType] = useState<string>();
    const [detailDate, setDetailDate] = useState<string>();
    const [isShowingJsonDetailModal, setIsShowingDetailModal] = useState<boolean>(false);
    const [highlight, setHighlight] = useState<number>();

    const setHighlighted = (id) => {
        setHighlight(id);
    };

    const JsonDetail = () => (
        <div className="json-detail">
            <SyntaxHighlighter language="json">{JSON.stringify(JSONDetail, null, 2)}</SyntaxHighlighter>
        </div>
    );

    const getChangeResume = (detail, analystNote) => {
        if (Array.isArray(detail)) {
            //check if the array is a seasonal changelog
            if (detail.some((obj) => obj.hasOwnProperty('apply_start_date'))) {
                return <SeasonalChangeSummary changes={detail} analystNote={analystNote} />;
            }
            return <IntervalChangeSummary changes={detail} />;
        }
        // assume is a regular change, and transform to array
        const keys = _.keys(detail);

        const changes: RegularChange[] = keys.map((key) => ({
            key,
            value: detail[key],
        }));

        return <RegularChangesSummary changes={changes} />;
    };

    const handleClose = () => {
        setJSONDetail(null);
        setIsShowingDetailModal(false);
    };

    const showJSONDetail = (json) => {
        const sortedJson =
            Array.isArray(json.change_detail) && json.change_detail.some((obj) => obj.date !== undefined)
                ? sortBy(json.change_detail, (obj) => new Date(obj.date))
                : json.change_detail;
        setJSONDetail(sortedJson);
        setDetailName(json.analyst_name);
        setDetailType(json.change_source);
        setDetailDate(format(new Date(json.created_at), DATETIME_FORMAT));
        setIsShowingDetailModal(true);
    };

    if (isFetchingHistory) {
        return (
            <div className="change-log-loading">
                <Loading />
            </div>
        );
    }
    return (
        <React.Fragment>
            <Modal
                title={'JSON Detail  [ ' + detailName + ' | ' + detailType + ' | ' + detailDate + ' ]'}
                show={isShowingJsonDetailModal && !!JSONDetail}
                onClose={handleClose}>
                <JsonDetail />
            </Modal>

            <div className="change-log-table">
                <div className="change-log-table-header">
                    <Tooltip message="Copy Process execution id" placement="top">
                        <div className="change-log-process-id-header">
                            Process <br />
                            Id
                        </div>
                    </Tooltip>
                    <div>Date</div>
                    <div>Analyst Name</div>
                    <div>Change Type</div>
                    <div>Analyst Reason</div>
                    <div>Analyst Note</div>
                    <div>Change Summary</div>
                    <div>{`Details {...}`}</div>
                </div>

                {_.isEmpty(sortedHistory) && <NoDataAlert />}

                {_.map(sortedHistory, (change) => {
                    return (
                        <div className={highlight === change.id ? 'change-log-table-row highlighted' : 'change-log-table-row'}>
                            <div className="change-log-process-id">
                                {!_.isEmpty(change.process_execution_id) && (
                                    <Tooltip message={change.process_execution_id} placement="right">
                                        <div>
                                            <Icon.Copy
                                                className="pointer"
                                                height={24}
                                                width={24}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(change.process_execution_id);
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div>{format(new Date(change.created_at), DATETIME_FORMAT)}</div>
                            <div>{change.analyst_name}</div>
                            <div>{change.change_source}</div>
                            <div> {change.analyst_reason}</div>
                            <div>
                                <div className="change-log-analyst-note">{change.analyst_note}</div>
                            </div>
                            <div>
                                <div className="change-log-change-resume">{getChangeResume(change.change_detail, change.analyst_note)}</div>
                            </div>

                            <div>
                                <Tooltip message="Open JSON details" placement="right">
                                    <div>
                                        <Icon.PlusCircle
                                            className="pointer"
                                            height={24}
                                            width={24}
                                            onClick={() => {
                                                setHighlighted(change.id);
                                                showJSONDetail(change);
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};
