import React from 'react';
import { DraggableItem } from './DraggableList';
import { useDrag, useDrop } from 'react-dnd';

type DnDCallback = (dragged: string, hover: string) => void;

export const useDragAndDrop = (
    type: string,
    element: { ref: React.MutableRefObject<any>; payload: DraggableItem },
    onHover: DnDCallback,
    onDrop: DnDCallback
) => {
    const [{ handlerId }, drop] = useDrop({
        accept: type,
        collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
        drop: (draggedObject, monitor) => element.payload,

        // this calls back more often than wanted, but has the "updated in realtime" effect
        hover: (dragObject: DraggableItem, monitor) => {
            if (dragObject.key === element.payload.key) {
                return;
            }
            onHover(dragObject.key, element.payload.key);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type,
        item: () => {
            return element.payload;
        },
        end: (result, monitor) => {
            const didDrop = monitor.didDrop();
            const dropResult = monitor.getDropResult() as DraggableItem;
            if (didDrop && dropResult) {
                onDrop(dropResult.key, element.payload.key);
            }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(element.ref));

    return {
        handlerId,
        isDragging,
    };
};
