import React, { useRef, MutableRefObject, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import * as _ from 'lodash';

import './PillsList.scss';

export interface PillsListProps {
    values: string[];
    onValueAdded: (unit: string) => void;
    onValueDeleted: (unit: string, index: number) => void;
    onPaste?: (content: string) => void;
    className?: string;
}

export const PillsList: React.FC<PillsListProps> = (props) => {
    const { values, onValueAdded, onValueDeleted, onPaste, className } = props;

    const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputRef]);

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            const value = inputRef.current.value.trim();
            if (!_.isEmpty(value)) {
                event.preventDefault();
                onValueAdded(value);
                if (!_.isEmpty(inputRef.current)) {
                    inputRef.current.value = '';
                }
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        const value = (event.target as HTMLInputElement).value.trim();
        const isEnter = event.key === 'Enter';
        const isComma = event.key === ',';

        if (value && (isEnter || isComma)) {
            event.preventDefault();
            onValueAdded(value);
            inputRef.current.value = '';
            return;
        }

        if (event.key === 'Backspace' && !value) {
            onValueDeleted(values[values.length - 1], values.length - 1);
        }
    };

    return (
        <React.Fragment>
            <div className={`pills-list ${className ?? ''}`}>
                <ul className="pills-list-tags">
                    {values.map((unit, i) => (
                        <li key={i}>
                            <Chip
                                className="pill"
                                label={unit}
                                onDelete={() => {
                                    onValueDeleted(unit, i);
                                }}
                            />
                        </li>
                    ))}
                    <li>
                        <input
                            className="pills-list-input"
                            ref={inputRef}
                            type="text"
                            autoFocus={true}
                            onKeyDown={handleKeyDown}
                            onPaste={(e) => {
                                e.preventDefault();
                                onPaste(e.clipboardData.getData('Text'));
                            }}
                        />
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
};
