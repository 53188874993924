import React, { ReactElement, useState } from 'react';
import { DataSourceBuilder } from '../VirtualizedTable/DataSourceBuilder';
import {
    BaseRateTableValues,
    ColumnOptions,
    DateChange,
    DowOptions,
    Filters,
    Message,
    SetCohortChange,
    SetUnitChange,
    Sidebar,
    StatusOptions,
} from '../../types';
import { ActionSidebar, DefaultActionsSidebar, IconToolBar, SaveFormValues, SaveSidebar, VirtualizedTable } from '..';
import './RatesTable.scss';
import { Column, Row } from '../../types/VirtualizedTable';
import * as _ from 'lodash';
import { isAfter, isBefore, startOfDay, startOfYesterday } from 'date-fns';
import { AlertMessage, Button, Icon, Tooltip } from '@vacasa/react-components-lib';
import { parseDate } from '@common/utils';
import { ColumnConfiguration, ColumnDescription } from '../VirtualizedTable/ColumnConfiguration';
import { CSVLink } from 'react-csv';
import { DoW, UnitDateCurrentStatus, UpdateContextDTO } from '@common/types';
import { UiUtils } from '../../utils';
import { Chip } from '@material-ui/core';

interface RatesTableProps<R extends BaseRateTableValues> {
    builder: DataSourceBuilder<R>;
    data: any[];
    onRowsChange: (column: keyof R, updatedRows: Row<R>[]) => void;
    onSave: (saveForm: SaveFormValues) => void;
    changes: DateChange[];
    dowFilterOption: DowOptions;
    statusFilterOption?: StatusOptions;
    message?: Message;
    columnConfig: {
        visible: ColumnDescription[];
        hidden: ColumnDescription[];
        onColumnsChange: (added: ColumnDescription[], hidden: ColumnDescription[]) => void;
        onResetDefault: () => void;
    };
    downloadConfig: {
        fileName: string;
    };
    saveConfig: {
        hasExpiration: boolean;
        isDisabled?: boolean;
    };
    cohortName?: string;
    regionId: number;
    countryName?: string;
    disabled?: boolean;
    havePermission?: boolean;
    actionSidebar?: {
        name: string;
        type: 'set' | 'multiply';
        enabled: boolean;
        tooltip: string;
        columnOptions: ColumnOptions;
        onSave: (updatedRows: SetCohortChange | SetUnitChange, context: UpdateContextDTO) => void;
    };
}

type RatesTableComponent = <T extends BaseRateTableValues>(props: RatesTableProps<T>) => ReactElement<any, any> | null;

export const RatesTable: RatesTableComponent = <T extends BaseRateTableValues>(props) => {
    const {
        data,
        onRowsChange,
        onSave,
        dowFilterOption,
        statusFilterOption,
        builder,
        message,
        changes,
        columnConfig,
        downloadConfig,
        saveConfig,
        cohortName,
        regionId,
        countryName,
        disabled,
        havePermission,
        actionSidebar,
    } = props;

    const [defaultActionsSidebar, setDefaultActionsSidebar] = useState<Sidebar>({ show: false });
    const [showSetSidebar, setShowSetSidebar] = useState<Sidebar>({ show: false });
    const [selectedRates, setSelectedRates] = useState<string[]>([]);
    const [isShowingSave, setIsShowingSave] = useState(false);
    const [isShowingColumnConfig, setIsShowingColumnConfig] = useState(false);
    const [isTableValid, setTableValid] = useState(true);

    const getValuesRange = (rows: Row<T>[]) => {
        let start: Date | null = null;
        let end: Date | null = null;

        if (_.isEmpty(rows)) {
            return { start, end };
        }

        start = parseDate(rows[0].date);
        end = parseDate(rows[rows.length - 1].date);
        return { start, end };
    };

    const handleSelectedRatesChange = (dates: string[]) => {
        setSelectedRates(dates);
    };

    const toggleIsShowingSetSidebar = () => {
        setShowSetSidebar({ show: !showSetSidebar.show });
    };

    const toggleIsShowingDefaultActionsSidebar = () => {
        setDefaultActionsSidebar({ show: !defaultActionsSidebar.show });
    };

    const getCopyOptions = (columns: Column[]) => {
        return columns.filter((col) => col.editable && col.editable.copyable).map((col) => ({ label: col.label, field: col.field }));
    };

    const filterValidation = (date: Date, start: Date, end: Date, dow: DoW[], status: UnitDateCurrentStatus[], row: Row<T>) => {
        if (isBefore(date, start) || isAfter(date, end)) {
            return false;
        }
        if (!_.isEmpty(dow) && !dow.includes(row.dow)) {
            return false;
        }
        if (!_.isEmpty(status) && !status.includes(row.unitStatus)) {
            return false;
        }
        return true;
    };

    const handleCopyAction = (rows: Row<T>[], column: keyof T, value: number, filter?: Filters) => {
        if (filter && 'selected' in filter && filter.selected) {
            const selectedDates = new Set(selectedRates);
            const changes: Row<T>[] = [];
            for (const row of rows) {
                if (selectedDates.has(row.key)) {
                    changes.push({ ...row, [column]: value });
                }
            }
            return onRowsChange(column, changes);
        }
        if (filter && 'start' in filter && 'end' in filter && 'dow' in filter && 'status' in filter) {
            const changes: Row<T>[] = [];
            for (const row of rows) {
                const date = startOfDay(parseDate(row.date));
                if (!filterValidation(date, filter.start, filter.end, filter.dow, filter.status, row)) {
                    continue;
                }
                changes.push({ ...row, [column]: value });
            }
            return onRowsChange(column, changes);
        }
        copyToAllDates(column, value, rows);
    };

    const copyToAllDates = (column: keyof T, value: number, rows: Row<T>[]) => {
        UiUtils.copyToAll(column, value, rows, onRowsChange);
    };

    const handleSetAction = async (rows: Row<T>[], column: keyof T, value: number, filter?: Filters, context?: UpdateContextDTO) => {
        if (filter && 'selected' in filter && filter.selected) {
            const selectedDates = new Set(selectedRates);
            const changes = { [column]: value, dates: Array.from(selectedDates) };
            return actionSidebar.onSave(changes, context);
        }
        if (filter && 'start' in filter && 'end' in filter && 'dow' in filter && 'status' in filter) {
            const validDates: string[] = [];
            for (const row of rows) {
                const date = startOfDay(parseDate(row.date));
                if (!filterValidation(date, filter.start, filter.end, filter.dow, filter.status, row)) {
                    continue;
                }
                validDates.push(row.date);
            }
            const changes = { [column]: value, dates: validDates };
            return actionSidebar.onSave(changes, context);
        }
        await setToAll(column, value, rows, context);
    };

    const setToAll = async (column: keyof T, value: number, rows: Row<T>[], context: UpdateContextDTO) => {
        const dates: string[] = [];
        for (const row of rows) {
            dates.push(row.date);
        }
        const changes = { [column]: value, dates };
        return actionSidebar.onSave(changes, context);
    };

    const handleMultiplyAction = (rows: Row<T>[], column: keyof T, value: number, filter?: Filters) => {
        if (filter && 'selected' in filter && filter.selected) {
            const selectedDates = new Set(selectedRates);
            const changes: Row<T>[] = [];
            for (const row of rows) {
                if (selectedDates.has(row.key)) {
                    changes.push({ ...row, [column]: +((_.isNil(row[column]) ? 1 : +row[column]) * value).toFixed(2) });
                }
            }
            return onRowsChange(column, changes);
        }
        if (filter && 'start' in filter && 'end' in filter && 'dow' in filter && 'status' in filter) {
            const changes: Row<T>[] = [];
            for (const row of rows) {
                const date = startOfDay(parseDate(row.date));
                if (!filterValidation(date, filter.start, filter.end, filter.dow, filter.status, row)) {
                    continue;
                }
                changes.push({ ...row, [column]: +((_.isNil(row[column]) ? 1 : +row[column]) * value).toFixed(2) });
            }
            return onRowsChange(column, changes);
        }
        multiplyToAllDates(column, value, rows);
    };

    const multiplyToAllDates = (column: keyof T, value: number, rows: Row<T>[]) => {
        UiUtils.multiplyToAll(column, value, rows, onRowsChange);
    };

    const handleOnSaveClicked = () => {
        setIsShowingSave(true);
    };

    const hideSaveSidebar = () => {
        setIsShowingSave(false);
    };

    builder.addCopyHandler(copyToAllDates);

    const tableData = builder.build(data);
    const workableData = data.filter((row) => isAfter(new Date(row.date), startOfYesterday()));

    const isCopyDisabled = _.isEmpty(tableData.rows) || disabled;
    const isSetSidebarDisabled = _.isEmpty(tableData.rows) || !actionSidebar?.enabled;

    const isSaveDisabled = _.isEmpty(changes) || !isTableValid || saveConfig.isDisabled || disabled;

    const getDownload = () => {
        return (
            <CSVLink
                headers={tableData.columns.map((c) => {
                    return { label: c.label, key: c.field };
                })}
                data={tableData.rows}
                filename={downloadConfig.fileName}>
                <Icon.Download className="tool-bar-icons pointer" width={24} height={24} />
            </CSVLink>
        );
    };

    return (
        <div className="rates-table">
            <div className="actions-header">
                {message && <AlertMessage text={message.content} type={message.type} height="small" customClass="alert-message" />}

                <IconToolBar
                    icons={[
                        {
                            icon: (
                                <div className={havePermission ? 'pointer new-action-button' : 'pointer-disabled-viewer'}>
                                    <div className="action-button-group">
                                        <Chip
                                            size="small"
                                            label="Set Actions"
                                            onClick={
                                                isSetSidebarDisabled
                                                    ? () => null
                                                    : cohortName
                                                    ? toggleIsShowingSetSidebar
                                                    : toggleIsShowingDefaultActionsSidebar
                                            }
                                        />
                                        <Chip
                                            size="small"
                                            label="Multiply Actions"
                                            onClick={
                                                isCopyDisabled
                                                    ? () => null
                                                    : cohortName
                                                    ? toggleIsShowingDefaultActionsSidebar
                                                    : toggleIsShowingSetSidebar
                                            }
                                        />
                                    </div>
                                    <Icon.FilterControls
                                        height={24}
                                        width={24}
                                        className={`tool-bar-icons ${isSetSidebarDisabled ? 'undisplayed' : 'pointer'} ${
                                            !havePermission ? 'disabled' : ''
                                        }`}
                                    />
                                </div>
                            ),
                            id: 'rates-table-set',
                            tip: havePermission ? '' : 'Editor access required',
                            onClick: null,
                        },
                        {
                            icon: getDownload(),
                            id: 'rates-table-export',
                            tip: 'Export table',
                            onClick: () => {},
                        },
                        {
                            icon: <Icon.Settings className="tool-bar-icons pointer" width={24} height={24} />,
                            id: 'rates-table-settings',
                            tip: 'Table Settings',
                            onClick: () => {
                                setIsShowingColumnConfig(true);
                            },
                        },
                    ]}
                />

                <Tooltip message={`${havePermission ? '' : 'Editor access required'}`}>
                    <div>
                        <Button variant="secondary" disabled={isSaveDisabled || !havePermission} onClick={handleOnSaveClicked}>
                            Save
                        </Button>
                    </div>
                </Tooltip>
            </div>

            {showSetSidebar && showSetSidebar.show && !_.isEmpty(tableData.rows) && !isSetSidebarDisabled && havePermission && (
                <ActionSidebar
                    actionName={actionSidebar.name}
                    onClose={toggleIsShowingSetSidebar}
                    columnOptions={actionSidebar.columnOptions}
                    onApply={async (column: string, value: number, filter?: Filters) => {
                        if (actionSidebar.type === 'multiply') {
                            await handleMultiplyAction(workableData, column as any, value, filter);
                        }
                        toggleIsShowingSetSidebar();
                    }}
                    onApplyAndSave={async (column: string, value: number, filter?: Filters, context?: UpdateContextDTO) => {
                        if (actionSidebar.type === 'set') {
                            await handleSetAction(workableData, column as any, value, filter, context);
                            toggleIsShowingSetSidebar();
                        }
                        if (actionSidebar.type === 'multiply') {
                            await handleMultiplyAction(workableData, column as any, value, filter);
                            console.log('waiting handle save');
                            toggleIsShowingSetSidebar();
                            handleOnSaveClicked();
                        }
                    }}
                    type={actionSidebar.type}
                    valueRange={getValuesRange(tableData.rows)}
                    dowFilterOption={dowFilterOption}
                    statusFilterOption={statusFilterOption}
                    cohortName={cohortName}
                    regionId={regionId}
                    countryName={countryName}
                    selectedDates={selectedRates}
                />
            )}

            {defaultActionsSidebar && defaultActionsSidebar.show && !_.isEmpty(tableData.rows) && !disabled && havePermission && (
                <DefaultActionsSidebar
                    onClose={toggleIsShowingDefaultActionsSidebar}
                    columnOptions={getCopyOptions(tableData.columns)}
                    onApply={(column: string, value: number, filter?: Filters) => {
                        handleCopyAction(workableData, column as any, value, filter);
                        toggleIsShowingDefaultActionsSidebar();
                    }}
                    onApplyAndSave={(column: string, value: number, filter?: Filters) => {
                        handleCopyAction(workableData, column as any, value, filter);
                        toggleIsShowingDefaultActionsSidebar();
                        handleOnSaveClicked();
                    }}
                    valueRange={getValuesRange(tableData.rows)}
                    dowFilterOption={dowFilterOption}
                    statusFilterOption={statusFilterOption}
                    cohortName={cohortName}
                    regionId={regionId}
                    countryName={countryName}
                    selectedDates={selectedRates}
                />
            )}

            {isShowingSave && !isSaveDisabled && (
                <SaveSidebar
                    type="interval"
                    hasChangeReason={true}
                    hasExpirationDays={saveConfig.hasExpiration}
                    onSave={(formValues) => {
                        onSave(formValues);
                        hideSaveSidebar();
                    }}
                    changes={changes}
                    onClose={hideSaveSidebar}
                />
            )}

            {isShowingColumnConfig && (
                <ColumnConfiguration
                    visibleColumns={columnConfig.visible}
                    hiddenColumns={columnConfig.hidden}
                    onApply={(added, hidden) => {
                        columnConfig.onColumnsChange(added, hidden);
                        setIsShowingColumnConfig(false);
                    }}
                    onResetDefault={columnConfig.onResetDefault}
                    onClose={() => setIsShowingColumnConfig(false)}
                />
            )}

            <VirtualizedTable
                dataSource={tableData}
                onSelectedChange={handleSelectedRatesChange}
                onRowChange={(column, row) => {
                    onRowsChange(column, [row]);
                }}
                onValidChange={(valid) => {
                    setTableValid(valid);
                }}
                disabled={disabled || !havePermission}
            />
        </div>
    );
};
