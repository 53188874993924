import { DataSource, Row, Column, ActionColumn, FilterConfiguration, Pagination, OrderField } from '../../types/VirtualizedTable';
import * as _ from 'lodash';
export class DataSourceBuilder<T extends { key: string } & { [key: string]: any }> {
    private columns: Column[] = [];
    private actionColumns: ActionColumn[] = [];
    private copyHandler;
    private filterHeaderConfig: FilterConfiguration;
    private pagination: Pagination<any>;
    private sortable: OrderField = null;

    // TODO: how to use <T>: Static members cannot reference class type parameters
    public static toRows(items: any[]): Row<any>[] {
        const rows: Row<any>[] = [];
        if (!_.isEmpty(items)) {
            for (const item of items) {
                rows.push({ ...item });
            }
        }
        return rows;
    }

    public addColumn(column: Column): void {
        this.columns.push(column);
    }

    public addActionColumn(column: ActionColumn) {
        this.actionColumns.push(column);
    }

    public addCopyHandler(handler: (column: keyof T, value: number, rows: Row<T>[]) => void): void {
        this.copyHandler = handler;
    }

    public setFilterHeader(config: FilterConfiguration) {
        this.filterHeaderConfig = config;
    }

    public addPagination<T>(pagination: Pagination<T>) {
        this.pagination = pagination;
    }

    public setSortable(initialOrder: OrderField = null) {
        this.sortable = initialOrder;
    }

    public build(values: T[]): DataSource<T> {
        return {
            columns: this.columns,
            actionColumns: this.actionColumns,
            rows: DataSourceBuilder.toRows(values),
            filterConfig: this.filterHeaderConfig,
            copyHandler: this.copyHandler,
            pagination: this.pagination,
            sortable: this.sortable,
        };
    }
}
