import React from 'react';
import * as _ from 'lodash';
import { UnitFactorDTO } from '@common/types';
import { Icon } from '@vacasa/react-components-lib';

interface UnitFactorsChangeSummaryProps {
    changes: UnitFactorDTO[];
}

export const UnitFactorChangeSummary: React.FC<UnitFactorsChangeSummaryProps> = (props) => {
    const { changes } = props;
    console.log(`factor change summary`, changes);
    return (
        <div className="change-summary">
            {_.map(changes, (change) => (
                <div className="change-summary-item">
                    <div className="change-summary-item-icon">
                        <Icon.AlertCircle width={16} height={16} />
                    </div>
                    <div className="change-summary-item-text">
                        <span className="change-summary-item-text">
                            <strong>UnitID {change.id}</strong>
                            {!_.isUndefined(change.factor) ? (
                                <>
                                    <br />
                                    <span>{`Factor: ${_.isNull(change.factor) ? `removed` : ` changed to ${change.factor}`}`}</span>
                                </>
                            ) : null}
                            {!_.isUndefined(change.base_rate_override) ? (
                                <>
                                    <br />
                                    <span>{`Base Override: ${
                                        _.isNull(change.base_rate_override) ? `removed` : ` changed to ${change.base_rate_override}`
                                    }`}</span>
                                </>
                            ) : null}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};
