import React from 'react';
import { GenericInput } from '..';
import './MinMaxSelector.scss';
import { Tooltip } from '@material-ui/core';

interface MinMaxSelectorProps {
    label: string;
    min: number;
    max: number;
    onChange: (minMax: { min: number; max: number }) => void;
    classname?: string;
}

export const MinMaxSelector: React.FC<MinMaxSelectorProps> = (props) => {
    const { label, min, max, onChange, classname } = props;
    // todo : Fix max and min values

    const handleMinChange = (value: number) => {
        onChange({ min: value, max });
    };

    const handleMaxChange = (value: number) => {
        onChange({ min, max: value });
    };
    let isValid: boolean = !(min > max);

    return (
        <Tooltip title={!isValid ? 'max value should be higher than min value, check min and max value from filter' : ''}>
            <div className={!isValid ? 'validation-error' : ''}>
                <div className={`min-max-selector ${classname ?? ''}`}>
                    <div className="min-max-selector-label">
                        <span>{label}:</span>
                    </div>
                    <div className="min-max-selector-input">
                        <span>Min:</span>
                        <GenericInput value={min} min={0} onChange={handleMinChange} />
                    </div>

                    <div className="min-max-selector-input">
                        <span>Max:</span>
                        <GenericInput value={max} min={0} onChange={handleMaxChange} />
                    </div>
                </div>
            </div>
        </Tooltip>
    );
};
