import React, {useState} from "react";
import {VirtualizedTable} from "..";
import {DataSourceBuilder} from "../VirtualizedTable/DataSourceBuilder";
import {Message, UnitTableValues} from "../../types";
import {AlertMessage, Icon, Tooltip} from "@vacasa/react-components-lib";
import * as _ from "lodash";
import {RateService} from "../../services/RateService";
import {UiUtils, UnitUtils} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {AppState, selectById, UiElements, upsertUnits} from "../../store";
import {AppRoutes} from "../../Routes";
import {Link, useLocation} from "react-router-dom";
import "./UnitsTable.scss";
import {FilterField, OrderField} from "../../types/VirtualizedTable";
import {BulkSidePanel} from "../BulkSidePanel/BulkSidePanel";
import {uploadBulkUnitAllowedScopes} from "@common/types";
import {history} from "../../App";

export const UnitsTable: React.FC = () => {
    const {search} = useLocation();
    const searchParams = UiUtils.getURLSearchParams(search);
    const cohortParam = searchParams.get("cohort");
    const [isShowingSave, setIsShowingSave] = useState(false);
    const dispatch = useDispatch();
    const unitTableFilters = useSelector((state: AppState) => selectById(state, UiElements.UnitTableFilters));
    const [uiAlert, setUiAlert] = useState<Message | null>(null);

    const builder = new DataSourceBuilder<UnitTableValues>();
    const className = "units-table";
    builder.addColumn({
        label: "Rates",
        field: "action",
        func: (obj) => (
            <Tooltip message="Go to Units Rates Evolution">
                <Link data-testid={`${className}-cell-href-${obj.id}`} to={AppRoutes.UNIT_EVOLUTION.replace(":id", `${obj.id}`)} target="_blank">
                    <Icon.BarChart className="pointer" height={20} width={20} />
                </Link>
            </Tooltip>
        ),
    });
    builder.addColumn({label: "Unit ID", field: "id"});
    builder.addColumn({label: "Unit Code", field: "code", displayConfiguration: {containerType: "div", flexGrow: 2}});
    builder.addColumn({label: "Unit Name", field: "name", displayConfiguration: {containerType: "div", flexGrow: 2}});
    builder.addColumn({label: "State", field: "state"});
    builder.addColumn({label: "City", field: "city", displayConfiguration: {containerType: "div", flexGrow: 2}});
    builder.addColumn({label: "Zip", field: "zip"});
    builder.addColumn({label: "Bedrooms", field: "bedroom_count"});
    builder.addColumn({label: "Assigned Cohort", field: "assigned_cohort", displayConfiguration: {containerType: "div", flexGrow: 2}});
    builder.addColumn({label: "Avg Rate", field: "average_rate"});
    builder.addColumn({label: "STD %", field: "std"});
    builder.addColumn({label: "Status", field: "status"});
    builder.addColumn({label: "Display", field: "display"});
    builder.addColumn({label: "Terminated", field: "terminated"});

    const initialFilters = [{field: "status", value: "1"}];

    if (cohortParam) {
        initialFilters.push({
            field: "assigned_cohort",
            value: cohortParam,
        });
    }
    builder.setFilterHeader({
        options: [
            ...(unitTableFilters?.options || [
                {field: "id", type: "number"},
                {field: "state", type: "select", values: ["ALL"]},
                {field: "status", type: "select", values: ["ALL", "1"]},
                {field: "display", type: "select", values: ["ALL"]},
                {field: "terminated", type: "select", values: ["ALL"]},
                {field: "bedroom_count", type: "range", options: "int"},
                {field: "average_rate", type: "range", options: "int"},
                {field: "std", type: "range", options: "float"},
            ]),
        ],
        initialFilters,
    });

    builder.setSortable({field: "id", order: "asc"});

    builder.addPagination({
        remote: true,
        function: async (pageNumber, pageSize, filters: FilterField[], order: OrderField) => {
            const params = {
                "page[number]": pageNumber.toString(),
                "page[size]": pageSize.toString(),
            };

            // cast filters
            filters.forEach((filter) => {
                // If there is a filter by queryParam, check if user changed assigned_cohort filter
                if (cohortParam && filter.field === "assigned_cohort" && filter.value !== cohortParam) {
                    // delete all queryParams from the url (cohort is the only one)
                    history.replace({
                        search: "",
                    });
                    // delete it from the request params
                    delete params["filter[cohort_name]"];
                }
                params[castFilter(filter.field)] = filter.value;
            });

            if (!_.isNil(order)) {
                let symbol = "";
                if (order.order === "desc") symbol = "-";
                params["sort"] = `${symbol}${getParamName(order.field)}`;
            }

            const {rows, count} = await RateService.getUnits(params);

            dispatch(upsertUnits(rows));
            return {result: UnitUtils.transformToTableData(rows), count};
        },
    });

    const castFilter = (key: string) => {
        const cast = {
            id: "filter[UnitID][contains]",
            name: "filter[unit_name][contains]",
            code: "filter[unit_code][contains]",
            state: "filter[state][contains]",
            city: "filter[city][contains]",
            zip: "filter[zip][contains]",
            bedroom_count: "filter[bedrooms][between]",
            assigned_cohort: "filter[cohort_name][contains]",
            average_rate: "filter[avg_rate][between]",
            std: "filter[std_per][between]",
            status: "filter[active]",
            display: "filter[display]",
            terminated: "filter[terminated]",
        };
        return cast[key] ? cast[key] : "filter[UnitID][contains]";
    };

    const getParamName = (key: string) => {
        const cast = {
            id: "UnitID",
            name: "unit_name",
            code: "unit_code",
            state: "state",
            city: "city",
            zip: "zip",
            bedroom_count: "bedrooms",
            assigned_cohort: "cohort_name",
            average_rate: "avg_rate",
            std: "std_per",
            status: "active",
            display: "display",
            terminated: "terminated",
        };
        return cast[key] ? cast[key] : "UnitID";
    };
    const handleSave = (message: Message) => {
        setUiAlert(message);
        setIsShowingSave(false);
    };

    return (
        <React.Fragment>
            <div className={"unit-table-header-container"}>
                <div className="unit-table-top-bar">
                    {uiAlert && <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small" />}
                    <Tooltip message={`${UiUtils.validateScope(uploadBulkUnitAllowedScopes) ? "Upload File" : "Editor access required"}`}>
                        <div className="icon-view" aria-disabled={true}>
                            <Icon.Upload
                                className={`${!UiUtils.validateScope(uploadBulkUnitAllowedScopes) ? "disabled" : ""}`}
                                height={24}
                                width={24}
                                onClick={() => setIsShowingSave(true)}
                            />
                        </div>
                    </Tooltip>
                </div>
                {isShowingSave && (
                    <BulkSidePanel
                        hasChangeReason={true}
                        onClose={() => setIsShowingSave(false)}
                        type={"unit"}
                        title={"Unit Bulk Override"}
                        onSave={handleSave}
                    />
                )}
                <VirtualizedTable className="units-table" dataSource={builder.build([])} onRowChange={() => null} />
            </div>
        </React.Fragment>
    );
};
