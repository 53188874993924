import React from 'react';
import { DataSourceBuilder } from '../VirtualizedTable/DataSourceBuilder';
import { UnitRateTableValues } from '../../types';
import { UiUtils } from '../../utils';
import { Icon, Tooltip } from '@vacasa/react-components-lib';
import { HolidaysIcon } from '../HolidaysIcon/HolidayIcons';
import { ActionColumn, Column } from '../../types/VirtualizedTable';
import { Configuration } from '../../Configuration';
import { DATE_FORMAT, UnitStatus } from '@common/types';
import { isNil } from 'lodash';
import { format } from 'date-fns';
import { IconHoa, IconOwner, IconVacasa } from '../../assets/images';

export enum UnitRateTableColumnsKeys {
    DATE = 'date',
    DOW = 'dow',
    UNIT_STATUS = 'unitStatus',
    MIN_STAY_OVERRIDE = 'minStayOverride',
    MIN_RATE = 'minRate',
    MAX_RATE = 'maxRate',
    ANALYST_BASE_OVERRIDE = 'analystBaseOverride',
    ANALYST_FACTOR = 'analystFactor',
    PREDICTED_RATE = 'predictedRate',
    ADJUSTED_RATE = 'adjustedRate',
    ADVERTISED_RATE = 'advertisedRate',
    COHORT_FACTOR = 'cohortFactor',
    SOLD_PAST_INV = 'soldPastInv',
    SEASONAL_STR_MIN_RATE = 'seasonalMinRate',
    // NOTES = 'notes',
}

export const DefaultUnitRateTableVisibleColumns = [
    UnitRateTableColumnsKeys.DOW,
    UnitRateTableColumnsKeys.MIN_RATE,
    UnitRateTableColumnsKeys.MAX_RATE,
    UnitRateTableColumnsKeys.ANALYST_BASE_OVERRIDE,
    UnitRateTableColumnsKeys.ANALYST_FACTOR,
    UnitRateTableColumnsKeys.PREDICTED_RATE,
    UnitRateTableColumnsKeys.ADJUSTED_RATE,
    UnitRateTableColumnsKeys.ADVERTISED_RATE,
    UnitRateTableColumnsKeys.SEASONAL_STR_MIN_RATE,
];

const today = format(new Date(), DATE_FORMAT);

export const UnitRateTableColumns: { [key in UnitRateTableColumnsKeys]: Column | ActionColumn } = {
    [UnitRateTableColumnsKeys.DATE]: {
        field: 'date',
        label: 'Date',
        displayConfiguration: {
            justifyContent: 'flex-start',
        },
        fieldConfiguration: {
            customRightComponent: (obj) => {
                if (obj['holidays'] && obj['holidays'].length > 0) {
                    return <HolidaysIcon holidays={obj['holidays']} />;
                }
            },
        },
    },

    [UnitRateTableColumnsKeys.SOLD_PAST_INV]: {
        field: 'soldPastInv',
        label: 'Sold Past Inv',

        func: (row) => {
            const isValid = !isNil(row.soldPastInv) && row.soldPastInv > 0;
            let formatted = UiUtils.formatToCurrency(row.soldPastInv);
            return (
                <>
                    {!isNil(row.soldPastInv) ? (
                        <span>{formatted}</span>
                    ) : (
                        <span className={'blue-text'}>
                            <b>--</b>
                        </span>
                    )}
                    {isValid && row.soldPastInv > row.advertisedRate && (
                        <Icon.AlertTriangle width={20} height={20} className={'red-alert-icon'} />
                    )}
                </>
            );
        },
    },

    [UnitRateTableColumnsKeys.DOW]: { field: 'dow', label: 'DOW' },

    [UnitRateTableColumnsKeys.UNIT_STATUS]: {
        field: 'unitStatus',
        label: 'Unit Status',
        displayConfiguration: {
            justifyContent: 'flex-center',
            flexGrow: 1,
        },
        fieldConfiguration: {
            customLeftComponent: (obj) => {
                let customClass = 'circle ';
                switch (obj.unitStatus) {
                    case UnitStatus.Available:
                        customClass = customClass + 'green';
                        break;
                    case UnitStatus.Booked:
                        customClass = customClass + 'red';
                        break;
                    case UnitStatus.Owner_Hold:
                        customClass = customClass + 'yellow';
                        break;
                    case UnitStatus.Vacasa_Hold:
                        customClass = customClass + 'midnight';
                        break;
                    case UnitStatus.Stateless:
                        customClass = customClass + 'empty';
                }
                return <span className={customClass} />;
            },
            customRightComponent: (obj) => {
                if (obj.reservationId) {
                    return (
                        <Tooltip message="Go to reservation page">
                            <a
                                className={'align-icon'}
                                href={Configuration.getAdminResEditUrl(obj.reservationId)}
                                target={'_blank'}
                                rel="noreferrer">
                                <Icon.ExternalLink height={16} width={16} />
                            </a>
                        </Tooltip>
                    );
                }
                return null;
            },
        },
    },

    [UnitRateTableColumnsKeys.MIN_STAY_OVERRIDE]: {
        field: 'minStayOverride',
        label: 'Minstay Opt Override',
        editable: {
            fieldType: 'input',
            copyable: true,
            inputConfiguration: {
                decimals: false,
                minValue: 1,
                maxValue: 500,
                validation: (obj) => {
                    const minStayOverride = obj['minStayOverride'];
                    if (minStayOverride && isNaN(minStayOverride)) {
                        return { isValid: false, validationSummary: 'Minstay optimization override cannot be less than 1' };
                    }
                    return { isValid: true };
                },
            },
        },
        tooltip: {
            contentType: 'element',
            content: (
                <div>
                    <span style={{ fontWeight: 'bold' }}>If minstay optimization is enabled:</span>
                    <ul>
                        <li>
                            We use the minstay override value if it is greater than the automatic "optimized" value (from minstay
                            optimization)
                        </li>
                        <li>The automatic "optimized" value will never be lower than the min minstay value from Admin</li>
                    </ul>
                    <span style={{ fontWeight: 'bold' }}>If minstay optimization is disabled:</span>
                    <ul>
                        <li>The minstay override value does not do anything (it's ignored) and we just use the min minstay from Admin</li>
                    </ul>
                    <span style={{ fontWeight: 'bold' }}>Example:</span>
                    <p>
                        If min minstay in Admin = 3 and override = 28, and minstay optimization is enabled, it will use the automatic
                        "optimized" value that will be at least 28 (but could technically be greater). If minstay optimization is disabled,
                        it will use the 3 directly from Admin.
                    </p>
                </div>
            ),
            hasCloseButton: true,
            hasBottomDetailsLink: false,
        },
    },

    [UnitRateTableColumnsKeys.SEASONAL_STR_MIN_RATE]: {
        field: 'seasonalMinRate',
        label: 'Seasonal STR Min Rate',
        fieldConfiguration: {
            format: UiUtils.formatToCurrency,
            customRightComponent: (obj) => {
                if (obj.seasonalMinRateHolder) {
                    const iconMap = {
                        seasonal_str_min_rate_hoa: { icon: IconHoa, tooltip: 'Seasonal STR Min Rate HOA' },
                        seasonal_str_min_rate_owner: { icon: IconOwner, tooltip: 'Seasonal STR Min Rate Owner' },
                        seasonal_str_min_rate_vacasa: { icon: IconVacasa, tooltip: 'Seasonal STR Min Rate Vacasa' },
                    };
                    return (
                        <Tooltip message={iconMap[obj.seasonalMinRateHolder].tooltip}>
                            <div className={'align-icon'}>
                                {React.createElement(iconMap[obj.seasonalMinRateHolder].icon, { height: 16, width: 16 })}
                            </div>
                        </Tooltip>
                    );
                }
                return null;
            },
        },
    },

    [UnitRateTableColumnsKeys.MIN_RATE]: {
        field: 'minRate',
        label: 'Min Rate',
        editable: {
            fieldType: 'input',
            copyable: true,
            inputConfiguration: {
                minValue: 0,
                validation: (obj) => {
                    if (obj['minRate'] === 0) {
                        const isValid = false;
                        return { isValid, validationSummary: 'Min Rate cannot be less than 0.01' };
                    }
                    if (obj['minRate'] && obj['maxRate']) {
                        const isValid = +obj['minRate'] <= +obj['maxRate'];
                        return { isValid, validationSummary: !isValid ? 'Min rate is greater than Max rate' : '' };
                    }
                    return { isValid: true };
                },
            },
        },
    },

    [UnitRateTableColumnsKeys.MAX_RATE]: {
        field: 'maxRate',
        label: 'Max Rate',
        editable: {
            fieldType: 'input',
            copyable: true,
            inputConfiguration: {
                minValue: 0,
                validation: (obj) => {
                    if (obj['maxRate'] === 0) {
                        const isValid = false;
                        return { isValid, validationSummary: 'Max Rate cannot be less than 0.01' };
                    }
                    if (obj['minRate'] && obj['maxRate']) {
                        const isValid = +obj['maxRate'] >= +obj['minRate'];
                        return { isValid, validationSummary: !isValid ? 'Max rate is lower than Min rate' : '' };
                    }
                    return { isValid: true };
                },
            },
        },
    },

    [UnitRateTableColumnsKeys.ANALYST_BASE_OVERRIDE]: {
        field: 'analystBaseOverride',
        label: 'Analyst Base Rate Override',
        editable: {
            fieldType: 'input',
            copyable: true,
            inputConfiguration: {
                minValue: 0,
                validation: (obj) => {
                    if (obj['analystBaseOverride'] === 0) {
                        const isValid = false;
                        return { isValid, validationSummary: 'Analyst Base Override cannot be less than 0.01' };
                    }
                    return { isValid: true };
                },
            },
        },

        tooltip: {
            contentType: 'element',
            content: (
                <div>
                    <p style={{ marginTop: 0 }}>
                        <span style={{ fontWeight: 'bold' }}>Analyst Base Rate Override</span> is a{' '}
                        <span style={{ fontWeight: 'bold' }}>fixed rate</span> that will be set as{' '}
                        <span style={{ fontWeight: 'bold' }}>Adjusted Rate</span> if present.
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        If an Analyst Base Rate Override is present for the date, Analyst Factor and Prediction Rate will be ignored for
                        that date.
                    </p>
                </div>
            ),
            hasCloseButton: true,
            hasBottomDetailsLink: false,
        },
    },

    [UnitRateTableColumnsKeys.ANALYST_FACTOR]: {
        field: 'analystFactor',
        label: 'Analyst Factor',
        editable: {
            fieldType: 'input',
            copyable: true,
            inputConfiguration: {
                minValue: 0,
                maxValue: 10,
                validation: (obj) => {
                    if (
                        (obj['analystFactor'] === 0 || obj['analystFactor'] > Configuration.getMaxValueAnalystFactor()) &&
                        new Date(obj['date']) >= new Date(today)
                    ) {
                        const isValid = false;
                        return { isValid, validationSummary: 'Analyst Factor must be a value between 0.01 and 10' };
                    }
                    return { isValid: true };
                },
            },
        },

        tooltip: {
            contentType: 'element',
            content: (
                <div>
                    <p style={{ marginTop: 0 }}>
                        <span style={{ fontWeight: 'bold' }}>Analyst Factor</span> will be applied to the{' '}
                        <span style={{ fontWeight: 'bold' }}>Predicted Rate</span> resulting in the{' '}
                        <span style={{ fontWeight: 'bold' }}>Adjusted Rate</span>.
                    </p>
                    <ul>
                        <li>Every time a Prediction Rate is updated a new Adjusted Rate will be calculated using the Analyst Factor.</li>
                        <li>
                            New predictions are triggered by daily prediction calculation, updates to Average Rate and Percentage Deviation
                            (Depending on the model).
                        </li>
                    </ul>
                    <span style={{ fontWeight: 'bold' }}>
                        If an Analyst Base Rate Override is present for the date, Analyst Factor and Prediction Rate will be ignored for
                        that date.
                    </span>
                </div>
            ),
            hasCloseButton: true,
            hasBottomDetailsLink: false,
        },
    },

    [UnitRateTableColumnsKeys.PREDICTED_RATE]: {
        field: 'predictedRate',
        label: 'Predicted Rate',
        fieldConfiguration: { format: UiUtils.formatToCurrency },
    },

    [UnitRateTableColumnsKeys.ADJUSTED_RATE]: {
        field: 'adjustedRate',
        label: 'Adjusted Rate',
        fieldConfiguration: { format: UiUtils.formatToCurrency },
    },

    [UnitRateTableColumnsKeys.ADVERTISED_RATE]: {
        field: 'advertisedRate',
        label: 'Advertised Rate',
        fieldConfiguration: { format: UiUtils.formatToCurrency },
    },

    [UnitRateTableColumnsKeys.COHORT_FACTOR]: {
        field: 'cohortFactor',
        label: 'Cohort Factor',
    },

    /* [UnitRateTableColumnsKeys.NOTES]: { label: 'Notes', func: (obj) => <Icon.FileText className="pointer" height={18} width={18} /> },*/
};

export const getUnitRateTableBuilder = (columns: string[], options?: any): DataSourceBuilder<UnitRateTableValues> => {
    const builder = new DataSourceBuilder<UnitRateTableValues>();

    builder.addColumn(UnitRateTableColumns[UnitRateTableColumnsKeys.DATE] as Column);

    for (const key of columns) {
        const col = UnitRateTableColumns[key];

        if ('func' in col && col.field !== 'soldPastInv') {
            builder.addActionColumn(col);
            continue;
        }

        if (
            key === UnitRateTableColumnsKeys.PREDICTED_RATE ||
            key === UnitRateTableColumnsKeys.ADJUSTED_RATE ||
            key === UnitRateTableColumnsKeys.ADVERTISED_RATE ||
            key === UnitRateTableColumnsKeys.SEASONAL_STR_MIN_RATE
        ) {
            col.fieldConfiguration.format = (value) => UiUtils.formatToCurrency(value, options?.currency);
        }

        builder.addColumn(col);
    }

    return builder;
};
