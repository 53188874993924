import React, { useState } from 'react';
import { Button, Icon, Select, Tooltip } from '@vacasa/react-components-lib';
import './SaveSidebar.scss';
import * as _ from 'lodash';
import { CustomCohortSummary, GenericInput, IntervalChangeSummary, RegularChangesSummary, UnitFactorChangeSummary } from '..';
import { CustomCohortChange, DateChange, RegularChange } from '../../types';
import { MinMaxChanged, UnitFactorDTO, UnitRateChangeDTO, UpdateContextDTO } from '@common/types';
import { manipulateDays, parseDate } from '@common/utils';

export interface SaveFormValues {
    context: UpdateContextDTO;
    changes: DateChange[] | RegularChange[] | CustomCohortChange;
}

type IntervalChangeProps = {
    type: 'interval';
    changes: DateChange[];
    onSave: (formValues: { context: UpdateContextDTO; changes: DateChange[] }) => void;
};

type RegularChangeProps = {
    type: 'regular';
    changes: RegularChange[];
    onSave: (formValues: { context: UpdateContextDTO; changes: RegularChange[] }) => void;
};

type CustomCohortChangeProps = {
    type: 'custom_cohort';
    changes: CustomCohortChange;
    onSave: (formValues: { context: UpdateContextDTO; changes: CustomCohortChange }) => void;
};

type UnitFactorsChangeProps = {
    type: 'unit_factors';
    changes: UnitFactorDTO[];
    onSave: (formValues: { context: UpdateContextDTO; changes: UnitFactorDTO[] }) => void;
};

type SaveSidebarProps = {
    onSave: (formValues: SaveFormValues) => void;
    onClose: () => void;
    hasExpirationDays?: boolean;
    hasChangeReason?: boolean;
    title?: string;
} & (IntervalChangeProps | RegularChangeProps | CustomCohortChangeProps | UnitFactorsChangeProps);

const CHANGE_REASON_ITEMS = [
    { value: 'optimization', display: 'Optimization' },
    { value: 'owner_changes', display: 'Owner Changes' },
];

export const SaveSidebar: React.FC<SaveSidebarProps> = (props) => {
    const { onClose, changes, onSave, type, title, hasExpirationDays, hasChangeReason } = props;
    const [expireBefore, setExpireBefore] = useState<number>(0);
    const [isValidExpireBefore, setIsValidExpireBefore] = useState<boolean>(true);
    const [changeReason, setChangeReason] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [warningInfo, setWarningInfo] = useState<string>(null);
    const [isAlreadyClicked, setIsAlreadyClicked] = useState<boolean>(false);
    const handleChangeReasonOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangeReason(event.target.value);
        setIsAlreadyClicked(false);
    };

    function getDisplayChanges(change: UnitRateChangeDTO) {
        if (change.hasOwnProperty('min_max_changed')) {
            if (change.min_max_changed === MinMaxChanged.ONLY_MAX) {
                delete change.minrate;
            }
            if (change.min_max_changed === MinMaxChanged.ONLY_MIN) {
                delete change.maxrate;
            }
            delete change.min_max_changed;
        }
    }

    const renderSummary = (changes) => {
        if (type === 'regular') {
            return <RegularChangesSummary changes={changes as RegularChange[]} />;
        }
        if (type === 'interval') {
            let copyChanges = _.cloneDeep(changes) as UnitRateChangeDTO[];
            copyChanges.filter((change) => getDisplayChanges(change));
            return <IntervalChangeSummary changes={copyChanges as DateChange[]} />;
        }

        if (type === 'custom_cohort') {
            return <CustomCohortSummary changes={changes as CustomCohortChange} />;
        }

        if (type === 'unit_factors') {
            return <UnitFactorChangeSummary changes={changes} />;
        }
    };

    const expireBeforeErrorClass = isValidExpireBefore ? '' : 'error';

    const validateDaysBeforeExpires = (days: number) => {
        let maxDate: string = null;
        if (type === 'unit_factors' || type === 'interval') {
            _.forEach(changes as { date: string }[], (row) => {
                const expiredDate = manipulateDays(row.date, -days);
                if (new Date() >= parseDate(expiredDate)) {
                    if (_.isNull(maxDate)) {
                        maxDate = row.date;
                    }
                    if (parseDate(maxDate) < parseDate(row.date)) {
                        maxDate = row.date;
                    }
                }
            });
        }
        if (!_.isNull(maxDate) && !_.isNull(days) && days !== 0) {
            setWarningInfo(`*Days Before Expires won't apply for factors before ${maxDate}, because they will be in the past by then`);
        } else {
            setWarningInfo(null);
        }
    };

    const isSaveDisabled =
        (hasChangeReason && _.isEmpty(changeReason)) || (hasExpirationDays && _.isUndefined(expireBefore)) || isAlreadyClicked;
    const label = isAlreadyClicked ? 'Waiting...' : 'Save';
    return (
        <div className="save-side-bar">
            <div className="save-side-bar-title">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>{title || 'Save Changes'}</span>
            </div>
            <div className="save-side-bar-step">
                <p>Changes Summary:</p>
                <div className="save-side-bar-summary">{renderSummary(changes)}</div>
            </div>

            <div className="step-separator" />

            <div className="save-side-bar-options">
                <div className="save-side-bar-step">
                    <p>Save Options:</p>
                    {hasExpirationDays && (
                        <div className="save-side-bar-input-group">
                            <div className="save-side-bar-input-label">
                                <span>Number of days before expire*:</span>
                            </div>
                            <div className="save-side-bar-value">
                                <Tooltip message={isValidExpireBefore ? '' : 'This field should be an integer value'}>
                                    <GenericInput
                                        className={expireBeforeErrorClass}
                                        min={0}
                                        decimals={false}
                                        value={expireBefore}
                                        onChange={(value) => {
                                            setIsValidExpireBefore((+value ?? 0) % 1 === 0);
                                            validateDaysBeforeExpires(+value);
                                            setExpireBefore(_.isFinite(+value) ? +value : 0);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            <div className="save-side-bar-input-note">
                                <span>
                                    *Factor overrides will automatically be erased <span className="bold">{expireBefore ?? '-'}</span> day
                                    {expireBefore > 1 ? 's' : ''} before first night. <br />
                                    {warningInfo && <span className={'danger-text'}>{warningInfo}</span>}
                                </span>
                            </div>
                        </div>
                    )}

                    {hasChangeReason && (
                        <div className="save-side-bar-input-group">
                            <div className="save-side-bar-input-label">
                                <span>Changes Reason:</span>
                            </div>
                            <div className="save-side-bar-value select-input">
                                <Select
                                    customClass="period-dow-filter-select"
                                    value={changeReason}
                                    options={CHANGE_REASON_ITEMS}
                                    onChange={handleChangeReasonOnChange}
                                />
                            </div>
                        </div>
                    )}
                    <div className="save-side-bar-input-group">
                        <div className="save-side-bar-input-label">
                            <span>Description:</span>
                        </div>
                        <div className="save-side-bar-value">
                            <textarea
                                rows={5}
                                maxLength={200}
                                style={{ width: '100%' }}
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                    setIsAlreadyClicked(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="save-side-bar-apply-button">
                <Button
                    variant="secondary"
                    onClick={() => {
                        const context: UpdateContextDTO = {
                            description,
                            changes_reason: hasChangeReason ? changeReason : undefined,
                            days_before_expire: hasExpirationDays ? expireBefore : undefined,
                        };
                        setIsAlreadyClicked(true);
                        onSave({ context, changes: changes as any });
                    }}
                    disabled={isSaveDisabled}>
                    {label}
                </Button>
            </div>
        </div>
    );
};
