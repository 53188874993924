import React, { useState } from 'react';
import { Button, Icon, Select } from '@vacasa/react-components-lib';
import './BulkSidePanel.scss';
import * as _ from 'lodash';
import { BulkLoadDTO, EncodingType, FileDTO, BulkProcessType, UpdateContextDTO } from '@common/types';
import driveLogo from '../../assets/images/drive.png';
import { useFilePicker } from 'use-file-picker';
import * as crypto from 'crypto';
import { RateService } from '../../services/RateService';
import { Message } from '../../types';
import { UiUtils } from '../../utils';

type BulkSidePanelProps = {
    onClose: () => void;
    hasChangeReason?: boolean;
    hasFileType?: boolean;
    title: string;
    type: string;
    onSave: (message: Message) => void;
};

const CHANGE_REASON_ITEMS = [
    { value: 'optimization', display: 'Optimization' },
    { value: 'owner_changes', display: 'Owner Changes' },
];
const FILE_TYPE_ITEMS_UNIT = [
    { value: BulkProcessType.UNIT_DATE_BULK_UPLOAD, display: 'Unit Date Override(date, min, max,base_rate, minstay_opt)' },
    { value: BulkProcessType.INITIAL_RATE_SETTING_UPLOAD, display: 'Initial Rate Setting(unitid, avg_rate, deviation, connected_cohort)' },
];
const FILE_TYPE_ITEMS_COHORT = [
    { value: BulkProcessType.COHORT_DATE_BULK_UPLOAD, display: 'Cohort Date Override(cohort_name, date, factor)' },
];

export const BulkSidePanel: React.FC<BulkSidePanelProps> = (props) => {
    const { onClose, title, hasChangeReason, type, onSave } = props;
    const [changeReason, setChangeReason] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [fileType, setFileType] = useState<string>('');
    const [isAlreadyClicked, setIsAlreadyClicked] = useState<boolean>(false);

    const [openFileSelector, { filesContent, clear }] = useFilePicker({
        multiple: false,
        readAs: 'Text',
        accept: ['.csv'],
        limitFilesConfig: { max: 1 },
        maxFileSize: 30,
    });

    const data_content: { lastModified: number; name: string; content: string } = _.head(filesContent);
    const context: UpdateContextDTO = {
        description,
        changes_reason: hasChangeReason ? changeReason : undefined,
    };

    const handleChangeReasonOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangeReason(event.target.value);
        setIsAlreadyClicked(false);
    };
    const handleFileTypeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileType(event.target.value);
    };
    const isSelectDisabled = _.isEmpty(fileType);
    const isSaveDisabled = (hasChangeReason && _.isEmpty(changeReason)) || isAlreadyClicked;
    const label = isAlreadyClicked ? 'Waiting...' : 'Save';

    const handleSave = async (
        data_content: { lastModified: number; name: string; content: string },
        fileType: string,
        context: UpdateContextDTO
    ) => {
        let alertMessage: Message;

        const file_content = UiUtils.getNonEmptyRowsFromCSVContent(data_content.content.split(/\r?\n/));

        if (_.isEmpty(file_content)) {
            alertMessage = UiUtils.getBulkEmptyFileMessage();
            onSave(alertMessage);
            return;
        }

        if (!UiUtils.isValidCSVLength(file_content, fileType as BulkProcessType)) {
            alertMessage = UiUtils.getBulkFileExceededMessage(fileType as BulkProcessType);
            onSave(alertMessage);
            return;
        }

        data_content.content = file_content.join('\r\n');

        const file: FileDTO = {
            filename: data_content.name,
            content: data_content.content,
            encoding: EncodingType.CSV,
            hash: crypto.createHash('md5', {}).update(data_content.content).digest('hex').toLowerCase(),
            processType: fileType as BulkProcessType,
        };

        const data: BulkLoadDTO = {
            file: file,
            context: context,
        };

        try {
            await RateService.uploadFile(data);
            alertMessage = UiUtils.getBulkSuccessMessage();
        } catch (e) {
            alertMessage = UiUtils.getBulkErrorMessage(e.message);
        }
        onSave(alertMessage);
    };
    const unitType: boolean =
        fileType === BulkProcessType.UNIT_DATE_BULK_UPLOAD || fileType === BulkProcessType.INITIAL_RATE_SETTING_UPLOAD;
    const cohortType: boolean = fileType === BulkProcessType.COHORT_DATE_BULK_UPLOAD;
    return (
        <div className="bulk-panel">
            <div className="bulk-panel-title">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>{title}</span>
            </div>
            <div className="bulk-panel-step">
                <p>Bulk Update Process:</p>
                <div className="bulk-panel-value select-input">
                    <Select
                        customClass="period-dow-filter-select"
                        value={fileType}
                        options={type === 'unit' ? FILE_TYPE_ITEMS_UNIT : FILE_TYPE_ITEMS_COHORT}
                        onChange={handleFileTypeOnChange}
                    />
                </div>
                <div className={'bulk_file_type_display'}>
                    {unitType && (
                        <a
                            rel="noopener noreferrer"
                            href={
                                fileType === BulkProcessType.UNIT_DATE_BULK_UPLOAD
                                    ? process.env.PUBLIC_URL + '/unit_date_override_template.csv'
                                    : process.env.PUBLIC_URL + '/initial_rate_setting_template.csv'
                            }
                            download={
                                fileType === BulkProcessType.UNIT_DATE_BULK_UPLOAD
                                    ? 'unit_date_override_template.csv'
                                    : 'initial_rate_setting_template.csv'
                            }>
                            <button className={'bulk-panel-selector-button-internal pointer'}>
                                <div className={'monitor-icon-position'}>
                                    <Icon.Download />
                                    CSV Template
                                </div>
                            </button>
                        </a>
                    )}
                </div>
                <div className={'bulk_file_type_display'}>
                    {cohortType && (
                        <a
                            rel="noopener noreferrer"
                            href={process.env.PUBLIC_URL + '/cohort_date_override_template.csv'}
                            download={'cohort_date_override_template.csv'}>
                            <button className={'bulk-panel-selector-button-internal pointer'}>
                                <div className={'monitor-icon-position'}>
                                    <Icon.Download />
                                    CSV Template
                                </div>
                            </button>
                        </a>
                    )}
                </div>
            </div>
            <div className="step-separator" />
            {_.isEmpty(filesContent) && (
                <div className={'bulk-panel-step'}>
                    <p>Upload File:</p>
                    <div className={'bulk-panel-selector-button-div'}>
                        <button
                            className={
                                !isSelectDisabled
                                    ? 'bulk-panel-selector-button-internal pointer'
                                    : 'bulk-panel-selector-button-internal not-available-button'
                            }
                            onClick={() => {
                                openFileSelector();
                            }}
                            disabled={isSelectDisabled}>
                            <div className={'monitor-icon-position'}>
                                <Icon.Monitor />
                                Your Computer
                            </div>
                        </button>
                        <button
                            className={'bulk-panel-selector-button-drive pointer'}
                            onClick={() => {
                                console.log('Implement in Future');
                            }}
                            disabled={true}>
                            <div className={'monitor-icon-position'}>
                                <img src={driveLogo} alt={'Google Drive'} />
                                Google Drive
                            </div>
                        </button>
                    </div>
                </div>
            )}
            {!_.isEmpty(data_content) && (
                <div className={'bulk-panel-step'}>
                    <p>Upload File:</p>
                    <div className={'bulk-panel-close-file-icon'}>
                        <input className={'bulk-panel-value select-input'} type="string" value={data_content.name} readOnly={true} />
                        <Icon.XCircleInverse onClick={clear} />
                    </div>
                </div>
            )}
            <div className="step-separator" />
            <div className="bulk-panel-options">
                <div className="bulk-panel-step">
                    <p>Save Options:</p>
                    {hasChangeReason && (
                        <div className="bulk-panel-input-group">
                            <div className="bulk-panel-input-label">
                                <span>Changes Reason:</span>
                            </div>
                            <div className="bulk-panel-value select-input">
                                <Select
                                    customClass="period-dow-filter-select"
                                    value={changeReason}
                                    options={CHANGE_REASON_ITEMS}
                                    onChange={handleChangeReasonOnChange}
                                />
                            </div>
                        </div>
                    )}
                    <div className="bulk-panel-input-group">
                        <div className="bulk-panel-input-label">
                            <span>Description:</span>
                        </div>
                        <div className="bulk-panel-value">
                            <textarea
                                rows={5}
                                maxLength={200}
                                style={{ width: '100%' }}
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                    setIsAlreadyClicked(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bulk-panel-apply-button">
                <Button
                    variant="secondary"
                    onClick={() => {
                        setIsAlreadyClicked(true);
                        handleSave(data_content, fileType, context).then((r) => r);
                    }}
                    disabled={isSaveDisabled || _.isEmpty(filesContent)}>
                    {label}
                </Button>
            </div>
        </div>
    );
};
