import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addDays, isBefore, isEqual, startOfToday} from "date-fns";
import {applyChangesForUnit, AppState, selectCohortsWithModel, selectUnitById, selectUnitRatesWithChanges, setRateChangesForUnit} from "../../store";
import {ChangeLog, GenericInput, Loading, RatesEvolutionChart, RatesTable, SaveFormValues, UnitDashboard} from "..";
import {fetchEnhancedUnit, fetchRatesForUnit} from "../../store/unit/unit.actions";
import {
    ChartData,
    DashboardHash,
    DowOptions,
    Message,
    SelectOption,
    SetUnitChange,
    StatusOptions,
    UnitDashboardValues,
    UnitRateTableValues,
    UnitWithRates,
} from "../../types";
import {RateUtils, UiUtils} from "../../utils";
import {Row} from "../../types/VirtualizedTable";
import {GenericContentPanel} from "../GenericContentPanel/GenericContentPanel";
import {AlertMessage, AutoCompleteSelect, Icon, Tabs, Tooltip, useDebounce} from "@vacasa/react-components-lib";
import {Configuration} from "../../Configuration";
import {DefaultUnitRateTableVisibleColumns, getUnitRateTableBuilder, UnitRateTableColumns} from "../RatesTable/UnitRateTable.builder";
import {
    CurrencyConversionDTO,
    dashboardUnitAllowedScopes,
    MinMaxChanged,
    NEUMANN_MAX_DAYS,
    RateDescription,
    RatePreviewData,
    ratesUnitAllowedScopes,
    UIComponentsKeys,
    UIConfigurationBoxesComponent,
    UIConfigurationChartComponent,
    UIConfigurationDashboardComponent,
    UIConfigurationTableComponent,
    UISectionKeys,
    UnitRateChangeDTO,
    UpdateContextDTO,
    UpdateUnitRatesDTO,
    UserPreferenceConfigurationDTO,
    UserPreferenceDTO,
} from "@common/types";
import {RateService} from "../../services/RateService";
import {Link, useLocation, useParams} from "react-router-dom";
import {history} from "../../App";
import {AppRoutes} from "../../Routes";
import {selectChangesForUnit} from "../../store/history/history.slice";
import {fetchHistory} from "../../store/history/history.actions";
import {LoggingService} from "../../services/LoggingService";
import {ColumnDescription} from "../VirtualizedTable/ColumnConfiguration";
import {FieldPreferenceName} from "../../utils/UserPreferencesUtils";
import {loadUserPreferences, updateUserPreferences} from "../../localstorage";
import {Switch} from "@material-ui/core";
import "./RatesEvolution.scss";
import {Helmet} from "react-helmet";
import {find, isEmpty, isInteger, isNil, map, reduce, some, values} from "lodash";

export const UnitRatesEvolution: React.FC = () => {
    let pressedIcon = false;
    const dispatch = useDispatch();
    const today = new Date();
    const delay: number = 2000;
    const cohortsWithModel = useSelector(selectCohortsWithModel);
    const [holidayName, setHolidayName] = useState<string>();
    const [monthIndex, setMonthIndex] = useState<string>();
    const [unitId, setUnitId] = useState<number>();
    const [startDate, setStartDate] = useState<Date>(today);
    const [processId, setProcessId] = useState<string>("");
    const [customCohortsOption, setCustomCohortsOption] = useState<{value: number; display: string}[]>([]);
    const [endDate, setEndDate] = useState<Date>(addDays(today, Configuration.DEFAULT_DAYS_RANGE));
    const {isFetchingRates, isFetchingEnhancedUnit} = useSelector((state: AppState) => state.unit);
    const unit = useSelector((state: AppState) => selectUnitById(state, unitId));
    const [unitDashboardValues, setUnitDashboardValues] = useState<UnitDashboardValues>({
        std: undefined,
        assigned_cohort: {display: undefined, value: undefined},
        average_rate: undefined,
        customCohortsOptions: undefined,
        suggested_average_rate: undefined,
        currency: "USD",
        exchange_rate: undefined,
        min_rate: undefined,
    });
    const [currencyConversionRate, setCurrencyConversionRate] = useState<number>(null);
    const [currencyConversionRateToUSD, setCurrencyConversionRateToUSD] = useState<number>(null);

    const error = useSelector((state: AppState) => state.unit.error);
    const [dowFilterOption, setDowFilterOption] = useState<DowOptions>("All");
    const [statusFilterOption, setStatusFilterOption] = useState<StatusOptions>("All");

    const [isShowingChart, setIsShowingChart] = useState<boolean>(true);
    const [message, setMessage] = useState<Message | null>(null);
    const [unitMessage, setUnitMessage] = useState<Message | null>(null);

    const [visibleColumns, setVisibleColumns] = useState<FieldPreferenceName[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<FieldPreferenceName[]>([]);

    const [selectedChart, setSelectedChart] = useState<string[]>([]);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);
    const [hasModel, setHasModel] = useState<boolean>(true);
    const [isNeumann, setIsNeumann] = useState<boolean>(false);
    const [isValidUnit, setIsValidUnit] = useState<boolean>(false);
    // @ts-ignore
    const changes = useSelector((state: AppState) => selectChangesForUnit(state, unitId));
    const {isFetchingHistory} = useSelector((state: AppState) => state.history);
    const [userPreference, setUserPreference] = useState<UserPreferenceDTO>(null);

    const [isCurrencyToggled, setIsCurrencyToggled] = useState<boolean>(false);
    const [boxesOrder, setBoxesOrder] = useState({});
    const [newUnitId, setNewUnitId] = useState<number>(0);
    const [loadingNewUnit, setLoadingNewUnit] = useState<boolean>(false);
    const havePermissionDashboard = UiUtils.validateScope(dashboardUnitAllowedScopes);
    const havePermissionRates = UiUtils.validateScope(ratesUnitAllowedScopes);
    const [assignableCohortOptions, setAssignableCohortOptions] = useState<SelectOption[]>([]);
    const cohortError = useSelector((state: AppState) => state.cohort.error);
    const params = useParams<{id: string}>();
    const pageTitle = `[${params.id}] Rates Tool App`;

    const [preference, setPreference] = useState<string>(UiUtils.getPreferenceDashboard(UISectionKeys.UNIT, UIComponentsKeys.RATES_PREFERENCE_TABS));

    const [saveToggle, setSaveToggle] = useState<boolean>(true);
    const [modifiedField, setModifiedField] = useState<string>("all");
    const [filterDate, setFilterDate] = useState<string>("");

    const handleToggle = () => {
        const preferences = UiUtils.getUpdatedTogglePreferences(UISectionKeys.UNIT, UIComponentsKeys.RATES_DATEPICKER, {
            toggle: !saveToggle,
        });
        updateUserPreferences(preferences);
        setSaveToggle(!saveToggle);
    };

    const locations = useLocation();

    const hashTab = UiUtils.validDashboardHash(useLocation().hash)
        ? (locations.hash as DashboardHash)
        : preference
        ? (preference as DashboardHash)
        : DashboardHash.DASHBOARD;

    const [selectedTab, setSelectedTab] = useState<number>(UiUtils.getDashboardPosition(hashTab));

    const rates = useSelector((state: AppState) =>
        selectUnitRatesWithChanges(state, unitId, {
            start: startDate,
            end: endDate,
        })
    );
    const [previewData, setPreviewData] = useState<RatePreviewData>(null);

    const canToggleCurrency = unit?.currency_code && unit?.currency_code !== "USD";

    const validationAnalystFactor = (): boolean => {
        return some(unit?.changes, (change) => {
            return change.rates.analyst_factor > Configuration.getMaxValueAnalystFactor();
        });
    };

    const getGetCustomCohortsByUnit = async () => {
        const response = await RateService.getCustomCohorsByUnit(unitId);
        setCustomCohortsOption(response.map((cohort) => ({value: cohort.cohort_id, display: cohort.cohort_name})));
    };

    const getCurrencyExchangeRate = useCallback(async () => {
        if (canToggleCurrency) {
            const currencyConversionRate: CurrencyConversionDTO = await RateService.getCurrentConversionRate(unit.currency_code, "USD");
            const rate = currencyConversionRate.quotes[`USD${unit.currency_code}`];
            const exchange_rate = Number.parseFloat(rate.toFixed(2));
            setCurrencyConversionRate(exchange_rate);
            setUnitDashboardValues((oldValues) => {
                return {
                    ...oldValues,
                    exchange_rate,
                };
            });
            const currencyConversionRateToUSD: CurrencyConversionDTO = await RateService.getCurrentConversionRate("USD", unit.currency_code);
            const rateToUSD = currencyConversionRateToUSD.quotes[`${unit.currency_code}USD`];
            const exchange_rate_to_usd = Number.parseFloat(rateToUSD.toFixed(2));
            setCurrencyConversionRateToUSD(exchange_rate_to_usd);
        }
    }, [unit?.currency_code]);

    useEffect(() => {
        history.push({hash: UiUtils.getDashboardFromPosition(selectedTab)});
    }, [selectedTab]);

    useEffect(() => {
        const options = cohortsWithModel.map((cohort) => ({value: cohort.name, display: cohort.name}));
        const sortedOptions = options.sort((a: SelectOption, b: SelectOption) => a.display.localeCompare(b.display));
        setAssignableCohortOptions(sortedOptions);
    }, [cohortsWithModel]);

    useEffect(() => {
        setIsValidUnit(validationAnalystFactor());
    }, [isValidUnit, unit?.changes]);

    useEffect(() => {
        const id = +params.id;
        if (isInteger(id) && id > 0) {
            setUnitId(+params.id);
            return;
        }

        history.push(AppRoutes.UNITS);
    }, [params]);

    useEffect(() => {
        if (unitId && !unit) {
            dispatch(fetchEnhancedUnit({id: unitId}));
            dispatch(fetchHistory({id: unitId}));
            getGetCustomCohortsByUnit();
            syncLoadUserPreferences();
        }
    }, [unitId]);

    useEffect(() => {
        setUnitDashboardValues({
            ...unitDashboardValues,
            customCohortsOptions: customCohortsOption,
        });
    }, [customCohortsOption]);

    useEffect(() => {
        if (unit) {
            setUnitDashboardValues(() => ({
                customCohortsOptions: customCohortsOption,
                std: unit.std,
                average_rate: unit.average_rate,
                assigned_cohort: {display: unit.assigned_cohort, value: unit.assigned_cohort || null},
                suggested_average_rate: unit.details?.suggested_average_rate,
                currency: "USD",
                exchange_rate: unitDashboardValues.exchange_rate,
                min_rate: unit.min_rate,
            }));
            dispatch(
                fetchRatesForUnit({
                    id: unit.id,
                    start: startDate,
                    end: endDate,
                    avg_rate: unit.average_rate,
                    std_per: unit.std,
                    assigned_cohort: unit.assigned_cohort,
                })
            );
            setHasModel(!isNil(unit.cohort?.rate_model?.model_name));
            setIsNeumann(unit.cohort?.rate_model?.model_id === 1000);
        }
    }, [startDate, endDate, unit?.id, unit?.assigned_cohort]);

    useEffect(() => {
        if (!hasModel) {
            setMessage({
                type: "warning",
                content: "This Unit hasn't a Cohort or Cohort with a valid model, you can't save rates or factor for this unit",
            });
            return;
        }

        if (error) {
            setMessage(UiUtils.getErrorMessage(error.message));
            return;
        }

        if (!isEmpty(getChanges(unit))) {
            setMessage(UiUtils.getPendingChangesMessage());
            return;
        }

        setMessage(null);
    }, [unit?.changes, error, hasModel]);

    useEffect(() => {
        const ratesTablePreferences = find(userPreference?.preferences?.components, (component) => {
            return component.section === UISectionKeys.UNIT && component.type === "TABLE" && component.key === UIComponentsKeys.RATES_TABLE;
        }) as UIConfigurationTableComponent;

        const {visible, hidden} = UiUtils.getVisibleAndHiddenColumns(ratesTablePreferences, UnitRateTableColumns, DefaultUnitRateTableVisibleColumns);

        const ratesChartPreferences = find(userPreference?.preferences.components, (component) => {
            return component.section === UISectionKeys.UNIT && component.type === "CHART" && component.key === UIComponentsKeys.RATES_CHART;
        }) as UIConfigurationChartComponent;

        const selected = UiUtils.getSelectedChartLines(ratesChartPreferences, values(RateUtils.BASE_RATES_LABELS));

        const dashboardPreferences = find(userPreference?.preferences.components, (component) => {
            return component.section === UISectionKeys.UNIT && component.type === "DASHBOARD" && component.key === UIComponentsKeys.RATES_DATEPICKER;
        }) as UIConfigurationDashboardComponent;

        const {start, end, holidayName, monthIndex, toggle} = UiUtils.getSelectedDashboardValue(dashboardPreferences, startDate, endDate);

        const boxesPreferences = find(userPreference?.preferences.components, (component) => {
            return component.section === UISectionKeys.UNIT && component.type === "BOXES" && component.key === UIComponentsKeys.RATES_BOXES;
        }) as UIConfigurationBoxesComponent;

        const boxes = UiUtils.getBoxesOrder(boxesPreferences?.visible);

        setHolidayName(holidayName);
        setMonthIndex(monthIndex);
        setEndDate(end);
        setStartDate(start);
        setSaveToggle(toggle);
        setSelectedChart(selected);
        setVisibleColumns(visible);
        setHiddenColumns(hidden);
        setBoxesOrder(boxes);
    }, [userPreference?.preferences]);

    useEffect(() => {
        if (message && message.type === "success") {
            dispatch(
                fetchRatesForUnit({
                    id: unit.id,
                    start: startDate,
                    end: endDate,
                    avg_rate: unit.average_rate,
                    std_per: unit.std,
                    assigned_cohort: unit.assigned_cohort,
                })
            );
        }
    }, [message]);

    useEffect(() => {
        getCurrencyExchangeRate();
    }, [getCurrencyExchangeRate, unit?.currency_code]);

    const syncLoadUserPreferences = async () => {
        const user = await loadUserPreferences();
        setUserPreference(user);
    };

    const syncUpdateUserPreferences = async (preference: UserPreferenceConfigurationDTO) => {
        await updateUserPreferences(preference);
    };

    const getPreviewData = async () => {
        try {
            const ratesData = await RateService.getRatesPreviewForUnit(
                unitId,
                startDate,
                endDate,
                unitDashboardValues.average_rate,
                unitDashboardValues.std
            );
            if (typeof ratesData === "string") {
                setUiAlert(UiUtils.getErrorMessage(ratesData));
                return;
            }
            setPreviewData(ratesData as RatePreviewData);
            setUiAlert(null);
            if (isNeumann && (isBefore(startDate, startOfToday()) || isBefore(addDays(startOfToday(), NEUMANN_MAX_DAYS), endDate))) {
                setUiAlert(UiUtils.getPreviewNeumannWarning());
            }
        } catch (e) {
            setUiAlert(isNeumann ? UiUtils.getPreviewNeumannWarning() : UiUtils.getPreviewWarning);
        }
    };
    useDebounce(getPreviewData, delay, [unitDashboardValues]);

    const toggleChartVisibility = () => {
        setIsShowingChart(!isShowingChart);
    };

    const handleDowFilterChange = (dayFilterOption: DowOptions) => {
        setDowFilterOption(dayFilterOption);
    };

    const handleHistoryFilterChange = (processId?: string, filterDate?: string, modifiedField?: string) => {
        setProcessId(processId);
        setFilterDate(filterDate);
        setModifiedField(modifiedField);
        if (isEmpty(processId) && isEmpty(filterDate) && modifiedField === "all") {
            dispatch(fetchHistory({id: unitId}));
        }
    };
    const handleApplyFilters = () => {
        dispatch(
            fetchHistory({
                id: unitId,
                process_execution_id: processId,
                modifiedDate: filterDate,
                modifiedField: modifiedField,
            })
        );
    };

    const handleStatusFilterChange = (statusFilterOption: StatusOptions) => {
        setStatusFilterOption(statusFilterOption);
    };

    const handleNameChange = (value: SelectOption) => {
        setUnitDashboardValues({
            ...unitDashboardValues,
            assigned_cohort: value,
        });
    };

    const handleDashboardChange = async (values) => {
        setUnitDashboardValues({...unitDashboardValues, ...values});
    };
    const handleTableEdit = (column: keyof UnitRateTableValues, updatedRows: Row<UnitRateTableValues>[]) => {
        const changes = map(updatedRows, (row) => ({
            date: row.date,
            value: RateUtils.transformToUnitRateData(column as string, row),
        }));
        dispatch(setRateChangesForUnit({id: unitId, changes}));
    };

    const handleSave = async (data: SaveFormValues) => {
        const payload = data as UpdateUnitRatesDTO;
        const update = {...payload, cohortName: unit?.assigned_cohort};
        try {
            await RateService.updateRatesForUnit(unitId, update);
            dispatch(applyChangesForUnit({id: unitId}));
            setMessage(UiUtils.getSaveSuccessMessage());
            dispatch(fetchHistory({id: unitId, process_execution_id: null, modifiedDate: null, modifiedField: null}));
            dispatch(
                fetchRatesForUnit({
                    id: unit.id,
                    start: startDate,
                    end: endDate,
                    avg_rate: unit.average_rate,
                    std_per: unit.std,
                    assigned_cohort: unit.assigned_cohort,
                })
            );
        } catch (e) {
            LoggingService.error(`error saving unit rates changes`, {unit_id: unit?.id, params: {update}});
            setMessage(UiUtils.getErrorMessage(e));
        }
    };

    const onDatesChange = (start: Date, end: Date, numberOfDays: number, holiday?: string, month?: string) => {
        handleDatesChanged(start, numberOfDays, holiday, month);
        setStartDate(start);
        setEndDate(end);
    };

    const handleSelectedChange = (selected: string[]) => {
        const preferences = UiUtils.getUpdatedChartPreferences(UISectionKeys.UNIT, UIComponentsKeys.RATES_CHART, {
            selected,
        });
        syncUpdateUserPreferences(preferences);
        setSelectedChart(selected);
    };

    const handleDatesChanged = (start: Date, numberOfDays: number, holiday: string, monthIndex: string) => {
        const preferences = UiUtils.getUpdatedDashboardPreferences(UISectionKeys.UNIT, UIComponentsKeys.RATES_DATEPICKER, {
            start,
            numberOfDays: numberOfDays,
            holiday: holiday,
            monthIndex: monthIndex,
            toggle: saveToggle,
        });
        if (saveToggle) {
            syncUpdateUserPreferences(preferences);
        }
    };

    const handleColumnsChange = (visible: ColumnDescription[], hidden: ColumnDescription[]) => {
        const preferences = UiUtils.getUpdatedTablePreferences(UISectionKeys.UNIT, UIComponentsKeys.RATES_TABLE, {
            visible,
            hidden,
        });
        syncUpdateUserPreferences(preferences);
        setVisibleColumns(visible);
        setHiddenColumns(hidden);
    };

    const handleBoxesChanged = (boxes: {[id: string]: number}) => {
        const preferences = UiUtils.getUpdatedBoxesPreferences(UISectionKeys.UNIT, UIComponentsKeys.RATES_BOXES, boxes);
        syncUpdateUserPreferences(preferences);
        setBoxesOrder(boxes);
    };

    const getDefaultRateTableColumns = () => {
        return UiUtils.getVisibleAndHiddenColumns(undefined, UnitRateTableColumns, DefaultUnitRateTableVisibleColumns);
    };

    const getChanges = (unit: UnitWithRates): UnitRateChangeDTO[] => {
        const changes: UnitRateChangeDTO[] = [];
        return reduce(
            unit?.changes,
            (acc, curr, date) => {
                const original: RateDescription = unit.rates[date];
                const changed: RateDescription = curr;
                const minStayOverrideChanged = original.minstay_override !== changed.minstay_override;
                const minRateOverrideChanged = original.min_rate_override !== changed.min_rate_override;
                const maxRateOverrideChanged = original.max_rate_override !== changed.max_rate_override;
                const analystBaseOverrideChanged = original.rates.base_rate_override !== changed.rates.base_rate_override;
                const analystFactorChanged = original.rates.analyst_factor !== changed.rates.analyst_factor;

                if (
                    minRateOverrideChanged ||
                    maxRateOverrideChanged ||
                    analystBaseOverrideChanged ||
                    minStayOverrideChanged ||
                    analystFactorChanged
                ) {
                    const change: UnitRateChangeDTO = {date};

                    if (analystFactorChanged) {
                        change.analyst_factor = changed.rates.analyst_factor;
                    }

                    if (analystBaseOverrideChanged) {
                        change.base_rate_override = changed.rates.base_rate_override;
                    }
                    if (minRateOverrideChanged && maxRateOverrideChanged) {
                        change.minrate = changed.min_rate_override ?? null;
                        change.maxrate = changed.max_rate_override ?? null;
                    } else {
                        if (minRateOverrideChanged) {
                            change.minrate = changed.min_rate_override ?? null;
                            change.maxrate = original.max_rate_override ?? null;
                            change.min_max_changed = MinMaxChanged.ONLY_MIN;
                        }

                        if (maxRateOverrideChanged) {
                            change.minrate = original.min_rate_override ?? null;
                            change.maxrate = changed.max_rate_override ?? null;
                            change.min_max_changed = MinMaxChanged.ONLY_MAX;
                        }
                    }

                    if (minStayOverrideChanged) {
                        change.minstay_override = changed.minstay_override;
                    }

                    acc.push(change);
                }

                return acc;
            },
            changes
        );
    };

    const convertOriginalChartData = (chartData: ChartData) => {
        const dataToConvert = [RateUtils.RATES_LABELS.sold_past_inventory, RateUtils.RATES_LABELS.sold_future_inventory];
        const convertedData: ChartData = map({...chartData}, (data) => {
            map(data.points, (point, key) =>
                point && dataToConvert.includes(key)
                    ? (data.points[key] = parseFloat((Number(point) * currencyConversionRateToUSD).toFixed(2)))
                    : point
            );
            return data;
        });
        return convertedData;
    };

    if ((!unit && isFetchingEnhancedUnit) || (isEmpty(unit?.rates) && isFetchingRates)) {
        return <Loading className="main-loading-spinner" />;
    }

    if (!unit) {
        return null;
    }
    const dowFiltered = RateUtils.applyDowFilter(rates, dowFilterOption);
    const filtered = RateUtils.applyStatusFilter(dowFiltered, statusFilterOption);
    const tableData: UnitRateTableValues[] = RateUtils.transformToRateTableData(filtered);
    const transformedData = RateUtils.transformToUnitChartData(filtered, previewData);
    const chartData = canToggleCurrency ? convertOriginalChartData(transformedData) : transformedData;

    const handleCurrencyToggle = () => {
        setIsCurrencyToggled(() => !isCurrencyToggled);
    };

    const convertChartData = (chartData: ChartData) => {
        const originalData = RateUtils.transformToUnitChartData(filtered, previewData);
        const getValue = (data, key) => {
            const result = originalData.filter((original) => (isEqual(original.date, data.date) ? original.points[key] : null));
            return result.length > 0 ? result[0].points[key] : null;
        };
        const dataToRecover = [RateUtils.RATES_LABELS.sold_past_inventory, RateUtils.RATES_LABELS.sold_future_inventory];
        const dataToConvert = [
            RateUtils.RATES_LABELS.predicted_rate,
            RateUtils.RATES_LABELS.adjusted_rate,
            RateUtils.RATES_LABELS.advertised_rate,
            RateUtils.RATES_LABELS.sold_past_inventory,
            RateUtils.RATES_LABELS.sold_future_inventory,
            RateUtils.RATES_LABELS.oz_min_rate,
            RateUtils.RATES_LABELS.advertised_min_rate,
            RateUtils.RATES_LABELS.seasonal_str_min_rate_vacasa,
            RateUtils.RATES_LABELS.seasonal_str_min_rate_owner,
            RateUtils.RATES_LABELS.seasonal_str_min_rate_hoa,
            RateUtils.RATES_LABELS.unit_min_rate,
            RateUtils.RATES_LABELS.max_rate,
            RateUtils.RATES_LABELS.avg_rate,
            RateUtils.RATES_LABELS.preview_analyst_adjusted_rate,
            RateUtils.RATES_LABELS.preview_advertised_rate,
        ];
        const convertedData: ChartData = map({...chartData}, (data) => {
            map(data.points, (point, key) =>
                point && dataToConvert.includes(key)
                    ? dataToRecover.includes(key)
                        ? (data.points[key] = getValue(data, key))
                        : (data.points[key] = parseFloat((Number(point) * currencyConversionRate).toFixed(2)))
                    : point
            );
            return data;
        });
        return convertedData;
    };

    const convertDashboardValues = (values: UnitDashboardValues) => {
        const convertedValues: UnitDashboardValues = {...values};
        const keys: string[] = ["average_rate", "suggested_average_rate", "min_rate"];
        keys.forEach((key) => {
            if (values[key]) {
                convertedValues[key] = values[key] * currencyConversionRate;
            }
        });
        convertedValues.currency = unit.currency_code;
        return convertedValues;
    };

    const convertRatesTable = (rates: UnitRateTableValues[]) => {
        const keys: string[] = ["minRate", "maxRate", "predictedRate", "adjustedRate", "advertisedRate", "analystBaseOverride", "seasonalMinRate"];
        return rates.map((rate: UnitRateTableValues) => {
            const convertedRate: UnitRateTableValues = {...rate};
            keys.forEach((key: string) => {
                if (rate[key]) {
                    convertedRate[key] = Number.parseFloat((rate[key] * currencyConversionRate).toFixed(2));
                }
            });
            return convertedRate;
        });
    };

    const handleTabPreference = (value: string) => {
        pressedIcon = !pressedIcon;
        const preferences = UiUtils.getUpdatedTabsPreferences(UISectionKeys.UNIT, UIComponentsKeys.RATES_PREFERENCE_TABS, value);
        syncUpdateUserPreferences(preferences);
        setPreference(value);
    };

    const handleTabChange = (index) => {
        if (!pressedIcon) {
            setSelectedTab(index);
        }
        pressedIcon = !pressedIcon;
    };

    const getTitleComponent = () => {
        const enabledIcon = newUnitId !== unit.id && newUnitId !== 0 && newUnitId;

        const handleError = (unitId) => {
            setNewUnitId(0);
            setUnitMessage(UiUtils.getUnitNotFoundMessage(unitId));
            setLoadingNewUnit(false);
        };

        const handleUnitLoad = async (unitId: number) => {
            setLoadingNewUnit(true);
            try {
                const unit = await RateService.getEnhancedUnit(unitId);
                if (unit.active !== 1) {
                    handleError(newUnitId);
                    return;
                }
                setLoadingNewUnit(false);
                setUnitMessage(null);
                history.push(AppRoutes.UNIT_EVOLUTION.replace(":id", newUnitId.toString()).concat(hashTab));
            } catch {
                handleError(newUnitId);
            }
        };

        return (
            <div className="title-container">
                <p>UnitID:</p>
                <div className="input-container">
                    <GenericInput value={newUnitId === 0 ? unit.id : newUnitId} onChange={(id) => setNewUnitId(+id)} min={1} />
                    <Tooltip message="Load unit data">
                        <div className="icon-container">
                            {loadingNewUnit ? (
                                <Loading className="loading-new-unit" />
                            ) : (
                                <Icon.RefreshCW
                                    className={enabledIcon ? "pointer" : "disabled"}
                                    height={16}
                                    width={16}
                                    onClick={() => handleUnitLoad(newUnitId)}
                                />
                            )}
                        </div>
                    </Tooltip>
                </div>
                <p className={"margin-separator"}>Assigned Cohort:</p>
                <div className="select-container">
                    <Tooltip
                        message={
                            hashTab !== DashboardHash.DASHBOARD
                                ? "Navigate to the Dashboard Tab to enable cohort assignment here"
                                : process.env.REACT_APP_STRATEGIC_COHORTS_RESTRICTION == "true"
                                ? "Please use Northstar UI to move units from/to strategic cohorts"
                                : ""
                        }
                    >
                        <div>
                            <AutoCompleteSelect
                                value={unitDashboardValues.assigned_cohort}
                                options={assignableCohortOptions}
                                getOptionLabel={(item) => item.display}
                                onChange={handleNameChange}
                                disabled={
                                    (isEmpty(assignableCohortOptions) && !!cohortError) ||
                                    !havePermissionDashboard ||
                                    (unit.cohort?.independent_of_location == 1 && process.env.REACT_APP_STRATEGIC_COHORTS_RESTRICTION === "true")
                                }
                                disabledAutoComplete={
                                    !havePermissionDashboard ||
                                    hashTab !== DashboardHash.DASHBOARD ||
                                    (unit.cohort?.independent_of_location == 1 && process.env.REACT_APP_STRATEGIC_COHORTS_RESTRICTION === "true")
                                }
                            />
                        </div>
                    </Tooltip>
                    {!isNil(unitDashboardValues.assigned_cohort?.display) ? (
                        <Tooltip message="Go to cohort">
                            <Link
                                to={AppRoutes.COHORT_EVOLUTION.replace(
                                    ":name",
                                    `${encodeURIComponent(unitDashboardValues.assigned_cohort?.display)}`
                                )}
                                target="_blank"
                            >
                                <Icon.ExternalLink className={"pointer icon-external-link"} height={22} width={22} />
                            </Link>
                        </Tooltip>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{pageTitle} </title>
            </Helmet>
            <div>
                <div id="chart-visibility-toggle" className="chart-visibility-toggle pointer" onClick={toggleChartVisibility}>
                    {isShowingChart ? (
                        <Icon.EyeOff height={24} width={24} className="chart-visibility-toggle-icon" />
                    ) : (
                        <Icon.Eye height={24} width={24} className="chart-visibility-toggle-icon" />
                    )}
                </div>
            </div>

            {isShowingChart && (
                <RatesEvolutionChart
                    rates={isCurrencyToggled ? convertChartData(chartData) : chartData}
                    key={chartData.join(",")}
                    options={{
                        models: {
                            models: reduce(
                                !isNeumann ? RateUtils.RATES_LABELS : RateUtils.NEUMANN_LABELS,
                                (acc, value: string, key: string) => {
                                    acc[key] = value;
                                    return acc;
                                },
                                {}
                            ) as {[id: string]: string},
                            disabled: [],
                            selected: selectedChart,
                            synced: values(!isNeumann ? RateUtils.RATES_LABELS : RateUtils.NEUMANN_LABELS),
                        },
                        preferences: {
                            onApply: handleSelectedChange,
                        },
                    }}
                />
            )}

            <div>
                <GenericContentPanel
                    title={getTitleComponent()}
                    cohortType={unit.cohort?.type || "model"}
                    details={[
                        {
                            key: "currency",
                            label: `Unit's Currency`,
                            content: (
                                <Switch
                                    checked={canToggleCurrency ? isCurrencyToggled : true}
                                    disabled={!canToggleCurrency}
                                    onChange={handleCurrencyToggle}
                                />
                            ),
                        },
                        {key: "code", label: "Unit Code", value: unit.code},
                        {key: "region", label: "Unit Region", value: unit.details?.region_id + ""},
                        {
                            key: "assigned_model",
                            label: "Assigned Model",
                            value: hasModel ? unit.cohort?.rate_model?.model_name + " " + unit.cohort?.rate_model?.model_version : "Undefined",
                        },
                    ]}
                    onDateRangeChange={onDatesChange}
                    onFilteredDaysChange={handleDowFilterChange}
                    onFilteredStatusChange={handleStatusFilterChange}
                    startDate={startDate}
                    endDate={endDate}
                    holidayName={holidayName}
                    monthIndex={monthIndex}
                    regionId={unit.details?.region_id}
                    unitId={unit.id}
                    countryName={unit.location.country_name}
                    message={unitMessage}
                    toggleSave={{
                        value: saveToggle,
                        setToggle: handleToggle,
                    }}
                    isHistoryTab={hashTab === DashboardHash.CHANGELOG}
                    filterOnChange={handleHistoryFilterChange}
                    processId={processId}
                    modifiedField={modifiedField}
                    filterDate={filterDate}
                    onSearchClick={handleApplyFilters}
                    isFetching={isFetchingHistory}
                />
            </div>
            {!isNil(unit.model_unit_level_id) ? (
                <div className={"div-alert"}>
                    <AlertMessage
                        customClass="ab-testing-message"
                        text="Be aware that this unit is part of the Neumann A/B testing"
                        type="warning"
                        height="small"
                    />
                </div>
            ) : null}
            {uiAlert ? (
                <div className="div-alert">
                    <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small" />
                </div>
            ) : null}
            <div className="dynamic-tabs">
                <Tabs
                    selected={selectedTab}
                    onChange={handleTabChange}
                    variant="filled"
                    tabs={[
                        {
                            id: "tab-rates-evolution-dashboard",
                            label: "dashboard",
                            component: (
                                <UnitDashboard
                                    unit={unit}
                                    values={isCurrencyToggled ? convertDashboardValues(unitDashboardValues) : unitDashboardValues}
                                    onChange={handleDashboardChange}
                                    onOrderChanged={handleBoxesChanged}
                                    disabled={isCurrencyToggled}
                                    boxesOrder={boxesOrder}
                                    havePermission={havePermissionDashboard}
                                />
                            ),
                            icon: (
                                <Icon.Pin
                                    className={`icon-tabs ${preference === DashboardHash.DASHBOARD ? "active-icon" : ""}`}
                                    width={19}
                                    height={19}
                                    onClick={() => handleTabPreference(DashboardHash.DASHBOARD)}
                                />
                            ),
                        },

                        {
                            id: "tab-rates-evolution-base-rates",
                            label: "base rates",
                            component: isFetchingRates ? (
                                <Loading className="main-loading-spinner" />
                            ) : (
                                <RatesTable
                                    builder={getUnitRateTableBuilder(
                                        map(visibleColumns, (c) => c.key),
                                        {currency: isCurrencyToggled ? unit?.currency_code : "USD", cohortName: unit?.assigned_cohort}
                                    )}
                                    data={isCurrencyToggled ? convertRatesTable(tableData) : tableData}
                                    onRowsChange={handleTableEdit}
                                    dowFilterOption={dowFilterOption}
                                    statusFilterOption={statusFilterOption}
                                    onSave={handleSave}
                                    changes={getChanges(unit)}
                                    message={message}
                                    columnConfig={{
                                        visible: visibleColumns,
                                        hidden: hiddenColumns,
                                        onColumnsChange: handleColumnsChange,
                                        onResetDefault: getDefaultRateTableColumns,
                                    }}
                                    downloadConfig={{fileName: `rateAdjustments_unit_${unitId}`}}
                                    saveConfig={{
                                        hasExpiration: true,
                                        isDisabled: !hasModel || isValidUnit,
                                    }}
                                    key={tableData.join(",")}
                                    regionId={unit.details?.region_id}
                                    countryName={unit.location.country_name}
                                    disabled={isCurrencyToggled}
                                    havePermission={havePermissionRates}
                                    actionSidebar={{
                                        name: "Multiply",
                                        type: "multiply",
                                        tooltip: "Multiply Analyst Factor",
                                        enabled: true,
                                        columnOptions: [{field: "analystFactor", label: "Analyst Factor"}],
                                        onSave: async (updatedRows: SetUnitChange, context: UpdateContextDTO) => {
                                            // Multiply never calls onSave, its tranformed to Set Unit override
                                            return Promise.resolve();
                                        },
                                    }}
                                />
                            ),
                            icon: (
                                <Icon.Pin
                                    className={`icon-tabs ${preference === DashboardHash.RATES ? "active-icon" : ""}`}
                                    width={19}
                                    height={19}
                                    onClick={() => handleTabPreference(DashboardHash.RATES)}
                                />
                            ),
                        },
                        {
                            id: "tab-rates-evolution-change-log",
                            label: "change log",
                            component: <ChangeLog history={changes} />,
                            icon: (
                                <Icon.Pin
                                    className={`icon-tabs ${preference === DashboardHash.CHANGELOG ? "active-icon" : ""}`}
                                    width={19}
                                    height={19}
                                    onClick={() => handleTabPreference(DashboardHash.CHANGELOG)}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </React.Fragment>
    );
};
