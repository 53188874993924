export enum UnitBoxes {
    // Info
    LOCATION = 'location',
    DATE_OF_GO_ACTIVE = 'date_of_go_active',
    DISPLAY_DATE = 'display_date',
    UNIT_SOURCE = 'unit_sources',
    CONTRACT_RATE = 'contract_rate',
    REVIEWS = 'reviews',
    LONG_STAY_DISCOUNT = 'long_stay_discount',
    LOCAL_RESTRICTIONS = 'local_restrictions',
    MIN_MINSTAY = 'min_minstay',
    MIN_RATE = 'min_rate',
    // Editable
    RATE_CONFIGURATIONS = 'rate_configurations',
    ASSIGNED_COHORT = 'assigned_cohort',
    CUSTOM_COHORTS = 'custom_cohorts',
    ROOMS = 'rooms',
    CURRENCY = 'currency',
    CHANNEL_MARKET_SHARE_30 = 'channel_market_share_30d',
    CHANNEL_MARKET_SHARE_365 = 'channel_market_share_365d',
}
