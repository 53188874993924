import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@common/types';
import { DatePicker } from '@vacasa/react-components-lib';
import './CohortDetailsCard.scss';

interface CohortDetailsCardProps {
    title: { label: string; value: string };
    details: { label: string; value: string }[];
    factor_details: { label: string; value: string }[];
    date: Date;
    onDateChange: (date: Date) => void;
}
export const CohortDetailsCard: React.FC<CohortDetailsCardProps> = (props) => {
    const { details, date, title, factor_details, onDateChange } = props;
    const [displayDate, setDisplayDate] = useState<string>(format(date, DATE_FORMAT));

    const handleDateChange = (date: Date | null, value?: string | null) => {
        const displayValue = value ?? '';
        setDisplayDate(displayValue);
        onDateChange(date);
    };

    useEffect(() => {
        setDisplayDate(format(date, DATE_FORMAT));
    }, [date]);

    return (
        <div className={'cohort-details-card'}>
            <div className="factor-cohort-details">
                <div>
                    <span>{title.label}</span>
                    {': '}
                    <strong>{title.value}</strong>
                </div>
                <DatePicker
                    variant="inline"
                    value={date}
                    onChange={handleDateChange}
                    label="Date:"
                    disableToolbar={true}
                    inputValue={displayDate}
                    autoOk={true}
                />
                <div>
                    {details.map(({ label, value }) => (
                        <div>
                            <span>{label}</span>
                            {': '}
                            <strong>{value}</strong>
                        </div>
                    ))}
                </div>

                <div>
                    <strong>Factor:</strong>
                    <div className="factor-values-details">
                        {factor_details.map(({ label, value }) => (
                            <div>
                                <span>{label}</span>
                                {': '}
                                <strong>{value}</strong>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
