import React, { useEffect, useState } from 'react';
import './DefaultActionsSidebar.scss';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { ButtonGroup, Icon, Select } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import { UiUtils } from '../../utils';
import { DateRangePickers, GenericInput } from '..';
import { ColumnOptions, CopyTargetOptions, DowOptions, Filters, StatusOptions } from '../../types';
import { ButtonProps } from '@vacasa/react-components-lib/lib/components/Button/Button';
import { DatePeriodConfiguration } from '../GenericContentPanel/DatePeriodConfiguration';

interface DefaultActionsSidebarProps {
    columnOptions: ColumnOptions;
    valueRange: { start: Date | null; end: Date | null };
    onApply: (column: string, value: number, filter?: Filters) => void;
    onApplyAndSave: (column: string, value: number, filter?: Filters) => void;
    onClose: () => void;
    statusFilterOption: StatusOptions;
    dowFilterOption: DowOptions;
    holidayName?: string;
    cohortName?: string;
    regionId: number;
    countryName?: string;
    selectedDates?: string[];
}

export const DefaultActionsSidebar: React.FC<DefaultActionsSidebarProps> = (props) => {
    const {
        columnOptions,
        valueRange,
        onApply,
        onClose,
        onApplyAndSave,
        dowFilterOption,
        statusFilterOption,
        holidayName,
        cohortName,
        regionId,
        countryName,
        selectedDates,
    } = props;

    const [value, setValue] = useState<number>(NaN);
    const [affectedColumn, setAffectedColumn] = useState<string>('');
    const [selectedRowsTarget, setSelectedRowsTarget] = useState<string>('');
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(valueRange.start);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(valueRange.end);
    const [dowFilter, setDowFilter] = useState<DowOptions>(dowFilterOption);
    const [statusFilter, setStatusFilter] = useState<StatusOptions>(statusFilterOption);
    const [holiday, setHoliday] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [validationInput, setValidationInput] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<string>('');

    useEffect(() => {
        setStartDateFilter(valueRange.start);
        setEndDateFilter(valueRange.end);
    }, [valueRange.start, valueRange.end]);

    const dayOptions = UiUtils.getDoWFilterOptions();
    const statusOptions = UiUtils.getStatusFilterOptions();

    const validationValue = () => {
        if (_.isNil(value) || _.isNaN(value)) {
            setValidationError('');
            return setValidationInput(false);
        }
        if (value <= 0) {
            switch (affectedColumn) {
                case 'minRate':
                    setValidationError('Min Rate must be greater than 0');
                    return setValidationInput(true);
                case 'maxRate':
                    setValidationError('Max Rate must be greater than 0');
                    return setValidationInput(true);
                case 'analystBaseOverride':
                    setValidationError('Analyst Base Override must be greater than 0');
                    return setValidationInput(true);
            }
        }

        if (affectedColumn === 'analystFactor') {
            if (value <= 0 || value > 10) {
                setValidationError('Analyst Factor must be a value between 0.01 and 10');
                return setValidationInput(true);
            }
        }

        if (affectedColumn === 'minStayOverride') {
            if (value <= 0 || value > 500 || !Number.isInteger(value)) {
                setValidationError('Min Stay Override must be a natural number between 1 and 500');
                return setValidationInput(true);
            }
        }

        setValidationError('');
        return setValidationInput(false);
    };

    useEffect(() => {
        validationValue();
    }, [value, affectedColumn]);

    useEffect(() => {
        if (selectedRowsTarget === CopyTargetOptions.HOLIDAY_OR_MONTH) {
            handleValueHolidayAndMonth();
        }
    }, [selectedRowsTarget]);

    const handleAffectedColumnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAffectedColumn(e.target.value);
    };

    const handleValueHolidayAndMonth = () => {
        setHoliday('');
        setMonth('');
    };

    const handleValueChange = (value: number) => {
        setValue(value);
    };

    const handleTargetRowsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target.value;
        setSelectedRowsTarget(target);

        if (target !== CopyTargetOptions.PERIOD) {
            setStartDateFilter(valueRange.start);
            setEndDateFilter(valueRange.end);
            setDowFilter('All');
            setStatusFilter('All');
        }
    };

    const onDateRangeChange = (start: Date, end: Date, numberOfDays?: number, holiday?: string, month?: string) => {
        setStartDateFilter(start);
        setEndDateFilter(end);
        setHoliday(holiday);
        setMonth(month);
    };

    const handleDowFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDowFilter(e.target.value as DowOptions);
    };

    const handleStatusFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStatusFilter(e.target.value as StatusOptions);
    };

    const isApplyDisabled = () => {
        const isValueValid = _.isNumber(value) && !_.isNaN(value);
        const isAnyFieldEmpty = _.isEmpty(affectedColumn) || _.isEmpty(selectedRowsTarget) || !isValueValid || validationInput;
        const isDatesSelectedEmpty = _.isEmpty(selectedDates) && selectedRowsTarget === CopyTargetOptions.SELECTED;

        if (selectedRowsTarget === CopyTargetOptions.PERIOD || selectedRowsTarget === CopyTargetOptions.HOLIDAY_OR_MONTH) {
            const isValidPeriod = _.isDate(startDateFilter) && _.isDate(endDateFilter);
            if (selectedRowsTarget === CopyTargetOptions.HOLIDAY_OR_MONTH) {
                const isValidHoliday = holiday != null && holiday.trim().length > 0;
                const isValidMonth = month != null && month.trim().length > 0;
                return isAnyFieldEmpty || !isValidPeriod || (!isValidMonth && !isValidHoliday);
            }
            return isAnyFieldEmpty || !isValidPeriod;
        }

        return isAnyFieldEmpty || isDatesSelectedEmpty;
    };

    const getFilter = () => {
        switch (selectedRowsTarget) {
            case CopyTargetOptions.SELECTED:
                return { selected: true };
            case CopyTargetOptions.PERIOD:
            case CopyTargetOptions.HOLIDAY_OR_MONTH:
                return {
                    start: startDateFilter as Date,
                    end: endDateFilter as Date,
                    dow: UiUtils.getDoWFilterFromOption(dowFilter),
                    status: statusFilterOption ? UiUtils.getStatusFilterFromOption(statusFilter) : undefined,
                };
            case CopyTargetOptions.ALL:
            default:
                return undefined;
        }
    };

    const handleApply = (e: React.MouseEvent) => {
        onApply(affectedColumn, value, getFilter());
    };

    const handleApplyAndSave = (e: React.MouseEvent) => {
        onApplyAndSave(affectedColumn, value, getFilter());
    };

    const applyBtn: ButtonProps = {
        variant: 'secondary',
        onClick: handleApply,
        children: 'Apply',
        disabled: isApplyDisabled(),
    };

    const applyAndSaveBtn: ButtonProps = {
        variant: 'info',
        onClick: handleApplyAndSave,
        children: 'Apply and Save',
        disabled: isApplyDisabled(),
    };

    return (
        <div className="copy-side-bar">
            <div className="copy-side-bar-title">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>{cohortName ? 'Multiply Actions' : 'Set Actions'} </span>
            </div>

            {/* Step 1*/}
            <div className="copy-side-bar-step">
                <p>Step 1: Column Affected</p>
                <div className="copy-side-bar-input-group">
                    <RadioGroup
                        aria-label="affected-column"
                        name="affected-column"
                        value={affectedColumn}
                        onChange={handleAffectedColumnChange}>
                        {columnOptions.map((col) => (
                            <FormControlLabel
                                value={col.field}
                                control={<Radio className="copy-side-bar-radio" />}
                                label={
                                    <span className="copy-side-bar-radio-group-label">
                                        {!cohortName ? `Set ${col.label}` : `Multiply Unit ${col.label}`}
                                    </span>
                                }
                            />
                        ))}
                    </RadioGroup>
                </div>
            </div>

            <div className="step-separator" />
            {/* Step 2*/}
            <div className="copy-side-bar-step">
                <p>Step 2: Value</p>
                <div className="copy-side-bar-input-group">
                    <div className="copy-side-bar-value">
                        <GenericInput
                            className={validationInput ? 'error-input' : ''}
                            value={value}
                            onChange={handleValueChange}
                            decimals={true}
                            min={0}
                            max={999999.99}
                        />
                        {validationInput && <span>{validationError}</span>}
                    </div>
                </div>
            </div>

            <div className="step-separator" />
            {/* Step 3*/}
            <div className="copy-side-bar-step">
                <p>Step 3: Target</p>
                <div className="copy-side-bar-input-group">
                    <RadioGroup aria-label="target-rows" name="target-rows" value={selectedRowsTarget} onChange={handleTargetRowsChange}>
                        <FormControlLabel
                            value={CopyTargetOptions.PERIOD}
                            control={<Radio className="copy-side-bar-radio" />}
                            label={<span className="copy-side-bar-radio-group-label">{CopyTargetOptions.PERIOD}</span>}
                        />

                        {selectedRowsTarget === CopyTargetOptions.PERIOD && (
                            <div className="copy-to-period-options">
                                <DateRangePickers start={startDateFilter} end={endDateFilter} onDateRangeChange={onDateRangeChange} />
                                <Select
                                    customClass="period-dow-filter-select"
                                    label="Day Filter"
                                    value={dowFilterOption !== 'All' ? dowFilterOption : dowFilter}
                                    disabled={dowFilterOption !== 'All'}
                                    options={dayOptions}
                                    onChange={handleDowFilterChange}
                                />
                                {statusFilterOption && (
                                    <Select
                                        customClass="period-status.filter-select"
                                        label="Status Filter"
                                        value={statusFilterOption !== 'All' ? statusFilterOption : statusFilter}
                                        disabled={statusFilterOption !== 'All'}
                                        options={statusOptions}
                                        onChange={handleStatusFilterChange}
                                    />
                                )}
                            </div>
                        )}

                        <FormControlLabel
                            value={CopyTargetOptions.SELECTED}
                            control={<Radio className="copy-side-bar-radio" />}
                            label={<span className="copy-side-bar-radio-group-label">{CopyTargetOptions.SELECTED}</span>}
                        />

                        <FormControlLabel
                            value={CopyTargetOptions.HOLIDAY_OR_MONTH}
                            control={<Radio className="copy-side-bar-radio" />}
                            label={<span className="copy-side-bar-radio-group-label">{CopyTargetOptions.HOLIDAY_OR_MONTH}</span>}
                        />

                        {selectedRowsTarget === CopyTargetOptions.HOLIDAY_OR_MONTH && (
                            <div className="copy-to-holiday-options">
                                <DatePeriodConfiguration
                                    start={startDateFilter}
                                    end={endDateFilter}
                                    holidayName={holidayName}
                                    onDateRangeChange={onDateRangeChange}
                                    filters={{ cohortName, regionId, countryName }}
                                />
                            </div>
                        )}

                        <FormControlLabel
                            value={CopyTargetOptions.ALL}
                            control={<Radio className="copy-side-bar-radio" />}
                            label={<span className="copy-side-bar-radio-group-label">{CopyTargetOptions.ALL}</span>}
                        />
                    </RadioGroup>
                </div>
            </div>

            <div className="copy-side-bar-apply-button">
                <ButtonGroup left={applyAndSaveBtn} right={applyBtn} />
            </div>
        </div>
    );
};
