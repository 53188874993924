import {format} from "date-fns";
import {
    AVAILABLE_MODEL_IDS,
    BaseCohortDTO,
    BaseUnitDTO,
    BulkLoadDTO,
    ChangeHistoryDTO,
    CitiesDTO,
    CohortFactorDTO,
    CohortModelPredictionDTO,
    CurrencyConversionDTO,
    CustomCohortOptionsDTO,
    CustomCohortUnitDTO,
    DATE_FORMAT,
    EnhancedCohortDTO,
    EnhancedCustomCohortDTO,
    EnhancedUnitDTO,
    HolidayDTO,
    ProcessExecutionDetailDTO,
    ProcessExecutionDTO,
    RateData,
    RateModelDTO,
    RatePreviewData,
    StatesDTO,
    UnitFactorDTO,
    UnitStatusDTO,
    UpdateCohortRatesDTO,
    UpdateUnitRatesDTO,
    UpdateUnitsFactorDTO,
    UserPreferenceDTO,
} from "@common/types";
import axios, {AxiosError} from "axios";
import {Configuration} from "../Configuration";
import {RateUtils, UiUtils} from "../utils";
import {StorageKeys} from "../types";
import {FilterOption} from "../types/VirtualizedTable";
import {flowId} from "../App";
import {LoggingService} from "./LoggingService";
import {chunk, isEmpty, map, values} from "lodash";
import {EventCompressionType, UtilCompress} from "@vacasa/core";

export class RateService {
    private static readonly url: string = Configuration.getApiURL();
    private static readonly url_v2: string = Configuration.getApiURLV2();
    private static readonly url_ecs_v2: string = Configuration.getApiECSURLV2();
    private static readonly url_ecs_v3: string = Configuration.getApiECSURLV3();

    private static getHeaders = () => {
        const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
        const idToken = localStorage.getItem(StorageKeys.ID_TOKEN);
        return {
            "Content-Type": "application/vnd.api+json",
            Authorization: `Bearer ${accessToken}`,
            "x-identity-token": idToken,
            "x-flow-id": flowId,
        };
    };

    private static getHeadersV2 = () => {
        const headers = RateService.getHeaders();
        return {
            ...headers,
            "Content-Type": "application/json",
        };
    };

    public static getProcessExecution = async (params: {[key: string]: string}): Promise<{count: number; rows: ProcessExecutionDTO[]}> => {
        LoggingService.info("fetching process execution", {params});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/process-executions`, {
            headers,
            params,
        });
        return {
            count: data.meta.pagination.count,
            rows: map(data.data, (d) => d.attributes),
        };
    };

    public static getProcessExecutionDetailByProcessId = async (id: string): Promise<ProcessExecutionDetailDTO[]> => {
        LoggingService.info(`fetching process execution details for process ID: ${id}`);
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/process-executions/${id}/details`, {
            headers,
        });
        return await UtilCompress.uncompress(data.data.attributes.message, {
            compression: data.data.attributes.type,
        });
    };

    public static getProcessExecutionUploadedFile = async (processExecutionId: string) => {
        LoggingService.info("fetching process execution uploaded file", {processExecutionId});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/process-executions/${processExecutionId}/original-file`, {
            headers,
        });
        const uncompressed: BulkLoadDTO = await UtilCompress.uncompress(data.data.attributes.message, {
            compression: data.data.attributes.type,
        });
        return uncompressed.file;
    };

    public static getUnits = async (params: {[key: string]: string}): Promise<{count: number; rows: BaseUnitDTO[]}> => {
        LoggingService.info("fetching units", {params});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/units`, {
            headers,
            params,
        });
        return {
            count: data.meta.pagination.count,
            rows: map(data.data, (d) => d.attributes),
        };
    };

    public static getUnitsFactor = async (name: string, date: string): Promise<UnitFactorDTO[]> => {
        const cohortUnits: number[] = await RateService.getCohortUnitIds(name, true);
        const headers = RateService.getHeaders();
        const chunks = chunk(cohortUnits, 150);
        const requestResults = [];
        const joinedResult = [];
        for (let unitsChunk of chunks) {
            const params = {
                "filter[date]": date,
                "filter[unit_ids]": JSON.stringify(unitsChunk),
            };
            LoggingService.info(`fetching units factor for cohort ${name}`, {params});
            const url = `${RateService.url_v2}/cohorts/${encodeURIComponent(name)}/units/factors`;
            const {data: response} = await axios.get(url, {headers, params});
            requestResults.push(response);
        }
        for (const factors of requestResults) {
            isEmpty(joinedResult)
                ? joinedResult.push(map(factors.data, (d) => d.attributes))
                : joinedResult[0].push(...map(factors.data, (d) => d.attributes));
        }
        return joinedResult[0];
    };

    public static getUnitsStatusByDate = async (name: string, date: string): Promise<UnitStatusDTO[]> => {
        const params = {
            "filter[date]": date,
        };
        LoggingService.info("fetching units status by date", {params});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/cohorts/${encodeURIComponent(name)}/units/status`, {
            headers,
            params,
        });
        return map(data.data, (d) => d.attributes);
    };

    public static getCustomCohorsByUnit = async (idUnit: number) => {
        LoggingService.info("get custom cohors by unit: ", {idUnit});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/unit/${idUnit}/cohorts`, {
            headers,
        });
        return data.data?.map((d) => d.attributes);
    };

    public static updateUnitFactors = async (update: UpdateUnitsFactorDTO) => {
        const headers = RateService.getHeadersV2();
        const data = {type: "update-unit", attributes: update};
        await axios.put(`${RateService.url_ecs_v2}/units/rates`, {data}, {headers});
    };

    public static getUnitsForCustomCohorts = async (params: {[key: string]: string}): Promise<{count: number; rows: CustomCohortUnitDTO[]}> => {
        LoggingService.info("fetching units for custom cohort", {params});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/units/custom-cohorts`, {
            headers,
            params,
        });
        return {
            count: data.meta.pagination.count,
            rows: map(data.data, (d) => d.attributes),
        };
    };

    public static getUnitsByCriteria = async (params: {[key: string]: any}): Promise<CustomCohortUnitDTO[]> => {
        LoggingService.info("fetching units by criteria", {params});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/units/criteria`, {
            headers,
            params,
        });
        return data.data?.map((d) => d.attributes);
    };

    public static sortAlphabeticInsensitive = (values: string[]): string[] => {
        return values?.sort((a, b) => {
            return a.localeCompare(b);
        });
    };

    public static getSelectFiltersForUnits = async (): Promise<FilterOption[]> => {
        LoggingService.info("fetching search table header for units");
        const headers = RateService.getHeaders();
        //todo dont hit the database to get the filters
        const {data} = await axios.get(`${RateService.url}/units/search-filters`, {
            headers,
        });

        return [
            {field: "id", type: "number"},
            {field: "state", values: ["ALL", ...RateService.sortAlphabeticInsensitive(data.data?.attributes.state)], type: "select"},
            {field: "status", values: ["ALL", ...data.data?.attributes.status], type: "select"},
            {field: "display", values: ["ALL", ...data.data?.attributes.display], type: "select"},
            {field: "terminated", values: ["ALL", ...data.data?.attributes.terminated], type: "select"},
            {field: "bedroom_count", type: "range", options: "int"},
            {field: "average_rate", type: "range", options: "int"},
            {field: "std", type: "range", options: "float"},
        ];
    };

    public static getCohorts = async (params: {[key: string]: string}): Promise<{count: number; rows: BaseCohortDTO[]}> => {
        LoggingService.info("fetching cohorts", {params});
        const headers = RateService.getHeaders();

        const {data} = await axios.get(`${RateService.url}/cohorts`, {
            headers,
            params,
        });
        return {
            count: data.meta.pagination.count,
            rows: map(data.data, (d) => d.attributes),
        };
    };

    public static getCustomCohortCriteriaOptions = async (): Promise<CustomCohortOptionsDTO> => {
        LoggingService.info("fetching custom cohort criteria options");
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/cohorts/custom/criteria`, {headers});
        return data.data?.attributes;
    };

    public static getCustomCohortWithActiveUnits = async (name: string): Promise<EnhancedCustomCohortDTO> => {
        LoggingService.info(`fetching custom cohort ${name}`, {cohort_name: name});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/cohorts/custom/${name}/with-active-units`, {headers});
        return data.data?.attributes;
    };

    public static getRatesForUnit = async (unitId: number, startDate: Date, endDate: Date, avg_rate: number, std_per: number): Promise<RateData> => {
        const headers = RateService.getHeaders();
        const start = format(startDate, DATE_FORMAT);
        const end = format(endDate, DATE_FORMAT);

        const params = {
            "filter[start_date]": start,
            "filter[end_date]": end,
            "filter[avg_rate]": avg_rate,
            "filter[std_per]": std_per,
        };

        LoggingService.info(`fetching rates for unit ${unitId}`, {unit_id: unitId, params});
        const {data} = await axios.get(`${RateService.url_v2}/units/${unitId}/rates`, {headers, params});

        return RateUtils.transformJsonApiDataToDateMap(data);
    };

    public static getRatesPreviewForUnit = async (
        unitId: number,
        startDate: Date,
        endDate: Date,
        avg_rate: number,
        std_per: number
    ): Promise<RatePreviewData | string> => {
        try {
            const headers = RateService.getHeaders();
            const start = format(startDate, DATE_FORMAT);
            const end = format(endDate, DATE_FORMAT);

            const params = {
                "filter[start_date]": start,
                "filter[end_date]": end,
                "filter[avg_rate]": avg_rate,
                "filter[std_per]": std_per,
            };
            const {data} = await axios.get(`${RateService.url}/units/${unitId}/rates-preview`, {
                headers,
                params,
            });

            return RateUtils.transformJsonApiDataToDateMap(data);
        } catch (e) {
            return e.response?.data?.[0]?.detail?.error ?? e.message;
        }
    };

    public static getEnhancedUnit = async (unitId: number): Promise<EnhancedUnitDTO> => {
        LoggingService.info(`fetching enhanced unit ${unitId}`, {unit_id: unitId});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/units/${unitId}`, {headers});
        return data.data.attributes;
    };
    public static getEnhancedCohort = async (cohortName: string): Promise<EnhancedCohortDTO> => {
        LoggingService.info(`fetching enhanced cohort ${cohortName}`);
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/cohorts/${encodeURIComponent(cohortName)}`, {headers});
        return data.data.attributes;
    };

    public static updateRatesForUnit = async (unitId: number, update: UpdateUnitRatesDTO): Promise<void> => {
        LoggingService.info(`updating rates for unit ${unitId}`, {unit_id: unitId, params: {update}});
        const headers = RateService.getHeadersV2();
        const data = {type: "update-unit", attributes: update};
        await axios.put(`${RateService.url_ecs_v2}/units/${unitId}/rates`, {data}, {headers});
    };

    public static updateUnit = async (unitId: number, unit: Partial<EnhancedUnitDTO>): Promise<EnhancedUnitDTO> => {
        LoggingService.info(`updating unit ${unitId}`, {unit_id: unitId, params: {update: unit}});
        const headers = RateService.getHeadersV2();
        const update = {type: "unit", attributes: unit};
        const url = `${RateService.url_ecs_v2}/units/${unitId}`;
        const {data} = await axios.put(url, {data: update}, {headers});
        return data.data.attributes;
    };

    public static createCustomCohort = async (cohortName: string, cohortData): Promise<EnhancedCustomCohortDTO> => {
        try {
            const headers = RateService.getHeaders();
            LoggingService.info(`Creating custom cohort ${cohortName}`, {
                cohort_name: cohortName,
                params: {update: cohortData},
            });
            const update = {type: "custom-cohort", attributes: cohortData};
            const response = await axios.post(`${RateService.url}/cohorts/custom/${cohortName}`, {data: [update]}, {headers});
            return response.data.data.attributes;
        } catch (e) {
            LoggingService.error(`Error creating custom cohort ${cohortName}`, {e});
            throw e.response.data;
        }
    };

    public static upsertCustomCohort = async (cohortName: string, cohortData): Promise<EnhancedCustomCohortDTO> => {
        const headers = RateService.getHeaders();
        LoggingService.info(`Starting upsert cohort ${cohortName}`, {
            cohort_name: cohortName,
            params: {update: cohortData},
        });
        const update = {type: "custom-cohort", attributes: cohortData};
        const {data} = await axios.put(`${RateService.url}/cohorts/custom/${cohortName}`, {data: update}, {headers});
        return data.data.attributes;
    };

    public static runProcecures = (cohortName: string, cohort: Partial<EnhancedCohortDTO>) => {
        const headers = RateService.getHeaders();
        const update = {type: "cohort", attributes: cohort};
        axios.put(`${RateService.url}/cohorts/${encodeURIComponent(cohortName)}/run-procedures`, {data: update}, {headers});
    };

    public static updateCohort = async (cohortName: string, cohort: Partial<EnhancedCohortDTO>): Promise<EnhancedCohortDTO> => {
        const headers = RateService.getHeadersV2();
        LoggingService.info(`updating cohort ${cohortName}`, {cohort_name: cohortName, params: {update: cohort}});
        const update = {type: "cohort", attributes: cohort};
        return await axios.put(`${RateService.url_ecs_v2}/cohorts/${encodeURIComponent(cohortName)}`, {data: update}, {headers});
    };

    public static updateCustomCohort = async (cohortName: string, cohort: Partial<EnhancedCohortDTO>): Promise<EnhancedCohortDTO> => {
        const headers = RateService.getHeaders();
        LoggingService.info(`updating cohort ${cohortName}`, {cohort_name: cohortName, params: {update: cohort}});
        const update = {type: "custom-cohort", attributes: cohort};
        return await axios.put(`${RateService.url}/cohorts/custom/${cohortName}/name`, {data: update}, {headers});
    };

    public static getRatesForCustomCohort = async (cohortName: string, startDate: Date, endDate: Date) => {
        const customCohortUnitIds: number[] = await RateService.getCohortUnitIds(cohortName, true);
        const chunks = chunk(customCohortUnitIds, 150);

        const requestResults = [];
        for (let unitsChunk of chunks) {
            const {data: response} = await RateService.makeCustomCohortRatesRequest(cohortName, startDate, endDate, unitsChunk);
            requestResults.push(response);
        }
        const ratesData = RateService.joinUnitChunksResponse(requestResults);
        const calculatedRates = RateUtils.calculateRatesValuesCustomCohort(ratesData);
        return RateUtils.transformJsonApiDataToDateMap(calculatedRates);
    };

    private static joinUnitChunksResponse(unitChunksResponse: any[]): any {
        const rateData = {};
        for (const response of unitChunksResponse) {
            for (const data of response.data) {
                const date = data.attributes.date;
                if (rateData[date]) {
                    rateData[date].description = {
                        ...rateData[date].description,
                        adjusted_rates: rateData[date].description.adjusted_rates.concat(data.attributes.description.adjusted_rates),
                        factors: rateData[date].description.factors.concat(data.attributes.description.factors),
                        predicted_rates: rateData[date].description.predicted_rates.concat(data.attributes.description.predicted_rates),
                        rates: rateData[date].description.rates.concat(data.attributes.description.rates),
                        occupancy: rateData[date].description.occupancy,
                    };
                    continue;
                }
                rateData[date] = {
                    date,
                    description: {
                        dow: data.attributes.description.dow,
                        adjusted_rates: data.attributes.description.adjusted_rates,
                        factors: data.attributes.description.factors,
                        predicted_rates: data.attributes.description.predicted_rates,
                        rates: data.attributes.description.rates,
                        occupancy: data.attributes.description.occupancy,
                        holidays: data.attributes.description.holidays,
                    },
                };
            }
        }
        return values(rateData);
    }

    public static async getCohortUnitIds(cohortName: string, onlyActive: boolean = false): Promise<number[]> {
        cohortName = encodeURIComponent(cohortName);
        const {data: response} = await axios.get(`${RateService.url}/cohorts/${cohortName}/unit-ids`, {
            headers: RateService.getHeaders(),
            params: {
                "filter[only_active]": onlyActive,
            },
        });
        return response.data.attributes.units;
    }

    private static async makeCustomCohortRatesRequest(cohortName: string, startDate: Date, endDate: Date, unitIds: number[]) {
        const start = format(startDate, DATE_FORMAT);
        const end = format(endDate, DATE_FORMAT);
        LoggingService.info(`fetching rates for custom cohort ${cohortName}`, {
            cohort_name: cohortName,
            params: {
                start_date: start,
                end_date: end,
            },
        });
        return await axios.get(`${RateService.url_v2}/cohorts/custom/${cohortName}/rates`, {
            headers: RateService.getHeaders(),
            params: {
                "filter[start_date]": start,
                "filter[end_date]": end,
                "filter[unit_ids]": JSON.stringify(unitIds),
            },
        });
    }

    public static getRatesForCohort = async (cohortName: string, startDate: Date, endDate: Date) => {
        const headers = RateService.getHeaders();
        const start = format(startDate, DATE_FORMAT);
        const end = format(endDate, DATE_FORMAT);
        LoggingService.info(`fetching rates for cohort ${cohortName}`, {
            cohort_name: cohortName,
            params: {
                start_date: start,
                end_date: end,
            },
        });
        cohortName = encodeURIComponent(cohortName);
        const {data} = await axios.get(`${RateService.url_v2}/cohorts/${cohortName}/rates`, {
            headers,
            params: {"filter[start_date]": start, "filter[end_date]": end},
        });

        return RateUtils.transformJsonApiDataToDateMap(data);
    };

    public static getModelsForCohort = async (): Promise<RateModelDTO[]> => {
        const params = {"filter[status][in]": "EXPERIMENTAL,ACTIVE", "filter[model_id][in]": AVAILABLE_MODEL_IDS};
        LoggingService.info(`fetching models`, {params});
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/models`, {
            headers,
            params,
        });
        return map(data.data, (d) => d.attributes);
    };

    public static getModelsForSpecificCohort = async (cohortName: string): Promise<RateModelDTO[]> => {
        LoggingService.info(`fetching models for specific cohort  ${cohortName}`);
        const headers = RateService.getHeaders();
        cohortName = encodeURIComponent(cohortName);
        const {data} = await axios.get(`${RateService.url}/cohorts/${cohortName}/models`, {
            headers,
        });
        return map(data.data, (d) => d.attributes);
    };

    public static getRatesPredictedModelsForSpecificCohort = async (
        cohortName: string,
        model: number,
        startDate: Date,
        endDate: Date
    ): Promise<CohortModelPredictionDTO> => {
        LoggingService.info(`fetching predicted rates for specific model cohort ${cohortName}`);
        const headers = RateService.getHeaders();
        const start = format(startDate, DATE_FORMAT);
        const end = format(endDate, DATE_FORMAT);
        cohortName = encodeURIComponent(cohortName);
        const {data} = await axios.get(`${RateService.url}/cohorts/${cohortName}/models/${model}/predictions`, {
            headers,
            params: {"filter[start_date]": start, "filter[end_date]": end},
        });
        return data.data.attributes;
    };

    public static updateRatesForCohort = async (cohortName: string, update: UpdateCohortRatesDTO): Promise<void> => {
        LoggingService.info(`updating rates for cohort ${cohortName}`, {cohort_name: cohortName, params: {update}});
        const headers = RateService.getHeaders();
        const data = {type: "update-cohort", attributes: update};
        cohortName = encodeURIComponent(cohortName);
        await axios.put(`${RateService.url}/cohorts/${cohortName}/rates`, {data}, {headers});
    };

    public static getHolidaysForYear = async (
        year: number,
        filters: {regionId?: number; cohortName?: string; countryName?: string}
    ): Promise<HolidayDTO[]> => {
        const headers = RateService.getHeaders();
        const {regionId, cohortName, countryName} = filters;
        const params = {
            "filter[year]": year,
            "filter[region_id]": regionId,
            "filter[cohort_name]": cohortName,
            "filter[country_name]": countryName,
        };
        LoggingService.info(`fetching holidays`, {params});
        const {data} = await axios.get(`${RateService.url}/holidays`, {
            headers,
            params,
        });
        return map(data.data, (d) => d.attributes);
    };

    public static async getHistory(params: {
        id: number | string;
        process_execution_id?: string;
        modifiedDate?: string;
        modifiedField?: string;
    }): Promise<ChangeHistoryDTO[]> {
        const headers = RateService.getHeaders();
        const {id, process_execution_id, modifiedDate, modifiedField} = params;
        let historyParams;
        if (typeof id === "number") {
            historyParams = {
                "filter[unit_id]": id,
                "filter[process_execution_id]": process_execution_id,
                "filter[modifiedDate]": modifiedDate,
                "filter[modifiedField]": modifiedField,
                "page[size]": 1000,
            };
        } else {
            historyParams = {
                "filter[cohort_name]": id,
                "filter[process_execution_id]": process_execution_id,
                "filter[modifiedDate]": modifiedDate,
                "filter[modifiedField]": modifiedField,
                "page[size]": 1000,
            };
        }
        const {data} = await axios.get(`${RateService.url}/history`, {
            headers,
            params: historyParams,
        });

        return map(data.data, (d) => d.attributes);
    }

    public static getUserPreference = async (email: string): Promise<UserPreferenceDTO> => {
        const headers = RateService.getHeaders();
        const params = {
            "filter[email]": email,
        };
        const {data} = await axios.get(`${RateService.url}/users`, {headers, params});
        return (data.data || [])[0]?.attributes;
    };

    public static createUserPreference = async (user: UserPreferenceDTO): Promise<UserPreferenceDTO> => {
        const headers = RateService.getHeaders();
        const update = {type: "user_preference", attributes: user};
        const {data} = await axios.post(`${RateService.url}/users`, {data: update}, {headers});
        return data.data?.attributes;
    };

    public static updateUserPreference = async (user: UserPreferenceDTO): Promise<UserPreferenceDTO> => {
        const headers = RateService.getHeaders();
        const update = {type: "user_preference", attributes: user};
        const {data} = await axios.put(`${RateService.url}/users/${user.id}`, {data: update}, {headers});
        return data.data?.attributes;
    };

    public static uploadFile = async (file: BulkLoadDTO): Promise<BulkLoadDTO> => {
        const headers = RateService.getHeaders();
        const message = (await UtilCompress.compress(file, EventCompressionType.GZIP))?.data || "";
        if (!UiUtils.isValidPayloadSize(message)) {
            throw Error("The file exceeds the maximum size of 6MB when compressed. Please reduce the size of the file");
        }
        const compressedData = {
            type: "compressed",
            attributes: {
                type: "gzip",
                message: message,
            },
        };
        const {data} = await axios.post(`${RateService.url}/bulk-load`, {data: compressedData}, {headers});
        return data.data?.attributes;
    };

    public static makeError(origin: string, error: AxiosError) {
        LoggingService.error(`making error for ${origin}, ${error.message}`);
        return {origin, message: error.message, code: error.code}; // TODO: make a custom error
    }

    public static getCurrentConversionRate = async (currency: string, baseCurrency: string): Promise<CurrencyConversionDTO> => {
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/currency-conversion/${baseCurrency}/${currency}`, {headers});
        return data.data?.attributes;
    };

    public static deleteCustomCohort = async (cohortName: string): Promise<void> => {
        LoggingService.info("Deleting custom cohort", {cohort_name: cohortName});
        const headers = RateService.getHeaders();
        await axios.delete(`${RateService.url}/cohorts/custom/${cohortName}`, {headers});
    };

    public static updateCohortUnitAnalystFactor = async (changes: CohortFactorDTO): Promise<void> => {
        const headers = RateService.getHeadersV2();
        const payload = {type: "cohort", attributes: changes};
        const url = `${RateService.url_ecs_v3}/cohorts/${encodeURIComponent(changes.cohort_name)}/units/factors`;
        return await axios.put(url, {data: payload}, {headers});
    };

    public static getStates = async (): Promise<Array<StatesDTO>> => {
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/states`, {headers});
        return data.data.map((d) => d?.attributes);
    };

    public static getCities = async (): Promise<Array<CitiesDTO>> => {
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/cities`, {headers});
        return data.data.map((d) => d?.attributes);
    };

    public static validateCohortName = async (cohort: string): Promise<any> => {
        const headers = RateService.getHeaders();
        try {
            const {data} = await axios.get(`${RateService.url}/cohorts/validate/${encodeURIComponent(cohort)}`, {headers});
            return data?.data;
        } catch (e) {
            if (e.response.data[0]?.detail) {
                return e.response.data[0]?.detail;
            }
            return e.message;
        }
    };

    public static createCohort = async (cohort: BaseCohortDTO): Promise<Array<CitiesDTO>> => {
        const headers = RateService.getHeaders();
        const post = {type: "create-cohort", attributes: cohort};
        const {data} = await axios.post(`${RateService.url}/cohort`, {data: post}, {headers});
        return data?.data?.attributes;
    };

    public static getUnitPredictions = async (unitId: number, modelId: number): Promise<any> => {
        const headers = RateService.getHeaders();
        const {data} = await axios.get(`${RateService.url}/units/${unitId}/models/${modelId}/predictions`, {headers});
        return data.data.attributes.predictions;
    };
}
