import React, { KeyboardEvent, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { filter, isEmpty, isNil, isNull, isUndefined, keys, map, reduce, toLower } from 'lodash';
import {
    Column,
    Table,
    AutoSizer,
    Index,
    TableCellProps,
    ColumnProps,
    TableHeaderProps,
    defaultTableRowRenderer,
    TableRowProps,
} from 'react-virtualized';
import {
    DataSource,
    Column as DataSourceColumn,
    Row,
    ActionColumn as DataSourceActionColumn,
    FilterField,
    OrderField,
} from '../../types/VirtualizedTable';
import { Checkbox, TablePagination } from '@material-ui/core';
import './VirtualizedTable.scss';
import { Cell } from './Cell';
import { Header } from './Header';
import { Loading } from '..';
import { DataSourceBuilder } from './DataSourceBuilder';
import { useTaskQueue } from '../../hooks/useTaskQueue';
import { isAfter, isValid, parse, startOfYesterday } from 'date-fns';
import { DATE_FORMAT } from '@common/types';
import { useDebounce } from '@vacasa/react-components-lib';

interface VirtualizedTableProps<T> {
    dataSource: DataSource<T>;
    onRowChange: (column: string, rowData: Row<T>) => void;
    onSelectedChange?: (selectedIds: string[]) => void;
    className?: string;
    onValidChange?: (isValid: boolean) => void;
    disabled?: boolean;
    headerOptions?: {
        height?: number;
    };
}

type VirtualizedTableComponent = <T>(props: VirtualizedTableProps<T>) => ReactElement<any, any> | null;

export const VirtualizedTable: VirtualizedTableComponent = (props) => {
    const { dataSource, onRowChange, onSelectedChange, className, onValidChange, disabled, headerOptions } = props;
    const { pagination, sortable } = dataSource;

    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set([]));
    const [filters, setFilters] = useState<FilterField[]>(dataSource.filterConfig?.initialFilters || []);
    const [order, setOrder] = useState<OrderField>(sortable);
    const [rows, setRows] = useState<Row<any>[]>([]);
    const invalidCells = new Set([]);
    const [paging, setPaging] = useState<{ size: number; number: number }>({ number: 1, size: +process.env.REACT_APP_PAGING_SIZE });
    const { addTask } = useTaskQueue({ shouldProcess: true });
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [originalValues, setOriginalValues] = useState<{ [key: string]: any } | null>(null);
    const headerHeight = headerOptions?.height ?? 50;
    // a change in this constant shouldn't render the component again
    // so this is not a component's state
    const editableCellRefs = useRef<{ [key: string]: any }>({});
    const [debouncedFilters, setDebouncedFilters] = useState<FilterField[]>(filters);
    useDebounce(() => setDebouncedFilters(filters), 1000, [filters]);

    useLayoutEffect(() => {
        return function cleanup() {
            setSelectedRows(new Set([]));
        };
    }, []);

    // Runs everytime the data changes or the user changes pages
    useEffect(() => {
        if (!pagination && !sortable) {
            return;
        }

        if (pagination && pagination.remote && filters === debouncedFilters) {
            addTask({ method: updatePage, params: [paging.number, paging.size, debouncedFilters, order] });
            return;
        }

        let rows = applyFilters(dataSource.rows);
        setCount(rows.length);
        if (sortable) {
            rows = sort(rows);
        }
        if (pagination) {
            rows = paginate(rows);
        }
        setRows(rows);
    }, [paging, debouncedFilters, order]);

    useEffect(() => {
        if (pagination || sortable) {
            return;
        }

        const filteredRows = applyFilters(dataSource.rows);
        setRows(filteredRows);
        setCount(filteredRows.length);
    }, [dataSource.rows, debouncedFilters]);

    useEffect(() => {
        onValidChange && onValidChange(!(invalidCells.size > 0));
    }, [invalidCells.size, rows]);

    useEffect(() => {
        if (rows.length == 0 || !isNull(originalValues)) {
            return;
        }
        const originalRowValuesByKey = reduce(
            rows,
            (acc, row) => {
                acc[row.key] = reduce(
                    row,
                    (acc, value, key) => {
                        if (key == `key`) {
                            return acc;
                        }
                        acc[key] = value;
                        return acc;
                    },
                    {}
                );
                return acc;
            },
            {}
        );
        setOriginalValues(originalRowValuesByKey);
    }, [rows]);
    const paginate = (rows: Row<any>[]): Row<any>[] => {
        const { number: pageNumber, size: pageSize } = paging;
        return rows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    };

    const sort = (rows: Row<any>[]): Row<any>[] => {
        const { field } = order;
        return rows.sort((a, b) => {
            if (isNaN(+a[field])) {
                return sortString(a[field], b[field], order.order);
            }
            return sortNumber(a[field], b[field], order.order);
        });
    };

    const sortNumber = (a: number, b: number, order: string) => {
        if (order === 'asc') {
            return a - b;
        }
        return b - a;
    };

    const sortString = (a: string, b: string, order: string) => {
        if (order === 'asc') {
            return a.localeCompare(b);
        }
        return a.localeCompare(b) * -1;
    };

    const updatePage = async (number, size, filters: FilterField[], order: OrderField): Promise<void> => {
        try {
            setIsLoading(true);

            const { result, count } = await dataSource?.pagination.function(number, size, filters, order);

            const updatedRows = DataSourceBuilder.toRows(result);
            setRows(updatedRows);
            setCount(count);
        } catch (e) {
            console.log('error calling pagination method ', { error: e });
        } finally {
            setIsLoading(false);
        }
    };

    const getRow = ({ index }: Index) => {
        return rows[index];
    };

    const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        let selected: string[] = [];

        if (e.target.checked) {
            selected = map(rows, (row) => row.key);
            selected = selected.filter((row) => isWorkableDate(row));
        }
        setSelectedRows(new Set(selected));
        if (onSelectedChange) {
            onSelectedChange(selected);
        }
    };

    const onSelectSingle = (id: string) => {
        let newSelected: Set<string> = new Set(Array.from(selectedRows));

        if (newSelected.has(id)) {
            newSelected.delete(id);
        } else {
            newSelected.add(id);
        }

        setSelectedRows(newSelected);

        if (onSelectedChange) {
            onSelectedChange(Array.from(newSelected));
        }
    };

    const onFilterChange = (filters: FilterField[]) => {
        setPaging((page) => ({ number: 1, size: page.size }));
        setFilters(filters);
    };

    const onOrderChange = (newOrder: OrderField) => {
        setPaging((page) => ({ number: 1, size: page.size }));
        setOrder(newOrder);
    };

    const applyFilters = (rows: Row<any>[]): Row<any>[] => {
        if (isEmpty(filters)) {
            return rows;
        }

        const getFilterForColumn = (column: string) => {
            for (const filter of filters) {
                if (filter.field === column) {
                    return filter;
                }
            }
            return null;
        };

        const passesFilters = (row: Row<any>) => {
            const columns = keys(row);

            for (const column of columns) {
                const value = row[column];
                const filterForField = getFilterForColumn(column);

                const isEmptyFilter = isNull(filterForField) || isUndefined(filterForField);
                const isEmptyValue = isNull(value) || isUndefined(value) || value === '';

                if (isEmptyFilter || isEmptyValue) {
                    continue;
                }
                let includes: boolean = true;
                switch (filterForField.type) {
                    case 'number':
                    case 'text':
                        includes = `${toLower(value)}`.includes(toLower(filterForField.value));
                        break;
                    case 'select':
                        includes = toLower(`${value}`).startsWith(toLower(filterForField.value));
                        break;
                    case 'range':
                        const range = filterForField.value.split(',');
                        includes = +value >= +range[0] && +value <= +range[1];
                        break;
                    default:
                        break;
                }

                if (!includes) {
                    return false;
                }
            }

            return true;
        };

        return filter(rows, (r) => passesFilters(r));
    };

    const renderHeader = (props: TableHeaderProps, column: DataSourceColumn | DataSourceActionColumn) => {
        const filtering = isEmpty(dataSource.filterConfig)
            ? undefined
            : {
                  config: dataSource.filterConfig,
                  onFilterChange,
                  filters,
              };

        const ordering = isEmpty(sortable)
            ? undefined
            : {
                  orderBy: order,
                  onOrderChange,
              };
        return <Header column={column} filtering={filtering} ordering={ordering} componentId={`${className}-header`} />;
    };
    const handleCellValidState = (id: string, isValid: boolean) => {
        if (!isValid) {
            invalidCells.add(id);
            return;
        }
        invalidCells.delete(id);
    };

    const renderRow = (props: TableRowProps) => {
        if (isLoading) {
            return null;
        }
        return defaultTableRowRenderer(props);
    };

    const isWorkableDate = (date) => {
        if (isNil(date) || !isValid(parse(date, DATE_FORMAT, new Date()))) {
            return true;
        }
        return isAfter(new Date(date), startOfYesterday());
    };

    const renderCell = (cellProps: TableCellProps, column: DataSourceColumn) => {
        if (cellProps.dataKey === 'base_rate_override' || cellProps.dataKey === 'factor') {
            cellProps.rowData = { ...cellProps.rowData, ...dataSource.rows.filter((row) => row.key === cellProps.rowData.key)[0] };
        }
        return (
            <Cell
                column={column}
                cellProps={cellProps}
                onRowChange={onRowChange}
                onRepeat={(dataKey, value) => {
                    dataSource.copyHandler(dataKey as any, value, workableRows);
                }}
                onValidChange={(isValid) => handleCellValidState(`${column.field}${cellProps.rowIndex}${cellProps.columnIndex}`, isValid)}
                ref={column.editable ? setReferenceEditableColumn(cellProps.rowIndex, cellProps.columnIndex) : undefined}
                onKeyDown={handleKeyDown}
                disabled={!isWorkableDate(cellProps.rowData.date) || disabled}
                originalValues={(originalValues || {})[cellProps.rowData.key] || {}}
            />
        );
    };

    const setReferenceEditableColumn = (rowIndex: number, columnIndex: number) => {
        return (input) => {
            editableCellRefs.current[`${rowIndex},${columnIndex}`] = input;
        };
    };

    const handleKeyDown = (event: KeyboardEvent, rowIndex: number, columnIndex: number): void => {
        switch (event.key) {
            case 'ArrowDown':
            case 'Enter':
                if (cellExists(rowIndex + 1, columnIndex)) setFocus(rowIndex + 1, columnIndex);
                break;
            case 'ArrowUp':
                if (cellExists(rowIndex - 1, columnIndex)) setFocus(rowIndex - 1, columnIndex);
                break;
        }
    };

    const setFocus = (rowIndex: number, columnIndex: number): void => {
        editableCellRefs.current[`${rowIndex},${columnIndex}`].focus();
    };
    const cellExists = (rowIndex: number, columnIndex: number): boolean => {
        return editableCellRefs.current[`${rowIndex},${columnIndex}`];
    };

    const renderActionCell = (cellProps: TableCellProps, column: DataSourceActionColumn) => {
        const { rowData } = cellProps;
        return <span className="virtualized-table-cell">{column.func(rowData)}</span>;
    };
    const renderColumnWithFunc = (cellProps: TableCellProps, column: DataSourceColumn) => {
        const { rowData } = cellProps;
        return <span className="virtualized-table-cell">{column.func(rowData)}</span>;
    };

    const numOfRows = rows.length;
    const workableRows = rows.filter((row) => isWorkableDate(row.date));
    const numOfWorkableRows = workableRows.length;
    const numOfSelected = selectedRows.size;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPaging((page) => ({ size: page.size, number: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        const size = value > 0 ? value : +process.env.REACT_APP_PAGING_SIZE;
        setPaging(() => ({ number: 1, size: size }));
    };

    return (
        <div className={className ?? ''}>
            <div>
                <div className="virtualized-table">
                    <React.Fragment>
                        <AutoSizer>
                            {(props) => {
                                const { height, width } = props;
                                return (
                                    <Table
                                        rowCount={numOfRows}
                                        height={height}
                                        width={width}
                                        headerHeight={headerHeight}
                                        rowHeight={50}
                                        rowGetter={getRow}
                                        rowRenderer={(props) => renderRow(props)}>
                                        {onSelectedChange && (
                                            <Column
                                                dataKey="select-checkbox"
                                                disableSort
                                                width={48}
                                                className="virtualized-table-column"
                                                headerRenderer={() => (
                                                    <Checkbox
                                                        indeterminate={numOfSelected > 0 && numOfSelected < numOfWorkableRows}
                                                        checked={numOfWorkableRows > 0 && numOfSelected === numOfWorkableRows}
                                                        onChange={onSelectAll}
                                                        className="virtualized-table-checkbox"
                                                    />
                                                )}
                                                cellRenderer={({ rowData }) => (
                                                    <Checkbox
                                                        checked={selectedRows.has(rowData.key)}
                                                        onChange={() => onSelectSingle(rowData.key)}
                                                        className="virtualized-table-checkbox"
                                                        disabled={!isWorkableDate(rowData.date)}
                                                    />
                                                )}
                                            />
                                        )}
                                        {map(dataSource.columns, (column) => (
                                            <Column
                                                key={column.label}
                                                label={column.label}
                                                dataKey={column.field}
                                                width={1}
                                                flexGrow={column.displayConfiguration?.flexGrow ?? 1}
                                                className={`virtualized-table-column ${
                                                    column.label === 'Unit Code' ? 'RowColumnUnitCode' : ''
                                                }`}
                                                cellRenderer={
                                                    !column.func
                                                        ? (props: TableCellProps) => renderCell(props, column)
                                                        : (props: TableCellProps) => renderColumnWithFunc(props, column)
                                                }
                                                headerRenderer={(props: ColumnProps) => renderHeader(props, column)}
                                            />
                                        ))}

                                        {map(dataSource.actionColumns, (column) => (
                                            <Column
                                                key={column.label}
                                                label={column.label}
                                                dataKey={column.label}
                                                width={1}
                                                flexGrow={column.displayConfiguration?.flexGrow ?? 1}
                                                className="virtualized-table-column"
                                                cellRenderer={(props: TableCellProps) => renderActionCell(props, column)}
                                                headerRenderer={(props: TableHeaderProps) => renderHeader(props, column)}
                                            />
                                        ))}
                                    </Table>
                                );
                            }}
                        </AutoSizer>
                        {isLoading && (
                            <div className="virtualized-table-loader">
                                <Loading />
                            </div>
                        )}
                    </React.Fragment>
                </div>
                {!isEmpty(pagination) && (
                    <TablePagination
                        data-testid={`${className}-pagination`}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={count}
                        rowsPerPage={paging.size}
                        page={paging.number - 1}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </div>
        </div>
    );
};
