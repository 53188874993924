import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Select } from '@vacasa/react-components-lib';
import { UiUtils } from '../../utils';
import { addDays, differenceInDays, endOfMonth, format, getYear, startOfDay } from 'date-fns';
import { isValidDateRange, parseDate } from '@common/utils';
import { RateService } from '../../services/RateService';
import { DATE_FORMAT, HolidayDTO } from '@common/types';
import { Configuration } from '../../Configuration';

export interface DateFilterProps {
    start: Date;
    end: Date;
    holidayName: string;
    monthIndex?: string;
    onDateRangeChange: (startDate: Date, endDate: Date, numberOfDays: number, holiday?: string, month?: string) => void;
    filters: { regionId?: number; cohortName?: string; countryName?: string };
}

export const DatePeriodConfiguration: React.FC<DateFilterProps> = (props) => {
    const { start, end, onDateRangeChange, filters, holidayName, monthIndex } = props;

    const [selectedYearIndex, setSelectedYearIndex] = useState<number>(getYear(new Date()));
    const [selectedHolidayIndex, setSelectedHolidayIndex] = useState<number | null>(null);
    const [selectedMonthIndex, setSelectedMonthIndex] = useState<number | null>(null);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);
    const [holidaysForSelectedYear, setHolidaysForSelectedYear] = useState<HolidayDTO[]>([]);
    const [holidaysByYear, setHolidaysByYear] = useState<{ [year: number]: HolidayDTO[] }>({});

    const numberOfDays = differenceInDays(startOfDay(end), startOfDay(start));

    useEffect(() => {
        if (!_.isEmpty(monthIndex)) {
            setSelectedOptionIndex(1);
            setSelectedMonthIndex(Number(monthIndex));
        }
    }, [monthIndex]);

    useEffect(() => {
        getHolidays(selectedYearIndex);
    }, [selectedYearIndex]);

    useEffect(() => {
        const aux = holidaysForSelectedYear.map((holiday) => holiday.name).indexOf(holidayName);
        if (aux !== -1) {
            setSelectedOptionIndex(0);
        }
        setSelectedHolidayIndex(aux);
    }, [holidaysForSelectedYear]);

    const resetFilters = (year) => {
        setSelectedYearIndex(year as any);
        setSelectedOptionIndex(null);
        setSelectedHolidayIndex(null);
        setSelectedMonthIndex(null);
    };

    useEffect(() => {
        const formattedStart = format(start, DATE_FORMAT);
        const formattedEnd = format(end, DATE_FORMAT);
        if (selectedOptionIndex === 0) {
            const selectedHoliday = holidaysForSelectedYear[selectedHolidayIndex];
            const selectedRangeIsCurrentHoliday =
                selectedHoliday?.first_night === formattedStart && selectedHoliday?.last_night === formattedEnd;
            if (!selectedRangeIsCurrentHoliday) {
                // this means the user changed the start,end range by date picker, reset holiday
                resetFilters(getYear(new Date()));
                return;
            }
        }
        if (selectedOptionIndex === 1) {
            const splittedStart = formattedStart.split('-');
            const splittedEnd = formattedEnd.split('-');
            const isSameMonthAndYear =
                splittedStart[0] === splittedEnd[0] && splittedStart[1] === splittedEnd[1] && !_.isNil(selectedMonthIndex);
            const currentMonthStart = format(new Date(Number(splittedStart[0]), selectedMonthIndex, 1), DATE_FORMAT);
            const currentMonthEnd = format(endOfMonth(new Date(Number(splittedStart[0]), selectedMonthIndex, 1)), DATE_FORMAT);
            const selectedRangeIsCurrentMonth =
                isSameMonthAndYear && currentMonthStart === formattedStart && currentMonthEnd === formattedEnd;
            if (!selectedRangeIsCurrentMonth) {
                // this means the user changed the start,end range by date picker, reset month
                resetFilters(getYear(new Date()));
                return;
            }
        }
    }, [start, end]);

    const getHolidays = async (year: number) => {
        const state = { ...holidaysByYear };
        const holidaysForYear = state[year];

        if (!holidaysForYear) {
            state[year] = await RateService.getHolidaysForYear(year, filters);
        }

        setHolidaysByYear(state);
        setHolidaysForSelectedYear(state[year]);
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const year = e.target.value;
        resetFilters(year);
    };

    const handleHolidayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = +e.target.value;
        setSelectedHolidayIndex(index);
        const holiday = holidaysForSelectedYear[index];
        const start = parseDate(holiday?.first_night);
        const end = parseDate(holiday?.last_night);
        if (!holiday || !isValidDateRange(start, end)) {
            const today = new Date();
            const end = addDays(today, Configuration.DEFAULT_DAYS_RANGE);
            onDateRangeChange(today, end, numberOfDays);
            return;
        }
        const aux = differenceInDays(startOfDay(end), startOfDay(start));
        onDateRangeChange(start, end, aux, holiday.name);
    };

    const handleOptionChange = (e) => {
        const index = +e.target.value;
        if (index === -1) {
            const today = new Date();
            const end = addDays(today, Configuration.DEFAULT_DAYS_RANGE);
            onDateRangeChange(today, end, Configuration.DEFAULT_DAYS_RANGE);
            setSelectedYearIndex(getYear(new Date()));
        }
        setSelectedOptionIndex(index);
    };

    const handleMonthChange = (e) => {
        const index = +e.target.value;
        setSelectedMonthIndex(index);
        const start = new Date(selectedYearIndex, index, 1);
        const end = endOfMonth(start);
        const aux = differenceInDays(startOfDay(end), startOfDay(start));
        onDateRangeChange(start, end, aux, undefined, index.toString());
    };

    const isHolidayDisabled = _.isEmpty(holidaysForSelectedYear);

    const mappedOptions = [
        { value: -1, display: 'None', tooltip: 'Default Option' },
        { value: 0, display: 'Holiday', tooltip: 'Holiday' },
        { value: 1, display: 'Month', tooltip: 'Month' },
    ];

    const mappedMonths = [
        { value: 0, display: 'January', tooltip: 'January' },
        { value: 1, display: 'February', tooltip: 'February' },
        { value: 2, display: 'March', tooltip: 'March' },
        { value: 3, display: 'April', tooltip: 'April' },
        { value: 4, display: 'May', tooltip: 'May' },
        { value: 5, display: 'June', tooltip: 'June' },
        { value: 6, display: 'July', tooltip: 'July' },
        { value: 7, display: 'August', tooltip: 'August' },
        { value: 8, display: 'September', tooltip: 'September' },
        { value: 9, display: 'October', tooltip: 'October' },
        { value: 10, display: 'November', tooltip: 'November' },
        { value: 11, display: 'December', tooltip: 'December' },
    ];

    const getComponent = (index) => {
        switch (index) {
            case 0:
                return (
                    <Select
                        value={selectedHolidayIndex}
                        options={holidaysForSelectedYear.map((holiday, i) => ({
                            value: i,
                            display: _.capitalize(holiday.name),
                            tooltip: `${holiday.location}, (${holiday.first_night} | ${holiday.last_night})`,
                        }))}
                        onChange={handleHolidayChange}
                        disabled={isHolidayDisabled}
                    />
                );
            case 1:
                return <Select value={selectedMonthIndex} options={mappedMonths} onChange={handleMonthChange} />;
            default:
                return <Select value={0} options={[{ value: -1, display: '', tooltip: '' }]} onChange={() => {}} disabled={true} />;
        }
    };

    return (
        <div className="input-group date-period">
            <Select label="Date Period" value={selectedYearIndex} onChange={handleYearChange} options={UiUtils.getHolidayYearOptions()} />

            <Select value={selectedOptionIndex} options={mappedOptions} onChange={handleOptionChange} />

            {getComponent(selectedOptionIndex)}
        </div>
    );
};
