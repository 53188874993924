import { ChartData, CohortRateTableValues, DowOptions, StatusOptions, UnitRateTableValues } from '../types';
import { isEqual, isWithinInterval, startOfDay } from 'date-fns';
import * as _ from 'lodash';
import { calculateAdjustedRate, parseDate } from '@common/utils';
import { CohortModelPredictionDTO, DoW, RateData, RateDataDTO, RateDescription, RatePreviewData } from '@common/types';
import { UiUtils } from './UiUtils';
import { JsonApiModelResponse } from '@vacasa/json-api-common';

export class RateUtils {
    public static transformJsonApiDataToDateMap = (response: JsonApiModelResponse<RateDataDTO>): RateData => {
        const rates: RateData = {};
        if (_.isArray(response.data)) {
            return _.reduce(
                response.data,
                (acc, curr) => {
                    const { attributes } = curr;
                    rates[attributes.date] = attributes.description;
                    return rates;
                },
                rates
            );
        }
        console.log('rates', rates);
        return rates;
    };

    public static readonly BASE_RATES_LABELS = {
        predicted_rate: 'Predicted Rate',
        adjusted_rate: 'Adjusted Rate',
        advertised_rate: 'Advertised Rate',
    };

    public static readonly RATES_LABELS = {
        ...RateUtils.BASE_RATES_LABELS,
        sold_past_inventory: 'Sold Past Inv.',
        sold_future_inventory: 'Sold Future Inv.',
        future_owner_holds: 'Future Owner Holds',
        oz_min_rate: 'Oz Min Rate',
        advertised_min_rate: 'Advertised Min Rate',
        unit_min_rate: 'Unit Min Rate',
        seasonal_str_min_rate_owner: 'Seasonal STR Min Rate Owner',
        seasonal_str_min_rate_vacasa: 'Seasonal STR Min Rate Vacasa',
        seasonal_str_min_rate_hoa: 'Seasonal STR Min Rate HOA',
        max_rate: 'Max. Rate',
        avg_rate: 'Average Rate',
        preview_analyst_adjusted_rate: 'Preview Analyst Adjusted Rate',
        preview_advertised_rate: 'Preview Advertised Rate',
    };

    public static readonly NEUMANN_LABELS = {
        ...RateUtils.RATES_LABELS,
        preview_predicted_rate: 'Preview Predicted Rate',
    };

    private static readonly BASE_COLORS = {
        allan_21: '#d62e4f',
        allan_30: '#d62e4f',
        allan_40: '#d62e4f',
        bacon: '#d62e4f',

        [RateUtils.BASE_RATES_LABELS.predicted_rate]: '#1e78b4',
        [RateUtils.BASE_RATES_LABELS.adjusted_rate]: '#adc7e8',
        [RateUtils.BASE_RATES_LABELS.advertised_rate]: '#ff7f10',
    };

    private static readonly COMPARE_BASE_COLORS = {
        [RateUtils.BASE_RATES_LABELS.predicted_rate]: '#1e78b4',
        [RateUtils.BASE_RATES_LABELS.adjusted_rate]: '#adc7e8',
        'Alan 1.0': '#adc7e8',
        'Alan 2.1': '#631c69',
        'Alan 2.0': '#5927d6',
        'Custom 1.0': '#b2d872',
        'International 1.0': '#00899e',
        'Alan 3.0': '#d18ab2',
        'International 1.1': '#ff9479',
        'Neumann 1.0': '#ff7f00',
        'Neumann 1.1': '#ab570c',
        'Northstar 1.0': '#26b44f',
    };

    public static transformToUnitChartData = (rateData: RateData, previewData?: RatePreviewData): ChartData => {
        const colors = {
            ...RateUtils.BASE_COLORS,
            [RateUtils.RATES_LABELS.preview_analyst_adjusted_rate]: '#6f2f69',
            [RateUtils.RATES_LABELS.preview_advertised_rate]: '#2aa02c',
            [RateUtils.RATES_LABELS.sold_future_inventory]: '#000000',
            [RateUtils.RATES_LABELS.sold_past_inventory]: '#878D92',
            [RateUtils.RATES_LABELS.future_owner_holds]: '#BE094A',
            [RateUtils.RATES_LABELS.oz_min_rate]: '#DF6747',
            [RateUtils.RATES_LABELS.advertised_min_rate]: '#aa1212',
            [RateUtils.RATES_LABELS.seasonal_str_min_rate_hoa]: '#DDAD49',
            [RateUtils.RATES_LABELS.seasonal_str_min_rate_vacasa]: '#3399A6',
            [RateUtils.RATES_LABELS.seasonal_str_min_rate_owner]: '#003349',
            [RateUtils.RATES_LABELS.unit_min_rate]: '#1561e8',
            [RateUtils.RATES_LABELS.max_rate]: '#D1D0DE',
            [RateUtils.RATES_LABELS.avg_rate]: '#636D97',
        };

        const strokeWidth = {
            [RateUtils.RATES_LABELS.future_owner_holds]: 8,
            [RateUtils.RATES_LABELS.oz_min_rate]: 3,
            [RateUtils.RATES_LABELS.advertised_min_rate]: 3,
            [RateUtils.RATES_LABELS.unit_min_rate]: 3,
            [RateUtils.RATES_LABELS.seasonal_str_min_rate_owner]: 3,
            [RateUtils.RATES_LABELS.seasonal_str_min_rate_hoa]: 3,
            [RateUtils.RATES_LABELS.seasonal_str_min_rate_vacasa]: 3,
            [RateUtils.RATES_LABELS.max_rate]: 3,
            [RateUtils.RATES_LABELS.avg_rate]: 3,
            [RateUtils.RATES_LABELS.sold_future_inventory]: 3,
            [RateUtils.RATES_LABELS.sold_past_inventory]: 3,
        };

        const opacity = {
            [RateUtils.RATES_LABELS.future_owner_holds]: 0.6,
        };
        // Empty point object for missing rate-preview dates
        const emptyDatePointsPreview = {
            preview_analyst_adjusted_rate: null,
            preview_advertised_rate: null,
            preview_predicted_rate: null,
        };
        // Merge rates data with previewData if the last is already loaded
        const data = _.reduce(
            rateData,
            (acc, value, key) => {
                const date = parseDate(key);

                let points = {
                    [RateUtils.RATES_LABELS.predicted_rate]: value.rates.predicted_rate,
                    [RateUtils.RATES_LABELS.adjusted_rate]: value.rates.adjusted_rate,
                    [RateUtils.RATES_LABELS.advertised_rate]: value.rates.advertised_rate,
                    [RateUtils.RATES_LABELS.sold_past_inventory]: value.unit_date_status?.sold_past_inventory || null,
                    [RateUtils.RATES_LABELS.sold_future_inventory]: value.unit_date_status?.sold_future_inventory || null,
                    [RateUtils.RATES_LABELS.future_owner_holds]:
                        value.unit_date_status?.status === 'Owner Hold' ? value.rates.advertised_rate : null,
                    [RateUtils.RATES_LABELS.oz_min_rate]: value.min_rate_override,
                    [RateUtils.RATES_LABELS.advertised_min_rate]: value.min_rate,
                    [RateUtils.RATES_LABELS.unit_min_rate]: value.unit_minrate,
                    [RateUtils.RATES_LABELS.seasonal_str_min_rate_vacasa]: value.seasonal_str_min_rate_vacasa,
                    [RateUtils.RATES_LABELS.seasonal_str_min_rate_hoa]: value.seasonal_str_min_rate_hoa,
                    [RateUtils.RATES_LABELS.seasonal_str_min_rate_owner]: value.seasonal_str_min_rate_owner,
                    [RateUtils.RATES_LABELS.max_rate]: value.max_rate,
                    [RateUtils.RATES_LABELS.avg_rate]: value.avg_rate,
                };

                if (!_.isEmpty(previewData)) {
                    const datePreviewData = previewData[key];
                    const prev = datePreviewData ? datePreviewData : emptyDatePointsPreview;
                    points = {
                        ...points,
                        [RateUtils.RATES_LABELS.preview_analyst_adjusted_rate]: prev.preview_analyst_adjusted_rate,
                        [RateUtils.RATES_LABELS.preview_advertised_rate]: prev.preview_advertised_rate,
                        [RateUtils.NEUMANN_LABELS.preview_predicted_rate]: prev.preview_predicted_rate,
                    };
                }

                acc.push({
                    date: date,
                    points,
                    colors,
                    strokeWidth,
                    opacity,
                });
                return acc;
            },
            [] as ChartData
        );

        return _.sortBy(data, [(o) => o.date]);
    };

    public static transformToCohortChartData = (rateData: RateData): ChartData => {
        const colors = {
            ...RateUtils.BASE_COLORS,
        };

        const data = _.reduce(
            rateData,
            (acc, value, key) => {
                const date = parseDate(key);

                const points = {
                    [RateUtils.BASE_RATES_LABELS.predicted_rate]: value.rates.predicted_rate,
                    [RateUtils.BASE_RATES_LABELS.adjusted_rate]: value.rates.adjusted_rate,
                    [RateUtils.BASE_RATES_LABELS.advertised_rate]: value.rates.advertised_rate,
                };

                acc.push({
                    date: date,
                    points,
                    colors,
                });
                return acc;
            },
            [] as ChartData
        );

        return _.sortBy(data, [(o) => o.date]);
    };

    public static transformToAdjustedRateData = (rates): { [key: string]: number }[] => {
        return _.reduce(
            rates,
            (acc, value, key) => {
                acc.push({
                    [key]: value.rates.adjusted_rate,
                });
                return acc;
            },
            [] as { [key: string]: number }[]
        );
    };

    public static transformToCompareCohortChartData = (
        predictedData: { [key: string]: CohortModelPredictionDTO },
        rateData: CohortModelPredictionDTO,
        adjustedRateData: { [key: string]: number }[]
    ): ChartData => {
        const colors = {
            ...RateUtils.COMPARE_BASE_COLORS,
        };

        const getPredictedByDate = (predicted: CohortModelPredictionDTO, date: string): number => {
            let predictedRate: number = null;
            const selectedDate = predicted.predictions.find((predict) => predict.date === date);
            if (selectedDate) {
                predictedRate = Math.round(selectedDate.predicted_rate * 100) / 100;
            }
            return predictedRate;
        };

        const getAdjustedRateByDate = (date: string): number => {
            let adjustedRate: number = 0;
            adjustedRateData.forEach((adjusted) => {
                if (!_.isNil(adjusted[date])) {
                    adjustedRate = adjusted[date];
                }
            });
            return adjustedRate;
        };

        const data: ChartData = _.map(rateData.predictions, (r) => {
            const date = parseDate(r.date);
            let points = {
                [RateUtils.BASE_RATES_LABELS.predicted_rate]: Math.round(r.predicted_rate * 100) / 100,
                [RateUtils.BASE_RATES_LABELS.adjusted_rate]: getAdjustedRateByDate(r.date),
            };
            const keys = _.keys(predictedData);
            keys.forEach((model) => {
                points[model] = getPredictedByDate(predictedData[model], r.date);
            });
            return { date, points, colors };
        });

        return _.sortBy(data, [(o) => o.date]);
    };

    public static transformToUnitRateData = (column: string, tableData: UnitRateTableValues): RateDescription => {
        const analystBaseOverride = tableData.analystBaseOverride;
        const analystFactor = tableData.analystFactor;
        const adjustedRate = calculateAdjustedRate(analystFactor, tableData.predictedRate, analystBaseOverride);
        return {
            dow: tableData.dow as DoW,
            min_rate_override: tableData.minRate,
            max_rate_override: tableData.maxRate,
            minstay_override: tableData.minStayOverride,
            rates: {
                analyst_factor: analystFactor,
                base_rate_override: analystBaseOverride,
                adjusted_rate: adjustedRate,
                predicted_rate: tableData.predictedRate,
                advertised_rate: tableData.advertisedRate,
            },
        };
    };

    public static transformToCohortRateData = (column: string, tableData: CohortRateTableValues): RateDescription => {
        let adjustedRate = tableData.adjustedRate;
        let analystFactor = tableData.analystFactor;
        let previewAdjusted: number;
        if (column === 'analystFactor') {
            previewAdjusted = +(adjustedRate * (analystFactor || 1)).toFixed(2);
        }

        return {
            dow: tableData.dow as DoW,
            rates: {
                analyst_factor: tableData.analystFactor,
                predicted_rate: tableData.predictedRate,
                adjusted_rate: adjustedRate,
                preview_adjusted: previewAdjusted,
                advertised_rate: tableData.advertisedRate,
            },
            occupancy: tableData.occupancy,
        };
    };

    public static transformToRateTableData = (rateData: RateData, cohortName?: string): any[] => {
        const data = _.reduce(
            rateData,
            (acc, value, key) => {
                const maxPropertyName = _.maxBy(
                    ['seasonal_str_min_rate_hoa', 'seasonal_str_min_rate_vacasa', 'seasonal_str_min_rate_owner'],
                    (propertyName) => value[propertyName]
                );
                acc.push({
                    key,
                    date: key,
                    dow: value.dow,
                    holidays: value.holidays || [],
                    minRate: value.min_rate_override,
                    maxRate: value.max_rate_override,
                    minStayOverride: value.minstay_override,
                    analystFactor: value.rates.analyst_factor,
                    predictedRate: value.rates.predicted_rate,
                    adjustedRate: value.rates.adjusted_rate,
                    previewAdjusted: value.rates.preview_adjusted ?? value.rates.adjusted_rate,
                    advertisedRate: value.rates.advertised_rate,
                    analystBaseOverride: value.rates.base_rate_override,
                    occupancy: value.occupancy?.current_occupancy || 0,
                    unitStatus: value.unit_date_status?.status || 'N/A',
                    reservationId: value.unit_date_status?.reservation_id,
                    factor_min: value.factor?.min,
                    factor_max: value.factor?.max,
                    factor_avg: value.factor?.avg,
                    factor_ratio: value.factor?.ratio,
                    cohortName: cohortName,
                    cohortFactor: value.cohort_factor,
                    soldPastInv: value.unit_date_status?.sold_past_inventory,
                    seasonalMinRate: value[maxPropertyName] ?? null,
                    seasonalMinRateHolder: maxPropertyName,
                });
                return acc;
            },
            []
        );
        return _.sortBy(data, [(o) => o.date]);
    };

    public static isBetweenDates = (date: Date, startDate: Date, endDate: Date) => {
        return (
            isEqual(startOfDay(date), startOfDay(startDate)) ||
            isWithinInterval(date, { start: startDate, end: endDate }) ||
            isEqual(startOfDay(date), startOfDay(endDate))
        );
    };

    public static applyDowFilter = (rates: RateData, dowFilterOption: DowOptions): RateData => {
        const dowFilter = UiUtils.getDoWFilterFromOption(dowFilterOption);
        return _.reduce(
            rates,
            (acc, value, date) => {
                if (dowFilter.includes(value.dow)) {
                    acc[date] = value;
                }

                return acc;
            },
            {} as RateData
        );
    };

    public static applyStatusFilter = (rates: RateData, statusFilterOption: StatusOptions): RateData => {
        const statusFilter = UiUtils.getStatusFilterFromOption(statusFilterOption);
        return _.reduce(
            rates,
            (acc, value, date) => {
                if (statusFilter.includes(value?.unit_date_status?.status || 'N/A')) {
                    acc[date] = value;
                }

                return acc;
            },
            {} as RateData
        );
    };

    public static getRatesBetweenDates = (rates: RateData, start: Date, end: Date): RateData => {
        const filtered: RateData = {};

        return _.reduce(
            rates,
            (acc, value, key) => {
                const date = parseDate(key);
                if (RateUtils.isBetweenDates(date, start, end)) {
                    acc[key] = value;
                }
                return acc;
            },
            filtered
        );
    };

    public static mergeChanges(rates: RateData, changes: RateData): RateData {
        const result: RateData = Object.create(null);
        const dates = _.keys(rates);

        for (const date of dates) {
            const change = changes[date] ?? {};
            const value = rates[date];
            result[date] = { ...value, ...change }; // TODO: why doesn't lodash work here?
        }

        return result;
    }

    public static calculateRatesValuesCustomCohort(cohortRates): JsonApiModelResponse<RateDataDTO> {
        const calculatedRates = cohortRates.map((cr) => {
            const { date, description } = cr;
            const { dow, rates, adjusted_rates, factors, predicted_rates, occupancy, holidays } = description;
            return {
                attributes: {
                    date: date,
                    description: {
                        dow,
                        rates: {
                            adjusted_rate: Math.round(_.mean(adjusted_rates) * 100) / 100,
                            advertised_rate: Math.round(_.mean(rates) * 100) / 100,
                            analyst_factor: Math.round(_.mean(factors) * 100) / 100,
                            predicted_rate: Math.round(_.mean(predicted_rates) * 100) / 100,
                        },
                        factor: {
                            min: Math.round(_.min(factors as number[]) * 100) / 100,
                            max: Math.round(_.max(factors as number[]) * 100) / 100,
                            avg: Math.round(_.mean(factors) * 100) / 100,
                            ratio: _.sum(_.map(factors ?? [], (f) => (Math.round(f * 100) / 100 !== 1 ? 1 : 0))) / rates.length,
                        },
                        occupancy,
                        holidays,
                    },
                },
                type: cr.type,
            };
        });
        return {
            data: calculatedRates,
        };
    }
}
