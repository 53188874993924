import React, { useEffect, useState } from 'react';
import { Button, Icon, withTooltip, Tooltip } from '@vacasa/react-components-lib';
import * as _ from 'lodash';

import './ColumnConfiguration.scss';

import { DraggableItem, DraggableList } from './DraggableList/DraggableList';

export type ColumnDescription = { key: string; label: string };

interface ColumnConfigurationProps {
    visibleColumns: ColumnDescription[];
    hiddenColumns: ColumnDescription[];
    onApply: (added: ColumnDescription[], hidden: ColumnDescription[]) => void;
    onClose: () => void;
    onResetDefault: () => { visible: ColumnDescription[]; hidden: ColumnDescription[] };
}

export const ColumnConfiguration: React.FC<ColumnConfigurationProps> = (props) => {
    const { visibleColumns, hiddenColumns, onApply, onClose, onResetDefault } = props;

    const [visible, setVisible] = useState<ColumnDescription[]>(visibleColumns);
    const [hidden, setHidden] = useState<ColumnDescription[]>(hiddenColumns);

    useEffect(() => {
        setVisible(visibleColumns);
    }, [visibleColumns]);

    useEffect(() => {
        setHidden(hiddenColumns);
    }, [hiddenColumns]);

    const handleVisibleOrderChange = (order: string[]) => {
        const orderedAdded = _.map(order, (key) => visible.find((column) => column.key === key));
        setVisible(orderedAdded);
    };

    const handleHiddenOrderChange = (order: string[]) => {
        const orderedRemoved = _.map(order, (key) => hidden.find((column) => column.key === key));
        setHidden(orderedRemoved);
    };

    const handleRemoveFromAdded = (column: ColumnDescription, index: number) => {
        const updatedVisible = [...visible];
        updatedVisible.splice(index, 1);
        setVisible(updatedVisible);

        const updatedRemoved = [...hidden, column];
        setHidden(updatedRemoved);
    };

    const handleAddFromHidden = (column: ColumnDescription, index: number) => {
        const updatedHidden = [...hidden];
        updatedHidden.splice(index, 1);
        setHidden(updatedHidden);
        const updatedVisible = [...visible, column];
        setVisible(updatedVisible);
    };

    const handleApply = () => {
        onApply(visible, hidden);
    };

    const getColumnItem = (label: string, icon: JSX.Element) => {
        return (
            <div className="column-configuration-item">
                <Icon.Server className="drag-column-icon" />
                <span>{label}</span>
                {icon}
            </div>
        );
    };

    const handleResetDefault = () => {
        const { visible, hidden } = onResetDefault();
        setVisible(visible);
        setHidden(hidden);
    };

    const addedItems: DraggableItem[] = _.map(visible, (column, i) => ({
        key: column.key,
        content: getColumnItem(
            column.label,
            withTooltip(
                'Hide Column',
                <div>
                    <Icon.XCircleInverse className="remove-column-icon pointer" onClick={() => handleRemoveFromAdded(column, i)} />
                </div>,
                { placement: 'bottom' }
            )
        ),
    }));

    const hiddenItems: DraggableItem[] = _.map(hidden, (column, i) => ({
        key: column.key,
        content: getColumnItem(
            column.label,
            withTooltip(
                'Show Column',
                <div>
                    <Icon.PlusCircle className="add-column-icon pointer" onClick={() => handleAddFromHidden(column, i)} />
                </div>,
                { placement: 'bottom' }
            )
        ),
    }));

    return (
        <div className="column-configuration">
            <div className="column-configuration-top-bar">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>{'Column Configuration'}</span>
            </div>

            <div className="column-configuration-column-list">
                <Tooltip message="Reset to default columns" placement="bottom">
                    <div className="column-configuration-reset-default-icon">
                        <Icon.RefreshCW onClick={handleResetDefault} />
                    </div>
                </Tooltip>
                <span className="column-configuration-column-list-title">Displayed Columns</span>
                <DraggableList items={addedItems} onOrderChange={handleVisibleOrderChange} />
            </div>

            <div className="separator" />

            <div className="column-configuration-column-list">
                <span className="column-configuration-column-list-title">Hidden Columns</span>
                <DraggableList items={hiddenItems} onOrderChange={handleHiddenOrderChange} />
            </div>

            <div className="column-configuration-save-action">
                <Button variant="secondary" onClick={handleApply}>
                    Apply
                </Button>
            </div>
        </div>
    );
};
