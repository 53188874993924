import React from 'react';
import './ListGroup.scss';
import { SelectOption } from '../../types';
import { Icon } from '@vacasa/react-components-lib';

export interface ListGroupProps {
    options: SelectOption[];
    onChange: (selected: SelectOption) => void;
}

export const ListGroup: React.FC<ListGroupProps> = (props) => {
    const { options, onChange } = props;

    return (
        <div className="list-options">
            {options.map((option) => {
                return (
                    <div key={option.display} className="d-flex menu-selector-item" onClick={() => onChange(option)}>
                        <div className="text-list">
                            <span> {option.display} </span>
                        </div>
                        <div className="image-list">
                            <Icon.ChevronRight className={'image-list'} width={16} height={16} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
