import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Icon } from '@vacasa/react-components-lib';
import './HeaderLabel.scss';

interface HeaderLabelProps {
    label: string;
    field: string;
    order: string;
    onOrderChange: (field: string, order: string) => void;
    tooltipText?: string;
}

export const HeaderLabel: React.FC<HeaderLabelProps> = (props) => {
    const { label, order, field, onOrderChange, tooltipText } = props;

    const handleSort = (event) => {
        event.preventDefault();
        onOrderChange(field, order);
    };

    if (tooltipText) {
        return (
            <Tooltip title={tooltipText} placement={'top'}>
                <div className={'sortable-header pointer'}>{label}</div>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={order === 'asc' ? `Sort ${label} descending` : `Sort ${label} ascending`} arrow={true} placement={'top'}>
                <div className={'sortable-header pointer'} onClick={handleSort}>
                    {order === 'desc' ? (
                        <Icon.ArrowDown className={'sort-icon'} height={12} width={12} />
                    ) : (
                        <Icon.ArrowUp className={'sort-icon ' + (!order ? 'hidden' : null)} height={12} width={12} />
                    )}
                    {label}
                </div>
            </Tooltip>
        );
    }
};
