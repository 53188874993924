import { createAsyncThunk } from '@reduxjs/toolkit';
import { RateService } from '../../services/RateService';
import { isEmpty } from 'lodash';

export const fetchHistory = createAsyncThunk(
    'history/fetchHistory',
    async (
        params: { id: number | string; process_execution_id?: string; modifiedDate?: string; modifiedField?: string },
        { rejectWithValue }
    ) => {
        try {
            console.log('fetching history', params);
            return await RateService.getHistory(params);
        } catch (e) {
            return rejectWithValue(RateService.makeError('fetchHistory', e));
        }
    }
);
