import React, { useState } from 'react';
import './InfoBox.scss';
import { Tooltip } from '@material-ui/core';
import { Icon } from '@vacasa/react-components-lib';
import { IconDoor, IconShower } from '../../assets/images';
import { Modal } from '../Modal/Modal';

type Object = {
    label: string;
    text?: string;
};

type Content = {
    label: string;
    headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    text?: string;
    icon?: string;
    object?: Object[];
};

export type InfoBoxProps = {
    id: string;
    title: string;
    footer: { subtitle: string; url?: string; onClick?: () => void };
    direction: 'vertical' | 'horizontal';
    type?: string;
    tip: string;
    content: Content[] | JSX.Element;
    note?: string;
    flag_class?: string;
};

export const InfoBox: React.FC<InfoBoxProps> = (props) => {
    const { title, footer, content, tip, direction, type, note, id, flag_class } = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    let keyCounter = 0;

    return (
        <div className={`info-box ${flag_class}`}>
            <Modal title={`${title} Details`} show={showModal} onClose={() => setShowModal(!showModal)}>
                <div>
                    <span className="info-details-modal">{`${title}: ${content[0]?.label}`}</span>
                    <span className="info-details-modal">{`${title} Notes:`}</span>
                    {note ? <span className="info-note-modal">{note}</span> : <span className="info-note-modal">No notes</span>}
                </div>
            </Modal>
            {flag_class === 'new' ? (
                <div className={'flag'}>
                    <div className={'flag-content'}>
                        <Icon.Flag name="flag" width={20} height={20} />
                        New
                    </div>
                    <div className={'flag-bottom'}></div>
                </div>
            ) : null}
            <div className="info-box-header">
                <span className="info-box-label">{title}</span>
                <Tooltip title={tip} arrow={true}>
                    <span className="info-box-icon">
                        <Icon.AlertCircle className="pointer" name="info" width={20} height={20} />
                    </span>
                </Tooltip>
            </div>

            <div className={`info-box-content ${direction === 'vertical' ? 'info-box-content-vertical' : ''}`}>
                {Array.isArray(content)
                    ? content.map((child) => {
                          return getType(type, child, ++keyCounter);
                      })
                    : content}
            </div>
            {id === 'min_minstay' || id === 'min_rate' ? (
                <button className="button-modal-note" onClick={() => setShowModal(!showModal)}>
                    View Notes {<Icon.ZoomIn name="zoomIn" className="icon-button-modal" width={20} height={20} />}
                </button>
            ) : (
                ''
            )}
            <div className="info-box-subtitle">
                {footer.url ? (
                    <a href={footer.url} target="_blank" rel="noreferrer">
                        {footer.subtitle}
                    </a>
                ) : (
                    <span className={`${footer.onClick ? 'as-link' : ''}`} onClick={footer.onClick ? footer.onClick : () => null}>
                        {footer.subtitle}
                    </span>
                )}
            </div>
        </div>
    );
};

function getObject(child: Content, input: Object) {
    return (
        <div className={`${child.object?.length === 1 ? 'bedroom' : 'bathroom'}`}>
            <div className="text-for-number">{input.label}</div>
            <div className="info-box-subtitle">{input.text}</div>
        </div>
    );
}

function getType(type: string, child: Content, keyCounter: number) {
    if (type === 'Rooms') {
        return (
            <div key={keyCounter} className="d-flex rooms">
                <div className={`${child.object?.length === 1 ? 'room-label' : 'rooms-label'}`}>
                    {child.label === 'Bathrooms:' ? (
                        <IconShower className="image-room" width={22} height={22} />
                    ) : (
                        <IconDoor className="image-room" width={22} height={22} />
                    )}
                    <span className="text-image"> {child.label} </span>
                </div>
                {child.object?.map((input) => {
                    return getObject(child, input);
                })}
            </div>
        );
    }
    const Heading = ({ ...props }) => React.createElement(child.headingLevel, props);
    return (
        <div key={keyCounter}>
            <Heading className={`${child.label?.length > 10 ? 'large-boxes-text-size' : ''}`}>{child.label}</Heading>
            {child.text && <span>{child.text}</span>}
        </div>
    );
}
