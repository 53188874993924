import { CustomCohortUnitTableValues } from '../types';
import * as _ from 'lodash';
import { CohortUnitDTO, CustomCohortUnitDTO } from '@common/types';

export class CustomCohortUtils {
    static transformToTableData(units: CustomCohortUnitDTO[], type: string = ''): CustomCohortUnitTableValues[] {
        return _.map(
            units,
            (unit) =>
                ({
                    key: `${unit.unit_id}`,
                    id: unit.unit_id,
                    code: unit.unit_code,
                    state: unit.state_name_short,
                    city: unit.city_name,
                    housing_type: unit.housing_type,
                    bedroom_count: unit.bedrooms,
                    association_type: unit.association_type,
                    manual: type,
                } as CustomCohortUnitTableValues)
        );
    }

    static transformFromCohortUnitsToTableData(cohortUnits: CohortUnitDTO[], type: string = ''): CustomCohortUnitTableValues[] {
        if (_.isEmpty(cohortUnits)) {
            return [];
        }
        return _.map(
            cohortUnits,
            (cohortUnit) =>
                ({
                    key: `${cohortUnit.unit_id}`,
                    id: cohortUnit.unit_id,
                    code: cohortUnit.unit?.unit_code,
                    state: cohortUnit.unit?.state_name_short,
                    city: cohortUnit.unit?.city_name,
                    housing_type: cohortUnit.unit?.housing_type,
                    bedroom_count: cohortUnit.unit?.bedrooms,
                    association_type: cohortUnit.association_type,
                    manual: type,
                } as CustomCohortUnitTableValues)
        );
    }

    static transformFromTableDataToAddedRemoved(tableDataValues: CustomCohortUnitTableValues[]): CohortUnitDTO[] {
        if (_.isEmpty(tableDataValues)) {
            return [];
        }
        return _.map(
            tableDataValues,
            (value) =>
                ({
                    unit_id: value.id,
                    association_type: value.association_type,
                } as CohortUnitDTO)
        );
    }
}
