import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { HistogramData } from '../../types';
import * as _ from 'lodash';
import { NoDataAlert } from '..';
import { ChartUtils } from '../../utils';
import './CohortFactorBarChart.scss';

interface BarChartProps {
    data: HistogramData;
}

export const CohortFactorBarChart: React.FC<BarChartProps> = (props) => {
    const { data } = props;
    const [axisNames, setAxisNames] = useState<string[]>([]);
    const [barState, setBarState] = useState<{ [model: string]: { color: string; hidden: boolean } }>({});

    useEffect(() => {
        const firstElement = _.head(data);
        const axis = firstElement ? firstElement.values : [];
        const allColors = firstElement && firstElement.colors ? firstElement.colors : {};

        setAxisNames(_.keys(axis));
        setBarState(
            _.reduce(
                _.keys(axis),
                (acc, curr) => {
                    return {
                        ...acc,
                        [curr]: {
                            color: allColors[curr] || ChartUtils.generateRandomHexColor(),
                            hidden: false,
                        },
                    };
                },
                {} as { [model: string]: { color: string; hidden: boolean } }
            )
        );
    }, []);

    if (_.isEmpty(data)) {
        return <NoDataAlert />;
    }

    const depuredData = _.reduce(
        data,
        (acc, curr) => {
            const value = { factor: curr.factor, ...curr.values };
            acc.push(value);
            return acc;
        },
        [] as { [key: string]: number }[]
    );

    let minVal = _.first(_.sortBy(data, (element) => element.factor));
    let maxVal = _.last(_.sortBy(data, (element) => element.factor));

    let min = minVal.factor / 2 < 0 ? 0 : +(minVal.factor - 0.05).toFixed(2);
    let max = maxVal.factor * 2 > 10 ? 10 : +(maxVal.factor + 0.05).toFixed(2);

    return (
        <div className={'cohort-factor-bar-chart'}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={depuredData} stackOffset={'expand'} barSize={15}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="factor" type="number" domain={[min, max]} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {axisNames.map((name, i) => {
                        return <Bar dataKey={name} fill={barState[name].color} key={i} />;
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
