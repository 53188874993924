import React, {useState} from "react";
import {Loading, Modal, VirtualizedTable} from "..";
import {DataSourceBuilder} from "../VirtualizedTable/DataSourceBuilder";
import {CohortTableValues, Message} from "../../types";
import {AlertMessage, Button, Icon, Tooltip} from "@vacasa/react-components-lib";
import {RateService} from "../../services/RateService";
import {CohortTableFilters, CohortUtils, UiUtils, VersionsByModel} from "../../utils";
import {upsertCohorts} from "../../store";
import {useDispatch} from "react-redux";
import {history} from "../../App";
import {AppRoutes} from "../../Routes";
import {Link} from "react-router-dom";
import "./CohortsTable.scss";
import {FilterField, FilterOption, OrderField} from "../../types/VirtualizedTable";
import * as _ from "lodash";
import {BulkSidePanel} from "../BulkSidePanel/BulkSidePanel";
import {CohortCreationPanel} from "../CohortCreationPanel/CohortCreationPanel";
import {CustomCohortCriteriaDTO, detailsCohortAllowedScopes} from "@common/types";
import {Chip} from "@material-ui/core";

export const CohortsTable: React.FC = () => {
    const dispatch = useDispatch();
    const [modelName, setModelName] = useState("ALL");
    const [cohortFilters, setCohortFilters] = useState<FilterOption[]>(CohortTableFilters);
    const [isShowingSave, setIsShowingSave] = useState(false);
    const [isShowingCreateOptions, setIsShowingCreateOptions] = useState<boolean>(false);
    const [isShowingCreateModelCohort, setIsShowingCreateModelCohort] = useState(false);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);
    const [isVisibleCohortModal, setIsVisibleCohortModal] = useState(false);
    const [isVisibleUnitModal, setIsVisibleUnitModal] = useState(false);
    const [cohort, setCohort] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const havePermission = UiUtils.validateScope(detailsCohortAllowedScopes);
    const handleCreateCustomCohort = () => {
        history.push(AppRoutes.CREATE_CUSTOM_COHORT);
    };
    const renderConfirmationModal = (cohortName: string, type: string, title: string, body: string) => {
        return (
            <Modal
                title={title}
                show={isVisibleCohortModal || isVisibleUnitModal}
                onClose={() => (type === "cohort" ? setIsVisibleCohortModal(!isVisibleCohortModal) : setIsVisibleUnitModal(!isVisibleUnitModal))}
            >
                <div className="confirmation-modal">
                    <div className="confirmation-content">
                        <Icon.AlertTriangle className="modal-alert-icon" height={50} width={50} />
                        <div className="text-style">
                            <span>{body}</span>
                            <strong>: {cohortName} ?</strong>
                        </div>
                    </div>

                    <div className="button-group">
                        <Button
                            variant="secondary"
                            onClick={() =>
                                type === "cohort" ? handleDeletingCustomCohort(cohortName) : handleDeletingAllCustomCohortUnits(cohortName)
                            }
                        >
                            Delete
                        </Button>
                        <Button
                            customClass="button-separation"
                            variant="info"
                            onClick={() => (type === "cohort" ? setIsVisibleCohortModal(false) : setIsVisibleUnitModal(false))}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    };
    const handleDeletingCustomCohort = async (cohort_name: string) => {
        try {
            setIsVisibleCohortModal(false);
            setUiAlert(UiUtils.getCohortDeleteProcessingMessage);
            setIsLoading(true);
            await RateService.deleteCustomCohort(cohort_name);
            setUiAlert(UiUtils.getCohortDeleteSuccessMessage);
        } catch (e) {
            console.error("Error Deleting Custom Cohort ", e);
            setUiAlert(UiUtils.getCohortDeleteErrorMessage(e));
        } finally {
            setIsLoading(false);
        }
    };
    const handleDeletingAllCustomCohortUnits = async (cohortName: string) => {
        try {
            const emptyCriteria: CustomCohortCriteriaDTO = {
                locations: [],
                destinations: [],
                housing_types: [],
                amenities: [],
                bedrooms: {min: undefined, max: undefined},
                bathrooms: {min: undefined, max: undefined},
                minMinStay: {min: undefined, max: undefined},
            };
            setIsVisibleUnitModal(false);
            setIsLoading(true);
            const cohort = await RateService.getCustomCohortWithActiveUnits(cohortName);
            const cohortCriteria = _.merge(cohort.grouping_criteria, emptyCriteria);
            const params = UiUtils.getFilterParamsForCriteria(cohortCriteria);
            const units = await RateService.getUnitsByCriteria(params);
            const unitsIds = _.map(units, (unit) => unit.unit_id);
            const context = {description: "remove all units", changes_reason: undefined, days_before_expire: undefined};
            const changes = {
                name: cohortName,
                grouping_criteria: cohortCriteria,
                added_units: [],
                removed_units: unitsIds,
            };
            const cohortData = {context, ...changes};
            await RateService.upsertCustomCohort(cohortName, cohortData);
            setUiAlert(UiUtils.getCohortUnitsDeleteSuccessMessage);
        } catch (e) {
            console.error("Error Deleting Custom Cohort Units ", e);
            setUiAlert(UiUtils.getCohortUnitsDeleteErrorMessage(e));
        } finally {
            setIsLoading(false);
        }
    };

    const builder = new DataSourceBuilder<CohortTableValues>();
    builder.addColumn({
        label: "Actions",
        field: "actions",
        func: (obj) => (
            <React.Fragment>
                <div className="table-icons">
                    <Tooltip
                        message={
                            obj.type === "model" || obj.type === "strategic"
                                ? "Not available for model and strategic cohorts"
                                : havePermission
                                ? "Delete all Units"
                                : "Editor access required"
                        }
                    >
                        <div>
                            <Icon.RotateCCW
                                className={
                                    obj.type === "model" || obj.type === "strategic"
                                        ? "pointer-disabled"
                                        : havePermission
                                        ? "pointer"
                                        : "pointer-disabled-viewer"
                                }
                                height={18}
                                width={18}
                                onClick={
                                    havePermission
                                        ? () => {
                                              setIsVisibleUnitModal(true);
                                              setCohort(obj.name);
                                          }
                                        : () => {}
                                }
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        message={
                            obj.type === "model" || obj.type === "strategic"
                                ? "Not available for model and strategic cohorts"
                                : havePermission
                                ? "Delete Custom Cohort"
                                : "Editor access required"
                        }
                    >
                        <div>
                            <Icon.Trash2
                                className={
                                    obj.type === "model" || obj.type === "strategic"
                                        ? "pointer-disabled"
                                        : havePermission
                                        ? "pointer"
                                        : "pointer-disabled-viewer"
                                }
                                height={18}
                                width={18}
                                onClick={
                                    havePermission
                                        ? () => {
                                              setIsVisibleCohortModal(true);
                                              setCohort(obj.name);
                                          }
                                        : () => {}
                                }
                            />
                        </div>
                    </Tooltip>
                    <Tooltip message="Go to Cohort Rates Evolution">
                        <Link to={AppRoutes.COHORT_EVOLUTION.replace(":name", `${encodeURIComponent(obj.name)}`)} target="_blank">
                            <Icon.BarChart className="pointer" height={18} width={18} />
                        </Link>
                    </Tooltip>
                </div>
            </React.Fragment>
        ),
    });
    builder.addColumn({
        label: "Cohort Name",
        field: "name",
        displayConfiguration: {containerType: "div", flexGrow: 2},
    });
    builder.addColumn({label: "Cohort Type", field: "type"});
    builder.addColumn({label: "Units", field: "unit_count"});
    builder.addColumn({label: "Region ID", field: "region_id"});
    builder.addColumn({label: "Model Name", field: "model_name"});
    builder.addColumn({label: "Model Version", field: "model_version"});
    builder.addColumn({label: "Model Status", field: "model_status"});

    builder.setSortable({field: "name", order: "asc"});

    builder.addPagination({
        remote: true,
        function: async (pageNumber: number, pageSize: number, filters: FilterField[], order: OrderField) => {
            let params = {
                "page[number]": pageNumber.toString(),
                "page[size]": pageSize.toString(),
            };
            const selectedModelName = filters.find((filter) => filter.field === "model_name")?.value ?? "ALL";
            if (selectedModelName !== modelName) {
                const versions = VersionsByModel.find((value) => value.model_name === selectedModelName)?.versions || [];
                const selectedVersion = filters.find((filter) => filter.field === "model_version");
                // if current model doesn't support current version, change filter to ALL and remove it from the query
                if (!_.isNil(selectedVersion) && !versions.includes(selectedVersion.value)) {
                    selectedVersion.value = "ALL";
                    filters = filters.filter((value) => value.field !== "model_version");
                }
                // when model_name changes, modify available model_versions
                if (selectedModelName === "ALL") {
                    setCohortFilters(CohortTableFilters);
                } else {
                    const newFilters: FilterOption[] = CohortTableFilters.filter((value) => value.field !== "model_version");
                    newFilters.push({
                        field: "model_version",
                        values: _.concat(["ALL"], versions),
                        type: "select",
                    });
                    setCohortFilters(newFilters);
                }
                // save to track changes
                setModelName(selectedModelName);
            }
            filters.forEach((filter) => {
                let parsedFilter: FilterField = {field: "", value: ""};
                switch (filter.field) {
                    case "type":
                        if (filter.value === "Strategic") {
                            parsedFilter.field = "independent_of_location";
                            parsedFilter.value = "1";
                        } else if (filter.value === "Model") {
                            parsedFilter.field = "independent_of_location";
                            parsedFilter.value = "0";
                            params[castFilter(filter.field)] = filter.value;
                        } else {
                            parsedFilter = filter;
                        }
                        break;
                    case "model_status":
                        parsedFilter.field = filter.field;
                        parsedFilter.value = filter.value.toLowerCase();
                        break;
                    default:
                        parsedFilter = filter;
                }
                params[castFilter(parsedFilter.field)] = parsedFilter.value;
            });

            if (!_.isNil(order)) {
                let symbol = "";
                if (order.order === "desc") symbol = "-";
                params["sort"] = `${symbol}${getParamName(order.field)}`;
            }

            const {rows, count} = await RateService.getCohorts(params);
            dispatch(upsertCohorts(rows));
            return {result: CohortUtils.transformToTableData(rows), count};
        },
    });

    const castFilter = (key: string): string => {
        const cast = {
            name: "filter[name][contains]",
            type: "filter[type][contains]",
            unit_count: "filter[unit_count][between]",
            model_name: "filter[model_name][contains]",
            model_version: "filter[model_version][contains]",
            model_status: "filter[model_status]",
            region_id: "filter[region_id]",
            independent_of_location: "filter[independent_of_location]",
        };
        return cast[key] ? cast[key] : "filter[name][contains]";
    };

    const getParamName = (key: string): string => {
        const cast = {
            name: "name",
            type: "type",
            unit_count: "unit_count",
            model_name: "model_name",
            model_version: "model_version",
            model_status: "model_status",
            region_id: "region_id",
        };
        return cast[key] ? cast[key] : "name";
    };
    // update filter when model_name changes
    builder.setFilterHeader({
        options: cohortFilters,
    });
    const handleSave = (message: Message) => {
        setUiAlert(message);
        setIsShowingSave(false);
        setIsShowingCreateModelCohort(false);
    };
    const handleCreateModelCohort = () => {
        setIsShowingCreateModelCohort(true);
    };
    const handleError = (message: Message) => {
        setUiAlert(message);
    };

    const handleCloseCohortCreationPanel = () => {
        setIsShowingCreateModelCohort(false);
        setIsShowingCreateOptions(false);
    };

    return (
        <React.Fragment>
            {isLoading && (
                <div className="virtualized-table-loader">
                    <Loading />
                </div>
            )}
            {isVisibleCohortModal
                ? renderConfirmationModal(cohort, "cohort", "Delete Custom Cohort", "Are you sure you want to delete Custom Cohort ")
                : ""}
            {isVisibleUnitModal
                ? renderConfirmationModal(cohort, "unit", "Delete Custom Cohort Units", "Are you sure you want to delete all the units from ")
                : ""}
            {!isLoading && (
                <div className={"cohort-table-header-container"}>
                    <div className="cohorts-table-top-bar">
                        {uiAlert && <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small" />}
                        <div className={havePermission ? "pointer new-cohort-button" : "pointer-disabled-viewer"}>
                            {isShowingCreateOptions && (
                                <div className="cohort-button-group">
                                    <Tooltip message="New Model Cohort">
                                        <Chip size="small" label="Model Cohort" onClick={handleCreateModelCohort} />
                                    </Tooltip>
                                    <Tooltip message="New Custom Cohort">
                                        <Chip size="small" label="Custom Cohort" onClick={handleCreateCustomCohort} />
                                    </Tooltip>
                                </div>
                            )}
                            <Tooltip
                                message={
                                    !havePermission
                                        ? "Editor access required"
                                        : isShowingCreateOptions
                                        ? "Close Create New Cohort"
                                        : "Open Create New Cohort"
                                }
                            >
                                <span>
                                    <Icon.PlusCircle
                                        height={24}
                                        width={24}
                                        className={isShowingCreateOptions ? "rotate-button" : ""}
                                        onClick={() => setIsShowingCreateOptions((prev) => !prev)}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                        <Tooltip message={havePermission ? "Upload File" : "Editor access required"}>
                            <div className={havePermission ? "pointer" : "pointer-disabled-viewer"}>
                                <Icon.Upload height={24} width={24} onClick={havePermission ? () => setIsShowingSave(true) : () => {}} />
                            </div>
                        </Tooltip>
                    </div>
                    {isShowingSave && (
                        <BulkSidePanel
                            hasChangeReason={true}
                            onClose={() => setIsShowingSave(false)}
                            type={"cohort"}
                            title={"Cohort Bulk Override"}
                            onSave={handleSave}
                        />
                    )}
                    {isShowingCreateModelCohort && (
                        <CohortCreationPanel onClose={handleCloseCohortCreationPanel} onSave={handleSave} onError={handleError} />
                    )}
                    <VirtualizedTable className="cohorts-table" dataSource={builder.build([])} onRowChange={() => null} />
                </div>
            )}
        </React.Fragment>
    );
};
