import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Configuration } from '../Configuration';
import { unitSlice } from './unit/unit.slice';
import { cohortSlice } from './cohort/cohort.slice';
import { changeHistorySlice } from './history/history.slice';
import { uiSlice } from './ui/ui.slice';
import { modelSlice } from './model/model.slice';

const rootReducer = combineReducers({
    unit: unitSlice.reducer,
    cohort: cohortSlice.reducer,
    history: changeHistorySlice.reducer,
    ui: uiSlice.reducer,
    model: modelSlice.reducer,
});
export const store = configureStore({ devTools: !Configuration.isProduction, reducer: rootReducer });

export type AppState = ReturnType<typeof store.getState>;
