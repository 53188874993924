import { RegularChange, UnitTableValues, UnitWithRates } from '../types';
import * as _ from 'lodash';
import { SaveFormValues } from '../components';
import { EnhancedUnitDTO } from '@common/types';

export class UnitUtils {
    static transformToTableData = (units: UnitWithRates[], type: string = ''): UnitTableValues[] => {
        return _.map(units, (unit) => ({
            key: `${unit.id}`,
            id: unit.id,
            code: unit.code,
            name: unit.name,
            state: unit.location.state,
            city: unit.location.city,
            zip: unit.location.zip,
            bedroom_count: unit.bedroom_count,
            assigned_cohort: unit.assigned_cohort,
            average_rate: unit.average_rate,
            std: unit.std,
            status: unit.active,
            display: unit.display,
            housing_type: unit.housing_type,
            manual: type,
            terminated: unit.terminated,
        }));
    };
    static transformSaveFormToEnhancedUnitDto = (saveForm: SaveFormValues): Partial<EnhancedUnitDTO> => {
        const unitUpdates: Partial<EnhancedUnitDTO> = { context: saveForm.context };
        for (const change of saveForm.changes as RegularChange[]) {
            unitUpdates[change.key] = change.value;
        }
        return unitUpdates;
    };
}
