import { createAsyncThunk } from '@reduxjs/toolkit';
import { RateService } from '../../services/RateService';
import { setIsFetchingModels } from './model.slice';
export const fetchModels = createAsyncThunk('model/fetchModels', async (params: undefined, { rejectWithValue, dispatch }) => {
    try {
        dispatch(setIsFetchingModels(true));
        const models = await RateService.getModelsForCohort();
        dispatch(setIsFetchingModels(false));
        return models;
    } catch (e) {
        dispatch(setIsFetchingModels(false));
        return rejectWithValue(RateService.makeError('fetchModels', e));
    }
});
