import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Routes } from './Routes';
import { Container } from '@material-ui/core';
import { ImplicitFlow, ThemeProvider } from '@vacasa/react-components-lib';
import theme from '@vacasa/react-components-lib/lib/themes/default';
import { Provider, useDispatch } from 'react-redux';
import { store } from './store';
import { Configuration } from './Configuration';
import { nanoid } from 'nanoid';
import { fetchUnitTableFilters } from './store/ui/ui.actions';
import { fetchCohorts } from './store/cohort/cohort.actions';
import { CohortTypes } from '@common/types';
import { datadogLogs } from '@datadog/browser-logs';
import { EnvironmentFlag } from './components/EnvironmentFlag/EnvironmentFlag';
import { Helmet } from 'react-helmet';

const {
    REACT_APP_DD_CLIENT_TOKEN: clientToken,
    REACT_APP_CUSTOM_NODE_ENV: environment,
    REACT_APP_CUSTOM_NODE_ENV_SHORT: env,
    REACT_APP_VERSION: app_version,
} = process.env;

export const history = createBrowserHistory();
export const flowId = nanoid(10);

if (env !== 'local') {
    datadogLogs.init({
        clientToken,
        forwardErrorsToLogs: true,
        env,
    });

    datadogLogs.setLoggerGlobalContext({
        team: 'acrobatic-wizards',
        service: 'rates-tool-app',
        env,
        environment,
        app_version,
    });
}

const isProduction = environment === 'production';

function App() {
    const AppContainer = () => {
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(fetchUnitTableFilters());
            dispatch(fetchCohorts({ type: CohortTypes.MODEL }));
        }, []);

        return (
            <ThemeProvider theme={theme}>
                <Container maxWidth={false}>
                    <Helmet>
                        <title>{'Rates Tool App'} </title>
                    </Helmet>
                    <Router history={history}>
                        <Routes />
                    </Router>
                </Container>
            </ThemeProvider>
        );
    };

    return (
        <ImplicitFlow {...Configuration.getIDPConfiguration()}>
            {!isProduction && <EnvironmentFlag color={'red'} label={environment} placement={'bottom-left'} />}
            <Provider store={store}>
                <AppContainer />
            </Provider>
        </ImplicitFlow>
    );
}

export default App;
