import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Icon } from '@vacasa/react-components-lib';
import './HolidayIcons.scss';

interface HolidaysIconProps {
    holidays: string[];
}

export const HolidaysIcon: React.FC<HolidaysIconProps> = (props) => {
    const { holidays } = props;

    function setHolidays() {
        if (!holidays || holidays.length === 0) return "Don't have information about holiday";
        return holidays.join('\n');
    }

    return (
        <Tooltip title={setHolidays()} arrow={true}>
            <span className="info-box-icon" data-testid="holiday-icon">
                <Icon.Briefcase className="pointer warning" height={18} width={18} />
            </span>
        </Tooltip>
    );
};
