import React, {useEffect, useState} from "react";
import {CohortDetailsCard} from "../../components/CohortDetailsCard/CohortDetailsCard";
import {history} from "../../App";
import {AppRoutes} from "../../Routes";
import {Loading, Navbar} from "../../components";
import {AlertMessage} from "@vacasa/react-components-lib";
import {Paper} from "@material-ui/core";
import {DATE_FORMAT, UnitFactorDTO, UnitStatusDTO, UpdateContextDTO, UpdateUnitsFactorDTO} from "@common/types";
import {calculateAdjustedRate, parseDate} from "@common/utils";
import {CohortFactorUnitTable} from "../../components/CohortFactorUnitTable/CohortFactorUnitTable";
import {CohortFactorBarChart} from "../../components/CohortFactorBarChart/CohortFactorBarChart";
import {useLocation, useParams} from "react-router-dom";
import * as _ from "lodash";
import {RateService} from "../../services/RateService";
import {CohortUtils, UiUtils} from "../../utils";
import {format, isValid} from "date-fns";
import "./CohortFactor.scss";
import {LoggingService} from "../../services/LoggingService";
import {CohortFactorAndStatusUnitTableValues, Message} from "../../types";
import {Row} from "../../types/VirtualizedTable";

export const CohortFactor: React.FC = () => {
    const params = useParams<{name: string}>();
    const {search} = useLocation();
    const [cohortName, setCohortName] = useState(null);
    const [date, setDate] = useState(new Date());
    const [unitFactors, setUnitFactors] = useState<UnitFactorDTO[]>([]);
    const [unitStatuses, setUnitStatuses] = useState<UnitStatusDTO[]>([]);
    const [alertMessage, setAlertMessage] = useState<Message>(null);
    const [isFetchingUnitsFactors, setIsFetchingUnitsFactors] = useState(true);
    const [isFetchingUnitsStatuses, setIsFetchingUnitsStatuses] = useState(true);
    const [error, setError] = useState<Error>(null);
    const [changes, setChanges] = useState<{[unit: number]: UnitFactorDTO}>({});
    const [unitFactorsWithChanges, setUnitFactorsWithChanges] = useState<UnitFactorDTO[]>([]);

    useEffect(() => {
        const name = params.name;

        if (!_.isEmpty(name)) {
            setCohortName(decodeURIComponent(params.name));
        }

        const searchParams = new URLSearchParams(search);
        const parsed = parseDate(searchParams.get("date"));

        if (isValid(parsed)) {
            setDate(parsed);
        }
    }, [params]);

    useEffect(() => {
        if (cohortName && date) {
            const formattedDate = format(date, DATE_FORMAT);
            fetchUnitsFactors(formattedDate);
            fetchUnitsStatuses(formattedDate);
        }
    }, [cohortName, date]);

    useEffect(() => {
        setAlertMessage(null);
        if (_.values(changes).length > 0) {
            setAlertMessage(UiUtils.getPendingChangesMessage());
        }
        if (error) {
            setAlertMessage(UiUtils.getErrorMessage(error.message));
        }
    }, [error, changes]);

    useEffect(() => {
        const updatedUnitFactors = [];
        for (const unit of unitFactors) {
            const changedUnit = changes[unit.id];
            const currentUnit = {...unit};
            const updatedUnit = changedUnit ? _.merge(currentUnit, changedUnit) : unit;
            updatedUnitFactors.push(updatedUnit);
        }

        setUnitFactorsWithChanges(updatedUnitFactors);
    }, [unitFactors, changes]);

    const fetchUnitsFactors = async (date: string) => {
        try {
            setIsFetchingUnitsFactors(true);
            setError(null);
            const data = await RateService.getUnitsFactor(cohortName, date);
            setUnitFactors(data);
        } catch (e) {
            LoggingService.error(`ERROR fetching unit factors`, {cohort_name: cohortName, date, error: e});
            setError(e);
        } finally {
            setIsFetchingUnitsFactors(false);
        }
    };

    const fetchUnitsStatuses = async (date: string) => {
        try {
            setIsFetchingUnitsStatuses(true);
            setError(null);
            const data = await RateService.getUnitsStatusByDate(cohortName, date);
            setUnitStatuses(data);
        } catch (e) {
            LoggingService.error(`ERROR fetching unit statuses`, {cohort_name: cohortName, date, error: e});
            setError(e);
        } finally {
            setIsFetchingUnitsStatuses(false);
        }
    };

    const handleSave = async (saveForm: {context: UpdateContextDTO; changes: UnitFactorDTO[]}) => {
        try {
            setError(null);
            const formattedDate = format(date, DATE_FORMAT);
            const update: UpdateUnitsFactorDTO = {
                cohortName,
                context: saveForm.context,
                changes: saveForm.changes.map((u) => ({
                    date: formattedDate,
                    unit_id: u.id,
                    analyst_factor: u.factor,
                    base_rate_override: u.base_rate_override,
                })),
            };

            await RateService.updateUnitFactors(update);
            applyChanges();
            setAlertMessage(UiUtils.getSaveSuccessMessage());
            await fetchUnitsFactors(formattedDate);
            await fetchUnitsStatuses(formattedDate);
        } catch (e) {
            setError(e);
        }
    };

    const handleTableEdit = (column: keyof CohortFactorAndStatusUnitTableValues, updatedRows: Row<CohortFactorAndStatusUnitTableValues>[]) => {
        for (const row of updatedRows) {
            const currentUnit = _.find(unitFactors, (unit) => unit.id == Number(row.key));

            let analystFactor = row.factor;
            let analystBaseOverride = row.base_rate_override;
            let adjustedRate = row.adjusted_rate;
            adjustedRate = calculateAdjustedRate(analystFactor, row.predicted_rate, analystBaseOverride);
            let updatedUnit = {
                ...currentUnit,
                adjusted_rate: adjustedRate,
                factor: analystFactor,
                base_rate_override: analystBaseOverride,
            };
            if (currentUnit.factor === analystFactor) {
                delete updatedUnit.factor;
            }
            if (currentUnit.base_rate_override === analystBaseOverride) {
                delete updatedUnit.base_rate_override;
            }
            setChanges((currentChanges) => {
                if (_.isEqual(currentUnit, updatedUnit)) {
                    delete currentChanges[currentUnit.id];
                    return {...currentChanges};
                }

                return {...currentChanges, [currentUnit.id]: updatedUnit};
            });
        }
    };

    const handleDateChange = (newDate: Date) => {
        // Clear current state
        setChanges({});
        setError(null);
        setAlertMessage(null);

        history.push({
            pathname: AppRoutes.COHORT_FACTOR.replace(":name", encodeURIComponent(cohortName)),
            search: `?date=${format(newDate, DATE_FORMAT)}`,
        });
    };

    const applyChanges = () => {
        const updatedReference = [...unitFactors];

        for (const unitId in changes) {
            const unitIndex = updatedReference.findIndex((u) => u.id === +unitId);
            updatedReference.splice(unitIndex, 1, changes[unitId]);
        }

        setUnitFactors(updatedReference);
        setChanges({});
    };

    const units = unitFactorsWithChanges.length;
    const factors: number[] = [];
    let unitsWithFactor: number = 0;

    for (const unit of unitFactorsWithChanges) {
        if (!unit.predicted_rate || unit.predicted_rate <= 0) {
            continue;
        }
        const factor = unit.factor ?? 1;
        factors.push(factor);
        if (factor !== 1 || factor === null) {
            unitsWithFactor = unitsWithFactor + 1;
        }
    }

    return (
        <React.Fragment>
            <Navbar title="Rates Cohort" domain="cohorts" />
            <Paper elevation={3} className="cohort-factor-view">
                <div className="cohort-factor-top-bar">
                    <h5>Cohort Factor</h5>
                </div>

                {(isFetchingUnitsFactors || isFetchingUnitsStatuses) && <Loading className={"chart-loading-spinner"} />}

                {!isFetchingUnitsFactors && !isFetchingUnitsStatuses && (
                    <div className="unit-cohort-factor-body">
                        <div className={"unit-cohort-factor-info"}>
                            {unitFactorsWithChanges.length > 0 && (
                                <CohortDetailsCard
                                    title={{label: "Cohort Name", value: `${cohortName}`}}
                                    details={[
                                        {label: "Cohort Factor", value: `${_.mean(factors).toFixed(2)}%`},
                                        {label: "Units", value: `${units}`},
                                        {
                                            label: "%units with factors",
                                            value: `${((unitsWithFactor * 100) / units).toFixed(2)}%`,
                                        },
                                    ]}
                                    date={date}
                                    factor_details={[
                                        {label: "Min", value: `${_.min(factors)}`},
                                        {label: "Max", value: `${_.max(factors)}`},
                                        {label: "Average", value: `${_.mean(factors).toFixed(2)}`},
                                    ]}
                                    onDateChange={handleDateChange}
                                />
                            )}

                            <div className="factor-bar-chart">
                                <CohortFactorBarChart data={CohortUtils.transformToBarCharData(unitFactorsWithChanges)} />
                            </div>
                        </div>

                        {alertMessage && (
                            <div className="cohort-factor-alert">
                                <AlertMessage height="small" type={alertMessage.type} text={alertMessage.content} />
                            </div>
                        )}

                        {!isFetchingUnitsFactors && !isFetchingUnitsStatuses && (
                            <CohortFactorUnitTable
                                units={CohortUtils.transformToUnitFactorAndStatusTableData(unitFactorsWithChanges, unitStatuses)}
                                changes={_.values(changes)}
                                onRowChange={handleTableEdit}
                                onSave={handleSave}
                                key={_.map(unitFactorsWithChanges, (unitFactor) => unitFactor.id).join(",")}
                            />
                        )}
                    </div>
                )}
            </Paper>
        </React.Fragment>
    );
};
