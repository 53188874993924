import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addDays} from "date-fns";
import {applyChangesForCohort, AppState, selectCohortByName, selectCohortRatesWithChanges, setRateChangesForCohort} from "../../store";
import * as _ from "lodash";
import {ChangeLog, CohortDashboard, Loading, RatesEvolutionChart, RatesTable, SaveFormValues} from "..";
import {fetchEnhancedCohort, fetchRatesForCohort} from "../../store/cohort/cohort.actions";
import {RateUtils, UiUtils} from "../../utils";
import {CohortDashboardValues, CohortRateTableValues, CohortWithRates, DashboardHash, DowOptions, Message, SetCohortChange} from "../../types";
import {Row} from "../../types/VirtualizedTable";
import {GenericContentPanel} from "../GenericContentPanel/GenericContentPanel";
import {AlertMessage, Icon, Tabs} from "@vacasa/react-components-lib";
import {Configuration} from "../../Configuration";

import {CohortRateTableColumns, DefaultCohortRateTableVisibleColumns, getCohortRateTableBuilder} from "../RatesTable/CohortRateTable.builder";
import {
    CohortFactorDTO,
    CohortModelPredictionDTO,
    CohortRateChange,
    CohortTypes,
    dashboardCohortAllowedScopes,
    detailsCohortAllowedScopes,
    RateDescription,
    ratesCohortAllowedScopes,
    UIComponentsKeys,
    UIConfigurationTableComponent,
    UISectionKeys,
    UpdateCohortRatesDTO,
    UpdateContextDTO,
    UserPreferenceConfigurationDTO,
    UserPreferenceDTO,
} from "@common/types";
import {RateService} from "../../services/RateService";
import {useLocation, useParams} from "react-router-dom";
import {history} from "../../App";
import {AppRoutes} from "../../Routes";
import {SwitchPillow} from "../SwitchPillow/SwitchPillow";
import {selectChangesForCohort} from "../../store/history/history.slice";
import {fetchHistory} from "../../store/history/history.actions";
import {LoggingService} from "../../services/LoggingService";
import {ColumnDescription} from "../VirtualizedTable/ColumnConfiguration";
import {loadUserPreferences, updateUserPreferences} from "../../localstorage";
import "./RatesEvolution.scss";
import {Helmet} from "react-helmet";
import {isEmpty} from "lodash";

export const CohortRatesEvolution: React.FC = () => {
    let pressedIcon = false;
    const dispatch = useDispatch();
    const today = new Date();
    const [cohortName, setCohortName] = useState<string>();
    const [startDate, setStartDate] = useState<Date>(today);
    const [endDate, setEndDate] = useState<Date>(addDays(today, Configuration.DEFAULT_DAYS_RANGE));
    const [cohortDashboardValues, setCohortDashboardValues] = useState<CohortDashboardValues>({
        key: "",
        region_id: undefined,
        assigned_model: {display: undefined, value: undefined},
        name: undefined,
    });
    const {isFetchingRates, isFetchingEnhancedCohort} = useSelector((state: AppState) => state.cohort);
    const cohort = useSelector((state: AppState) => selectCohortByName(state, cohortName));
    const error = useSelector((state: AppState) => state.cohort.error);
    const havePermissionDashboard = UiUtils.validateScope(dashboardCohortAllowedScopes);
    const havePermissionRates = UiUtils.validateScope(ratesCohortAllowedScopes);
    const havePermissionDetails = UiUtils.validateScope(detailsCohortAllowedScopes);

    const [dowFilterOption, setDowFilterOption] = useState<DowOptions>("All");

    const [isShowingChart, setIsShowingChart] = useState<boolean>(true);
    const [isComparingChart, setIsComparingChart] = useState<boolean>(false);
    const [message, setMessage] = useState<Message | null>(null);

    const [modelCohorts, setModelCohorts] = useState<{[p: string]: string}>({});

    const [isFetchingModels, setIsFetchingModels] = useState<boolean>(false);
    const params = useParams<{name: string}>();
    const pageTitle = `[${cohortName}] Rates Tool App`;

    const [preference, setPreference] = useState<string>(
        UiUtils.getPreferenceDashboard(UISectionKeys.COHORT, UIComponentsKeys.RATES_PREFERENCE_TABS)
    );

    const locations = useLocation();

    const hashTab = UiUtils.validDashboardHash(useLocation().hash)
        ? (locations.hash as DashboardHash)
        : preference
        ? (preference as DashboardHash)
        : DashboardHash.DASHBOARD;
    const [selectedTab, setSelectedTab] = useState<number>(UiUtils.getDashboardPosition(hashTab));

    // @ts-ignore
    const rates = useSelector((state: AppState) => selectCohortRatesWithChanges(state, cohortName, {start: startDate, end: endDate}));
    // @ts-ignore
    const changes = useSelector((state) => selectChangesForCohort(state, cohortName));
    const {isFetchingHistory} = useSelector((state: AppState) => state.history);
    const [comparedChartData, setComparedChartData] = useState([]);
    const [modelSynced, setModelSynced] = useState(["Predicted Rate", "Adjusted Rate"]);
    const [compareRates, setCompareRates] = useState({});
    const [modelSelected, setModelSelected] = useState(["Predicted Rate", "Adjusted Rate"]);

    const [userPreference, setUserPreference] = useState<UserPreferenceDTO>(null);

    const [visibleColumns, setVisibleColumns] = useState<ColumnDescription[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<ColumnDescription[]>([]);
    const [isActionChange, setIsActionChange] = useState<boolean>(false);
    const [processId, setProcessId] = useState<string>("");
    const [modifiedField, setModifiedField] = useState<string>("");
    const [filterDate, setFilterDate] = useState<string>("");

    const cohortIsModelOrStrategic = cohort?.type === "model" || cohort?.type === "strategic";
    const handleFilterDateChange = (date: string) => {
        setFilterDate(date);
    };

    useEffect(() => {
        history.push({hash: UiUtils.getDashboardFromPosition(selectedTab)});
    }, [selectedTab]);

    useEffect(() => {
        const name = params.name;
        if (_.isString(name)) {
            setCohortName(decodeURIComponent(params.name));
            return;
        }

        history.push(AppRoutes.COHORTS);
    }, [params]);

    useEffect(() => {
        if (cohortName) {
            dispatch(fetchEnhancedCohort({name: cohortName}));
            dispatch(fetchHistory({id: cohortName, process_execution_id: null, modifiedDate: null, modifiedField: null}));
            syncLoadUserPreferences();
        }
    }, [cohortName]);

    useEffect(() => {
        if (message && message.type === "success" && !isActionChange) {
            dispatch(fetchRatesForCohort({name: cohortName, start: startDate, end: endDate, cohortType: cohort.type}));
        }
    }, [message]);

    useEffect(() => {
        if (cohort) {
            dispatch(fetchRatesForCohort({name: cohortName, start: startDate, end: endDate, cohortType: cohort.type}));
        }
    }, [startDate, endDate, cohort?.type]);

    useEffect(() => {
        if (cohort) {
            if (cohortIsModelOrStrategic) {
                getModelsforCohort();
            }
            setCohortDashboardValues(() => ({
                key: cohort.name,
                region_id: cohort.region_id,
                name: cohort.name,
                assigned_model: cohort.model
                    ? {
                          display: `${cohort.model.name} ${cohort.model.version} ${cohort.model.status.substr(0, 3)}`,
                          value: cohort.model.id,
                      }
                    : {display: null, value: null},
            }));
        }
    }, [cohort?.name]);

    useEffect(() => {
        if (error) {
            setMessage(UiUtils.getErrorMessage(error.message));
            return;
        }
        if (!_.isEmpty(getChanges(cohort))) {
            setMessage(UiUtils.getPendingChangesMessage());
            return;
        }
        setMessage(null);
    }, [cohort?.changes, error]);

    useEffect(() => {
        const first = _.head(_.keys(modelCohorts));
        if (cohort && cohortIsModelOrStrategic) {
            syncData(parseInt(first));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (_.isEqual(modelSynced, ["Predicted Rate", "Adjusted Rate"]) && _.keys(rates).length > 0 && !_.isEmpty(modelCohorts)) {
            const first = _.head(_.keys(modelCohorts));
            if (cohort && cohortIsModelOrStrategic) {
                syncData(parseInt(first));
            }
        }
    }, [_.keys(rates).join(","), modelCohorts]);

    const syncLoadUserPreferences = async () => {
        const user = await loadUserPreferences();
        setUserPreference(user);
    };

    const syncUpdateUserPreferences = async (preference: UserPreferenceConfigurationDTO) => {
        await updateUserPreferences(preference);
    };

    let predictedRatesPreview: CohortModelPredictionDTO = null;

    const getPredictedRatesToCompare = async () => {
        if (cohortIsModelOrStrategic && cohort?.model.id) {
            predictedRatesPreview = await RateService.getRatesPredictedModelsForSpecificCohort(cohortName, cohort.model.id, startDate, endDate);
        }
    };

    const syncData = async (modelId: number) => {
        setIsFetchingModels(true);
        if (!predictedRatesPreview) {
            await getPredictedRatesToCompare();
        }
        const newRates = {
            [modelCohorts[modelId]]: await RateService.getRatesPredictedModelsForSpecificCohort(cohortName, modelId, startDate, endDate),
        };
        const adjustedRatesPreview = RateUtils.transformToAdjustedRateData(rates);
        setCompareRates({...compareRates, ...newRates});
        setComparedChartData(
            RateUtils.transformToCompareCohortChartData({...compareRates, ...newRates}, predictedRatesPreview, adjustedRatesPreview)
        );
        setModelSynced([...modelSynced, ...[modelCohorts[modelId]]]);
        setModelSelected([...modelSelected, ...[modelCohorts[modelId]]]);
        setIsFetchingModels(false);
    };

    const getModelsforCohort = async () => {
        setIsFetchingModels(true);
        const models = await RateService.getModelsForSpecificCohort(cohortName);

        const newModels = {};
        for (const model of models) {
            if (model.id !== cohort?.model.id) {
                newModels[`${model.id}`] = model.name + (!_.isEmpty(model.version) ? " " + model.version : "");
            }
        }
        setModelCohorts(newModels);
        setIsFetchingModels(false);
    };

    useEffect(() => {
        const ratesTablePreferences = _.find(userPreference?.preferences?.components, (component) => {
            return component.section === UISectionKeys.COHORT && component.type === "TABLE" && component.key === UIComponentsKeys.RATES_TABLE;
        }) as UIConfigurationTableComponent;

        const {visible, hidden} = UiUtils.getVisibleAndHiddenColumns(
            ratesTablePreferences,
            CohortRateTableColumns,
            DefaultCohortRateTableVisibleColumns
        );

        setVisibleColumns(visible);
        setHiddenColumns(hidden);
    }, [userPreference?.preferences]);

    const toggleChartVisibility = () => {
        setIsShowingChart(!isShowingChart);
    };

    const handleDashboardChange = (values) => {
        setCohortDashboardValues(() => ({...cohortDashboardValues, ...values}));
    };

    const handleDowFilterChange = (dayFilterOption: DowOptions) => {
        setDowFilterOption(dayFilterOption);
    };

    const handleTableEdit = (column: keyof CohortRateTableValues, updatedRows: Row<CohortRateTableValues>[]) => {
        const changes = _.map(updatedRows, (row) => ({
            date: row.date,
            value: RateUtils.transformToCohortRateData(column, row),
        }));

        dispatch(setRateChangesForCohort({name: cohortName, changes}));
    };

    const handleSet = async (updatedRows: SetCohortChange, context: UpdateContextDTO) => {
        const update: CohortFactorDTO = {
            cohort_name: cohortName,
            context,
            changes: updatedRows,
        };
        try {
            await RateService.updateCohortUnitAnalystFactor(update);
            setIsActionChange(true);
            setMessage(UiUtils.getSaveSuccessMessage());
        } catch (e) {
            LoggingService.error(`error setting cohort unit analyst factor`, {cohort_name: cohortName, params: {update}});
            setMessage(UiUtils.getErrorMessage(e));
        }
    };

    const handleSave = async (data: SaveFormValues) => {
        const update: UpdateCohortRatesDTO = {
            type: cohort.type === "strategic" ? CohortTypes.MODEL : cohort.type,
            units: [], // todo: add units to cohort info in the backend
            context: data.context,
            changes: data.changes as CohortRateChange[],
        };

        try {
            await RateService.updateRatesForCohort(cohortName, update);
            dispatch(applyChangesForCohort({name: cohortName}));
            setMessage(UiUtils.getSaveSuccessMessage());
            dispatch(fetchHistory({id: cohortName, process_execution_id: null, modifiedDate: null, modifiedField: null}));
        } catch (e) {
            LoggingService.error(`error saving cohort rates changes`, {cohort_name: cohortName, params: {update}});
            setMessage(UiUtils.getErrorMessage(e));
        }
    };

    const onDatesChange = (start: Date, end: Date) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleColumnsChange = (visible: ColumnDescription[], hidden: ColumnDescription[]) => {
        const preferences = UiUtils.getUpdatedTablePreferences(UISectionKeys.COHORT, UIComponentsKeys.RATES_TABLE, {
            visible,
            hidden,
        });
        syncUpdateUserPreferences(preferences);
        setVisibleColumns(visible);
        setHiddenColumns(hidden);
    };

    const getDefaultRateTableColumns = () => {
        return UiUtils.getVisibleAndHiddenColumns(undefined, CohortRateTableColumns, DefaultCohortRateTableVisibleColumns);
    };

    const getChanges = (cohort: CohortWithRates): CohortRateChange[] => {
        const changes: CohortRateChange[] = [];
        return _.reduce(
            cohort?.changes,
            (acc, curr, date) => {
                const changed: RateDescription = curr;

                if (!_.isNil(changed.rates.analyst_factor)) {
                    acc.push({
                        date,
                        analyst_factor: changed.rates.analyst_factor || 1,
                    });
                }
                return acc;
            },
            changes
        );
    };

    if ((!cohort && isFetchingEnhancedCohort) || (_.isEmpty(cohort?.rates) && isFetchingRates)) {
        return <Loading className="main-loading-spinner" />;
    }

    if (!cohort) {
        return null;
    }
    const filtered = RateUtils.applyDowFilter(rates, dowFilterOption);
    const tableData = RateUtils.transformToRateTableData(filtered, cohortName);
    const chartData = RateUtils.transformToCohortChartData(filtered);

    const changeChart = (option: boolean) => {
        if (cohortIsModelOrStrategic) setIsComparingChart(!option);
    };

    const handleTabPreference = (value: string) => {
        pressedIcon = !pressedIcon;
        const preferences = UiUtils.getUpdatedTabsPreferences(UISectionKeys.COHORT, UIComponentsKeys.RATES_PREFERENCE_TABS, value);
        syncUpdateUserPreferences(preferences);
        setPreference(value);
    };

    const handleTabChange = (index) => {
        if (!pressedIcon) {
            setSelectedTab(index);
        }
        pressedIcon = !pressedIcon;
    };

    const handleHistoryFilterChange = (processId?: string, filterDate?: string, modifiedField?: string) => {
        setProcessId(processId);
        setFilterDate(filterDate);
        setModifiedField(modifiedField);
        if (isEmpty(processId) && isEmpty(filterDate) && modifiedField === "all") {
            dispatch(fetchHistory({id: cohortName}));
        }
    };

    const handleApplyFilters = () => {
        dispatch(
            fetchHistory({
                id: cohortName,
                process_execution_id: processId,
                modifiedDate: filterDate,
                modifiedField: modifiedField,
            })
        );
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{pageTitle} </title>
            </Helmet>
            {isFetchingModels ? (
                <Loading className={"chart-loading-spinner"} />
            ) : (
                <div className="menu-selector-right">
                    {isShowingChart && cohortIsModelOrStrategic && (
                        <SwitchPillow labelLeft="Current" labelRight="Preview" initial={!isComparingChart} callback={changeChart} />
                    )}
                    <div id="chart-visibility-toggle" className="chart-visibility-toggle pointer" onClick={toggleChartVisibility}>
                        {isShowingChart ? (
                            <Icon.EyeOff height={24} width={24} className="chart-visibility-toggle-icon" />
                        ) : (
                            <Icon.Eye height={24} width={24} className="chart-visibility-toggle-icon" />
                        )}
                    </div>
                </div>
            )}

            {isShowingChart && !isFetchingModels && (
                <div className={"div-chart"}>
                    {!isComparingChart && <RatesEvolutionChart rates={chartData} key={`rates-chart-${chartData.join(",")}`} />}
                    {isComparingChart && (
                        <RatesEvolutionChart
                            rates={comparedChartData}
                            key={`compared-model-chart-${comparedChartData.join(",")}`}
                            options={{
                                models: {
                                    models: modelCohorts,
                                    selected: modelSelected,
                                    synced: modelSynced,
                                    syncData,
                                },
                            }}
                        />
                    )}
                </div>
            )}

            <div>
                <GenericContentPanel
                    title={`Cohort Name: ${cohort.name}`}
                    cohortType={cohort.type}
                    details={[
                        {key: "region", label: "Cohort Region", value: `${cohort.region_id ?? "-"}`},
                        {key: "cohort_type", label: "Cohort Type", value: cohort.type},
                        {key: "assigned_model", label: "Assigned Model", value: cohort.model?.name + " " + cohort.model?.version},
                    ]}
                    onDateRangeChange={onDatesChange}
                    startDate={startDate}
                    endDate={endDate}
                    onFilteredDaysChange={handleDowFilterChange}
                    cohortName={cohort.name}
                    regionId={cohort.region_id}
                    havePermission={havePermissionDetails}
                    isHistoryTab={hashTab === DashboardHash.CHANGELOG}
                    filterOnChange={handleHistoryFilterChange}
                    processId={processId}
                    modifiedField={modifiedField}
                    filterDate={filterDate}
                    handleFilterDate={handleFilterDateChange}
                    onSearchClick={handleApplyFilters}
                    isFetching={isFetchingHistory}
                />
            </div>

            {Configuration.getABTestingCohorts().includes(cohortName) ? (
                <div className={"div-alert"}>
                    <AlertMessage
                        customClass="ab-testing-message"
                        text="Be aware that this cohort is part of the Neumann A/B testing"
                        type="warning"
                        height="small"
                    />
                </div>
            ) : null}

            <div className="dynamic-tabs">
                <Tabs
                    selected={selectedTab}
                    onChange={handleTabChange}
                    variant="filled"
                    tabs={[
                        {
                            id: "tab-rates-evolution-dashboard",
                            label: "dashboard",
                            component: (
                                <CohortDashboard
                                    cohort={cohort}
                                    values={cohortDashboardValues}
                                    onChange={handleDashboardChange}
                                    havePermission={havePermissionDashboard}
                                />
                            ),
                            icon: (
                                <Icon.Pin
                                    className={`icon-tabs ${preference === DashboardHash.DASHBOARD ? "active-icon" : ""}`}
                                    width={19}
                                    height={19}
                                    onClick={() => handleTabPreference(DashboardHash.DASHBOARD)}
                                />
                            ),
                        },
                        {
                            id: "tab-rates-evolution-base-rates",
                            label: "base rates",
                            component: isFetchingRates ? (
                                <Loading className="main-loading-spinner" />
                            ) : (
                                <RatesTable
                                    builder={getCohortRateTableBuilder(_.map(visibleColumns, (c) => c.key))}
                                    data={tableData}
                                    onRowsChange={handleTableEdit}
                                    dowFilterOption={dowFilterOption}
                                    onSave={handleSave}
                                    changes={getChanges(cohort)}
                                    message={message}
                                    columnConfig={{
                                        visible: visibleColumns,
                                        hidden: hiddenColumns,
                                        onColumnsChange: handleColumnsChange,
                                        onResetDefault: getDefaultRateTableColumns,
                                    }}
                                    downloadConfig={{fileName: `rateAdjustments_cohort_${cohortName}`}}
                                    saveConfig={{hasExpiration: false}}
                                    cohortName={cohort.name}
                                    regionId={cohort.region_id}
                                    actionSidebar={{
                                        name: "Set",
                                        tooltip: "Set Unit Factor",
                                        type: "set",
                                        enabled: true,
                                        columnOptions: [{field: "factor_avg", label: "Unit Analyst Factor"}],
                                        onSave: handleSet,
                                    }}
                                    havePermission={havePermissionRates}
                                />
                            ),
                            icon: (
                                <Icon.Pin
                                    className={`icon-tabs ${preference === DashboardHash.RATES ? "active-icon" : ""}`}
                                    width={19}
                                    height={19}
                                    onClick={() => handleTabPreference(DashboardHash.RATES)}
                                />
                            ),
                        },
                        {
                            id: "tab-rates-evolution-change-log",
                            label: "change log",
                            component: <ChangeLog history={changes} />,
                            icon: (
                                <Icon.Pin
                                    className={`icon-tabs ${preference === DashboardHash.CHANGELOG ? "active-icon" : ""}`}
                                    width={19}
                                    height={19}
                                    onClick={() => handleTabPreference(DashboardHash.CHANGELOG)}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </React.Fragment>
    );
};
