import { RateService } from '../../services/RateService';
import * as _ from 'lodash';
import { IdentityToken, UserPreferenceConfigurationDTO, UserPreferenceDTO } from '@common/types';
import jwtDecode from 'jwt-decode';
import { StorageKeys } from '../../types';

export const loadUserPreferences = async (): Promise<UserPreferenceDTO> => {
    const identity = jwtDecode(localStorage.getItem(StorageKeys.ID_TOKEN)) as IdentityToken;
    const localStorageUserPreferences = localStorage.getItem('userPreferences');
    if (!_.isNull(localStorageUserPreferences)) {
        return JSON.parse(localStorageUserPreferences) as UserPreferenceDTO;
    }
    let user = await RateService.getUserPreference(identity.email);
    if (!user) {
        user = await RateService.createUserPreference({
            user_name: identity.name,
            email: identity.email,
            preferences: { components: [] },
        } as UserPreferenceDTO);
    }
    setLocalUserPreferences(user);
    return user;
};

export const updateUserPreferences = async (preferences: UserPreferenceConfigurationDTO) => {
    const userPreferences = await loadUserPreferences();
    await RateService.updateUserPreference({ ...userPreferences, preferences });
    setLocalUserPreferences({ ...userPreferences, preferences });
};

const setLocalUserPreferences = (preferences: UserPreferenceDTO) => {
    localStorage.setItem('userPreferences', JSON.stringify(preferences));
};

export const getLocalUserPreferences = () => {
    const localStorageUserPreferences = localStorage.getItem('userPreferences');
    return JSON.parse(localStorageUserPreferences) as UserPreferenceDTO;
};
