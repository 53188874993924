import React, { useState } from 'react';
import './EnvironmentFlag.scss';

interface EnvironmentFlagProps {
    color: 'green' | 'red' | 'yellow';
    label: string;
    placement: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    shadow?: boolean;
}

export const EnvironmentFlag: React.FC<EnvironmentFlagProps> = (props) => {
    const { placement, color, shadow, label } = props;
    const [isVisible, setVisibility] = useState(true);
    const shadowStyle = shadow ? 'shadow' : '';
    return (
        <>
            {isVisible && (
                <div className={`corner-ribbon ${placement} sticky ${color} ${shadowStyle}`} onClick={() => setVisibility(false)}>
                    {label}
                </div>
            )}
        </>
    );
};
