import React, { useState } from 'react';
import { ActionColumn, Column, FilterConfiguration, FilterField, FilterOption, OrderField } from '../../types/VirtualizedTable';
import { HeaderLabel } from './HeaderLabel/HeaderLabel';
import { Select } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import { UiUtils } from '../../utils';
import { SelectOption } from '../../types';
import { GenericInput } from '../GenericInput/GenericInput';
import { ColumnTooltip } from './ColumnTooltip';

interface HeaderProps {
    column: Column | ActionColumn;
    filtering?: {
        config: FilterConfiguration;
        onFilterChange: (currentFilters: FilterField[]) => void;
        filters: FilterField[];
    };
    ordering?: {
        onOrderChange: (orderBy: OrderField) => void;
        orderBy: OrderField;
    };
    componentId?: string;
}

const getSelectOptions = (options: any[], renderBoolean?: boolean): SelectOption[] => {
    //if options only have 1 o 0, then show true o false
    return options.map((option) => {
        let label = option;
        if (option === 1 && renderBoolean) {
            label = 'True';
        }
        if (option === 0 && renderBoolean) {
            label = 'False';
        }
        return {
            display: `${label}`,
            value: `${option}`,
        };
    });
};

export const Header: React.FC<HeaderProps> = (props) => {
    const { column, filtering, ordering, componentId } = props;
    const { label } = column;
    const [error, setError] = useState(false);
    const tooltip = column.tooltip && <ColumnTooltip {...column.tooltip} id={_.lowerCase(column.label).replaceAll(' ', '-')} />;

    if ('func' in column) {
        // is an ActionColumn
        return (
            <div className={`virtualized-table-header ${filtering ? 'filtered-action-header' : ''}`}>
                {column.label}
                {tooltip}
            </div>
        );
    }

    const getFilter = (field: string): FilterField => {
        return _.find(filtering?.filters, (filter) => {
            return filter.field === field;
        });
    };

    const orderingFunction = (field: string, order: string) => {
        if (_.isNil(ordering)) return;
        const { onOrderChange } = ordering;
        switch (order) {
            case 'asc': {
                ordering.orderBy = {
                    field,
                    order: 'desc',
                };
                break;
            }
            default: {
                ordering.orderBy = {
                    field,
                    order: 'asc',
                };
                break;
            }
        }
        onOrderChange(ordering.orderBy);
    };

    if (filtering?.config) {
        const { field } = column;
        const { config, onFilterChange } = filtering;

        const handleSelectChange = (field: string, e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const filter = value === 'ALL' ? null : value;
            const newFilter = { field, value: filter, type: 'select' };
            updateFilters(newFilter);
        };

        const handleInputChange = (field: string, e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const newFilter = { field, value, type: 'text' };
            updateFilters(newFilter);
        };

        const handleNumberInputChange = (field: string, e: number | string) => {
            const value = e?.toString();
            const newFilter = { field, value, type: 'number' };
            updateFilters(newFilter);
        };

        const handleRangeChange = (field: string, e: number | string, type: 'min' | 'max') => {
            let value = e?.toString();
            const filter = getFilter(field);

            const min = !_.isEmpty(value) && _.isFinite(+value) && type === 'min' ? value : (-UiUtils.MAX_INT_OZ).toString();
            const max = !_.isEmpty(value) && _.isFinite(+value) && type === 'max' ? value : UiUtils.MAX_INT_OZ.toString();
            const currentRange = (filter?.value || '').split(',');

            const currMin = currentRange[0] || (-UiUtils.MAX_INT_OZ).toString();
            const currMax = currentRange[1] || UiUtils.MAX_INT_OZ.toString();

            const range = type === 'min' ? [min, currMax] : [currMin, max];

            const newFilter = { field, value: range.join(','), type: 'range' };
            if (newFilter.value === (-UiUtils.MAX_INT_OZ).toString() + ',' + UiUtils.MAX_INT_OZ.toString()) {
                newFilter.value = null;
            }
            setError(!isValidRange(range, field));
            updateFilters(newFilter);
        };

        const updateFilters = (newFilter: FilterField) => {
            const filtered = _.filter(filtering.filters, (filter) => filter.field !== field);
            if (!_.isEmpty(newFilter.value)) {
                onFilterChange([...filtered, newFilter]);
            } else {
                onFilterChange(filtered);
            }
        };

        const getFieldConfig = (field: string): FilterOption => {
            return _.find(config.options, (option) => {
                return option.field === field;
            });
        };

        const getFilterInput = (filterOption: FilterOption) => {
            if (!filterOption) {
                return (
                    <input
                        data-testid={`${componentId}-input-${field}`}
                        type="text"
                        value={getFilter(field)?.value}
                        onChange={(e) => handleInputChange(field, e)}
                    />
                );
            }

            const { type } = filterOption;

            if (type === 'text') {
                return (
                    <input
                        data-testid={`${componentId}-input-${field}`}
                        disabled={filterOption.disabled}
                        className={filterOption.disabled ? 'input-disabled' : ''}
                        type="text"
                        value={getFilter(field)?.value}
                        onChange={(e) => handleInputChange(field, e)}
                    />
                );
            }

            if (type === 'select') {
                return getSelectInput(filterOption);
            }

            if (type === 'number') {
                return getNumberInput();
            }

            if (type === 'range') {
                return getRangeInput(filterOption);
            }
        };

        const getSelectInput = (fieldOption: FilterOption) => {
            return (
                <Select
                    data-testid={`${componentId}-input-${field}`}
                    customClass="filtered-header-select"
                    value={getFilter(field)?.value ?? 'ALL'}
                    options={getSelectOptions(fieldOption.values, fieldOption.options === 'boolean')}
                    onChange={(e) => {
                        handleSelectChange(field, e);
                    }}
                />
            );
        };

        const getNumberInput = () => {
            return (
                <GenericInput
                    dataTestId={`${componentId}-input-${field}`}
                    className="filtered-header-input"
                    value={getFilter(field)?.value}
                    onChange={(e) => handleNumberInputChange(field, e)}
                    min={0}
                    max={UiUtils.MAX_INT_OZ}
                />
            );
        };

        const isValidRange = (range, field): boolean => {
            const intOnly = getFieldConfig(field)?.options === 'int';
            let [min, max] = range;

            if (+min > +max) {
                return false;
            }

            return (intOnly && UiUtils.isInt(min) && UiUtils.isInt(max)) || (UiUtils.isFloat(min) && UiUtils.isFloat(max));
        };

        const getRangeInput = (fieldOption: FilterOption) => {
            const intOnly = fieldOption.options === 'int';
            const [min, max] = getFilter(field)?.value.split(',') || [];

            return (
                <div className={'range-filter'}>
                    <GenericInput
                        dataTestId={`${componentId}-input-${field}-min`}
                        decimals={!intOnly}
                        className={'min ' + (error && 'error')}
                        placeholder={'Min'}
                        value={min === (-UiUtils.MAX_INT_OZ).toString() ? null : min}
                        onChange={(e) => handleRangeChange(field, e, 'min')}
                        min={0}
                    />
                    <GenericInput
                        dataTestId={`${componentId}-input-${field}-max`}
                        decimals={!intOnly}
                        className={'max ' + (error && 'error')}
                        placeholder={'Max'}
                        value={max === UiUtils.MAX_INT_OZ.toString() ? null : max}
                        onChange={(e) => handleRangeChange(field, e, 'max')}
                        min={0}
                    />
                </div>
            );
        };

        const getHeader = () => {
            return (
                <HeaderLabel
                    label={label}
                    field={field}
                    order={ordering?.orderBy?.field === field ? ordering.orderBy?.order : null}
                    onOrderChange={orderingFunction}
                    tooltipText={column.disableSort?.tooltipText}
                />
            );
        };

        return (
            <div className="virtualized-table-header filtered-header">
                {!_.isUndefined(ordering) ? getHeader() : <div>{label}</div>}
                {getFilterInput(getFieldConfig(field))}
            </div>
        );
    }

    return (
        <div className="virtualized-table-header">
            <div>
                {label}
                {tooltip}
            </div>
        </div>
    );
};
