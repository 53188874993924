import { createAsyncThunk } from '@reduxjs/toolkit';
import { RateService } from '../../services/RateService';
import { setIsFetchingUnits } from './unit.slice';

export const fetchUnits = createAsyncThunk(
    'unit/fetchUnits',
    async (request: { size?: number; number?: number }, { rejectWithValue, dispatch }) => {
        try {
            const { size, number } = request;
            const isFetchingFirstPage = !size && !number;

            if (isFetchingFirstPage) {
                dispatch(setIsFetchingUnits(true));
            }

            const pageSize = size || 1000;
            const pageNumber = number || 1;
            //CLAF: added custom getUnits
            const units = await RateService.getUnits({
                'page[number]': pageNumber.toString(),
                'page[size]': pageSize.toString(),
                'filter[active]': '1',
            });

            if (units.rows.length > pageSize) {
                dispatch(setIsFetchingUnits(false));
                return units.rows;
            }

            const nextPage = pageNumber + 1;
            dispatch(fetchUnits({ size: pageSize, number: nextPage }));
            return units.rows;
        } catch (e) {
            dispatch(setIsFetchingUnits(false));
            return rejectWithValue(RateService.makeError('fetchUnits', e));
        }
    }
);

export const fetchRatesForUnit = createAsyncThunk(
    'unit/fetchRatesForUnit',
    async (
        request: { id: number; start: Date; end: Date; avg_rate: number; std_per: number; assigned_cohort: string },
        { rejectWithValue }
    ) => {
        try {
            const { id, start, end, avg_rate, std_per } = request;
            const rates = await RateService.getRatesForUnit(id, start, end, avg_rate, std_per);
            return { id, rates };
        } catch (e) {
            return rejectWithValue(RateService.makeError('fetchRatesForUnit', e));
        }
    }
);

export const fetchEnhancedUnit = createAsyncThunk('unit/fetchEnhancedUnit', async (request: { id: number }, { rejectWithValue }) => {
    try {
        const { id } = request;
        return await RateService.getEnhancedUnit(id);
    } catch (e) {
        return rejectWithValue(RateService.makeError('fetchRatesForUnit', e));
    }
});
