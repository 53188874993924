import React, { Fragment, KeyboardEvent, useEffect, useState } from 'react';
import './GenericContentPanel.scss';
import { DatePeriodConfiguration } from './DatePeriodConfiguration';
import { DowOptions, Message, StatusOptions } from '../../types';
import { DateRangePickers, GenericInput } from '..';
import { AlertMessage, Button, DatePicker, Icon, Input, Select, Tooltip } from '@vacasa/react-components-lib';
import { addDays, differenceInDays, format, isValid, startOfDay } from 'date-fns';
import { UiUtils } from '../../utils';
import { Configuration } from '../../Configuration';
import { history } from '../../App';
import { AppRoutes } from '../../Routes';
import { Switch, TextField } from '@material-ui/core';
import { DATE_FORMAT } from '@common/types';

interface GenericContentPanelProps {
    title: string | JSX.Element;
    details: { key: string; label: string; value?: string; content?: JSX.Element }[];
    startDate: Date;
    endDate: Date;
    holidayName?: string;
    monthIndex?: string;
    onDateRangeChange: (startDate: Date, endDate: Date, numberOfDays: number, holiday?: string) => void;
    onFilteredDaysChange: (filter: DowOptions) => void;
    onFilteredStatusChange?: (filter: StatusOptions) => void;
    regionId: number;
    cohortType: 'model' | 'custom' | 'strategic';
    cohortName?: string;
    unitId?: number;
    countryName?: string;
    message?: Message;
    havePermission?: boolean;
    isHistoryTab?: boolean;
    toggleSave?: {
        value: boolean;
        setToggle: () => void;
    };
    filterOnChange?: (processId?: string, filterDate?: string, modifiedField?: string) => void;
    processId?: string;
    onSearchClick?: () => void;
    modifiedField?: string;
    filterDate?: string;
    handleFilterDate?: (date: string) => void;
    onClearFilterDate?: () => void;
    isFetching?: boolean;
}

const FieldOptions = [
    {
        value: 'all',
        display: 'All',
    },
    {
        value: 'minrate',
        display: 'Minrate',
    },
    {
        value: 'maxrate',
        display: 'Maxrate',
    },
    {
        value: 'base_rate_override',
        display: 'Baserate Override',
    },
    {
        value: 'analyst_factor',
        display: 'Analyst Factor',
    },
    {
        value: 'minstay_override',
        display: 'Minstay Override',
    },
];

export const GenericContentPanel: React.FC<GenericContentPanelProps> = (props) => {
    const {
        cohortType,
        title,
        details,
        startDate,
        endDate,
        holidayName,
        monthIndex,
        onDateRangeChange,
        onFilteredDaysChange,
        onFilteredStatusChange,
        regionId,
        cohortName,
        unitId,
        countryName,
        message,
        havePermission,
        toggleSave,
        isHistoryTab,
        filterOnChange,
        processId,
        onSearchClick,
        modifiedField,
        filterDate,
        isFetching,
    } = props;
    const [selectedDowFilterOption, setSelectedDowFilterOption] = useState<DowOptions>('All');
    const [selectedStatusFilterOption, setSelectedStatusFilterOption] = useState<StatusOptions>('All');
    const [selectedDate, setSelectedDate] = useState<string>(filterDate);
    const [renderCounter, setRenderCounter] = useState(0);
    const numberOfDays = differenceInDays(startOfDay(endDate), startOfDay(startDate));

    useEffect(() => {
        setSelectedDate(filterDate);
    }, [filterDate]);

    const handleNumberOfDaysChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const amount = +e.target.value;
        if (amount > 540) {
            return;
        }
        if (amount && amount >= 1) {
            const date = addDays(startDate, +amount);
            onDateRangeChange(startDate, date, amount);
        }
    };

    const handleSaveToggle = () => {
        toggleSave?.setToggle();
    };

    const handleDowFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as DowOptions;
        setSelectedDowFilterOption(value);
        onFilteredDaysChange(value);
    };

    const handleStatusFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as StatusOptions;
        setSelectedStatusFilterOption(value);
        onFilteredStatusChange(value);
    };

    const resetDateRange = () => {
        const today = new Date();
        const end = addDays(today, Configuration.DEFAULT_DAYS_RANGE);
        onDateRangeChange(today, end, Configuration.DEFAULT_DAYS_RANGE);
    };

    const handleEditCustomCohortClick = () => {
        history.push(AppRoutes.CUSTOM_COHORT.replace(':name', cohortName));
    };

    const handleSelectField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        filterOnChange(processId, selectedDate, value);
    };

    const handleChangeProcessId = (value: string) => {
        filterOnChange(value, selectedDate, modifiedField);
    };

    const handleEnterProcessId = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            const value: string = (event.target as HTMLInputElement).value;
            filterOnChange(value, selectedDate, modifiedField);
            onSearchClick();
        }
    };

    const handleClearFilters = () => {
        filterOnChange('', '', 'all');
    };

    const handleDateChange = (newDate: string) => {
        if (newDate && isValid(newDate)) {
            const date = new Date(newDate);
            const transformedDate = format(date, DATE_FORMAT);
            setSelectedDate(transformedDate);
            filterOnChange(processId, transformedDate, modifiedField);
        } else {
            setSelectedDate('');
            filterOnChange(processId, '', modifiedField);
        }
        setRenderCounter((prevCounter) => prevCounter + 1);
    };

    let keyCounter = 0;
    return (
        <div className="content-panel-configuration">
            {message && (
                <div className="error-container">
                    <AlertMessage text={message.content} type={message.type} height="small" customClass="alert-message" />
                </div>
            )}
            <div className="content-panel-top-bar">
                <div className="content-panel-configuration-title">
                    <span>{title}</span>
                </div>
                {!isHistoryTab && (
                    <div className={'context-icons'}>
                        {toggleSave && (
                            <Tooltip message={`${toggleSave?.value ? 'Disable' : 'Enable'} save filters`}>
                                <Switch checked={toggleSave?.value} onChange={handleSaveToggle} color={'primary'} />
                            </Tooltip>
                        )}
                        <Tooltip message="Reset Date Range">
                            <span className={'refresh-criteria-icon'}>
                                <Icon.RefreshCW className="date-refresh-icon pointer" height={18} width={18} onClick={resetDateRange} />
                            </span>
                        </Tooltip>
                    </div>
                )}
            </div>

            <div className="divided-row">
                <div className="content-panel-details">
                    <div>
                        {details.map(({ key, label, value, content }) => {
                            const defaultContent = (
                                <div key={++keyCounter}>
                                    <strong>{label}</strong>
                                    {': '}
                                    {!value && <Fragment>{content}</Fragment>}
                                    {!content && <span>{value}</span>}
                                </div>
                            );
                            if (cohortType === 'model' || cohortType === 'strategic') {
                                return defaultContent;
                            } else {
                                switch (key) {
                                    // do not show assigned model on custom cohorts
                                    case 'assigned_model':
                                        return <div key={++keyCounter} />;
                                    case 'cohort_type':
                                        return (
                                            <div key={++keyCounter}>
                                                <strong>{label}</strong>
                                                {': '}
                                                <Tooltip message={`${havePermission ? 'Edit Custom Cohort' : 'Editor access required'}`}>
                                                    <span className="pointer">
                                                        {value}{' '}
                                                        <Icon.ExternalLink
                                                            height={20}
                                                            width={20}
                                                            className="external-link"
                                                            onClick={havePermission ? handleEditCustomCohortClick : () => {}}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        );
                                    default:
                                        return defaultContent;
                                }
                            }
                        })}
                    </div>
                    <div className={'link-row'}>
                        {unitId && <strong> Links: </strong>}
                        {unitId && (
                            <Tooltip message="Go to admin unit page">
                                <span className="pointer">
                                    <a href={Configuration.getAdminUnitPageUrl(unitId)} target={'_blank'} rel="noreferrer">
                                        <Icon.Clipboard height={20} width={20} className="external-link" />
                                    </a>
                                </span>
                            </Tooltip>
                        )}
                        {unitId && (
                            <Tooltip message="Go to unit resgrid">
                                <span className="pointer">
                                    <a
                                        href={Configuration.getUnitResGrid(unitId, startDate, numberOfDays)}
                                        target={'_blank'}
                                        rel="noreferrer">
                                        <Icon.Calendar height={20} width={20} className="external-link" />
                                    </a>
                                </span>
                            </Tooltip>
                        )}
                        {unitId && (
                            <Tooltip message="Go .com listing page">
                                <span className="pointer">
                                    <a href={Configuration.getUnitListingPage(unitId)} target={'_blank'} rel="noreferrer">
                                        <Icon.Globe height={20} width={20} className="external-link" />
                                    </a>
                                </span>
                            </Tooltip>
                        )}
                    </div>
                </div>

                {!isHistoryTab ? (
                    <div className="input-group date-range-selector">
                        <DateRangePickers
                            start={startDate}
                            end={endDate}
                            onDateRangeChange={onDateRangeChange}
                            decorators={{ start: 'Start Date', end: 'End Date' }}
                        />
                        <Tooltip message={'Max number of days allowed is 540'}>
                            <div className="input-days">
                                <Input
                                    value={numberOfDays}
                                    type="number"
                                    onChange={handleNumberOfDaysChanged}
                                    decoration="N° of Days"
                                    customClass={'days'}
                                    max={540}
                                />
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <div className={'input-filter'}>
                        <label>{'Process ID'}</label>
                        <GenericInput
                            value={processId}
                            onChange={handleChangeProcessId}
                            isString={true}
                            onKeyDown={handleEnterProcessId}
                            maxLength={36}
                            disable={isFetching}
                        />
                    </div>
                )}

                {!isHistoryTab ? (
                    <div className="input-group date-period-configuration">
                        <DatePeriodConfiguration
                            start={startDate}
                            end={endDate}
                            holidayName={holidayName}
                            monthIndex={monthIndex}
                            onDateRangeChange={onDateRangeChange}
                            filters={{ cohortName, regionId, countryName }}
                        />
                    </div>
                ) : null}

                {!isHistoryTab ? (
                    <div className="input-group date-range-filters">
                        <Select
                            label="Day Filter"
                            value={selectedDowFilterOption}
                            onChange={handleDowFilterChange}
                            options={UiUtils.getDoWFilterOptions()}
                            customClass="day-filter-input"
                        />

                        {onFilteredStatusChange && (
                            <Select
                                label="Status Filter"
                                value={selectedStatusFilterOption}
                                onChange={handleStatusFilterChange}
                                options={UiUtils.getStatusFilterOptions()}
                                customClass=""
                            />
                        )}
                    </div>
                ) : (
                    <div>
                        <div className="input-group date-minrate-filters">
                            <div>
                                <DatePicker
                                    variant="inline"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    disableToolbar={true}
                                    disabled={isFetching}
                                    inputValue={selectedDate}
                                    customClass="date-picker"
                                    autoOk={true}
                                    decoration={'Modified Date'}
                                    key={`${selectedDate}-${renderCounter}`}
                                    TextFieldComponent={(props) => (
                                        <TextField {...props} onChange={(e) => handleDateChange(e.target.value)} />
                                    )}
                                />
                            </div>
                            {unitId && (
                                <div>
                                    <Select
                                        value={modifiedField}
                                        onChange={handleSelectField}
                                        options={FieldOptions}
                                        label={'Modified field'}
                                        disabled={isFetching}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!isHistoryTab ? null : (
                    <div className="apply-filters-button">
                        {
                            <Button variant="info" onClick={onSearchClick}>
                                <div className={'search-icon-position'}>
                                    {`Apply`}
                                    <Icon.Search />
                                </div>
                            </Button>
                        }
                        {
                            <Button variant="info" onClick={handleClearFilters}>
                                <div className={'search-icon-position'}>
                                    {`Clear`}
                                    <Icon.RefreshCW />
                                </div>
                            </Button>
                        }
                    </div>
                )}
            </div>
        </div>
    );
};
