import React, { useEffect, useState } from 'react';
import { AlertMessage, Button, DraggableGrid, Icon, Tooltip } from '@vacasa/react-components-lib';
import './UnitDashboard.scss';
import { EditableBox, EditableBoxProps, IconToolBar, InfoBox, InfoBoxProps } from '..';
import { Message, RegularChange, SelectOption, UnitDashboardValues, UnitWithRates } from '../../types';
import { UiUtils, UnitUtils } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, selectCohortsWithModel, selectUnitById, updateUnit } from '../../store';
import { UnitBoxes } from './UnitBoxes';
import { SaveFormValues, SaveSidebar } from '../SaveSidebar/SaveSidebar';
import { RateService } from '../../services/RateService';
import { DATE_FORMAT, EnhancedUnitDTO, NEUMANN_1_0_ID, NEUMANN_1_1_ID, NEW_DATE_LIMIT } from '@common/types';
import { LoggingService } from '../../services/LoggingService';
import { AppRoutes } from '../../Routes';
import { capitalize, concat, first, isEmpty, isNil, map } from 'lodash';
import { addDays, parse } from 'date-fns';

interface UnitDashboardProps {
    unit: UnitWithRates;
    values: UnitDashboardValues;
    onChange: (values: Partial<UnitDashboardValues>) => void;
    onOrderChanged: (boxes: { [id: string]: number }) => void;
    boxesOrder: { [id: string]: number };
    disabled?: boolean;
    havePermission?: boolean;
}

export const UnitDashboard: React.FC<UnitDashboardProps> = (props) => {
    const { unit, values, onChange, disabled, havePermission, onOrderChanged, boxesOrder } = props;
    const { location, details } = unit;
    const cohortsWithModel = useSelector(selectCohortsWithModel);
    const dispatch = useDispatch();
    const [uiAlert, setUiAlert] = useState<Message | null>(null);
    const cohortError = useSelector((state: AppState) => state.cohort.error);
    const [isShowingSave, setIsShowingSave] = useState(false);
    const [changes, setChanges] = useState<RegularChange[]>([]);
    const [isValidAvg, setIsValidAvg] = useState<boolean>(true);
    const [isValidStd, setIsValidStd] = useState<boolean>(true);
    const [isOrderingDashboard, setIsOrderingDashboard] = useState<boolean>(false);
    const [newBoxesOrder, setNewBoxesOrder] = useState({});
    const unitWithCohort = useSelector((state: AppState) => selectUnitById(state, unit.id));
    let cohortWithModel = cohortsWithModel.find((cohort) => cohort.name === values.assigned_cohort?.value);

    useEffect(() => {
        const updatedChanges: RegularChange[] = [];
        const assignedCohortChanged = values.assigned_cohort?.value !== unit.assigned_cohort;
        if (assignedCohortChanged) {
            if (cohortWithModel?.model?.type === 'unit-level') {
                validatePredictions(unit.id, cohortWithModel.model_id);
            }
            updatedChanges.push({
                key: 'assigned_cohort',
                value: values.assigned_cohort == null ? null : values.assigned_cohort?.value,
                label: 'Assigned Cohort',
            });
        }
        const averageRateChanged = values.average_rate !== unit.average_rate;
        if (averageRateChanged) {
            updatedChanges.push({ key: 'average_rate', value: values.average_rate, label: 'Average Rate' });
        }
        const deviationPercentageChanged = values.std !== unit.std;
        if (deviationPercentageChanged) {
            updatedChanges.push({ key: 'std', value: values.std, label: 'Deviation%' });
        }
        setChanges(updatedChanges);
    }, [values]);

    useEffect(() => {
        if (changes.length > 0) {
            setUiAlert(UiUtils.getPendingChangesMessage());
        } else {
            setUiAlert(null);
        }

        if (!!cohortError) {
            setUiAlert(UiUtils.getErrorMessage(cohortError.message));
        }
    }, [cohortError, changes]);

    const handleGoToCustomCohort = (customCohort: SelectOption) => {
        if (customCohort) {
            return window.open(AppRoutes.COHORT_EVOLUTION.replace(':name', customCohort.display.toString()), '_blank');
        }
    };

    const isUnitWithNewDisplayDate = (date: string): boolean => {
        if (!date) {
            return false;
        }
        const today = new Date();
        const parsedDate = parse(date, DATE_FORMAT, today);
        return addDays(parsedDate, NEW_DATE_LIMIT) >= today;
    };

    const validatePredictions = async (unitId: number, modelId: number) => {
        const predictions = await RateService.getUnitPredictions(unitId, modelId);
        if (!predictions.length) {
            setUiAlert(UiUtils.getUnitWithoutPredictions());
        }
    };

    const handleAverageRateChange = (value: number) => {
        onChange({ average_rate: value });
        setIsValidAvg(false);
        if (value > 0) {
            setIsValidAvg(true);
        }
    };

    const handleDeviationPercentageChange = (value: number) => {
        onChange({ std: value });
        setIsValidStd(false);
        if (value > 0) {
            setIsValidStd(true);
        }
    };

    const handleSave = async (data: SaveFormValues) => {
        const unitData = UnitUtils.transformSaveFormToEnhancedUnitDto(data);
        try {
            const updatedUnit: EnhancedUnitDTO = await RateService.updateUnit(unit.id, unitData);
            dispatch(updateUnit(updatedUnit));
            setUiAlert(UiUtils.getSaveSuccessMessage());
            document.location.reload();
        } catch (e) {
            setUiAlert(UiUtils.getErrorMessage(e));
            LoggingService.error(`error saving unit dashboard changes `, { unit_id: unit?.id, params: { update: unitData } });
        } finally {
            setIsShowingSave(false);
        }
    };

    const isSaveDisabled = isEmpty(changes) || !isValidAvg || !isValidStd;

    const infoBoxes: InfoBoxProps[] = [];

    if (details) {
        infoBoxes.push({
            id: UnitBoxes.DATE_OF_GO_ACTIVE,
            title: 'Go Active Date',
            content: [
                {
                    label: details.live_date,
                    headingLevel: 'h3',
                },
            ],
            footer: { subtitle: 'Date of Go Active' },
            tip: 'Go Active Date',
            direction: 'horizontal',
        });

        infoBoxes.push({
            id: UnitBoxes.DISPLAY_DATE,
            title: 'Go Public Date',
            content: [
                {
                    label: details.display_date ?? '-',
                    headingLevel: 'h3',
                },
            ],
            footer: { subtitle: 'As of this day on the channels' },
            tip: 'Display Date',
            direction: 'horizontal',
            flag_class: isUnitWithNewDisplayDate(details.display_date) ? 'new' : '',
        });

        infoBoxes.push({
            id: UnitBoxes.UNIT_SOURCE,
            title: 'Source of Unit',
            content: [
                {
                    label: capitalize(details.source),
                    headingLevel: 'h3',
                },
            ],
            footer: { subtitle: 'Source of the Unit' },
            tip: 'Source of the Unit',
            direction: 'horizontal',
        });

        infoBoxes.push({
            id: UnitBoxes.CONTRACT_RATE,
            title: 'Contract Rate',
            content: [
                {
                    label: UiUtils.toPercentage(details.contract_rate),
                    headingLevel: 'h3',
                },
            ],
            footer: { subtitle: 'Percentage from Rate' },
            tip: 'Percentage from Rate',
            direction: 'horizontal',
        });

        const vacasaReviewsInfo = first(details.review_metrics.filter((r) => r.channel === 'vacasa'));

        infoBoxes.push({
            id: 'local_team',
            title: 'Local Team',
            content: [
                {
                    label: unit.details.local_team.general_manager,
                    headingLevel: 'h5',
                    text: 'General Manager:',
                },

                {
                    label: unit.details.local_team.local_operations_manager,
                    headingLevel: 'h5',
                    text: 'Local Operation Manager:',
                },
            ],
            footer: { subtitle: 'Local Team' },
            tip: 'General Manager | Local Operation Manager',
            direction: 'vertical',
        });

        infoBoxes.push({
            id: UnitBoxes.REVIEWS,
            title: 'Reviews Metrics',
            content: [
                {
                    label: vacasaReviewsInfo.number_of_reviews.toString(),
                    headingLevel: 'h3',
                    text: 'Number',
                },
                {
                    label: Number.isInteger(vacasaReviewsInfo.average_reviews)
                        ? vacasaReviewsInfo.average_reviews.toString()
                        : vacasaReviewsInfo.average_reviews.toFixed(2),
                    headingLevel: 'h3',
                    text: 'Average',
                },
            ],
            direction: 'horizontal',
            footer: { subtitle: 'Vacasa Reviews' },
            tip: 'Number | Average of Reviews',
        });

        infoBoxes.push({
            id: UnitBoxes.LONG_STAY_DISCOUNT,
            title: 'Long Stay Discount',
            content: [
                {
                    label: details.ltd_exclude.toString(),
                    headingLevel: 'h3',
                },
            ],
            footer: { subtitle: 'Exclude from Long Stay Discount' },
            tip: 'Exclude from Long Stay Discount',
            direction: 'horizontal',
        });

        infoBoxes.push({
            id: UnitBoxes.MIN_MINSTAY,
            title: 'Min Minstay',
            content: [
                {
                    label: `${isNil(unit.min_min_stay) ? '-' : unit.min_min_stay.toString()}`,
                    headingLevel: 'h3',
                },
            ],
            footer: { subtitle: 'Owner Minstay' },
            tip: 'Owner Minstay',
            direction: 'horizontal',
            note: details.notes_details.custom_minstay,
        });

        infoBoxes.push({
            id: UnitBoxes.MIN_RATE,
            title: 'Min Rate',
            content: [
                {
                    label: isNil(values.min_rate) ? '-' : values.min_rate.toString() + ` ${values.currency}`,
                    headingLevel: 'h3',
                },
            ],
            footer: { subtitle: 'Owner Minimum Rate' },
            tip: 'Owner Minimum Rate',
            direction: 'horizontal',
            note: details.notes_details.custom_rates,
        });
    }

    infoBoxes.push({
        id: UnitBoxes.LOCATION,
        title: 'Location',
        content: [
            {
                headingLevel: 'h3',
                label: location.state,
            },
        ],
        footer: {
            url: `https://www.vacasa.com/usa/${location.state}`,
            subtitle: `${location.city}, ${location.state}`,
        },
        tip: "Unit's location",
        direction: 'horizontal',
    });

    infoBoxes.push({
        id: UnitBoxes.ROOMS,
        title: 'Rooms',
        type: 'Rooms',
        content: [
            {
                headingLevel: 'h5',
                label: 'Bathrooms:',
                object: [
                    {
                        label: `${unit.full_baths ?? '-'}`,
                        text: 'full',
                    },
                    {
                        label: `${unit.half_baths ?? '-'}`,
                        text: 'half',
                    },
                ],
            },
            {
                headingLevel: 'h5',
                label: 'Bedrooms:',
                object: [
                    {
                        label: `${unit.bedroom_count ?? '-'}`,
                        text: '',
                    },
                ],
            },
        ],
        direction: 'vertical',
        footer: { subtitle: 'Unit' },
        tip: 'Number of Bathrooms and Bedrooms',
    });

    infoBoxes.push({
        id: UnitBoxes.CURRENCY,
        title: 'Local Currency',
        content: (
            <div>
                <h3>{unit?.currency_code ?? 'USD'}</h3>
                {values.exchange_rate && (
                    <span className={'center light-gray'}>
                        Exchange rate:
                        <br />1 USD = {values.exchange_rate} {unit.currency_code}
                    </span>
                )}
            </div>
        ),
        direction: 'horizontal',
        footer: { subtitle: 'Unit Currency' },
        tip: 'Unit Currency',
    });

    /*
    infoBoxes.push({
        id: UnitBoxes.LOCAL_RESTRICTIONS,
        title: 'Owner/Local restrictions',
        content: <Icon.FileText className="pointer download-restrictions-icon" height={42} width={42} onClick={downloadRestrictions} />,
        footer: { subtitle: 'Download Restrictions', onClick: downloadRestrictions },
        tip: 'Download Restrictions',
        direction: 'horizontal',
    });
*/
    const getChannelMarketShareData = (channelMarketShare) => {
        if (isEmpty(channelMarketShare)) return [];
        const sortedData = [...channelMarketShare].sort((a, b) => (a.channel > b.channel ? 1 : -1));
        const capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        return sortedData.map((channel) => {
            const displayData = JSON.stringify({
                percent: !isNil(channel.percent) ? parseFloat((channel.percent * 100).toFixed(1)) : '-',
                reservation: !isNil(channel.reservation) && channel.reservation !== 0 ? channel.reservation : '-',
                rent: !isNil(channel.rent) ? parseFloat(channel.rent.toFixed(1)) : '-',
            });
            return { value: `${capitalizeWord(channel.channel)}`, display: displayData };
        });
    };
    const isNeumann = [NEUMANN_1_0_ID, NEUMANN_1_1_ID].includes(unitWithCohort.cohort?.rate_model?.model_id);

    const editableBoxes: EditableBoxProps[] = [
        {
            id: UnitBoxes.RATE_CONFIGURATIONS,
            title: 'Configurations',
            tip: 'Rate Configurations',
            textBox: {
                title: 'Suggested Average Rate',
                description: `${values.suggested_average_rate ?? '-'}`,
            },
            type: 'input',
            inputs: [
                {
                    label: 'Average Rate:',
                    value: values.average_rate,
                    decimals: false,
                    min: 1,
                    onChange: handleAverageRateChange,
                    inline: true,
                    disable: disabled,
                    invalid: !isValidAvg,
                    tooltip: !isValidAvg ? 'Avg does not have a valid value' : '',
                },
                {
                    label: '% Deviation:',
                    value: values.std,
                    decimals: true,
                    max: 1,
                    min: 0,
                    onChange: handleDeviationPercentageChange,
                    tooltip: isNeumann ? 'Deviation % has no impact on this model' : !isValidStd ? 'Std does not have a valid value' : '',
                    disable: isNeumann || disabled,
                    inline: true,
                    invalid: !isValidStd,
                },
            ],
            havePermission: havePermission,
        },
        {
            id: UnitBoxes.CUSTOM_COHORTS,
            title: 'Custom Cohort',
            tip: 'Custom Cohort',
            type: 'customCohort',
            footer: { subtitle: 'Unit in ' + values.customCohortsOptions?.length + ' custom cohort' },
            options: values.customCohortsOptions ?? [],
            value: null,
            onChange: handleGoToCustomCohort,
        },
        {
            id: UnitBoxes.CHANNEL_MARKET_SHARE_30,
            title: 'Channel Market Share 30d',
            tip: 'Sum of percentages could eventually have an error range of 0.1%',
            type: 'channelMarketShare',
            footer: { subtitle: 'Channel | percent | reservation | rent' },
            options: getChannelMarketShareData(details?.channel_market_share_30),
            value: null,
            label: 'test',
        },
        {
            id: UnitBoxes.CHANNEL_MARKET_SHARE_365,
            title: 'Channel Market Share 365d',
            tip: 'Sum of percentages could eventually have an error range of 0.1%',
            type: 'channelMarketShare',
            footer: { subtitle: 'Channel | percent | reservation | rent' },
            options: getChannelMarketShareData(details?.channel_market_share_365),
            value: null,
            label: 'test',
        },
    ];

    let items = concat(
        map(editableBoxes, (box, i) => ({ id: box.id, content: <EditableBox key={i} {...box} /> })),
        map(infoBoxes, (box, i) => ({ id: box.id, content: <InfoBox key={i} {...box} /> }))
    );

    if (!isEmpty(boxesOrder)) {
        items = items.sort((a, b) => {
            return boxesOrder[a.id] - boxesOrder[b.id];
        });
    }

    const handleSaveLayout = () => {
        setIsOrderingDashboard(false);
        if (!isEmpty(newBoxesOrder)) {
            onOrderChanged(newBoxesOrder);
        }
    };

    const handleOrderChanged = (orderedItems: { [id: string]: number }) => {
        setNewBoxesOrder(orderedItems);
    };

    return (
        <div className="unit-dashboard">
            <div className="actions-header">
                {uiAlert && !disabled && (
                    <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small" />
                )}

                <div className="unit-dashboard-preferences-container">
                    <div>
                        {isOrderingDashboard && (
                            <div className="save-button-container">
                                <IconToolBar
                                    icons={[
                                        {
                                            icon: (
                                                <Icon.XCircleInverse
                                                    className="tool-bar-icons pointer save-button"
                                                    height={24}
                                                    width={24}
                                                />
                                            ),
                                            id: 'save-unit-dashboard-preferences',
                                            tip: 'Close & Save The Layout',
                                            onClick: handleSaveLayout,
                                        },
                                    ]}
                                />
                                <span className="save-text">Close & Save The Layout</span>
                            </div>
                        )}
                    </div>
                    <div>
                        {!isOrderingDashboard && (
                            <IconToolBar
                                icons={[
                                    {
                                        icon: <Icon.Grid className="tool-bar-icons pointer" height={24} width={24} />,
                                        id: 'units-dashboard-preferences',
                                        tip: 'Edit Box Order Mode',
                                        onClick: () => {
                                            setIsOrderingDashboard(true);
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
                <Tooltip message={`${havePermission ? '' : 'Editor access required'}`}>
                    <div>
                        <Button
                            variant="secondary"
                            onClick={() => setIsShowingSave(true)}
                            disabled={isSaveDisabled || disabled || !havePermission}>
                            Save
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <div className={`dashboard-boxes ${isOrderingDashboard ? 'make-draggable' : ''}`}>
                <div className="grid">
                    {isOrderingDashboard ? (
                        <DraggableGrid items={items} onOrderChanged={handleOrderChanged} />
                    ) : (
                        items.map((item) => <div>{item.content}</div>)
                    )}
                </div>
            </div>
            {isShowingSave && !isSaveDisabled && (
                <SaveSidebar
                    type="regular"
                    hasChangeReason={true}
                    hasExpirationDays={false}
                    onSave={handleSave}
                    changes={changes}
                    onClose={() => setIsShowingSave(false)}
                />
            )}
        </div>
    );
};
