import {
    AccessToken,
    BulkProcessType,
    CustomCohortCriteriaDTO,
    DATE_FORMAT,
    DoW,
    IdentityToken,
    OnlyNumbersRegex,
    Scopes,
    TwoDecimalNumberRegex,
    UIComponentConfiguration,
    UIComponentsKeys,
    UIConfigurationBoxesComponent,
    UIConfigurationChartComponent,
    UIConfigurationDashboardComponent,
    UIConfigurationPreferenceTabs,
    UIConfigurationTableComponent,
    UISectionKeys,
    UnitDateCurrentStatus,
    UserPreferenceConfigurationDTO,
} from '@common/types';
import * as _ from 'lodash';
import { addDays, format, getYear } from 'date-fns';
import {
    BaseRateTableValues,
    CohortFactorAndStatusUnitTableValues,
    DashboardHash,
    DowOptions,
    Message,
    StatusOptions,
    StorageKeys,
} from '../types';
import { CurrencyUtils, parseDate } from '@common/utils';
import jwtDecode from 'jwt-decode';
import decode from 'jwt-decode';
import { ColumnDescription } from '../components/VirtualizedTable/ColumnConfiguration';
import { ActionColumn, Column, Row } from '../types/VirtualizedTable';
import { getLocalUserPreferences } from '../localstorage';
import { Configuration } from '../Configuration';

const dowByOption: { [key in DowOptions]: DoW[] } = {
    All: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    Monday: ['Mon'],
    Tuesday: ['Tue'],
    Wednesday: ['Wed'],
    Thursday: ['Thu'],
    Friday: ['Fri'],
    Saturday: ['Sat'],
    Sunday: ['Sun'],
    Weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu'],
    Weekends: ['Fri', 'Sat'],
};

const statusByOption: { [key in StatusOptions]: UnitDateCurrentStatus[] } = {
    All: ['Available', 'Vacasa Hold', 'Owner Hold', 'Booked', 'N/A'],
    Available: ['Available'],
    'Vacasa Hold': ['Vacasa Hold'],
    'Owner Hold': ['Owner Hold'],
    Booked: ['Booked'],
    'N/A': ['N/A'],
};

export class UiUtils {
    public static MAX_INT_OZ = Math.pow(2, 31) - 1; // max number for int4

    public static CSV_HEADER_MAX_ROWS = 2;

    public static getDoWFilterOptions = () => _.map(dowByOption, (value, key) => ({ value: key, display: key }));

    public static getStatusFilterOptions = () => _.map(statusByOption, (value, key) => ({ value: key, display: key }));

    public static getDoWFilterFromOption = (option: DowOptions) => dowByOption[option];

    public static getStatusFilterFromOption = (option: StatusOptions) => statusByOption[option];

    public static getHolidayYearOptions = () => {
        const currentYear = getYear(new Date());
        const threeYearsFromNow = currentYear + 3;

        const holidayYearOptions = [];
        let year = currentYear - 9; // nine years ago;
        while (year <= threeYearsFromNow) {
            holidayYearOptions.push({ value: year, display: year });
            year++;
        }

        return holidayYearOptions;
    };

    public static formatToCurrency = (value: number, currency: string = 'USD') => CurrencyUtils.formatCurrency(value, currency);

    public static transformInPercentage = (value: number) => `${Math.round(value * 100)}%`;

    public static getPendingChangesMessage = (): Message => ({ type: 'warning', content: 'You have unsaved changes' });

    public static getEmptyModelMessage = (): Message => ({
        type: 'warning',
        content: 'Save is disabled until a model is selected',
    });

    public static getEmptyNameMessage = (): Message => ({
        type: 'warning',
        content: 'Save is disabled, Cohort name cannot be empty',
    });

    public static getErrorMessage = (e: string): Message => ({ type: 'error', content: `An error occurred: ${e}` });

    public static getBulkSuccessMessage = (): Message => ({
        type: 'success',
        content: `File sent successfully. The data may take a while to be charged`,
    });

    public static getCohortDeleteSuccessMessage = (): Message => ({
        type: 'success',
        content: `Custom Cohort deleted successfully`,
    });
    public static getCohortUnitsDeleteSuccessMessage = (): Message => ({
        type: 'success',
        content: `Units from cohort removed successfully`,
    });
    public static getCohortDeleteProcessingMessage = (): Message => ({
        type: 'info',
        content: `Custom Cohort is being deleted, may take a while to reflect`,
    });
    public static getCohortDeleteErrorMessage = (e: string): Message => ({
        type: 'error',
        content: `An error occurred trying to delete the cohort ,${e}`,
    });
    public static getCohortUnitsDeleteErrorMessage = (e: string): Message => ({
        type: 'error',
        content: `An error occurred trying to remove units from the cohort ,${e}`,
    });

    public static getBulkErrorMessage = (e: string): Message => ({
        type: 'error',
        content: `An error occurred trying to send the file:  ${e}`,
    });
    public static getBulkEmptyFileMessage = (): Message => ({
        type: 'error',
        content: `You are trying to send an empty file, please check your csv file`,
    });
    public static getBulkFileExceededMessage = (fileType: BulkProcessType): Message => ({
        type: 'error',
        content: `The file exceeds the maximum of ${UiUtils.getMaxCSVLength(fileType).toLocaleString()} rows. Please update the file`,
    });
    public static getSaveSuccessMessage = (): Message => ({
        type: 'success',
        content: `Changes submitted successfully. They may take a while to reflect`,
    });

    public static getCustomUnitsWarning = (notAddedUnits?: number[]): Message => ({
        type: 'warning',
        content: `${
            notAddedUnits.length > 0
                ? 'The following units were not added: ' +
                  notAddedUnits +
                  ' check that the units to add are active and are not added in the criteria'
                : ''
        }`,
    });

    public static getUnitNotFoundMessage = (id: number): Message => ({
        type: 'error',
        content: `Unit ${id} does not exist or the unit is not active`,
    });

    public static getUnitWithoutPredictions = (): Message => ({
        type: 'warning',
        content:
            'You have unsaved changes. Cohort Factors will not be applied for this unit since the unit doesn’t have predictions available yet',
    });

    public static getNoCitiesForStateMessage = (): Message => ({
        type: 'warning',
        content: 'The selected State does not have cities in our database.',
    });

    public static getPreviewWarning = (): Message => ({
        type: 'warning',
        content: 'There was an error getting the preview data, you could save the modification in any case.',
    });

    public static getPreviewNeumannWarning = (): Message => ({
        type: 'warning',
        content:
            "There was an error getting the preview for Neumann model, you could save the modification in any case. Preview's are available only from today up to 548 days in the future.",
    });

    public static toPercentage(value: number): string {
        const result = Number(value * 100);
        return result === 0 ? `0%` : `${result.toFixed(2)}%`;
    }

    public static getFilterParamsForCriteria(criteria: CustomCohortCriteriaDTO) {
        let params = {};
        if (!_.isEmpty(criteria?.destinations)) params['filter[destinations][in]'] = JSON.stringify(criteria.destinations);
        if (!_.isEmpty(criteria?.locations)) params['filter[locations][in]'] = JSON.stringify(criteria.locations);
        if (!_.isEmpty(criteria?.amenities)) params['filter[amenities][in]'] = JSON.stringify(criteria.amenities);
        if (!_.isEmpty(criteria?.housing_types)) params['filter[housing_types][in]'] = JSON.stringify(criteria.housing_types);
        if (!_.isUndefined(criteria?.bathrooms.min)) params['filter[bathrooms][in]'] = JSON.stringify(criteria.bathrooms);
        if (!_.isUndefined(criteria?.bedrooms.min)) params['filter[bedrooms][in]'] = JSON.stringify(criteria.bedrooms);
        if (!_.isUndefined(criteria?.minMinStay.min)) params['filter[min_min_stay][in]'] = JSON.stringify(criteria.minMinStay);

        return params;
    }

    public static isFloat(val: string): boolean {
        const asNumber = Number(val);
        const isNumeric = _.isNumber(asNumber) && !_.isNaN(asNumber);
        return isNumeric && (OnlyNumbersRegex.test(val) || TwoDecimalNumberRegex.test(val));
    }

    public static isInt(val: string): boolean {
        const asNumber = Number(val);
        const isNumeric = _.isNumber(asNumber) && !_.isNaN(asNumber);
        return isNumeric && OnlyNumbersRegex.test(val);
    }

    public static getEmailForLoggedInUser = (): string => {
        const identity = jwtDecode(localStorage.getItem(StorageKeys.ID_TOKEN)) as IdentityToken;
        return identity?.email;
    };

    public static getSelectedChartLines = (userConfig: UIConfigurationChartComponent, defaultSelected: string[]): string[] => {
        const selectedColumns = userConfig ? _.map(userConfig.visible, (visible) => visible.key) : defaultSelected;

        const selected: string[] = [];

        for (const key of selectedColumns) {
            selected.push(key);
        }

        return selected;
    };

    private static getPreferenceValue(daysConfig: UIConfigurationDashboardComponent, name: string) {
        return _.head(_.filter(daysConfig.visible, (visible) => visible.key === name))?.value;
    }

    public static getSelectedDashboardValue = (
        daysConfig: UIConfigurationDashboardComponent,
        startDate: Date,
        endDate: Date
    ): { start: Date; end: Date; holidayName: string; monthIndex: string; toggle: boolean } => {
        if (!daysConfig) {
            return {
                start: startDate,
                end: endDate,
                holidayName: null,
                monthIndex: null,
                toggle: false,
            };
        }
        //Get the first coincidence of start_date and end_date
        let user_preference_start = UiUtils.getPreferenceValue(daysConfig, 'start_date');

        if (user_preference_start === 'today') {
            user_preference_start = format(new Date(), DATE_FORMAT);
        }
        const start = parseDate(user_preference_start) ?? startDate;

        let numberOfDays = UiUtils.getPreferenceValue(daysConfig, 'number_of_days');
        const end = numberOfDays ? addDays(start, parseInt(numberOfDays)) : endDate;

        let holiday = UiUtils.getPreferenceValue(daysConfig, 'holiday');
        let monthIndex = UiUtils.getPreferenceValue(daysConfig, 'month_index');
        let toggle = UiUtils.getPreferenceValue(daysConfig, 'toggle') ?? 'true';

        if (_.isNil(user_preference_start) || _.isNil(numberOfDays)) {
            return {
                start: startDate,
                end: endDate,
                holidayName: holiday,
                monthIndex: monthIndex,
                toggle: toggle === 'true',
            };
        }

        return {
            start,
            end,
            holidayName: holiday,
            monthIndex: monthIndex,
            toggle: toggle === 'true',
        };
    };

    public static getVisibleAndHiddenColumns = (
        userConfig: UIConfigurationTableComponent,
        availableColumns: { [key: string]: Column | ActionColumn },
        defaultVisible: string[]
    ): { visible: ColumnDescription[]; hidden: ColumnDescription[] } => {
        const visibleColumns = userConfig ? _.map(userConfig.visible, (visible) => visible.key) : defaultVisible;

        const visible: ColumnDescription[] = [];
        const hidden: ColumnDescription[] = [];

        for (const key of visibleColumns) {
            const label = availableColumns[key]?.label;
            visible.push({ key, label });
        }

        for (const key of _.keys(availableColumns)) {
            const label = availableColumns[key]?.label;
            if (!visibleColumns.includes(key) && key !== 'date') {
                hidden.push({ key, label });
            }
        }

        return { visible, hidden };
    };

    public static getUpdatedTablePreferences = (
        section: UISectionKeys,
        key: UIComponentsKeys,
        config: { visible: ColumnDescription[]; hidden: ColumnDescription[] }
    ): UserPreferenceConfigurationDTO => {
        const updatedRatesTableUIComponent: UIConfigurationTableComponent = {
            type: 'TABLE',
            section,
            key,
            visible: config.visible,
            hidden: config.hidden,
        };

        const preferences = getLocalUserPreferences()?.preferences || { components: [] };

        const updatedComponents = [...preferences.components];

        const ratesTableConfigIndex = _.findIndex(
            updatedComponents,
            (component) => component.type === 'TABLE' && component.key === key && component.section === section
        );

        if (ratesTableConfigIndex > -1) {
            updatedComponents.splice(ratesTableConfigIndex, 1, updatedRatesTableUIComponent);
        } else {
            updatedComponents.push(updatedRatesTableUIComponent);
        }

        return { ...preferences, components: updatedComponents };
    };

    public static getUpdatedChartPreferences = (
        section: UISectionKeys,
        key: UIComponentsKeys,
        config: { selected: string[] }
    ): UserPreferenceConfigurationDTO => {
        const updatedRatesChartUIComponent: UIConfigurationChartComponent = {
            type: 'CHART',
            section,
            key,
            visible: _.map(config.selected, (sel) => ({ key: sel })),
        };

        const preferences = getLocalUserPreferences()?.preferences || { components: [] };

        const updatedComponents = [...preferences.components];

        const ratesChartConfigIndex = _.findIndex(
            updatedComponents,
            (component) => component.type === 'CHART' && component.key === key && component.section === section
        );

        if (ratesChartConfigIndex > -1) {
            updatedComponents.splice(ratesChartConfigIndex, 1, updatedRatesChartUIComponent);
        } else {
            updatedComponents.push(updatedRatesChartUIComponent);
        }

        return { ...preferences, components: updatedComponents };
    };

    public static getUpdatedDashboardPreferences = (
        section: UISectionKeys,
        key: UIComponentsKeys,
        config: { start: Date; numberOfDays: number; holiday: string; monthIndex: string; toggle: boolean }
    ): UserPreferenceConfigurationDTO => {
        let startDate = 'today';
        if (format(config.start, DATE_FORMAT) !== format(new Date(), DATE_FORMAT)) {
            startDate = format(config.start, DATE_FORMAT);
        }

        const updatedRatesDashboardUIComponent: UIConfigurationDashboardComponent = {
            type: 'DASHBOARD',
            section,
            key,
            visible: [
                { key: 'start_date', value: startDate },
                { key: 'number_of_days', value: config.numberOfDays.toString() },
                { key: 'holiday', value: config.holiday },
                { key: 'month_index', value: config.monthIndex },
                { key: 'toggle', value: `${config.toggle}` },
            ],
        };

        const preferences = getLocalUserPreferences()?.preferences || { components: [] };
        const updatedComponents = [...preferences.components];
        const ratesDashboardConfigIndex = _.findIndex(
            updatedComponents,
            (component) => component.type === 'DASHBOARD' && component.key === key && component.section === section
        );

        if (ratesDashboardConfigIndex > -1) {
            updatedComponents.splice(ratesDashboardConfigIndex, 1, updatedRatesDashboardUIComponent);
        } else {
            updatedComponents.push(updatedRatesDashboardUIComponent);
        }

        return { ...preferences, components: updatedComponents };
    };

    public static getUpdatedTogglePreferences = (
        section: UISectionKeys,
        key: UIComponentsKeys,
        config: { toggle: boolean }
    ): UserPreferenceConfigurationDTO => {
        const toggleValue = { key: 'toggle', value: `${config.toggle}` };

        const preferences = getLocalUserPreferences()?.preferences || { components: [] };
        const updatedComponents = [...preferences.components];
        const ratesDashboardConfigIndex = _.findIndex(
            updatedComponents,
            (component) => component.type === 'DASHBOARD' && component.key === key && component.section === section
        );

        if (ratesDashboardConfigIndex > -1) {
            const toggleIndex = _.findIndex(updatedComponents[ratesDashboardConfigIndex].visible, (value) => value.key === 'toggle');
            if (toggleIndex > -1) {
                updatedComponents[ratesDashboardConfigIndex].visible[toggleIndex] = toggleValue;
            }
        } else {
            const updatedRatesDashboardUIComponent: UIConfigurationDashboardComponent = {
                type: 'DASHBOARD',
                section,
                key,
                visible: [toggleValue],
            };
            updatedComponents.push(updatedRatesDashboardUIComponent);
        }

        return { ...preferences, components: updatedComponents };
    };

    public static getUpdatedBoxesPreferences = (
        section: UISectionKeys,
        key: UIComponentsKeys,
        config: { [id: string]: number }
    ): UserPreferenceConfigurationDTO => {
        const updatedRatesBoxesUIComponent: UIConfigurationBoxesComponent = {
            type: 'BOXES',
            section,
            key,
            visible: _.map(config, (value, key) => {
                return {
                    key: `${key}`,
                    value: `${value}`,
                };
            }),
        };

        const preferences = getLocalUserPreferences()?.preferences || { components: [] };

        const updatedComponents = [...preferences.components];

        const ratesTableConfigIndex = _.findIndex(
            updatedComponents,
            (component) => component.type === 'BOXES' && component.key === key && component.section === section
        );

        if (ratesTableConfigIndex > -1) {
            updatedComponents.splice(ratesTableConfigIndex, 1, updatedRatesBoxesUIComponent);
        } else {
            updatedComponents.push(updatedRatesBoxesUIComponent);
        }

        return { ...preferences, components: updatedComponents };
    };

    public static getBoxesOrder = (boxes: UIComponentConfiguration[]): { [id: string]: number } => {
        return _.reduce(
            boxes,
            (acc, box) => {
                acc[box.key] = +box.value;
                return acc;
            },
            {}
        );
    };

    public static validDashboardHash = (option: string) => {
        return Object.values(DashboardHash).includes(option as DashboardHash);
    };

    public static getDashboardPosition = (option: DashboardHash) => {
        return Object.values(DashboardHash).indexOf(option);
    };

    public static getDashboardFromPosition = (index: number) => {
        return Object.values(DashboardHash)[index];
    };

    public static getNonEmptyRowsFromCSVContent = (csvContent: string[]): string[] => {
        return csvContent.filter((csvLine) => !csvLine.match(/^[,\s]*$/));
    };

    public static getNonEmptyRowsLengthFromCSVContent = (csvContent: string[]): number => {
        return UiUtils.getNonEmptyRowsFromCSVContent(csvContent).length;
    };

    public static isValidCSVLength = (csvContent: string[], fileType: BulkProcessType): boolean => {
        return csvContent.length <= UiUtils.getMaxCSVLength(fileType) + UiUtils.CSV_HEADER_MAX_ROWS;
    };

    public static getMaxCSVLength = (fileType: BulkProcessType): number => {
        switch (fileType) {
            case BulkProcessType.UNIT_DATE_BULK_UPLOAD:
                return Configuration.getMaxCSVLengthUnitDate();
            case BulkProcessType.INITIAL_RATE_SETTING_UPLOAD:
                return Configuration.getMaxCSVLengthInitialRateSettingUpload();
            default:
                return Configuration.getMaxCSVLength();
        }
    };

    public static isValidPayloadSize = (message: string): boolean => {
        return Buffer.from(message).length < Configuration.getMaxSizeOfPayload();
    };

    public static getPreferenceDashboard(type: string, key: UIComponentsKeys): string {
        const preferences = getLocalUserPreferences()?.preferences || { components: [] };
        const componentId = preferences.components.findIndex((c) => c.section === type && c.key === key);
        const component = componentId > 0 ? preferences.components[componentId] : null;
        const visible = component?.visible ? (component.visible[0] as UIComponentConfiguration) : null;
        return visible ? visible.value : null;
    }

    public static getUpdatedTabsPreferences = (
        section: UISectionKeys,
        key: UIComponentsKeys,
        value: string
    ): UserPreferenceConfigurationDTO => {
        const updatedPreferencesComponent: UIConfigurationPreferenceTabs = {
            type: 'TABS',
            section,
            key,
            visible: [
                {
                    key: 'preference',
                    value: value,
                },
            ],
        };
        const preferences = getLocalUserPreferences()?.preferences || { components: [] };
        const updatedComponents = [...preferences.components];
        const ratesTableConfigIndex = _.findIndex(
            updatedComponents,
            (component) => component.type === 'TABS' && component.key === key && component.section === section
        );

        if (ratesTableConfigIndex > -1) {
            updatedComponents.splice(ratesTableConfigIndex, 1, updatedPreferencesComponent);
        } else {
            updatedComponents.push(updatedPreferencesComponent);
        }
        return { ...preferences, components: updatedComponents };
    };

    public static validateScope = (scopesFunctionality: Scopes[]): boolean => {
        const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
        const scopes = (decode(accessToken) as AccessToken).scopes;
        return scopes.some((scope) => scopesFunctionality.includes(scope as Scopes));
    };

    public static copyToAll = <T extends BaseRateTableValues | CohortFactorAndStatusUnitTableValues>(
        column: keyof T,
        value: number,
        rows: Row<T>[],
        onRowsChange: (column: keyof T, updatedRows: Row<T>[]) => void
    ) => {
        const allRows = _.map(rows, (row) => ({ ...row, [column]: value }));
        return onRowsChange(column, allRows);
    };

    public static multiplyToAll = <T extends BaseRateTableValues | CohortFactorAndStatusUnitTableValues>(
        column: keyof T,
        value: number,
        rows: Row<T>[],
        onRowsChange: (column: keyof T, updatedRows: Row<T>[]) => void
    ) => {
        const allRows = _.map(rows, (row) => ({
            ...row,
            [column]: +((_.isNil(row[column]) ? 1 : +row[column]) * value).toFixed(2),
        }));
        return onRowsChange(column, allRows);
    };

    // Get a map with the query params from a URL search
    // Similar to URLSearchParams() but it doesn't replace + symbol to spaces
    public static getURLSearchParams(urlSearch: string) {
        const [, queryString = ''] = urlSearch.split('?');
        return queryString.split('&').reduce<Map<string, string>>((prev, curr) => {
            const [key, value] = curr.split('=');
            prev.set(key, unescape(value));
            return prev;
        }, new Map());
    }
}
