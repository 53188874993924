import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { fetchCohorts, fetchEnhancedCohort, fetchRatesForCohort } from './cohort.actions';
import { CohortWithRates } from '../../types';
import * as _ from 'lodash';
import { AppState } from '../store';
import { RateUtils } from '../../utils';
import { CohortTypes, EnhancedCohortDTO, RateDescription } from '@common/types';

type CohortState = EntityState<CohortWithRates> & {
    isFetchingCohorts: boolean;
    isFetchingRates: boolean;
    isFetchingEnhancedCohort: boolean;
    error: Error | null;
};

const cohortAdapter = createEntityAdapter<CohortWithRates>({
    selectId: (model) => model.name,
});

const initialState: CohortState = cohortAdapter.getInitialState({
    isFetchingCohorts: false,
    isFetchingRates: false,
    isFetchingEnhancedCohort: false,
    error: null,
});

export const cohortSlice = createSlice({
    name: 'cohort',
    initialState,
    reducers: {
        setIsFetchingCohorts(state, action: PayloadAction<boolean>) {
            state.isFetchingCohorts = action.payload;
        },

        setRateChangesForCohort(state, action: PayloadAction<{ name: string; changes: { date: string; value: RateDescription }[] }>) {
            const { name, changes } = action.payload;
            const cohort = state.entities[name];
            if (cohort) {
                const currentChanges = cohort.changes || {};
                for (const change of changes) {
                    currentChanges[change.date] = change.value;
                }
                cohort.changes = currentChanges;
            }
        },

        updateCohort(state, action: PayloadAction<EnhancedCohortDTO>) {
            const cohort = action.payload;
            cohortAdapter.upsertOne(state, cohort);
        },

        upsertCohorts(state, action: PayloadAction<EnhancedCohortDTO[]>) {
            cohortAdapter.upsertMany(state, action.payload);
        },

        applyChangesForCohort(state, action: PayloadAction<{ name: string }>) {
            const { name } = action.payload;
            const cohort = state.entities[name];
            if (cohort) {
                const changes = cohort.changes;
                for (const date of _.keys(changes)) {
                    if (cohort.rates && cohort.rates[date]) {
                        const updatedRates = _.merge(cohort.rates[date], changes[date]);
                        cohort.rates[date] = updatedRates;
                        delete cohort.changes[date];
                    }
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCohorts.pending, (state) => {
            state.error = null;
        });

        builder.addCase(fetchCohorts.fulfilled, (state, action) => {
            cohortAdapter.upsertMany(state, action.payload);
        });

        builder.addCase(fetchCohorts.rejected, (state, action) => {
            state.error = action.payload as Error;
        });

        builder.addCase(fetchEnhancedCohort.pending, (state) => {
            state.isFetchingEnhancedCohort = true;
            state.error = null;
        });

        builder.addCase(fetchRatesForCohort.pending, (state) => {
            state.isFetchingRates = true;
            state.error = null;
        });

        builder.addCase(fetchRatesForCohort.fulfilled, (state, action) => {
            state.isFetchingRates = false;
            const { name, rates } = action.payload;
            const cohort = state.entities[name];
            if (cohort) {
                cohort.rates = _.merge(cohort.rates, rates);
            }
        });

        builder.addCase(fetchRatesForCohort.rejected, (state, action) => {
            state.isFetchingRates = false;
            state.error = action.payload as Error;
        });

        builder.addCase(fetchEnhancedCohort.fulfilled, (state, action) => {
            state.isFetchingEnhancedCohort = false;
            const cohort = action.payload;
            cohortAdapter.upsertOne(state, cohort);
        });

        builder.addCase(fetchEnhancedCohort.rejected, (state, action) => {
            state.isFetchingEnhancedCohort = false;
            state.error = action.payload as Error;
        });
    },
});

export const { selectById: selectCohortByName, selectAll: selectAllCohorts } = cohortAdapter.getSelectors(
    (state: AppState) => state.cohort
);

export const { setRateChangesForCohort, applyChangesForCohort, setIsFetchingCohorts, updateCohort, upsertCohorts } = cohortSlice.actions;

export const selectRatesForCohortBetweenDateRange = createSelector(
    [
        (state: AppState, name: string, range: { name: string; start: Date; end: Date }) => selectCohortByName(state, name),
        (state: AppState, name: string, range: { name: string; start: Date; end: Date }) => range,
    ],
    (cohort, { name, start, end }) => {
        const rates = cohort?.rates || {};
        return RateUtils.getRatesBetweenDates(rates, start, end);
    }
);

export const selectCohortRatesWithChanges = createSelector(
    [
        (state: AppState, name: string, range: { start: Date; end: Date }) => selectCohortByName(state, name),
        selectRatesForCohortBetweenDateRange,
    ],
    (cohort, rates) => {
        const changes = cohort?.changes || {};
        return RateUtils.mergeChanges(rates, changes);
    }
);

export const selectCohortsWithModel = createSelector([selectAllCohorts], (cohorts) => {
    return _.filter(
        cohorts,
        (cohort) =>
            cohort.type === CohortTypes.MODEL ||
            (cohort.type === CohortTypes.STRATEGIC && process.env.REACT_APP_STRATEGIC_COHORTS_RESTRICTION === 'false')
    );
});

export const selectModelAndCustomCohorts = createSelector([selectAllCohorts], (cohorts) => {
    // there are a few cohorts with type=null in the DB, so we are filtering them here
    return _.filter(cohorts, (cohort) => ['model', 'custom'].includes(cohort.type));
});

export const selectCustomCohorts = createSelector([selectAllCohorts], (cohorts) => {
    return _.filter(cohorts, (cohort) => cohort.type === CohortTypes.CUSTOM);
});
