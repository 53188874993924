import * as _ from "lodash";
import {CohortFactorAndStatusUnitTableValues, CohortTableValues, CohortWithRates, HistogramData} from "../types";
import {CohortTypes, UnitFactorDTO, UnitStatusDTO} from "@common/types";

export const VersionsByModel = [
    {model_name: "Alan", versions: ["1.0", "1.1", "1.2", "1.3", "1.6", "2.0", "2.1", "3.0", "4.0"]},
    {model_name: "Bacon", versions: ["1.0", "1.0.1", "1.1", "1.2", "1.3", "1.4", "1.5", "2.0"]},
    {model_name: "Custom", versions: ["1.0"]},
    {model_name: "International", versions: ["1.0", "1.1"]},
    {model_name: "Live_average", versions: ["1.0"]},
    {model_name: "Neumann", versions: ["1.0", "1.1"]},
    {model_name: "Northstar", versions: ["1.0"]},
];

const modelNames = VersionsByModel.map((value) => value.model_name);
export const CohortTableFilters = [
    {field: "type", values: ["ALL", "Custom", "Model", "Strategic"], type: "select"},
    {field: "model_name", values: ["ALL", ...modelNames], type: "select"},
    {field: "model_version", values: ["ALL"], type: "select"},
    {field: "model_status", values: ["ALL", "Active", "Inactive", "Experimental"], type: "select"},
    {field: "unit_count", type: "range", options: "int"},
    {field: "region_id", type: "number"},
];
export class CohortUtils {
    public static transformToTableData(cohorts: CohortWithRates[]): CohortTableValues[] {
        return _.map(cohorts, (cohort) => ({
            key: cohort.name,
            name: cohort.name,
            type: cohort.type,
            unit_count: cohort.unit_count,
            region_id: cohort.region_id,
            model_name: cohort.model?.name ?? "",
            model_version: cohort.model?.version ?? "",
            model_status: CohortUtils.getModelStatus(cohort),
        }));
    }

    public static getModelStatus(cohort: CohortWithRates): string {
        if (cohort.type === CohortTypes.MODEL || cohort.type === CohortTypes.STRATEGIC) {
            return !cohort.model?.status ? "ACTIVE" : cohort.model?.status;
        }
        return "";
    }

    public static transformToBarCharData(unitFactors: UnitFactorDTO[]): HistogramData {
        const colors = {
            units: "#1e78b4",
        };

        let count: {[key: number]: number} = {};
        for (const unit of unitFactors) {
            if (!_.isFinite(unit.factor)) {
                continue;
            }
            const factor = unit.factor.toFixed(2);
            count[factor] = (count[factor] || 0) + 1;
        }

        const data = _.reduce(
            count,
            (acc, value, key) => {
                const values = {
                    units: value,
                };

                acc.push({
                    factor: parseFloat(key),
                    values,
                    colors,
                });
                return acc;
            },
            [] as HistogramData
        );

        return _.sortBy(data, [(o) => o.factor]);
    }

    public static transformToUnitFactorAndStatusTableData(
        unitFactors: UnitFactorDTO[],
        unitStatuses: UnitStatusDTO[]
    ): CohortFactorAndStatusUnitTableValues[] {
        return _.map(unitFactors, (factor) => {
            return {
                key: factor.id.toString(),
                id: factor.id,
                code: factor.unit_code,
                factor: factor.factor,
                base_rate_override: factor.base_rate_override,
                predicted_rate: factor.predicted_rate,
                adjusted_rate: factor.adjusted_rate,
                advertised_rate: factor.advertised_rate,
                date: factor.date,
                status: _.find(unitStatuses, {unit_id: factor.id})?.status || "N/A",
            };
        });
    }
}
