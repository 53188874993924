import React from 'react';
import { RegularChange } from '../../types';
import * as _ from 'lodash';
import { Icon } from '@vacasa/react-components-lib';
import './ChangeSummary.scss';

interface RegularChangesSummaryProps {
    changes: RegularChange[];
}

export const RegularChangesSummary: React.FC<RegularChangesSummaryProps> = (props) => {
    const { changes } = props;

    return (
        <div className="change-summary">
            {changes.map((change) => {
                const { key, value, label } = change;
                return (
                    <div className="change-summary-item">
                        <div className="change-summary-item-icon">
                            <Icon.AlertCircle width={16} height={16} />
                        </div>
                        <div className="change-summary-item-text">
                            <strong>{label ?? key}</strong>
                            <span>{!_.isFinite(value) && _.isEmpty(value) ? ` Deleted` : ` Modified to ${value}`}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
