import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Paper} from "@material-ui/core";
import {AlertMessage, Button, Icon, Input, Tabs} from "@vacasa/react-components-lib";
import {
    CUSTOM_COHORT_NAME_PATTERN as cohortPattern,
    CohortUnitAssociation,
    CustomCohortCriteriaDTO,
    CustomCohortOptionsDTO,
    CustomCohortUnitDTO,
    EnhancedCustomCohortDTO,
} from "@common/types";
import {CriteriaSelector, CustomCohortUnitTable, Loading, Navbar, SaveFormValues, SaveSidebar} from "../../components";
import "./CustomCohort.scss";
import {history} from "../../App";
import {RateService} from "../../services/RateService";
import {HouseImg} from "../../assets/images";
import {CustomCohortUnitTableValues, Message} from "../../types";
import {CustomCohortUtils, UiUtils} from "../../utils";
import {AppRoutes} from "../../Routes";
import {LoggingService} from "../../services/LoggingService";
import {ManageUnitSidebar} from "../../components/AddSidebar/ManageUnitSidebar";
import {difference, isEmpty, isEqual, map, merge, sortBy, uniq, uniqBy} from "lodash";

export const CustomCohort: React.FC = () => {
    const {name} = useParams<{name: string}>();

    const emptyCriteria: CustomCohortCriteriaDTO = {
        locations: [],
        destinations: [],
        housing_types: [],
        amenities: [],
        bedrooms: {min: undefined, max: undefined},
        bathrooms: {min: undefined, max: undefined},
        minMinStay: {min: undefined, max: undefined},
    };

    const [cohortName, setCohortName] = useState<string>();
    const [selectedTab, setSelectedTab] = useState(0);
    const [isFetchingCustomCohortDetails, setIsFetchingCustomCohortDetails] = useState<boolean>(false);
    const [isValidatingExtraUnits, setIsValidatingExtraUnits] = useState<boolean>(false);
    const [isFetchingCriteriaOptions, setIsFetchingCriteriaOptions] = useState<boolean>(false);
    const [isUpsertingCohort, setIsUpsertingCohort] = useState<boolean>(false);

    const [error, setError] = useState<Error>(null);
    const [alertMessage, setAlertMessage] = useState<Message>(null);
    const [tableWarning, setTableWarning] = useState<Message>(null);
    const [customCohort, setCustomCohort] = useState<EnhancedCustomCohortDTO>(null);
    const [criteriaOptions, setCriteriaOptions] = useState<CustomCohortOptionsDTO>(null);

    const [criteria, setCriteria] = useState<CustomCohortCriteriaDTO>(emptyCriteria);
    const [cohortUnits, setCohortUnits] = useState<CustomCohortUnitTableValues[]>([]);

    const [unitsByCriteria, setUnitsByCriteria] = useState<CustomCohortUnitDTO[]>([]);
    const [addedUnits, setAddedUnits] = useState<CustomCohortUnitTableValues[]>([]);
    const [removedUnits, setRemovedUnits] = useState<CustomCohortUnitTableValues[]>([]);

    const [isShowingSave, setIsShowingSave] = useState<boolean>(false);
    const [savedEnabled, setSaveEnabled] = useState<boolean>(false);
    const [isShowingAddExtraUnitsModal, setIsShowingAddExtraUnitsModal] = useState<boolean>(false);

    useEffect(() => {
        getCriteriaOptions();
    }, []);

    useEffect(() => {
        if (name) {
            setCohortName(name);
            getCustomCohort(name);
        }
    }, [name]);

    useEffect(() => {
        setSaveEnabled(false);
        setAlertMessage(null);

        let criteriaChanged = !isEqual(criteria, emptyCriteria);
        let cohortUnitsChanged = !isEqual(cohortUnits, []);
        if (customCohort) {
            const cohortUnitsTableData = sortBy(CustomCohortUtils.transformFromCohortUnitsToTableData(customCohort.cohort_units), (cu) => cu.id);

            const unmodifiedCohortUnitsRemoved = cohortUnitsTableData
                .filter((cu) => cu.association_type === CohortUnitAssociation.REMOVED)
                .map((u) => u.id)
                .sort();
            const unmodifiedCohortUnits = cohortUnitsTableData
                .filter((cu) => !unmodifiedCohortUnitsRemoved.includes(cu.id))
                .map((u) => u.id)
                .sort();
            cohortUnitsChanged =
                !isEqual(unmodifiedCohortUnitsRemoved, removedUnits.map((u) => u.id).sort()) ||
                !isEqual(unmodifiedCohortUnits, cohortUnits.map((u) => u.id).sort());
            criteriaChanged = !isEqual(criteria, merge(customCohort.grouping_criteria, emptyCriteria));
        }

        let validRangeCriteria: boolean = criteriaChanged
            ? criteria.bedrooms.max < criteria.bedrooms.min ||
              criteria.bathrooms.max < criteria.bathrooms.min ||
              criteria.minMinStay.max < criteria.minMinStay.min
            : true;

        if ((criteriaChanged && !validRangeCriteria) || cohortUnitsChanged) {
            if (alertMessage?.type !== "error") {
                setAlertMessage(UiUtils.getPendingChangesMessage());
            }
            setSaveEnabled(!!cohortName);
        }
    }, [addedUnits, removedUnits, criteria, cohortUnits, cohortName]);

    useEffect(() => {
        if (customCohort) {
            setCriteria(merge(customCohort.grouping_criteria, emptyCriteria));
            const removedUnits = CustomCohortUtils.transformFromCohortUnitsToTableData(
                customCohort.cohort_units?.filter((cu) => cu.association_type === CohortUnitAssociation.REMOVED)
            );
            setRemovedUnits(removedUnits);
            const unitsList = CustomCohortUtils.transformFromCohortUnitsToTableData(
                customCohort.cohort_units?.filter((cu) => !removedUnits.map((r) => r.id).includes(cu.unit_id))
            );
            setCohortUnits(unitsList);
        }
    }, [customCohort]);

    useEffect(() => {
        if (customCohort && customCohort.grouping_criteria === criteria) {
            return;
        }
        getUnitsByCriteria();
    }, [criteria]);

    useEffect(() => {
        const removedIds = removedUnits.map((r) => r.id);
        const updatedCohortUnits = CustomCohortUtils.transformToTableData(unitsByCriteria).filter((u) => !removedIds.includes(u.id));
        const added = cohortUnits.filter(
            (cu) => cu.association_type === CohortUnitAssociation.ADDED && !updatedCohortUnits.map((u) => u.id).includes(cu.id)
        );
        setCohortUnits([...updatedCohortUnits, ...added]);
        setRemovedUnits(removedUnits.filter((r) => unitsByCriteria.map((u) => u.unit_id).includes(r.id)));
    }, [unitsByCriteria]);

    useEffect(() => {
        const updatedCohortUnits = cohortUnits.filter((u) => !removedUnits.map((r) => r.id).includes(u.id));
        setCohortUnits(uniqBy([...updatedCohortUnits, ...addedUnits], (a) => a.id));
    }, [addedUnits, removedUnits]);

    const getCriteriaOptions = async () => {
        try {
            setAlertMessage(null);
            setIsFetchingCriteriaOptions(true);
            const options = await RateService.getCustomCohortCriteriaOptions();
            setCriteriaOptions(options);
        } catch (e) {
            setAlertMessage(UiUtils.getErrorMessage(e));
            setError(e);
        } finally {
            setIsFetchingCriteriaOptions(false);
        }
    };

    const getCustomCohort = async (name: string) => {
        try {
            setAlertMessage(null);
            setIsFetchingCustomCohortDetails(true);
            const cohort = await RateService.getCustomCohortWithActiveUnits(name);
            setCustomCohort(cohort);
        } catch (e) {
            setError(e);
            setAlertMessage(UiUtils.getErrorMessage(e));
        } finally {
            setIsFetchingCustomCohortDetails(false);
        }
    };

    const getUnitsByCriteria = async () => {
        try {
            const params = UiUtils.getFilterParamsForCriteria(criteria);

            if (isEmpty(params)) {
                setUnitsByCriteria([]);
                return;
            }
            const units = await RateService.getUnitsByCriteria(params);
            setUnitsByCriteria(units);
        } catch (e) {
            LoggingService.error(`error fetching units by criteria`, {cohort_name: cohortName, criteria});
            setAlertMessage(UiUtils.getErrorMessage(e.message));
        }
    };

    const handleCohortNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        if (cohortPattern.test(name.trim()) || isEmpty(name)) {
            setCohortName(e.target.value);
        }
    };

    const handleCriteriaChange = (criteria: any) => {
        setCriteria(criteria);
    };

    const handleGoBack = () => {
        history.length <= 2 ? history.replace(AppRoutes.COHORTS) : history.goBack();
    };

    const handleSave = async (saveForm: SaveFormValues) => {
        const {context, changes} = saveForm;
        const cohortData = {context, ...changes};
        try {
            setIsUpsertingCohort(true);
            setAlertMessage(null);
            if (isUpdating) {
                await RateService.upsertCustomCohort(cohortName, cohortData);
            } else {
                await RateService.createCustomCohort(cohortName, cohortData);
            }
            setAlertMessage(UiUtils.getSaveSuccessMessage());
            history.push(AppRoutes.COHORT_EVOLUTION.replace(":name", cohortName));
        } catch (e) {
            LoggingService.error(`error saving custom cohort`, {cohort_name: cohortName, params: {update: cohortData}});
            setAlertMessage(UiUtils.getErrorMessage(e[0]?.detail?.error ?? e.message));
            setIsUpsertingCohort(false);
            setIsShowingSave(false);
        }
    };

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    const handleRemoveSelectedUnits = (unitIds: string[]) => {
        const ids = unitIds.map((u) => parseInt(u));
        const updatedCohortUnits = [...cohortUnits].filter((u) => !ids.includes(u.id));
        const updatedAdded = [...addedUnits].filter((u) => !ids.includes(u.id));
        const updatedRemoved = [...removedUnits];
        for (const id of ids) {
            if (cohortUnits.map((cu) => cu.id).includes(id)) {
                const unitToRemove = cohortUnits.filter((au) => au.id === id)[0];
                if (unitToRemove.association_type === CohortUnitAssociation.CRITERIA) {
                    unitToRemove.association_type = CohortUnitAssociation.REMOVED;
                    updatedRemoved.push(unitToRemove);
                }
            }
        }
        setCohortUnits(updatedCohortUnits);
        setAddedUnits(uniq(updatedAdded));
        setRemovedUnits(uniq(updatedRemoved));
    };

    const handleAddRemovedUnits = (units: string[]) => {
        const ids = units.map((u) => parseInt(u));
        const removedUnitsToAdd = removedUnits.filter((r) => ids.includes(r.id));
        removedUnitsToAdd.forEach((ru) => (ru.association_type = CohortUnitAssociation.CRITERIA));
        const updatedCohortUnits = [...cohortUnits, ...removedUnitsToAdd];
        const updatedRemoved = [...removedUnits].filter((u) => !ids.includes(u.id));
        setCohortUnits(updatedCohortUnits);
        setRemovedUnits(uniq(updatedRemoved));
    };

    const handleAddExtraUnits = async (unitIds: number[]) => {
        const possibleUnitIds = uniq(unitIds.filter((u) => !cohortUnits.map((a) => a.id).includes(u)));
        if (!isEmpty(possibleUnitIds)) {
            // Users can input any number, we make an extra api call to validate and filter real units
            const activeUnits = await getValidatedActiveUnits(possibleUnitIds);
            const foundUnits: number[] = map(activeUnits, (id) => id.unit_id);
            const notFoundUnits: number[] = difference(unitIds, foundUnits);
            if (notFoundUnits.length > 0) {
                setTableWarning(UiUtils.getCustomUnitsWarning(notFoundUnits));
            }
            setAddedUnits(CustomCohortUtils.transformToTableData(activeUnits));
        }
        setIsShowingAddExtraUnitsModal(false);
    };

    const getValidatedActiveUnits = async (possibleUnits: number[]): Promise<CustomCohortUnitDTO[]> => {
        setIsValidatingExtraUnits(true);
        let activeUnits: CustomCohortUnitDTO[] = [];
        let pageNumber = 1;
        const pageSize = 100;

        try {
            while (true) {
                const {rows} = await RateService.getUnitsForCustomCohorts({
                    "filter[active]": "1",
                    "filter[unit_id][in]": possibleUnits.join(","),
                    "page[number]": pageNumber.toString(),
                    "page[size]": pageSize.toString(),
                });

                activeUnits = [
                    ...activeUnits,
                    ...rows.map((r) => {
                        return {
                            ...r,
                            association_type: CohortUnitAssociation.ADDED,
                        };
                    }),
                ];

                if (rows.length < pageSize) {
                    break;
                }

                pageNumber++;
            }
            return activeUnits;
        } catch (e) {
            LoggingService.error(`error validating manually added units`, {error: e, units: possibleUnits, pageNumber, pageSize});
            setAlertMessage(UiUtils.getErrorMessage("Failed validating manually added units"));
            return [];
        } finally {
            setIsValidatingExtraUnits(false);
        }
    };

    const handleRefreshCriteria = () => {
        setCriteria(emptyCriteria);
    };

    const isUpdating = !!name;
    const isLoading = isFetchingCustomCohortDetails || isFetchingCriteriaOptions || isUpsertingCohort || isValidatingExtraUnits;

    const changes = {
        name: cohortName,
        grouping_criteria: criteria,
        added_units: cohortUnits.filter((cu) => cu.association_type === CohortUnitAssociation.ADDED).map((a) => a.id),
        removed_units: removedUnits.map((r) => r.id),
    };
    return (
        <React.Fragment>
            <Navbar title="Rates Cohort" domain="cohorts" />
            <Paper elevation={3} className="custom-cohort-view">
                <div className="custom-cohort-top-bar">
                    <Icon.CornerDownLeft className="pointer" height={24} width={24} onClick={handleGoBack} />
                    <h5>Custom Cohort</h5>
                </div>

                <div className="custom-cohort-content">
                    {!error && (
                        <div className="custom-cohort-name">
                            <span className="">Cohort Name:</span>
                            {isUpdating ? (
                                <span>{name}</span>
                            ) : (
                                <Input
                                    customClass="custom-cohort-name-input"
                                    type="text"
                                    value={cohortName}
                                    onChange={handleCohortNameChange}
                                    placeholder="Type new name"
                                />
                            )}
                        </div>
                    )}

                    {alertMessage && (
                        <div className="custom-cohort-alert">
                            <AlertMessage type={alertMessage.type} text={alertMessage.content} height="small" />
                        </div>
                    )}

                    {isLoading && <Loading className="custom-cohort-loading" />}

                    <div>
                        {!isLoading && error && (
                            <div className="custom-cohort-error">
                                <HouseImg />
                                <h2>Sorry, an error occurred getting the custom cohort details </h2>
                            </div>
                        )}
                    </div>

                    {!isLoading && !error && (
                        <div className="save-wrapper">
                            {isShowingSave && savedEnabled && (
                                <SaveSidebar
                                    title="Save Custom Cohort"
                                    type="custom_cohort"
                                    changes={changes}
                                    hasExpirationDays={false}
                                    onSave={handleSave}
                                    onClose={() => setIsShowingSave(false)}
                                />
                            )}

                            {isShowingAddExtraUnitsModal && (
                                <ManageUnitSidebar
                                    units={addedUnits.map((au) => au.id)}
                                    onApply={handleAddExtraUnits}
                                    onClose={() => {
                                        setIsShowingAddExtraUnitsModal(false);
                                    }}
                                />
                            )}
                            <Button
                                customClass="save-custom-cohort-button"
                                variant="secondary"
                                onClick={() => setIsShowingSave(!isShowingSave)}
                                disabled={!savedEnabled}
                            >
                                Save
                            </Button>
                            <Tabs
                                customClass="custom-cohort-tabs"
                                selected={selectedTab}
                                onChange={handleTabChange}
                                tabs={[
                                    {
                                        id: "custom-cohort-criteria-selector",
                                        label: "Criteria",
                                        component: (
                                            <CriteriaSelector
                                                initialCriteria={criteria}
                                                criteriaOptions={criteriaOptions}
                                                onCriteriaChange={handleCriteriaChange}
                                                onRefreshCriteria={handleRefreshCriteria}
                                            />
                                        ),
                                    },
                                    {
                                        id: "custom-cohort-units-selector",
                                        label: "Units",
                                        component: (
                                            <CustomCohortUnitTable
                                                units={cohortUnits}
                                                onAdd={() => {
                                                    setIsShowingAddExtraUnitsModal(true);
                                                }}
                                                onDelete={handleRemoveSelectedUnits}
                                                message={tableWarning}
                                            />
                                        ),
                                    },
                                    {
                                        id: "custom-cohort-units-removed",
                                        label: "Units Removed",
                                        component: (
                                            <CustomCohortUnitTable units={removedUnits} onAdd={handleAddRemovedUnits} message={tableWarning} />
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
            </Paper>
        </React.Fragment>
    );
};
