import { createAsyncThunk } from '@reduxjs/toolkit';
import { RateService } from '../../services/RateService';
import { setIsFetchingCohorts } from './cohort.slice';
import { CohortTypes } from '@common/types';

export const fetchCohorts = createAsyncThunk(
    'cohort/fetchCohorts',
    async (request: { type?: CohortTypes; size?: number; number?: number }, { rejectWithValue, dispatch }) => {
        try {
            const { type, size, number } = request;
            const isFetchingFirstPage = !size && !number;

            if (isFetchingFirstPage) {
                dispatch(setIsFetchingCohorts(true));
            }

            let pageSize = size || 1000;
            let pageNumber = number || 1;

            const cohorts = await RateService.getCohorts({
                'page[number]': pageNumber.toString(),
                'page[size]': pageSize.toString(),
                'filter[type]': type,
            });

            if (cohorts.rows.length < pageSize) {
                dispatch(setIsFetchingCohorts(false));
                return cohorts.rows;
            }
            const nextPage = pageNumber + 1;
            dispatch(fetchCohorts({ type, size: pageSize, number: nextPage }));
            return cohorts.rows;
        } catch (e) {
            dispatch(setIsFetchingCohorts(false));
            return rejectWithValue(RateService.makeError('fetchCohorts', e));
        }
    }
);
export const fetchEnhancedCohort = createAsyncThunk(
    'cohort/fetchEnhancedCohort',
    async (request: { name: string }, { rejectWithValue }) => {
        try {
            const { name } = request;
            return await RateService.getEnhancedCohort(name);
        } catch (e) {
            return rejectWithValue(RateService.makeError('fetchEnhancedCohort', e));
        }
    }
);
export const fetchRatesForCohort = createAsyncThunk(
    'cohort/fetchRatesForCohort',
    async (request: { name: string; start: Date; end: Date; cohortType: CohortTypes }, { rejectWithValue }) => {
        try {
            const { name, start, end, cohortType } = request;
            const rates =
                cohortType === CohortTypes.MODEL || cohortType === CohortTypes.STRATEGIC
                    ? await RateService.getRatesForCohort(name, start, end)
                    : await RateService.getRatesForCustomCohort(name, start, end);
            return { name, rates };
        } catch (e) {
            return rejectWithValue(RateService.makeError('fetchRatesForCohort', e));
        }
    }
);
