import React, { useEffect, useState } from 'react';
import './ActionSidebar.scss';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Button, Icon, Select } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import { UiUtils } from '../../utils';
import { DateRangePickers, GenericInput } from '..';
import { ActionTargetOptions, ColumnOptions, DowOptions, Filters, StatusOptions } from '../../types';
import { DatePeriodConfiguration } from '../GenericContentPanel/DatePeriodConfiguration';
import { UpdateContextDTO } from '@common/types';

interface ActionSidebarProps {
    actionName: string;
    type: 'set' | 'multiply';
    columnOptions: ColumnOptions;
    valueRange: { start: Date | null; end: Date | null };
    onApplyAndSave: (column: string, value: number, filter?: Filters, context?: UpdateContextDTO) => void;
    onApply?: (column: string, value: number, filter?: Filters) => void;
    onClose: () => void;
    statusFilterOption: StatusOptions;
    dowFilterOption: DowOptions;
    holidayName?: string;
    cohortName?: string;
    regionId: number;
    countryName?: string;
    selectedDates?: string[];
}

export const ActionSidebar: React.FC<ActionSidebarProps> = (props) => {
    const {
        actionName,
        columnOptions,
        valueRange,
        onApplyAndSave,
        onApply,
        onClose,
        dowFilterOption,
        statusFilterOption,
        holidayName,
        cohortName,
        regionId,
        countryName,
        type,
        selectedDates,
    } = props;

    const [value, setValue] = useState<number>(NaN);
    const [affectedColumn, setAffectedColumn] = useState<string>('');
    const [selectedRowsTarget, setSelectedRowsTarget] = useState<string>('');
    const [startDateFilter, setStartDateFilter] = useState<Date | null>();
    const [endDateFilter, setEndDateFilter] = useState<Date | null>();
    const [dowFilter, setDowFilter] = useState<DowOptions>(dowFilterOption);
    const [statusFilter, setStatusFilter] = useState<StatusOptions>(statusFilterOption);
    const [holiday, setHoliday] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [validationInput, setValidationInput] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<string>('');
    const [changeReason, setChangeReason] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isAlreadyClicked, setIsAlreadyClicked] = useState<boolean>(false);

    const CHANGE_REASON_ITEMS = [
        { value: 'optimization', display: 'Optimization' },
        { value: 'owner_changes', display: 'Owner Changes' },
    ];

    const handleChangeReasonOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangeReason(event.target.value);
        setIsAlreadyClicked(false);
    };

    useEffect(() => {
        if (!startDateFilter) {
            setStartDateFilter(valueRange.start);
        }
        if (!endDateFilter) {
            setEndDateFilter(valueRange.end);
        }
    }, []);

    useEffect(() => {
        setStartDateFilter(valueRange.start);
        setEndDateFilter(valueRange.end);
    }, [valueRange.start, valueRange.end]);

    const dayOptions = UiUtils.getDoWFilterOptions();
    const statusOptions = UiUtils.getStatusFilterOptions();

    const validationValue = () => {
        if (_.isNil(value) || _.isNaN(value)) {
            setValidationError('');
            return setValidationInput(false);
        }

        if (['factor_avg', 'analystFactor'].includes(affectedColumn)) {
            if (value <= 0 || value > 10) {
                setValidationError('Factor must be a value between 0.01 and 10');
                return setValidationInput(true);
            }
        }

        setValidationError('');
        return setValidationInput(false);
    };

    useEffect(() => {
        validationValue();
    }, [value, affectedColumn]);

    useEffect(() => {
        if (selectedRowsTarget === ActionTargetOptions.HOLIDAY_OR_MONTH) {
            handleValueHolidayAndMonth();
        }
    }, [selectedRowsTarget]);

    const handleAffectedColumnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAffectedColumn(e.target.value);
    };

    const handleValueHolidayAndMonth = () => {
        setHoliday('');
        setMonth('');
    };

    const handleValueChange = (value: number) => {
        setValue(value);
    };

    const handleTargetRowsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target.value;
        setSelectedRowsTarget(target);

        if (target !== ActionTargetOptions.PERIOD) {
            setStartDateFilter(valueRange.start);
            setEndDateFilter(valueRange.end);
            setDowFilter('All');
            setStatusFilter('All');
        }
    };

    const onDateRangeChange = (start: Date, end: Date, numberOfDays?: number, holiday?: string, month?: string) => {
        setStartDateFilter(start);
        setEndDateFilter(end);
        setHoliday(holiday);
        setMonth(month);
    };

    const handleDowFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDowFilter(e.target.value as DowOptions);
    };

    const handleStatusFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStatusFilter(e.target.value as StatusOptions);
    };

    const isButtonDisabled = () => {
        const isValueValid = _.isNumber(value) && !_.isNaN(value);
        const isAnyFieldEmpty = _.isEmpty(affectedColumn) || _.isEmpty(selectedRowsTarget) || !isValueValid || validationInput;
        const isDatesSelectedEmpty = _.isEmpty(selectedDates) && selectedRowsTarget === ActionTargetOptions.SELECTED;

        if (selectedRowsTarget === ActionTargetOptions.PERIOD || selectedRowsTarget === ActionTargetOptions.HOLIDAY_OR_MONTH) {
            const isValidPeriod = _.isDate(startDateFilter) && _.isDate(endDateFilter);
            if (selectedRowsTarget === ActionTargetOptions.HOLIDAY_OR_MONTH) {
                const isValidHoliday = holiday != null && holiday.trim().length > 0;
                const isValidMonth = month != null && month.trim().length > 0;
                return isAnyFieldEmpty || !isValidPeriod || (!isValidMonth && !isValidHoliday);
            }
            return isAnyFieldEmpty || !isValidPeriod;
        }

        return isAnyFieldEmpty || isDatesSelectedEmpty;
    };

    const isSetDisabled = () => {
        return isButtonDisabled() || _.isEmpty(changeReason) || _.isEmpty(description);
    };

    const isMultiplyDisabled = () => {
        return isButtonDisabled();
    };

    const getFilter = () => {
        switch (selectedRowsTarget) {
            case ActionTargetOptions.SELECTED:
                return { selected: true };
            case ActionTargetOptions.PERIOD:
            case ActionTargetOptions.HOLIDAY_OR_MONTH:
                return {
                    start: startDateFilter as Date,
                    end: endDateFilter as Date,
                    dow: UiUtils.getDoWFilterFromOption(dowFilter),
                    status: statusFilterOption ? UiUtils.getStatusFilterFromOption(statusFilter) : undefined,
                };
            case ActionTargetOptions.ALL:
            default:
                return undefined;
        }
    };

    const handleApplyAndSave = () => {
        setIsAlreadyClicked(true);
        onApplyAndSave(affectedColumn, value, getFilter(), { days_before_expire: 0, changes_reason: changeReason, description });
    };

    const handleApply = () => {
        setIsAlreadyClicked(true);
        onApply(affectedColumn, value, getFilter());
    };

    return (
        <div className="set-side-bar">
            <div className="set-side-bar-title">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>{actionName} Actions</span>
            </div>

            {/* Step 1*/}
            <div className="set-side-bar-step">
                <p>Step 1: Column Affected</p>
                <div className="set-side-bar-input-group">
                    <RadioGroup
                        aria-label="affected-column"
                        name="affected-column"
                        value={affectedColumn}
                        onChange={handleAffectedColumnChange}>
                        {columnOptions.map((col) => (
                            <FormControlLabel
                                value={col.field}
                                control={<Radio className="set-side-bar-radio" />}
                                label={
                                    <span className="set-side-bar-radio-group-label">
                                        {type === 'set' ? `Set ${col.label}` : `Multiply ${col.label}`}
                                    </span>
                                }
                            />
                        ))}
                    </RadioGroup>
                </div>
            </div>

            <div className="step-separator" />
            {/* Step 2*/}
            <div className="set-side-bar-step">
                <p>Step 2: Value</p>
                <div className="set-side-bar-input-group">
                    <div className="set-side-bar-value">
                        <GenericInput
                            className={validationInput ? 'error-input' : ''}
                            value={value}
                            onChange={handleValueChange}
                            decimals={true}
                            min={0.01}
                            max={999999.99}
                        />
                        {validationInput && <span>{validationError}</span>}
                    </div>
                </div>
            </div>

            <div className="step-separator" />
            {/* Step 3*/}
            <div className="set-side-bar-step">
                <p>Step 3: Target</p>
                <div className="set-side-bar-input-group">
                    <RadioGroup aria-label="target-rows" name="target-rows" value={selectedRowsTarget} onChange={handleTargetRowsChange}>
                        <FormControlLabel
                            value={ActionTargetOptions.PERIOD}
                            control={<Radio className="set-side-bar-radio" />}
                            label={<span className="set-side-bar-radio-group-label">{ActionTargetOptions.PERIOD}</span>}
                        />

                        {selectedRowsTarget === ActionTargetOptions.PERIOD && (
                            <div className="set-to-period-options">
                                <DateRangePickers start={startDateFilter} end={endDateFilter} onDateRangeChange={onDateRangeChange} />
                                <Select
                                    customClass="period-dow-filter-select"
                                    label="Day Filter"
                                    value={dowFilterOption !== 'All' ? dowFilterOption : dowFilter}
                                    disabled={dowFilterOption !== 'All'}
                                    options={dayOptions}
                                    onChange={handleDowFilterChange}
                                />
                                {statusFilterOption && (
                                    <Select
                                        customClass="period-status.filter-select"
                                        label="Status Filter"
                                        value={statusFilterOption !== 'All' ? statusFilterOption : statusFilter}
                                        disabled={statusFilterOption !== 'All'}
                                        options={statusOptions}
                                        onChange={handleStatusFilterChange}
                                    />
                                )}
                            </div>
                        )}

                        <FormControlLabel
                            value={ActionTargetOptions.SELECTED}
                            control={<Radio className="set-side-bar-radio" />}
                            label={<span className="set-side-bar-radio-group-label">{ActionTargetOptions.SELECTED}</span>}
                        />

                        <FormControlLabel
                            value={ActionTargetOptions.HOLIDAY_OR_MONTH}
                            control={<Radio className="set-side-bar-radio" />}
                            label={<span className="set-side-bar-radio-group-label">{ActionTargetOptions.HOLIDAY_OR_MONTH}</span>}
                        />

                        {selectedRowsTarget === ActionTargetOptions.HOLIDAY_OR_MONTH && (
                            <div className="set-to-holiday-options">
                                <DatePeriodConfiguration
                                    start={startDateFilter}
                                    end={endDateFilter}
                                    holidayName={holidayName}
                                    onDateRangeChange={onDateRangeChange}
                                    filters={{ cohortName, regionId, countryName }}
                                />
                            </div>
                        )}

                        <FormControlLabel
                            value={ActionTargetOptions.ALL}
                            control={<Radio className="set-side-bar-radio" />}
                            label={<span className="set-side-bar-radio-group-label">{ActionTargetOptions.ALL}</span>}
                        />
                    </RadioGroup>
                </div>
            </div>

            {type === 'set' && <div className="step-separator" />}
            {/* Step 4*/}
            {type === 'set' && (
                <div className="set-side-bar-step">
                    <p>Step 4: Save Options</p>
                    <div className="save-side-bar-input-group">
                        <div className="save-side-bar-input-label">
                            <span>Changes Reason:</span>
                        </div>
                        <div className="save-side-bar-value select-input">
                            <Select
                                customClass="period-dow-filter-select"
                                value={changeReason}
                                options={CHANGE_REASON_ITEMS}
                                onChange={handleChangeReasonOnChange}
                            />
                        </div>
                    </div>
                    <div className="save-side-bar-input-group">
                        <div className="save-side-bar-input-label">
                            <span>Description:</span>
                        </div>
                        <div className="save-side-bar-value">
                            <textarea
                                rows={5}
                                maxLength={200}
                                style={{ width: '100%' }}
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                    setIsAlreadyClicked(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {type === 'set' ? (
                <div className="set-side-bar-set-button">
                    {
                        <Button variant="secondary" onClick={handleApplyAndSave} disabled={isSetDisabled() || isAlreadyClicked}>
                            {isAlreadyClicked ? 'Waiting...' : `${actionName} and Save`}
                        </Button>
                    }
                </div>
            ) : (
                <div className="set-side-bar-set-button">
                    {
                        <Button variant="info" onClick={handleApplyAndSave} disabled={isMultiplyDisabled() || isAlreadyClicked}>
                            {isAlreadyClicked ? 'Waiting...' : `${actionName} and Save`}
                        </Button>
                    }
                    {onApply && (
                        <Button variant="secondary" onClick={handleApply} disabled={isMultiplyDisabled() || isAlreadyClicked}>
                            {isAlreadyClicked ? 'Waiting...' : `${actionName}`}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};
