import {
    DATETIME_FORMAT,
    ProcessExecutionDetailDTO,
    ProcessExecutionDetailStatus,
    ProcessExecutionDTO,
    ProcessStepsType,
} from '@common/types';
import { ProcessDetailsTableValues, ProcessTableValues } from '../types';
import * as _ from 'lodash';
import { format, intervalToDuration } from 'date-fns';

export class ProcessExecutionUtils {
    static getExecutionTime = (start: Date, end: Date): string => {
        const data = {
            start: new Date(start),
            end: new Date(end),
        };
        const { days, hours, minutes, seconds } = intervalToDuration(data);
        return `${days === 0 ? '' : days + ':'}${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
            seconds < 10 ? '0' + seconds : seconds
        }`;
    };

    static transformToTableData = (processes: ProcessExecutionDTO[]): ProcessTableValues[] => {
        return _.map(processes, (process) => ({
            key: `${process.id}`,
            id: `${process.id}`,
            user: process.user,
            status: {
                id: `${process.id}`,
                status: process.status,
                process_type: process.process_type,
            },
            process_type: process.process_type,
            description: process.description,
            metadata: process.metadata,
            started_at: format(new Date(process.started_at), DATETIME_FORMAT),
            execution_time: `${
                !_.isNil(process.ended_at) && !_.isNil(process.started_at)
                    ? ProcessExecutionUtils.getExecutionTime(process.started_at, process.ended_at)
                    : '-- -- --'
            }`,
            // changes_history contains the units ids changed by the process execution id
            changed_units: process.changes_history,
        }));
    };

    public static transformToProcessDetailsTableData = (processDetails: ProcessExecutionDetailDTO[]): ProcessDetailsTableValues[] => {
        return _.map(processDetails, (processDetail) => ({
            key: `${processDetail.id}`,
            id: processDetail.id,
            process_execution_id: `${processDetail.process_execution_id}`,
            status: processDetail.status,
            step: ProcessStepsType[processDetail.step],
            metadata: processDetail.metadata,
            started_at:
                !_.isNil(processDetail.started_at) && processDetail.status !== ProcessExecutionDetailStatus.Canceled
                    ? format(new Date(processDetail.started_at), DATETIME_FORMAT)
                    : '-- -- --',
            execution_time: `${
                _.isNil(processDetail.ended_at) ||
                processDetail.status === ProcessExecutionDetailStatus.Canceled ||
                processDetail.status === ProcessExecutionDetailStatus.Processing ||
                processDetail.status === ProcessExecutionDetailStatus.Waiting
                    ? '-- -- --'
                    : ProcessExecutionUtils.getExecutionTime(processDetail.started_at, processDetail.ended_at)
            }`,
        }));
    };
}
