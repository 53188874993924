import React from 'react';
import { Modal as MaterialModal, Paper } from '@material-ui/core';
import { Icon } from '@vacasa/react-components-lib';
import './Modal.scss';

interface ModalProps {
    title: string;
    show: boolean;
    onClose: () => void;
    children: JSX.Element | JSX.Element[];
    className?: string;
}
export const Modal: React.FC<ModalProps> = (props) => {
    const { title, show, onClose, children, className } = props;

    return (
        <MaterialModal className="vacasa-modal" open={show} onClose={onClose}>
            <Paper className={`vacasa-modal-card  ${className ?? ''}`}>
                <div className="vacasa-modal-header">
                    <p>{title}</p>
                    <Icon.XCircleInverse className="pointer icon-opacity" height={24} width={24} onClick={onClose} />
                </div>
                <div className="vacasa-modal-body">{children}</div>
            </Paper>
        </MaterialModal>
    );
};
