import React from 'react';
import './Loading.scss';

interface LoadingProps {
    className?: string;
}
export const Loading: React.FC<LoadingProps> = (props) => {
    return (
        <div id="loading" className={`loading ${props.className ?? ''}`}>
            <div className={'loader-dots'}>
                <div className={'bounce1'}></div>
                <div className={'bounce2'}></div>
                <div className={'bounce3'}></div>
            </div>
        </div>
    );
};
