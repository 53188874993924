import React, {useEffect, useState} from "react";
import {AlertMessage, AutoCompleteSelect, Button, Icon, Select, useDebounce} from "@vacasa/react-components-lib";
import "./CohortCreationPanel.scss";
import {Message, SelectOption} from "../../types";
import {RateService} from "../../services/RateService";
import {UiUtils} from "../../utils";
import {BaseCohortDTO, COHORT_NAME_PATTERN, NORTHSTAR_1_0_ID} from "@common/types";
import {LoggingService} from "../../services/LoggingService";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {AppState, selectAllModels} from "../../store";
import * as _ from "lodash";
import {fetchModels} from "../../store/model/model.actions";

type CohortCreationPanelProps = {
    onClose: () => void;
    onSave: (message: Message) => void;
    onError: (message: Message) => void;
};

export const CohortCreationPanel: React.FC<CohortCreationPanelProps> = (props) => {
    const dispatch = useDispatch();
    const {onClose, onSave, onError} = props;
    const models = useSelector((state: AppState) => selectAllModels(state));
    const [warningMessage, setWarningMessage] = useState<string>("");
    const [selectedModel, setSelectedModel] = useState<SelectOption>(null);
    const [modelOptions, setModelOptions] = useState<SelectOption[]>([]);
    const [selectedUnitModel, setSelectedUnitModel] = useState<SelectOption>(null);
    const [unitModelOptions, setUnitModelOptions] = useState<SelectOption[]>([]);
    const [selectedState, setSelectedState] = useState<string>(null);
    const [selectedCity, setSelectedCity] = useState<string>(null);
    const [isAlreadyClicked, setIsAlreadyClicked] = useState<boolean>(false);
    const [isIndependentOfLocation, setIsIndependentOfLocation] = useState<boolean>(
        process.env.REACT_APP_DISABLE_STRATEGIC_COHORTS_CREATION == "false"
    );
    const [states, setStates] = useState<Array<any>>([]);
    const [statesOptions, setStatesOptions] = useState<Array<any>>([{value: "Select State", display: "Select State"}]);
    const [cities, setCities] = useState<Array<any>>([]);
    const [citiesOptions, setCitiesOptions] = useState<Array<any>>([{value: "Select City", display: "Select City"}]);
    const [isWarningShown, setShowWarning] = useState<boolean>(false);
    const [isCohortNameBeingChecked, setIsCohortNameBeingChecked] = useState<boolean>(true);
    const [cohortName, setCohortName] = useState<string>("");
    const delay: number = 2000;

    useEffect(() => {
        if (_.isEmpty(models)) {
            dispatch(fetchModels());
        }
        const options = models.reduce((acc, model) => {
            //Northstar should be available only for strategic cohorts
            if (model.id !== NORTHSTAR_1_0_ID) {
                acc.push({
                    value: model.id,
                    display: `${model.name} ${model.version} ${model.status.substr(0, 3)}`,
                });
            }
            return acc;
        }, []);
        const unitModelOptions = models.reduce((acc, model) => {
            if (model.type === "unit-level") {
                acc.push({
                    value: model.id,
                    display: `${model.name} ${model.version} ${model.status.substr(0, 3)}`,
                });
            }
            return acc;
        }, []);
        setModelOptions(_.sortBy(options, "display"));
        setUnitModelOptions(_.sortBy(unitModelOptions, "display"));
    }, [models]);

    useEffect(() => {
        try {
            RateService.getStates().then((res) =>
                setStates(() =>
                    res
                        .slice()
                        .sort((state1, state2) => state1.name.localeCompare(state2.name))
                        .map((state) => ({name: state.name, short_name: state.short_name}))
                )
            );

            RateService.getCities().then((res) =>
                setCities(() =>
                    res
                        .slice()
                        .sort((city1, city2) => city1.city.localeCompare(city2.city))
                        .map((city) => ({name: city.city, state: city.state}))
                )
            );
        } catch (e) {
            LoggingService.error(`error loading states and cities`);
            onError(UiUtils.getErrorMessage(e));
            onClose();
        }
    }, []);

    useEffect(() => {
        setStatesOptions(() => [
            {value: "Select State", display: "Select State"},
            ...states.map((s) => ({value: s.short_name, display: s.short_name})),
        ]);
    }, [states]);

    useEffect(() => {
        if (cities.length > 0) {
            const options = cities
                .filter((city) => city.state.toUpperCase() === selectedState?.toUpperCase())
                .map((city) => ({value: city.name, display: city.name}));
            if (options.length == 0) {
                onError(UiUtils.getNoCitiesForStateMessage());
            } else {
                setCitiesOptions(() => [{value: "Select City", display: "Select City"}, ...options]);
            }
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedCity && selectedCity != "Select City") {
            checkCohortName(`${selectedState}-${selectedCity}`).then();
        }
    }, [selectedCity]);

    const isStateSelected = selectedState && selectedState !== "Select State";
    const isCitySelected = selectedCity && selectedCity !== "Select City";

    const saveDisableWithoutLocation: boolean = _.isEmpty(cohortName) || _.isNil(selectedUnitModel);
    const SaveDisableWithLocation: boolean = !isStateSelected || !isCitySelected || _.isNil(selectedModel);
    const generalSaveDisable: boolean = isAlreadyClicked || isWarningShown || isCohortNameBeingChecked;
    let isSaveDisabled: boolean;

    if (isIndependentOfLocation) {
        isSaveDisabled = generalSaveDisable || saveDisableWithoutLocation;
    } else {
        isSaveDisabled = generalSaveDisable || SaveDisableWithLocation;
    }

    const label = isAlreadyClicked ? "Waiting..." : "Save";

    const handleSave = async () => {
        let cohort;
        if (!isIndependentOfLocation) {
            const model = _.find(models, (m) => m.id == selectedModel.value);
            cohort = {
                name: `${selectedState}-${selectedCity}`,
                model_id: model.id,
                independent_of_location: 0,
                model: {id: model.id, name: model.name, version: model.version, status: model.status, type: model.type},
            } as BaseCohortDTO;
        } else {
            const model = _.find(models, (m) => m.id == selectedUnitModel.value);
            cohort = {
                name: cohortName.trim(),
                model_id: model.id,
                independent_of_location: 1,
                model: {id: model.id, name: model.name, version: model.version, status: model.status, type: model.type},
            } as BaseCohortDTO;
        }
        try {
            await RateService.createCohort(cohort);
            onSave(UiUtils.getSaveSuccessMessage());
        } catch (e) {
            LoggingService.error(`error creating cohort`, {cohort: cohort});
            onError(UiUtils.getErrorMessage(e));
            onClose();
        }
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowWarning(false);
        setSelectedCity(null);
        setSelectedState(event.target.value);
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowWarning(false);
        setIsCohortNameBeingChecked(true);
        setSelectedCity(event.target.value);
    };

    const checkCohortName = async (cohort) => {
        setShowWarning(false);
        if (!_.isEmpty(cohort) || !_.isEmpty(cohortName)) {
            const response = await RateService.validateCohortName(isIndependentOfLocation ? cohortName : cohort);
            if (response?.error) {
                setShowWarning(true);
                setWarningMessage(response?.error);
            }
            setIsCohortNameBeingChecked(false);
        }
    };

    const handleCohortNameChange = (event) => {
        const cohortName = event.target.value;
        const pattern = COHORT_NAME_PATTERN;
        if (pattern.test(cohortName.trim()) || _.isEmpty(cohortName)) {
            setCohortName(cohortName);
            setIsCohortNameBeingChecked(true);
        }
    };

    const handleIndependentOfLocationChange = () => {
        setIsIndependentOfLocation((prev) => !prev);
        setSelectedState(null);
        setSelectedCity(null);
        setCohortName("");
        setSelectedUnitModel(null);
        setSelectedModel(null);
        setShowWarning(false);
    };
    useDebounce(checkCohortName, delay, [cohortName.trim()]);

    return (
        <div className="cohort-creation-panel">
            <div className="cohort-creation-panel-title">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>Create new Model Cohort</span>
            </div>
            <div className={"checkbox-style"}>
                <FormControlLabel
                    label="New Strategic Cohort"
                    control={
                        <Checkbox
                            onChange={handleIndependentOfLocationChange}
                            size="medium"
                            className={"model-checkbox-box"}
                            value={isIndependentOfLocation}
                            checked={isIndependentOfLocation}
                            disabled={process.env.REACT_APP_DISABLE_STRATEGIC_COHORTS_CREATION === "true"}
                        />
                    }
                />
            </div>
            {!isIndependentOfLocation && (
                <div className="cohort-creation-panel-step">
                    <div className="cohort-creation-panel-value select-input">
                        {isWarningShown && <AlertMessage customClass="alert-message" text={warningMessage} type="warning" height="small" />}
                        <div>
                            <label className={"generic-label"} htmlFor="state-selector">
                                Cohort Name: {isStateSelected ? selectedState : "[state ID]"} - {isCitySelected ? selectedCity : "[city name]"}
                            </label>
                            <Select
                                key={"state-selector"}
                                disabled={statesOptions.length < 2}
                                customClass="options-select"
                                value={selectedState}
                                options={statesOptions}
                                onChange={handleStateChange}
                            />
                            <Select
                                disabled={citiesOptions.length < 2}
                                customClass="options-select"
                                value={selectedCity}
                                options={citiesOptions}
                                onChange={handleCityChange}
                            />
                        </div>

                        <div className={"components-separator"}>
                            <label className={"generic-label"} htmlFor="model">
                                Model:
                            </label>
                            <AutoCompleteSelect
                                key={"model"}
                                value={selectedModel}
                                options={modelOptions}
                                getOptionLabel={(item) => item.display}
                                onChange={(newValue) => {
                                    setSelectedModel(newValue);
                                }}
                                customClass={"auto-complete-select"}
                            />
                        </div>
                    </div>
                </div>
            )}
            {isIndependentOfLocation && (
                <div className="cohort-creation-panel-step">
                    <div className="cohort-creation-panel-value select-input">
                        {isWarningShown && <AlertMessage customClass="alert-message" text={warningMessage} type="warning" height="small" />}
                        <div>
                            <div style={{marginBottom: "8px"}}>
                                <label className={"generic-label"} htmlFor="cohortName">
                                    Cohort Name:
                                </label>
                            </div>

                            <TextField
                                variant={"outlined"}
                                fullWidth
                                id="cohortName"
                                className={"name-input"}
                                type="string"
                                value={cohortName}
                                onChange={handleCohortNameChange}
                            />
                        </div>
                        <div className={"components-separator"}>
                            <label className={"generic-label"} htmlFor="unitModel">
                                Model:
                            </label>
                            <AutoCompleteSelect
                                key={"unitModel"}
                                value={selectedUnitModel}
                                options={unitModelOptions}
                                getOptionLabel={(item) => item.display}
                                onChange={(newValue) => {
                                    setSelectedUnitModel(newValue);
                                }}
                                customClass={"auto-complete-select"}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="cohort-creation-panel-apply-button">
                <Button
                    variant="secondary"
                    onClick={() => {
                        setIsAlreadyClicked(true);
                        handleSave();
                    }}
                    disabled={isSaveDisabled}
                >
                    {label}
                </Button>
            </div>
            <div className="cohort-panel-step-separator" />
        </div>
    );
};
