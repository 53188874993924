import React, { useEffect, useState } from 'react';
import { Button, Icon } from '@vacasa/react-components-lib';
import './ManageUnitSidebar.scss';
import * as _ from 'lodash';
import { PillsList } from '..';

type ManageUnitSidebarProps = {
    units: number[];
    onApply: (units: number[]) => void;
    onClose: () => void;
};

export const ManageUnitSidebar: React.FC<ManageUnitSidebarProps> = (props) => {
    const { onClose, onApply, units } = props;

    const [unitIds, setUnitIds] = useState<string[]>([]);

    useEffect(() => {
        setUnitIds(units.map((u) => u.toString()));
    }, [units]);

    const handleDelete = (u, i) => {
        const updatedIds = [...unitIds];
        updatedIds.splice(i, 1);
        setUnitIds(updatedIds);
    };

    const handleAdd = (u: string) => {
        let uniqueIds: string[] = _.uniq([...unitIds, u]);
        uniqueIds = _.map(uniqueIds, (idString) => Number(idString))
            .filter((u) => _.isFinite(u))
            .map((id) => `${id}`);
        setUnitIds(uniqueIds);
    };

    const handlePaste = (content: string) => {
        const ids = content.split(',').map((u) => u.trim());
        const updatedIds = [...unitIds, ...ids];
        setUnitIds(updatedIds);
    };

    return (
        <div className="manage-unit-side-bar">
            <div className="manage-unit-side-bar-title">
                <Icon.XCircleInverse className="pointer" height={24} width={24} onClick={onClose} />
                <span>Add Unit</span>
            </div>

            <div className="manage-unit-input">
                <div className="manage-unit-input-label">
                    <span>Add Units IDs:</span>
                </div>
                <PillsList
                    className="manage-unit-pill-list"
                    onValueAdded={handleAdd}
                    onValueDeleted={handleDelete}
                    values={unitIds}
                    onPaste={handlePaste}
                    key={unitIds.join(',')}
                />
            </div>

            <div className="manage-unit-note">
                <Icon.AlertCircle height={18} width={18} onClick={onClose} />
                <span>Add units by pressing enter or comma. Only valid units Ids will be added.</span>
            </div>

            <div className="add-side-bar-apply-button">
                <Button
                    variant="secondary"
                    disabled={unitIds.map((u) => Number(u)).filter((u) => _.isFinite(u)).length === 0}
                    onClick={() => {
                        const validUnitsIds = unitIds.map((u) => Number(u)).filter((u) => _.isFinite(u));
                        onApply(validUnitsIds);
                    }}>
                    Apply
                </Button>
            </div>
        </div>
    );
};
