import React from 'react';
import { DateChange } from '../../types';
import * as _ from 'lodash';
import { RateUtils } from '../../utils';
import { parseDate } from '@common/utils';
import { differenceInDays, isEqual, startOfDay } from 'date-fns';
import { Icon } from '@vacasa/react-components-lib';
import './ChangeSummary.scss';

interface IntervalChangeSummaryProps {
    changes: DateChange[];
}

export const IntervalChangeSummary: React.FC<IntervalChangeSummaryProps> = (props) => {
    const { changes } = props;

    const intervalsByProperty: { [property: string]: { start: string; end: string } } = {};

    for (const change of changes) {
        const currDate = change.date;
        const properties = _.keys(change).filter((key) => key !== 'date');

        for (const property of properties) {
            const intervalForProperty = intervalsByProperty[property];

            if (!intervalForProperty) {
                intervalsByProperty[property] = { start: currDate, end: currDate };
                continue;
            }

            if (RateUtils.isBetweenDates(parseDate(currDate), parseDate(intervalForProperty.start), parseDate(intervalForProperty.end))) {
                continue;
            }

            const lowerDiff = differenceInDays(parseDate(currDate), parseDate(intervalForProperty.start));

            if (lowerDiff <= -1) {
                // currDate is before start, update lower
                intervalsByProperty[property] = { start: currDate, end: intervalForProperty.end };
                continue;
            }

            const higherDiff = differenceInDays(parseDate(currDate), parseDate(intervalForProperty.end));

            if (higherDiff >= 1) {
                // currDate is the day after end, updater higher
                intervalsByProperty[property] = { start: intervalForProperty.start, end: currDate };
                continue;
            }
        }
    }

    return (
        <div className="change-summary">
            {_.keys(intervalsByProperty).map((property) => {
                const interval = intervalsByProperty[property];
                const isOneDay = isEqual(startOfDay(parseDate(interval.start)), startOfDay(parseDate(interval.end)));
                return (
                    <div className="change-summary-item">
                        <div className="change-summary-item-icon">
                            <Icon.AlertCircle width={16} height={16} />
                        </div>
                        <span className="change-summary-item-text">
                            <strong>{property}</strong>
                            <span>
                                {' '}
                                {` updated ${isOneDay ? ` for ${interval.start}` : ` between ${interval.start} and ${interval.end}`}`}
                            </span>
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
